import { createSelector } from "reselect";
import language from "i18next";
import { profileBuilderCreateModule } from "../services";

const errorMessages = {
  missingField: () => language.t("Please select an item in order to continue"),
  missingLayerType: () =>
    language.t(
      "Layers are not configured. Please configure all the layers in order to continue"
    ),
};

export const getCurrentStep = createSelector(
  (state) => state.clusterprofile.create.steps,
  (state) => state.clusterprofile.create.stepId,
  (steps, stepId) => steps.findIndex((step) => step.id === stepId)
);

export const getSteps = createSelector(
  getCurrentStep,
  (state) => state.clusterprofile.create.steps,
  (state) => state.forms.clusterprofile?.data,
  (currentStep, steps, formData) => {
    const checkStepStatus = (index) => {
      if (index === currentStep) {
        return "process";
      }
      if (index < currentStep) {
        return "finish";
      }

      return "wait";
    };

    return steps
      .map((step, index) => {
        return {
          ...step,
          title: step.title(),
          status: checkStepStatus(index),
        };
      })
      .filter((step) => {
        if (formData?.profileType === "add-on" && step.id === "cloud-type") {
          return false;
        }

        return true;
      });
  }
);

export const getStepIndex = createSelector(
  getSteps,
  (state) => state.clusterprofile.create.stepId,
  (steps, stepId) => steps.findIndex((step) => step.id === stepId)
);

export const getFormError = createSelector(
  (state) => state.clusterprofile.create.stepId,
  (state) => state.forms.clusterprofile?.errors,
  () => profileBuilderCreateModule?.isProfileValid(),
  (stepId, errors, isProfileValid) => {
    let message = "";

    if (stepId === "profile-layers") {
      return !isProfileValid ? errorMessages.missingLayerType() : "";
    }

    if (!errors?.length) {
      return null;
    }

    if (stepId === "basic-info" || stepId === "cloud-type") {
      message = errorMessages.missingField();
    }

    return message;
  }
);

const RESET_STEPS = ["cloud-type"];
export const showResetFieldsWarning = createSelector(
  (state) => state.clusterprofile.create.steps,
  (state) => state.clusterprofile.create.stepId,
  (steps, stepId) => {
    const currentStep = steps.find((step) => step.id === stepId);
    if (!currentStep?.description) {
      return false;
    }
    return RESET_STEPS.includes(stepId);
  }
);
