import api from "services/api";
import dataFetcher from "modules/dataFetcher";
import { ClusterSchema, WorkspaceSchema } from "utils/schemas";
import ModalService from "services/modal";
import store from "services/store";

export const WORKSPACES_FORM_MODULE = "workspace";
export const WIZARD_MODULE = "workspace";
export const CLUSTER_RBAC_FORM_MODULE = "clusterRbac";
export const NAMESPACE_RBAC_FORM_MODULE = "namespaceRbac";

export const cancelCreateWorkspaceModal = new ModalService();
export const removeRoleConfirmModal = new ModalService();
export const clusterRoleBindingsModal = new ModalService("clusterRoleBindings");
export const namespaceRoleBindingsModal = new ModalService(
  "namespaceRoleBindings"
);

export const workspacesClustersMetadataFetcher = dataFetcher({
  selectors: ["workspaces", "spectroclusters", "metadata"],
  schema: [ClusterSchema],
  async fetchData() {
    const response = await api.post("v1/dashboard/spectroclusters/metadata", {
      filter: {
        state: "Running",
        isImportReadOnly: false,
      },
    });
    return (response?.items || []).map((item) => ({
      guid: item?.guid,
      metadata: { name: item.name, uid: item.uid },
      spec: item.spec || {},
      status: item.status || {},
    }));
  },
});

export const workspaceClustersFetcher = dataFetcher({
  selectors: ["workspace", "clusters"],
  schema: [ClusterSchema],
  async fetchData(_) {
    const params = store.getState().location.params;
    const response = await api.get(`v1/workspaces/${params.id}`);

    return response?.spec?.clusterRefs || [];
  },
});

export const workspaceClustersAndNamespacesFetcher = dataFetcher({
  selectors: ["workspace", "clusters", "namespaces"],
  schema: WorkspaceSchema,
  async fetchData(_) {
    const params = store.getState().location.params;
    const response = await api.get(`v1/workspaces/${params.id}`);

    return {
      clusters: response?.spec?.clusterRefs || [],
      namespaces: response?.spec?.clusterNamespaces || [],
    };
  },
});
