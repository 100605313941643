import i18next from "i18next";
import createFormActions from "modules/form/actions";
import { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { ApplyIf, Missing } from "services/validator/rules";
import { getCurrentUser } from "state/auth/selectors";
import { ssoAuthProvidersFetcher } from "./services";

const validator = new Validator();
validator.addRule(
  ["ssoLogins"],
  ApplyIf(
    (value, key, data) =>
      data.isEnabled && (data?.ssoLogins || []).length === 0,
    Missing()
  )
);

export function onSsoLoginEnable() {
  return (dispatch) => {
    dispatch(
      providersFormActions.validateForm({
        module: "providers",
      })
    );
  };
}

export const providersFormActions = createFormActions({
  validator,
  init: async () => {
    const currentUser = getCurrentUser(store.getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

    store.dispatch(ssoAuthProvidersFetcher.fetch());
    const data = await nonProjectApi.get(
      `v1/tenants/${tenantUid}/sso/auth/providers`
    );

    return Promise.resolve({
      isEnabled: !!data?.isEnabled,
      ssoLogins: data?.ssoLogins || [],
    });
  },
  submit: async (data) => {
    const currentUser = getCurrentUser(store.getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

    try {
      await nonProjectApi.post(`v1/tenants/${tenantUid}/sso/auth/providers`, {
        isEnabled: data.isEnabled,
        ssoLogins: data.ssoLogins,
      });

      notifications.success({
        message: i18next.t(
          "The authentication providers have been set successfully"
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
    }
  },
});
