import styled from "styled-components";
import { Input } from "antd";

export default styled(Input.Search)`
  height: 45px;

  > .ant-input:not(:last-child) {
    padding-right: 0;
    padding-left: 35px;
  }

  > .ant-input-suffix {
    right: 0;
    left: 12px;
    width: 16px;
    font-size: 16px;
  }
`;
