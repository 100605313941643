import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";

import { TeamSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import createList, { Blocks } from "modules/list";

import {
  teamListActions,
  setSelectedTeam,
  openTeamFormModal,
  teamDeleteService,
  onTeamRemove,
} from "state/teams/actions";
import { getSelectedTeam } from "state/roleManagement/selectors";

import TeamInformation from "pages/management/components/TeamInformation";
import Enumeration from "components/common/Enumeration";
import Confirm from "components/common/ConfirmationModal";

const EditIcon = styled(RightOutlined)`
  margin-right: -14px;
`;

const TeamWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const ListWrap = styled.div`
  flex-grow: 1;

  .ant-table-row {
    td:first-child {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const ListModule = createList({
  actions: teamListActions,
  schema: [TeamSchema],
});

const TeamInformationRender = ({
  selectedTeam,
  openTeamFormModal,
  onTeamRemove,
}) => {
  if (!selectedTeam) {
    return null;
  }

  return (
    <TeamInformation
      team={selectedTeam}
      openTeamFormModal={openTeamFormModal}
      onTeamRemove={onTeamRemove}
    />
  );
};

const ConnectedTeamInformation = connect(
  (state) => ({ selectedTeam: getSelectedTeam(state) }),
  {
    openTeamFormModal,
    onTeamRemove,
  }
)(TeamInformationRender);

const Teams = ({ setSelectedTeam, entityToBeDeleted }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Name"),
      dataIndex: ["metadata", "name"],
      key: "name",
    },
    {
      title: t("Members"),
      dataIndex: ["spec", "users"],
      render: function renderTeams(users) {
        return <span>{users.length || "-"}</span>;
      },
      key: "users",
    },
    {
      title: t("Roles"),
      dataIndex: ["spec", "roles"],
      render: function renderRoles(roles = []) {
        const rolesNames = roles.map((role) => role.metadata.name);
        return <Enumeration items={rolesNames} />;
      },
      key: "roles",
    },
    {
      title: t("Projects"),
      dataIndex: ["spec", "projects"],
      render: function renderRoles(projects = []) {
        return <span>{projects.length}</span>;
      },
      key: "projects",
    },
    {
      render: function renderEditIcon(data) {
        return <EditIcon />;
      },
    },
  ];

  return (
    <ListModule module="teams">
      <TeamWrap>
        <ListWrap>
          <Blocks.Table columns={columns} onRowClick={setSelectedTeam} />
        </ListWrap>

        <ConnectedTeamInformation />
        <Confirm type="error" service={teamDeleteService}>
          <Confirm.Question>
            {t(`Are you sure you want to delete "{{team}}"?`, {
              team: `${entityToBeDeleted?.metadata?.name}`,
            })}
          </Confirm.Question>
        </Confirm>
      </TeamWrap>
      <Blocks.LoadAsYouScroll />
    </ListModule>
  );
};

const getDeletedEntity = getEntity(
  () => teamDeleteService.data?.guid,
  TeamSchema
);

export default connect(
  (state) => ({
    selectedTeam: getSelectedTeam(state),
    entityToBeDeleted: getDeletedEntity(state),
  }),
  {
    setSelectedTeam,
    openTeamFormModal,
  }
)(Teams);
