import i18n from "i18next";

import ListActions from "modules/list/actions";
import { OverlordSchema } from "utils/schemas";
import api from "services/api";
import notifications from "services/notifications";

import {
  deleteOverlordConfirmation,
  OVERLORD_MODULE,
  resetOverlordConfirmation,
} from "../services/list";
import { getStoreEntity } from "services/store";
import { overlordsFiltersCache } from "services/localstorage/cache";
import { parseFilterGroup } from "utils/parsers";

export function mapOverlordsQuery({ filterGroups = [] } = {}) {
  const filters = filterGroups.map((group) => ({
    conjunction: group.conjunction,
    filters: group.conditions.map((condition) => parseFilterGroup(condition)),
  }));

  return {
    filter: {
      conjunction: "and",
      filterGroups: filters,
    },
  };
}

export const overlordsListActions = new ListActions({
  defaultQuery: {
    filterGroups: [],
    limit: 50,
  },
  schema: [OverlordSchema],
  initialQuery: () => {
    const filters = overlordsFiltersCache.get("pcgFilters") || [];
    return {
      filterGroups: filters,
    };
  },
  async fetchData(query) {
    const payload = mapOverlordsQuery(query);

    const response = await api.post(
      "v1/dashboard/pcgs/search",
      {
        ...payload,
      },
      {
        params: { continue: query.continue },
      }
    );

    const overlords = (response?.items || []).map((overlord, index) => ({
      ...overlord,
      index,
      status: {
        ...overlord.status,
        state: overlord.status.state.toLowerCase(),
      },
    }));

    return Promise.resolve({
      ...response,
      items: overlords,
    });
  },
});

export function resetOverlord(guid) {
  return (dispatch) => {
    resetOverlordConfirmation.open({ guid }).then(async () => {
      const overlord = getStoreEntity(
        resetOverlordConfirmation.data.guid,
        OverlordSchema
      );

      const promise = api.put(`v1/overlords/${overlord.metadata.uid}/reset`);
      dispatch({
        type: "OVERLORD_RESET",
        guid,
        promise,
      });

      try {
        const { msg } = await promise;
        notifications.success({
          message: i18n.t("Private cloud gateway reset successfully"),
          description: msg,
        });
      } catch (err) {
        notifications.error({
          message: i18n.t(
            "Something went wrong while resetting the private cloud gateway"
          ),
          description: err?.message,
        });
      }
    });
  };
}

export function deleteOverlord(guid) {
  return (dispatch) => {
    deleteOverlordConfirmation.open({ guid }).then(async () => {
      const overlord = getStoreEntity(
        deleteOverlordConfirmation.data.guid,
        OverlordSchema
      );

      const promise = api.delete(`v1/overlords/${overlord.metadata.uid}`);

      dispatch({
        type: "DELETE_OVERLORD",
        promise,
        guid,
      });

      try {
        const { msg } = await promise;
        dispatch(overlordsListActions.initialize(OVERLORD_MODULE));
        notifications.success({
          message: i18n.t(
            'Private cloud gateway "{{overlordName}}" has been deleted successfully',
            { overlordName: overlord.metadata.name || "(unnamed)" }
          ),
          description: msg,
        });
      } catch (error) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying to delete the private cloud gateway"
          ),
          description: error.message,
        });
      }
    });
  };
}
