import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Fields from "components/ui/Fields";
import Button from "components/ui/Button";
import { createForm } from "modules/form";
import store, { getStoreEntity } from "services/store";
import { UserSchema } from "utils/schemas";
import { nonProjectApi } from "services/api";
import createActions from "modules/form/actions";
import notifications from "services/notifications";
import i18next from "i18next";
import { fetchCurrentUser, redirectToDefaultRoute } from "state/auth/actions";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import Permissions from "components/ui/Permissions";
import Title from "components/common/Title";
import PageHeader from "components/common/layouts/App/PageHeader";
import { CenterPageWrap } from "components/styled/mixins";

const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 428px;
  > div {
    max-width: 210px;
    margin-top: 0;
  }
`;

const accountDetailsValidator = new Validator();
const addressValidator = new Validator();

accountDetailsValidator.addRule(["firstName", "lastName"], Missing());
addressValidator.addRule(
  ["addressLine1", "addressLine2", "city", "state", "country", "pincode"],
  Missing()
);

const accountFormActions = createActions({
  async init() {
    const currentUser = getStoreEntity(store.getState().auth.me, UserSchema);
    const userData = await nonProjectApi.get(
      `v1/users/${currentUser.metadata.uid}`
    );

    return {
      emailId: userData.spec.emailId,
      firstName: userData.spec.firstName,
      lastName: userData.spec.lastName,
      roles: userData.spec.roles,
    };
  },
  async submit(data) {
    const currentUser = getStoreEntity(store.getState().auth.me, UserSchema);

    const payload = {
      metadata: {
        uid: currentUser.metadata.uid,
      },
      spec: {
        firstName: data.firstName,
        lastName: data.lastName,
        emailId: data.emailId,
        roles: data.roles,
      },
    };

    try {
      await nonProjectApi.put(`v1/users/${currentUser.metadata.uid}`, payload);

      notifications.success({
        message: i18next.t("Your profile has been updated successfully"),
      });

      store.dispatch(fetchCurrentUser());
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while updating your profile"),
        description: err.message,
      });
    }
  },
  validator: accountDetailsValidator,
});

const tenantAddressActions = createActions({
  async init() {
    const currentUser = getStoreEntity(store.getState().auth.me, UserSchema);
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

    const userData = await nonProjectApi.get(`v1/tenants/${tenantUid}`);

    const address = userData.spec?.address || {};

    return {
      addressLine1: address.addressLine1 || "",
      addressLine2: address.addressLine2 || "",
      city: address.city || "",
      state: address.state || "",
      country: address.country || "",
      pincode: address.pincode || "",
    };
  },
  async submit(data) {
    const currentUser = getStoreEntity(store.getState().auth.me, UserSchema);
    const payload = {
      address: {
        addressLine1: data.addressLine1 || "",
        addressLine2: data.addressLine2 || "",
        city: data.city || "",
        state: data.state || "",
        country: data.country || "",
        pincode: data.pincode || "",
      },
    };
    try {
      await nonProjectApi.patch(
        `v1/tenants/${currentUser.metadata.uid}/address`,
        payload
      );
      notifications.success({
        message: i18next.t("Your address has been updated successfully"),
      });
      store.dispatch(fetchCurrentUser());
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while updating your address"),
        description: err.message,
      });
    }
  },
  validator: addressValidator,
});

function AccountSettings({ onSubmit, isSubmitDisabled, submitting }) {
  const { t } = useTranslation();
  return (
    <>
      <Fields.Input name="firstName" data-qa="firstName" label="First Name" />
      <Fields.Input name="lastName" data-qa="lastName" label="Last Name" />
      <Fields.Input
        name="emailId"
        disabled={true}
        data-qa="emailId"
        autoComplete="username"
        label="Email"
      />
      <Button
        data-qa="submit"
        onClick={onSubmit}
        disabled={isSubmitDisabled}
        loading={submitting}
      >
        {t("Update account")}
      </Button>
    </>
  );
}

function TenantAddress({ onSubmit, isSubmitDisabled, submitting }) {
  const { t } = useTranslation();
  return (
    <>
      <Fields.Input
        name="addressLine1"
        data-qa="addressLine1"
        label="Address Line 1"
      />
      <Fields.Input
        name="addressLine2"
        data-qa="addressLine2"
        label="Address Line 1"
      />
      <SideBySide>
        <Fields.Input name="city" data-qa="city" label="City" />
        <Fields.Input name="state" data-qa="state" label="State" />
      </SideBySide>
      <SideBySide>
        <Fields.Input name="country" data-qa="country" label="Country" />
        <Fields.Input name="pincode" data-qa="pincode" label="Zip Code" />
      </SideBySide>

      <Button
        data-qa="submit"
        onClick={onSubmit}
        disabled={isSubmitDisabled}
        loading={submitting}
      >
        {t("Update address")}
      </Button>
    </>
  );
}

const TenantAddressForm = createForm({
  Component: TenantAddress,
  actions: tenantAddressActions,
});

function PasswordChange({
  onSubmit,
  onFieldChange,
  data,
  isSubmitDisabled,
  submitting,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Fields.PasswordField
        name="currentPassword"
        data-qa="currentPassword"
        label="Current Password"
        allowAutofill={false}
        displayEyeIcon={true}
      />
      <Fields.PasswordField
        name="newPassword"
        data-qa="newPassword"
        label="New Password"
        autoComplete="new-password"
        displayEyeIcon={true}
        onChange={(value) => {
          onFieldChange("newPassword", value);
          onFieldChange("confirmPassword", data.confirmPassword);
        }}
      />
      <Fields.PasswordField
        name="confirmPassword"
        data-qa="confirmPassword"
        label="Confirm Password"
        autoComplete="new-password"
        displayEyeIcon={true}
      />
      <Button
        data-qa="submit"
        onClick={onSubmit}
        disabled={isSubmitDisabled}
        loading={submitting}
      >
        {t("Update password")}
      </Button>
    </>
  );
}

const AccountForm = createForm({
  Component: AccountSettings,
  actions: accountFormActions,
});

const passwordValidator = new Validator();
passwordValidator.addRule(
  ["newPassword", "confirmPassword", "currentPassword"],
  Missing()
);
passwordValidator.addRule("confirmPassword", (value, key, data) => {
  if (value !== data.newPassword) {
    return i18next.t("Please make sure your passwords match");
  }
});
passwordValidator.addRule("newPassword", (value, key, data) => {
  if (value === data.currentPassword) {
    return i18next.t("New password must be different from your current one");
  }
});

const passwordFormActions = createActions({
  init() {
    return Promise.resolve({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  },
  async submit(data) {
    const currentUser = getStoreEntity(store.getState().auth.me, UserSchema);

    try {
      await nonProjectApi.patch(
        `v1/users/${currentUser.metadata.uid}/password/change`,
        {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        }
      );
      notifications.success({
        message: i18next.t("Your password has been updated successfully"),
      });
      store.dispatch(redirectToDefaultRoute());
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while updating your password"),
        description: err.message,
      });
    }
  },
  validator: passwordValidator,
});

const PasswordForm = createForm({
  Component: PasswordChange,
  actions: passwordFormActions,
});

const Wrap = styled(CenterPageWrap)`
  background-color: white;
  padding: 16px 24px;
`;

const Content = styled.div`
  display: flex;
`;

const Col = styled.div`
  flex-grow: 1;

  h2 {
    margin: 24px 0 22px;
  }
`;

export default function MyProfile() {
  const { t } = useTranslation();
  return (
    <Wrap>
      <Title>{t("My Profile")}</Title>
      <PageHeader title={t("My Profile")} withBack={false} />
      <Content>
        <Col>
          <h2>{t("Account information")}</h2>
          <AccountForm module="my-profile" />

          <Permissions isAdmin>
            <h2>{t("Update tenant address")}</h2>
            <TenantAddressForm module="update-tenant-address" />
          </Permissions>
        </Col>
        <Col>
          <h2>{t("Change password")}</h2>
          <PasswordForm module="passwordChange" />
        </Col>
      </Content>
    </Wrap>
  );
}
