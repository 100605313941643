import React from "react";
import styled from "styled-components";
import history from "services/history";
import { useTranslation } from "react-i18next";
import noProjectsPath from "assets/images/login/no_projects.png";
import FullPageOverlay from "components/common/layouts/Page";
import { white } from "utils/constants/colors";
import Icon from "components/ui/Icon";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ContentWrap = styled.div`
  height: 504px;
  width: 1088px;
  background: ${white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const NoProjectsContent = styled.div`
  padding: 30px;
  position: relative;

  h1 {
    width: 55%;
    font-size: 30px;
    line-height: 40px;
    color: rgb(77, 74, 190);
    font-weight: 500;
    margin: 4px 0;
  }

  h2 {
    width: 55%;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
  }

  p {
    width: 48%;
    font-size: 16px;
    line-height: 26px;
    margin: 20px 0;
  }

  svg {
    margin-right: 10px;
  }
`;

const Actions = styled.div`
  margin-bottom: 20px;
  display: inline-flex;
  flex-direction: column;

  a {
    color: rgb(0, 0, 0);
    padding: 6px 8px;
  }

  a:hover {
    color: rgb(77, 74, 190);
    background-color: rgb(232, 235, 238);
  }
`;

const Environment = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
`;

export default function Error() {
  const { t } = useTranslation();
  const { message, code } = history?.getQuery();

  return (
    <FullPageOverlay>
      <ContentWrap>
        <Environment src={noProjectsPath} alt="error" />
        <NoProjectsContent>
          <Actions>
            <a href="https://console.spectrocloud.com/auth">
              <Icon awesome={faLongArrowLeft} />
              {t("Try another account")}
            </a>
            <a href="/auth?forcePassword=true">
              <Icon awesome={faLongArrowLeft} />
              {t("SSO issues?")} (<strong>{t("Use your password")}</strong>)
            </a>
          </Actions>
          {code !== "UserSignUpForbidden" && (
            <h1>{code !== "undefined" ? code : t("Ooops")}</h1>
          )}
          <h2>
            {message !== "undefined" ? message : t("An error has occurred")}
          </h2>
          <p>
            {t("Our experts can get you up and running, click ")}
            <a href="https://www.spectrocloud.com/contact">{t("here")}</a>
            {t(" to send them a message")}
          </p>
        </NoProjectsContent>
      </ContentWrap>
    </FullPageOverlay>
  );
}
