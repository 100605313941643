import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import Modal from "components/ui/Modal";
import Button from "components/ui/Button";

import { appRefreshModalService } from "state/auth/services";
import { getCurrentUser } from "state/auth/selectors";
import {
  fetchCurrentUser,
  openAppRefreshModalService,
} from "state/auth/actions";
import history from "services/history";
import { AUTH } from "utils/constants/routes";

function compareUserData(previousUser, currentUser) {
  const tenantUidPath = "status.tenant.tenantUid";
  const userUidPath = "metadata.uid";

  const current = {
    userUid: get(currentUser, userUidPath),
    tenantUid: get(currentUser, tenantUidPath),
  };
  const previous = {
    userUid: get(previousUser, userUidPath),
    tenantUid: get(previousUser, tenantUidPath),
  };

  return !isEqual(current, previous);
}

function useStaleLoginRevalidation() {
  const dispatch = useDispatch();
  const previousUser = useSelector(getCurrentUser);
  const pathname = history.location.pathname;

  useEffect(() => {
    const isAuthPath = pathname.startsWith(AUTH.ROOT);

    async function handleFocus() {
      let shouldRevalidate = false;

      if (!isAuthPath) {
        const newUser = await dispatch(fetchCurrentUser());
        shouldRevalidate = compareUserData(previousUser, newUser);
      }

      if (shouldRevalidate) {
        dispatch(openAppRefreshModalService());
      }
    }

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [dispatch, previousUser, pathname]);
}

export default function AppRefreshModal() {
  const { t } = useTranslation();

  useStaleLoginRevalidation();

  return (
    <Modal
      closable={false}
      service={appRefreshModalService}
      footer={
        <Button
          onClick={() => (window.location.href = "/")}
          data-qa="refresh-page"
        >
          Refresh
        </Button>
      }
    >
      {t(
        "You have logged in with a different account, please refresh the page in order to access Palette."
      )}
    </Modal>
  );
}
