import { v4 as uuid } from "uuid";

import api from "services/api";
import dataFetcher from "modules/dataFetcher";
import { isValidRegex } from "utils/strings";

import { removeRoleConfirmationModal } from "components/common/RoleBindings/Table";

export function createRoleBindingsFormFactory({
  formActions,
  formModuleName,
  getClusterUid,
}) {
  const module = formModuleName;

  function onChangeRoleBindings({ index, name, data }) {
    return (dispatch, getState) => {
      const items = getState().forms?.[formModuleName]?.data[name] || [];
      const updatedRoleBinding = [...items];
      updatedRoleBinding.splice(index, 1, data);

      dispatch(
        formActions.onChange({
          module: formModuleName,
          name,
          value: index >= 0 ? updatedRoleBinding : [...items, data],
        })
      );
    };
  }

  function removeRoleBinding({ index, name, field }) {
    return (dispatch, getState) => {
      const roleBindings = getState().forms[formModuleName]?.data[field] || [];
      removeRoleConfirmationModal.open({ name }).then(() =>
        dispatch(
          formActions.onChange({
            module: formModuleName,
            name: field,
            value: roleBindings.filter((_, i) => i !== index),
          })
        )
      );
    };
  }

  const clusterRbacsFetcher = dataFetcher({
    selectors: ["clusterRoleBindings", getClusterUid],
    async fetchData([_, clusterUid]) {
      const response = await api.get(
        `v1/spectroclusters/${clusterUid}/config/rbacs`
      );
      return response?.items || [];
    },
  });

  function addNamespace() {
    return async (dispatch, getState) => {
      const { namespaces, namespace } = getState().forms[module]?.data || {};
      const guid = uuid();
      const errors = await dispatch(
        formActions.validateField({
          name: "namespace",
          module,
        })
      );

      if (errors?.find((error) => error.field === "namespace")) {
        return;
      }

      dispatch(
        formActions.batchChange({
          module,
          updates: {
            namespace: "",
            namespaces: [
              ...(namespaces || []),
              {
                guid,
                namespaceName: namespace,
                isRegex: isValidRegex(namespace),
                alocMemory: "",
                alocCpu: "",
              },
            ].sort((ns1, ns2) => {
              return ns1.isRegex - ns2.isRegex;
            }),
          },
        })
      );

      dispatch(
        formActions.validateField({
          name: "namespaces",
          module,
        })
      );
    };
  }

  function deleteNamespace(guid) {
    return (dispatch, getState) => {
      const { data, errors } = getState().forms[module] || {};
      const namespaceName = data.namespaces.find(
        (ns) => ns.guid === guid
      )?.namespaceName;
      const updatedErrors = errors.map((error) => {
        if (error.field.includes("namespaces")) {
          return { ...error, result: false };
        }
        return error;
      });

      dispatch(
        formActions.batchChange({
          module,
          updates: {
            namespaces: (data.namespaces || []).filter(
              (ns) => ns.guid !== guid
            ),
            roleBindings: (data.roleBindings || []).filter(
              (rb) => rb.namespace !== namespaceName
            ),
          },
        })
      );

      dispatch(
        formActions.updateErrors({
          module,
          errors: updatedErrors,
        })
      );
    };
  }

  function onChangeNamespaces(name, value) {
    return (dispatch, getState) => {
      const parentIndex = name.split(".")[1];
      const resourceName = name.split(".")[2];
      const childIndex = name.split(".")[3];

      dispatch(formActions.onChange({ module, name, value }));

      // This means we are modifying the parent(default) namespace resource
      if (!childIndex && parseFloat(value) >= 0) {
        const clusters = getState().forms[module].data?.clusters || [];

        dispatch(
          formActions.batchChange({
            module,
            updates: clusters.reduce(
              (acc, _, index) => ({
                ...acc,
                [`namespaces.${parentIndex}.children.${index}.${resourceName}`]:
                  value,
              }),
              {}
            ),
          })
        );
      }

      dispatch(formActions.onChange({ module, name, value }));

      dispatch(
        formActions.validateField({
          name: "namespaces",
          module,
        })
      );
    };
  }

  function onWorkspaceQuotaChange(name, value) {
    return (dispatch) => {
      dispatch(
        formActions.onChange({
          name,
          value,
          module,
        })
      );

      dispatch(
        formActions.validateField({
          name: "namespaces",
          module,
        })
      );
    };
  }

  return {
    actions: {
      onChangeRoleBindings,
      removeRoleBinding,
      addNamespace,
      deleteNamespace,
      onChangeNamespaces,
      onWorkspaceQuotaChange,
    },
    fetchers: {
      clusterRbacsFetcher,
    },
  };
}
