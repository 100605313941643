import moment from "moment";

import store from "services/store";
import createFormActions from "modules/form/actions";
import {
  workspacesCostsFetcher,
  workspacesUsageFetcher,
  USAGE_COST_FILTERS,
  workspacesFetcher,
} from "../services/usagecost";
import { getSelectedKey } from "../selectors/usagecost";

export const workspacesFilterFormActions = createFormActions({
  init: () => {
    const { query, filter, consumptionType, filterBy } =
      store.getState().forms[USAGE_COST_FILTERS]?.data || {};

    return Promise.resolve({
      query: {
        startTime: query?.startTime,
        endTime: query?.endTime,
      },
      filter: {
        value: filter?.value || 7,
        unit: filter?.unit || "days",
        type: filter?.type || "period",
      },
      consumptionType: consumptionType || "cpu",
      filterBy: filterBy || [],
    });
  },
});

function refreshWorkspacesCharts() {
  return (dispatch, getState) => {
    const state = getState();
    const timePeriod = getSelectedKey(state);
    const selectedMonth =
      state.forms?.[USAGE_COST_FILTERS]?.data?.query?.endTime;

    if (timePeriod === "calendar") {
      dispatch(calendarChange(selectedMonth));
    } else {
      dispatch(timePeriodChange(timePeriod));
    }
  };
}

export function initWorkspacesUsageCost() {
  return async (dispatch) => {
    await dispatch(
      workspacesFilterFormActions.init({ module: USAGE_COST_FILTERS })
    );
    dispatch(workspacesFetcher.fetch());
    dispatch(refreshWorkspacesCharts());
  };
}

export function timePeriodChange(timePeriod) {
  return (dispatch) => {
    const [value, unit] = timePeriod.split(" ");
    const query = {
      startTime: moment().subtract(value - 1, unit),
      endTime: moment(),
    };

    dispatch(
      workspacesFilterFormActions.batchChange({
        module: USAGE_COST_FILTERS,
        updates: {
          query,
          filter: { value, unit, type: "period" },
        },
      })
    );

    dispatch(workspacesCostsFetcher.fetch(query));
    dispatch(workspacesUsageFetcher.fetch(query));
  };
}

export function calendarChange(date = moment()) {
  return (dispatch) => {
    const startOfMonth = moment(date).startOf("month");
    const endOfMonth = moment(date).endOf("month");

    const query = {
      startTime: startOfMonth,
      endTime: endOfMonth,
    };

    dispatch(
      workspacesFilterFormActions.batchChange({
        module: USAGE_COST_FILTERS,
        updates: {
          query,
          filter: {
            value: moment(date).daysInMonth(),
            unit: "days",
            type: "calendar",
          },
        },
      })
    );

    dispatch(workspacesCostsFetcher.fetch(query));
    dispatch(workspacesUsageFetcher.fetch(query));
  };
}

export function onWorkspaceSelect(name, value) {
  return (dispatch) => {
    dispatch(
      workspacesFilterFormActions.onChange({
        module: USAGE_COST_FILTERS,
        name,
        value,
      })
    );
  };
}

export function onUsageFilterChange(filter) {
  return async (dispatch) => {
    dispatch(
      workspacesFilterFormActions.onChange({
        module: USAGE_COST_FILTERS,
        name: "consumptionType",
        value: filter,
      })
    );
  };
}
