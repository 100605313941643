import React from "react";
import { useTranslation } from "react-i18next";

import Fields from "components/ui/Fields";
import { WorkspaceRoleSelector } from "components/common/ScopedRolesSelector";

import { createSelectComponent } from "modules/dataFetcher";
import { projectWorkspacesFetcher } from "state/roleManagement/services";

const WorkspaceSelector = createSelectComponent({
  parseOptions(workspaces) {
    return (
      workspaces?.map((workspace) => ({
        label: workspace.metadata.name,
        value: workspace.metadata.uid,
      })) || []
    );
  },
  dataFetcher: projectWorkspacesFetcher,
});

function Form({ data, projects, selectedWorkspaceRoleId, onProjectChange }) {
  const { t } = useTranslation();

  return (
    <>
      <Fields.Select
        label={t("Project")}
        options={projects}
        name="projectUid"
        data-qa="select_project"
        onChange={onProjectChange}
        disabled={selectedWorkspaceRoleId}
      />
      <WorkspaceSelector
        label={t("Workspaces")}
        name="workspaces"
        data-qa="select_workspaces"
        mode="multiple"
        disabled={selectedWorkspaceRoleId || !projects.length}
      />
      <WorkspaceRoleSelector name="roles" value={data.roles} />
    </>
  );
}

export default Form;
