import { createSelector } from "reselect";
import {
  packRegistryFetcher,
  helmRegistryFetcher,
  ociRepositoryFetcher,
} from "./services";

export const getSelectedPackRegistry = createSelector(
  packRegistryFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedHelmRegistry = createSelector(
  helmRegistryFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedOCIRegistry = createSelector(
  ociRepositoryFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);
