import ListActions from "modules/list/actions";
import api from "services/api";
import ModalService from "services/modal";
import { FilterSchema } from "utils/schemas";

export const FILTERS_LISTING_MODULE = "filters";
export const FILTER_FORM_MODULE = "newFilter";

export const filterFormModal = new ModalService("filterForm");
export const deleteFilterModal = new ModalService("deleteFilter");

export const filtersListingActions = new ListActions({
  fetchData: async () => {
    const res = await api.get("v1/filters");

    return res;
  },
  schema: [FilterSchema],
});
