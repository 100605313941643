import React from "react";

import createNamespace from "services/language";

import ManagementLayout from "./components/ManagementLayout";
import Route from "components/common/History/Route";
import { MANAGEMENT } from "utils/constants/routes";

const CreateUser = React.lazy(() => import("./users/create"));
const CreateTeam = React.lazy(() => import("./teams/create"));

const namespace = "management";

export default createNamespace({
  namespace,
  Component: function Main() {
    return (
      <>
        <Route adminOnly path={MANAGEMENT.ROOT} component={ManagementLayout} />
        <Route adminOnly path={MANAGEMENT.CREATE_USER} component={CreateUser} />
        <Route adminOnly path={MANAGEMENT.CREATE_TEAM} component={CreateTeam} />
        <Route adminOnly path={MANAGEMENT.EDIT_TEAM} component={CreateTeam} />
      </>
    );
  },
});
