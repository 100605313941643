export const initialState = {
  isLoading: false,
};

export function SUBSCRIBE_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function SUBSCRIBE_SUCCESS(nextState) {
  nextState.isLoading = false;
}

export function SUBSCRIBE_FAILURE(nextState) {
  nextState.isLoading = false;
}
