export const initialState = {
  filters: {
    query: null,
    selectedKey: "7 days",
    consumptionFilter: "cpu",
  },
};

export function PROJECT_OVERVIEW_TIME_PERIOD_CHANGE(
  nextState,
  { query, selectedKey }
) {
  nextState.filters.selectedKey = selectedKey;
  nextState.filters.query = query;
}

export function PROJECT_OVERVIEW_CONSUMPTION_FILTER_CHANGE(
  nextState,
  { filter }
) {
  nextState.filters.consumptionFilter = filter;
}
