import dataFetcher from "modules/dataFetcher";
import { nonProjectApi } from "services/api";
import ModalService from "services/modal";

export const EMAIL_ALERTS_MODULE = "emailAlerts";
export const WEBHOOK_ALERTS_MODULE = "webhookAlerts";

export const alertTypesFetcher = dataFetcher({
  selectors: ["projects", "alerts"],
  fetchData() {
    return nonProjectApi.get("v1/projects/alerts");
  },
});

export const webhookAlertModal = new ModalService("webhookAlert");
export const deleteWebhookAlertModal = new ModalService("deleteWebhookAlert");
