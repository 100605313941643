import {
  adminGOIDsFetcher,
  cloudAccountsFetcher,
  getSelectedCloudAccount,
  privateDnsZonesFetcher,
  regionsFetcher,
  resourceGroupsFetcher,
  storageAccountsFetcher,
  storageContainersFetcher,
  subscriptionsFetcher,
  virtualNetworksFetcher,
} from "state/cluster/services/create";
import clusterFormActions from "../form";
import notifications from "services/notifications";

export function selectNetworkResourceGroup(vnetResourceGroup) {
  return (dispatch, getState) => {
    const hasDisablePropertiesRequest = getSelectedCloudAccount(getState())
      ?.spec?.settings?.disablePropertiesRequest;

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vnetResourceGroup,
          vnetName: "",
          vnetCidrBlock: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
          privateDNSName: "",
        },
      })
    );

    if (!hasDisablePropertiesRequest) {
      dispatch(virtualNetworksFetcher.fetch());
      dispatch(privateDnsZonesFetcher.fetch());
    }
  };
}

export function selectVirtualNetwork(vnetName) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vnetName,
          vnetCidrBlock: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );
  };
}

export function selectSubscription(subscriptionId) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          subscriptionId,
          region: "",
          resourceGroup: "",
          vnetResourceGroup: "",
          vnetName: "",
          vnetCidrBlock: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );

    dispatch(regionsFetcher.fetch());
  };
}

function onChangeStaticPlacement(value) {
  return (dispatch) => {
    if (!value) {
      dispatch(
        clusterFormActions.batchChange({
          updates: {
            vnetResourceGroup: undefined,
            vnetName: undefined,
            vnetCidrBlock: undefined,
            controlPlaneSubnet: undefined,
            workerSubnet: undefined,
          },
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "vnetResourceGroup",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "vnetName",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "vnetCidrBlock",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "controlPlaneSubnet",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "workerSubnet",
          module: "cluster",
        })
      );

      dispatch(
        clusterFormActions.clearFieldErrors({
          field: "apiServerLBStaticIP",
          module: "cluster",
        })
      );
    }

    dispatch(
      clusterFormActions.onChange({
        name: "privateDNSName",
        value: "",
        module: "cluster",
      })
    );
  };
}

export function onControlPlaneSubnetChange(value) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "controlPlaneSubnet",
        value,
      })
    );

    dispatch(
      clusterFormActions.clearFieldErrors({
        field: "apiServerLBStaticIP",
        module: "cluster",
      })
    );
  };
}

function selectCredentialEffect() {
  return async (dispatch, getState) => {
    const cloudAccounts = cloudAccountsFetcher.selector(getState())?.result;
    const credential = getState().forms?.cluster?.data?.credential;
    const selectedCloudAccount = cloudAccounts?.find(
      (ca) => ca.metadata.uid === credential
    );

    const hasTenantName = selectedCloudAccount?.spec?.tenantName;

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          region: "",
          storageAccountName: "",
          containerName: "",
          subscriptionId: "",
          vnetResourceGroup: "",
          vnetName: "",
          vnetCidrBlock: "",
          resourceGroup: "",
          hasTenantName,
          managedAD: true,
          adminGroupObjectIDs: [],
        },
      })
    );

    hasTenantName && dispatch(adminGOIDsFetcher.fetch());
    try {
      await dispatch(subscriptionsFetcher.fetch());
    } catch (err) {
      notifications.error({
        message: "Failed to fetch Cloud Account subscriptions",
        details: err.message,
      });
    }
  };
}

function selectRegionEffect() {
  return function (dispatch, getState) {
    const hasDisablePropertiesRequest = getSelectedCloudAccount(getState())
      ?.spec?.settings?.disablePropertiesRequest;

    let subnetUpdates = {
      controlPlaneSubnetName: "",
      controlPlaneSubnetCidr: "",
      controlPlaneSubnetSecurity: "",
      workerSubnetName: "",
      workerSubnetCidr: "",
      workerSubnetSecurity: "",
    };

    if (!hasDisablePropertiesRequest) {
      subnetUpdates = { controlPlaneSubnet: "", workerSubnet: "" };
    }

    dispatch(resourceGroupsFetcher.fetch());
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vnetResourceGroup: "",
          storageAccountName: "",
          containerName: "",
          vnetName: "",
          vnetCidrBlock: "",
          resourceGroup: "",
          privateDNSName: "",
          ...subnetUpdates,
        },
      })
    );
  };
}

export function selectResourceGroup(value) {
  return function (dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          resourceGroup: value,
          storageAccountName: "",
          containerName: "",
          privateDNSName: "",
        },
      })
    );
    dispatch(storageAccountsFetcher.fetch());
    dispatch(privateDnsZonesFetcher.fetch());
  };
}

export function selectStorageAccount(value) {
  return function (dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          storageAccountName: value,
          containerName: "",
        },
      })
    );
    dispatch(storageContainersFetcher.fetch());
  };
}

export function onChangeApiServerPrivate(value) {
  return function (dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          apiServerPrivate: value,
        },
      })
    );
    if (!value) {
      dispatch(
        clusterFormActions.clearFieldErrors({
          module: "cluster",
          field: "apiServerLBStaticIP",
        })
      );
    }
  };
}

export function onChangeIpAllocationMethod(value) {
  return function (dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          ipAllocationMethod: value,
          apiServerLBStaticIP: "",
        },
      })
    );
    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: "apiServerLBStaticIP",
      })
    );
  };
}

const effects = {
  onChangeStaticPlacement,
  selectCredentialEffect,
  selectRegionEffect,
};

export default effects;
