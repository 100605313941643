import React from "react";
import styled, { css } from "styled-components";
import { darkGray, regentGray } from "utils/constants/colors";
import illustration_empty from "assets/images/illustration_empty.png";
const SIZES = {
  medium: {
    titleFontSize: "32px",
    subtitleFontSize: "18px",
  },
  large: {
    titleFontSize: "40px",
    subtitleFontSize: "20px",
  },
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  background-image: url(${illustration_empty});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;

  > div {
    max-width: 660px;
  }

  ${({ size }) => {
    return css`
      ${Title} {
        font-size: ${SIZES[size].titleFontSize};
      }

      ${Subtitle} {
        font-size: ${SIZES[size].subtitleFontSize};
      }
    `;
  }}
`;

const TextWrapper = styled.div`
  margin-bottom: 25px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.005em;
  color: ${darkGray};
  padding: 10px 0;
`;

const Subtitle = styled.div`
  color: ${regentGray};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
`;

function EmptyPage({
  title = "",
  subtitle = "",
  extraContent,
  size = "large",
}) {
  return (
    <Wrapper size={size}>
      <div>
        <TextWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextWrapper>
        {extraContent}
      </div>
    </Wrapper>
  );
}

export default EmptyPage;
