import React, { useMemo } from "react";
import AppIcon from "assets/icons/app.svg?react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import CircleUpdateIcon from "assets/icons/circle_update.svg?react";
import { formatTimestampToGeneralDateFormat } from "utils/date";
import Status from "components/styled/Status/new";
import HealthStatusMessage from "components/styled/HealthStatusMessage";
import Loader from "components/ui/Loader";
import { PermissionService } from "services/permissions";
import uniqBy from "lodash/uniqBy";
import { red } from "utils/constants/colors";
import ActionMenu from "components/common/ActionMenu";

const IconWrap = styled.div`
  position: relative;
  top: 3px;
  width: 16px;
  display: inline-block;
  margin-left: 6px;
`;

const ClusterNotification = styled.div`
  font-size: 16px;
  line-height: 16px;
`;

const VirtualClusterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
`;

export const useAppDeploymentsColumns = ({
  dashboardColumn = false,
  deleteDeployment,
} = {}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () =>
      [
        {
          key: "type",
          render: () => {
            return (
              <IconWrap>
                <AppIcon />
              </IconWrap>
            );
          },
        },
        {
          title: t("App Name"),
          key: "name",
          dataIndex: ["metadata", "name"],
        },
        {
          title: t("App Profile"),
          key: "name",
          render: (data) => {
            const profile = data.spec.profile;
            return (
              <>
                {profile?.metadata?.name || "-"}
                {data.status?.notifications?.isAvailable ? (
                  <IconWrap>
                    <Tooltip title={t("Updates Available")}>
                      <ClusterNotification>
                        <CircleUpdateIcon />
                      </ClusterNotification>
                    </Tooltip>
                  </IconWrap>
                ) : null}
              </>
            );
          },
        },
        !dashboardColumn && {
          title: t("Last Modified Date"),
          key: "updateTime",
          dataIndex: ["metadata", "lastModifiedTimestamp"],
          render: (lastModifiedTimestamp) =>
            formatTimestampToGeneralDateFormat(lastModifiedTimestamp),
        },
        {
          title: t("Virtual Cluster"),
          key: "virtualCluster",
          render: (item) => {
            const clusterName =
              item?.spec?.config?.target?.clusterRef?.name || "-";
            const clusterState = item?.status?.cluster?.state?.toLowerCase();
            const loadingStatuses = [
              "provisioning",
              "deleting",
              "deploying",
              "updating",
            ];

            return (
              <VirtualClusterWrapper>
                <div>{clusterName}</div>
                {loadingStatuses.includes(clusterState) ? (
                  <IconWrap>
                    <Loader small />
                  </IconWrap>
                ) : null}
              </VirtualClusterWrapper>
            );
          },
        },
        {
          title: t("Status"),
          key: "status",
          render(item) {
            const status = (item?.status?.state || "").toLowerCase();
            if (!status) {
              return "-";
            }
            return (
              <Status
                status={status}
                loadingStatuses={[
                  "provisioning",
                  "deleting",
                  "deploying",
                  "updating",
                ]}
              >
                {status}
              </Status>
            );
          },
        },
        {
          title: dashboardColumn ? t("Health") : t("Cluster Health"),
          key: "health",
          dataIndex: ["status", "cluster", "health"],
          render: (health) => {
            const healthConditions = uniqBy(
              health?.conditions || [],
              "message"
            );
            const renderMessagesInTooltip = (condition, index) => (
              <div key={index}>{condition.message}</div>
            );
            return (
              <IconWrap>
                <HealthStatusMessage
                  state={health.state ? health.state.toLowerCase() : null}
                  message={healthConditions.map(renderMessagesInTooltip)}
                  compact
                  health={health.state}
                />
              </IconWrap>
            );
          },
        },
        !dashboardColumn && {
          key: "actions",
          render(data) {
            const { permissions } = data?.metadata?.annotations || {};
            const appProfilePermissions = new PermissionService(
              permissions?.split(",")
            );
            const options = [
              appProfilePermissions.has("appDeployment.delete") && {
                key: "delete",
                color: red,
                label: t("Delete"),
                onClick: () => deleteDeployment(data.guid),
              },
            ].filter(Boolean);

            return <ActionMenu options={options} />;
          },
        },
      ].filter(Boolean),
    [t, deleteDeployment, dashboardColumn]
  );

  return columns;
};
