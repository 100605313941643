import { combineReducers } from "redux";
import createReducer from "utils/createReducer";

import * as create from "./create";
import * as details from "./details";
import * as layerConfig from "./layerConfig";
import * as updates from "./updates";

export default combineReducers({
  create: createReducer(create),
  details: createReducer(details),
  layerConfig: createReducer(layerConfig),
  updates: createReducer(updates),
});
