import i18next from "i18next";

import ListActions from "modules/list/actions";
import AsyncAction from "modules/asyncAction";
import api from "services/api";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import { deleteModalService } from "../services/details";

import { AppProfileSchema } from "utils/schemas";

export const APP_PROFILES_MODULE = "appProfiles";

export const appProfilesListActions = new ListActions({
  hasPagination: true,
  defaultQuery: {
    search: "",
    limit: 20,
  },
  initialQuery: () => {
    return {
      search: "",
      limit: 20,
    };
  },
  fetchData: async (query) => {
    const { offset, limit, continue: continueToken, search } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    const payload = {
      filter: {
        profileName: {
          contains: search || "",
        },
      },
      sort: [
        {
          field: "lastModifiedTimestamp",
          order: "desc",
        },
      ],
    };

    const data = await api.post(
      `v1/dashboard/appProfiles?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );
    return {
      ...data,
      items: (data?.appProfiles || []).map((appProfile) => ({
        ...appProfile,
        spec: {
          ...appProfile.spec,
          published: {
            packs: (appProfile?.spec?.template?.appTiers || []).map(
              (appTier) => ({
                ...appTier,
                ...appTier.source,
                name: appTier.name,
                layer: appTier.type,
              })
            ),
          },
        },
      })),
    };
  },
  schema: [AppProfileSchema],
});

export const deleteAppProfileAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const appProfile = getStoreEntity(guid, AppProfileSchema);
    const profileName = appProfile?.metadata?.name;
    const version = appProfile?.spec?.version;

    const promise = api.delete(`v1/appProfiles/${appProfile.metadata.uid}`);
    store.dispatch({
      type: "DELETE_APP_PROFILE",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "App Profile '{{profileName}}', version '{{ version }}' was deleted successfully",
          { profileName, version }
        ),
      });

      store.dispatch(appProfilesListActions.refreshItems(APP_PROFILES_MODULE));
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while deleting {{profileName}}",
          { profileName }
        ),
        description: err.message,
      });
    }
  },
});

export function onDeleteAppProfile(guid) {
  return function thunk() {
    deleteModalService.open({ guid }).then(() => {
      deleteAppProfileAsyncAction.key(guid).trigger();
    });
  };
}
