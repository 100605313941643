import i18next from "i18next";

import AsyncAction from "modules/asyncAction";
import store, { getStoreEntity } from "services/store";
import { deleteProfileModal } from "../services";
import { ClusterProfileSchema } from "utils/schemas";
import { getArrayFromQuery } from "utils/parsers";
import api from "services/api";
import notifications from "services/notifications";
import history from "services/history";

export const deleteProfileAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const { tab } = store.getState().location.params;
    const clusterprofile = getStoreEntity(guid, ClusterProfileSchema);
    const version = clusterprofile?.spec?.version;
    const profileName = clusterprofile.metadata.name;
    const promise = api.delete(
      `v1/clusterprofiles/${clusterprofile.metadata.uid}`
    );

    store.dispatch({
      type: "DELETE_CLUSTER_PROFILE",
      promise,
    });
    try {
      await promise;
      notifications.success({
        message: i18next.t(
          "Profile '{{profileName}}', version '{{ version }}' was deleted successfully",
          { profileName, version }
        ),
      });
      history.push(`/profiles/${tab}`);
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while deleting {{profileName}}",
          { profileName }
        ),
        description: err.message,
      });
    }
  },
});

export function onDeleteProfile(guid) {
  return function thunk() {
    deleteProfileModal.open({ guid }).then(() => {
      return deleteProfileAsyncAction.key(guid).trigger();
    });
  };
}

export function mapClusterProfilesQuery(data) {
  const currentTab = store.getState().location?.params?.tab;
  const defaultProfileTypes = {
    cluster: ["cluster", "infra", "add-on"],
    system: ["system"],
  };

  const {
    search,
    cloudTypes,
    profileTypes = [],
    sortField,
    sortOrder,
    context,
    fips,
  } = data || {};

  let environment = getArrayFromQuery(cloudTypes);
  const profileType =
    profileTypes.length > 0
      ? getArrayFromQuery(profileTypes)
      : defaultProfileTypes[currentTab];

  if (environment.includes("edge")) {
    environment = [...environment, "libvirt"];
  }

  const scopeFilterMetadata = context
    ? {
        scope: context,
      }
    : {};

  return {
    filter: {
      ...scopeFilterMetadata,
      environment,
      profileName: {
        contains: search || "",
      },
      profileType,
      fips,
    },
    sort: [
      {
        field: sortField || "lastModifiedTimestamp",
        order: sortOrder || "desc",
      },
    ],
  };
}
