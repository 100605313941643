import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import Fields from "components/ui/Fields";
import { useFormContext } from "modules/form";
import { FieldLabel } from "components/styled/Field";
import { useTranslation } from "react-i18next";
import { lightMidGray, lightWhite, greenGray } from "utils/constants/colors";
import { createConnector } from "modules/binder";
import { createSelector } from "reselect";
import Clipboard from "components/common/Clipboard";
import Icon from "components/ui/Icon";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

const FIELDS_TYPES = {
  string: Fields.Input,
  password: Fields.PasswordField,
  number: Fields.InputNumber,
  boolean: Fields.Checkbox,
  list: Fields.Select,
};

const FormWrapper = styled.div`
  margin-top: -20px;
`;

const VariablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightWhite};
  padding: 8px 16px 16px;
`;

const Variable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${lightMidGray};
  padding: 8px 0;
  width: 100%;

  > div {
    width: 100%;
  }

  span > .copy-button {
    background: transparent;
    color: ${greenGray};
    border: 0;
  }

  input.ant-input {
    min-height: 24px;
  }
`;

const VariableText = styled.div`
  width: 100%;
  font-family: "Monaco";
  font-size: 13px;
  color: ${greenGray};
`;

const StyledFieldLabel = styled(FieldLabel)`
  svg {
    margin-left: 5px;
    font-size: 14px;
  }
`;

function DBOperatorForm({ activeTierName, isEdit, isPersisted }) {
  const { data } = useFormContext();
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <Fields.Input
        data-qa="name"
        name="name"
        label={t("Name")}
        disabled={isEdit && isPersisted}
      />
      {(data?.parameters?.inputParameters || []).map((input) => {
        if (["base64", "password"].includes(input.format)) {
          input.type = "password";
        }

        if (input.format === "list") {
          input.type = "list";
          input.options = [...(input?.listOptions || [])].map((option) => ({
            label: option,
            value: option,
          }));
        }

        const Field = FIELDS_TYPES[input.type];

        return (
          <Field
            key={input.name}
            data-qa={input.name}
            label={input?.displayName || input.name}
            name={input.name}
            defaultValue={input.value}
            options={input.options}
          />
        );
      })}
      {data?.parameters?.outputParameters?.length > 0 && (
        <>
          <StyledFieldLabel>
            {t("Output variables")}
            <Tooltip
              title={t(
                "The output variables of this tier that may be used in higher tiers, typically for connection purposes"
              )}
            >
              <Icon awesome={faInfoCircle} />
            </Tooltip>
          </StyledFieldLabel>
          <VariablesWrapper>
            {(data?.parameters?.outputParameters || []).map((output) => (
              <Variable key={output.name}>
                <Clipboard
                  text={`{{.spectro.app.$appDeploymentName.${activeTierName}.${output.name}}}`}
                >
                  <VariableText>{`{{.spectro.app.$appDeploymentName.${activeTierName}.${output.name}}}`}</VariableText>
                </Clipboard>
              </Variable>
            ))}
          </VariablesWrapper>
        </>
      )}
    </FormWrapper>
  );
}

const ConnectedDBOperatorForm = createConnector({
  selectors: {
    activeTierName: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.fileList.entities,
      (activeFileGuid, entities) => {
        return entities?.[activeFileGuid]?.name || "";
      }
    ),
    isEdit: (state) => state.editor?.isEdit,
    isPersisted: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.fileList.entities,
      (activeFile, entities) => entities[activeFile]?.persisted
    ),
  },
}).connect(DBOperatorForm);

export default ConnectedDBOperatorForm;
