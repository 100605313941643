import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import createList, { Blocks } from "modules/list";
import { UserSchema } from "utils/schemas";
import { getFullName } from "utils/presenters";
import { getEntity } from "utils/entities";

import {
  usersListActions,
  setSelectedUser,
  onUserRemove,
  deleteConfirmService,
  resetUserPassword,
  confirmResetPassword,
  USERS_MODULE,
} from "state/users/actions";

import Enumeration from "components/common/Enumeration";
import UserInformation from "../components/UserInformation";
import Icon from "components/ui/Icon";
import Confirm from "components/common/ConfirmationModal";
import StatusCard from "components/styled/StatusCard";

import { getSelectedUser } from "state/roleManagement/selectors";
import UsersFiltersForm from "./components/UsersFiltersForms";

const EditIcon = styled(Icon)`
  margin-right: -14px;
`;

const UsersListModule = createList({
  actions: usersListActions,
  schema: [UserSchema],
});

const Users = ({
  setSelectedUser,
  selectedUser,
  entityToBeDeleted,
  onUserRemove,
  resetUserPassword,
  isInitializing,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Name"),
      key: ["name"],
      render: function renderName(data) {
        return <span>{getFullName(data)}</span>;
      },
    },
    {
      title: t("Email"),
      dataIndex: ["spec", "emailId"],
      key: "emailId",
    },
    {
      title: t("Team"),
      dataIndex: ["spec", "teams"],
      render: function renderTeams(teams) {
        const teamsNames = teams.map((team) => team.metadata.name);
        return <Enumeration items={teamsNames} />;
      },
      key: "roles",
    },
    {
      title: t("Projects"),
      dataIndex: ["spec", "projectsCount"],
      render: function renderRoles(projectsCount = 0) {
        return <span>{projectsCount}</span>;
      },
      key: "projects",
    },
    {
      title: t("Status"),
      dataIndex: ["status"],
      render: function renderStatus(status) {
        if (status.isActive) {
          if (status.activationLink && status.isPasswordResetting) {
            return <StatusCard status="warning" customText={t("Resetting")} />;
          }

          return <StatusCard status={"active"} />;
        }

        return <StatusCard status={"inactive"} />;
      },
    },
    {
      render: function renderEditIcon(data) {
        return <EditIcon type="right" />;
      },
    },
  ];

  return (
    <>
      <UsersListModule module="users">
        <UsersFiltersForm />
        <Blocks.Table
          columns={columns}
          onRowClick={setSelectedUser}
          uiPagination={true}
        />
      </UsersListModule>

      <Confirm type="error" service={deleteConfirmService}>
        <Confirm.Question>
          {t(`Are you sure you want to delete {{user}} ?`, {
            user: `${entityToBeDeleted?.spec?.firstName} ${entityToBeDeleted?.spec?.lastName}`,
          })}
        </Confirm.Question>
      </Confirm>

      <Confirm service={confirmResetPassword}>
        {t("Are you sure you want to reset the password ?")}
      </Confirm>

      {selectedUser && (
        <UserInformation
          user={selectedUser}
          onUserRemove={onUserRemove}
          resetUserPassword={resetUserPassword}
        />
      )}
    </>
  );
};

const getDeletedEntity = getEntity(
  () => deleteConfirmService.data?.guid,
  UserSchema
);

export default connect(
  (state) => ({
    selectedUser: getSelectedUser(state),
    entityToBeDeleted: getDeletedEntity(state),
    isInitializing: state.list[USERS_MODULE]?.isInitializing,
  }),
  {
    setSelectedUser,
    onUserRemove,
    resetUserPassword,
    confirmResetPassword,
  }
)(Users);
