import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

import Page from "components/common/layouts/Page";
import Link from "components/common/History/Link";
import noProjectsPath from "assets/images/login/no_projects.png";
import { white, lavender } from "utils/constants/colors";
import { orgsFetcher } from "state/auth/actions";
import { useDataFetcher } from "modules/dataFetcher";
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons";
import Icon from "components/ui/Icon";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { AUTH } from "utils/constants/routes";

const ContentWrap = styled.div`
  height: 504px;
  width: 1088px;
  background: ${white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const NoProjectsContent = styled.div`
  padding: 50px 63px;
  position: relative;

  h1 {
    font-size: 38px;
    line-height: 48px;
    color: rgb(77, 74, 190);
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    margin: 10px 0;
  }
`;

const Environment = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BackToAuthLink = styled(Link)`
  margin-top: 16px;
  font-size: 16px;
  color: ${lavender};

  .anticon {
    margin-right: 8px;
  }

  &:hover {
    color: ${lavender};
    text-decoration: underline;
  }
`;

function NoProjects() {
  const { result: organizations } = useDataFetcher(orgsFetcher);
  return (
    <Page>
      <Wrap>
        <ContentWrap>
          <Environment src={noProjectsPath} alt="no projects environment" />
          <NoProjectsContent>
            <Trans>
              <h1>Sorry, no projects yet!</h1>
              <br />
              <p>
                {"You haven't been invited to any projects yet."}
                <br />
                {"Please contact your administrator."}
              </p>
            </Trans>
          </NoProjectsContent>
        </ContentWrap>
        <BackToAuthLink to={AUTH.ROOT}>
          <Icon awesome={faLongArrowLeft} />
          Back to Sign in
        </BackToAuthLink>
        {organizations?.length > 1 ? (
          <BackToAuthLink to={AUTH.ORG_SELECT}>
            <Icon awesome={faBuilding} />
            Select a different tenant
          </BackToAuthLink>
        ) : null}
      </Wrap>
    </Page>
  );
}

export default NoProjects;
