import api from "services/api";

import { ProjectSchema } from "utils/schemas";

export function fetchProjectToEdit(uid) {
  return function thunk(dispatch) {
    const promise = api.get(`v1/projects/${uid}`);

    dispatch({
      type: "FETCH_PROJECT_TO_EDIT",
      promise,
      schema: ProjectSchema,
    });

    return promise;
  };
}
