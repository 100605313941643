import dataFetcher from "modules/dataFetcher";

import { nonProjectApi } from "services/api";
import store from "services/store";

import { getCurrentTenantUid } from "state/auth/selectors";

export const nonFipsSettingsFetcher = dataFetcher({
  selectors: ["preferences/fips"],
  async fetchData() {
    const tenantUid = getCurrentTenantUid(store.getState());
    const response = await nonProjectApi.get(
      `v1/tenants/${tenantUid}/preferences/fips`
    );

    return response;
  },
});
