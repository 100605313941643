import api, { nonProjectApi } from "services/api";
import ListActions from "modules/list/actions";
import { ProjectSchema } from "utils/schemas";

import { configureAlertsModal } from "../services/list";

import createFormActions from "modules/form/actions";
import store, { getStoreEntity } from "services/store";
import dataFetcher from "modules/dataFetcher";
import notifications from "services/notifications";
import i18next from "i18next";
import { organizationFilterFormActions } from "state/organization/actions";
import {
  ORGANIZATION_OVERVIEW_FILTERS_MODULE,
  projectsConsumptionFetcher,
} from "state/organization/services";
import moment from "moment";
import { getCurrentProject } from "state/auth/selectors/common";

export const projectListActions = new ListActions({
  hasPagination: true,
  async fetchData(query) {
    const { limit, offset, continue: continueToken } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    return api.get(
      `v1/dashboard/projects?limit=${limit}&offset=${offset}${continueQueryParam}`
    );
  },
  schema: [ProjectSchema],
  initialQuery() {
    return {
      limit: 20,
    };
  },
});

const alertTypesFetcher = dataFetcher({
  selectors: ["projects", "alerts"],
  fetchData() {
    return nonProjectApi.get("v1/projects/alerts");
  },
});

export const currentProjectFetcher = dataFetcher({
  selectors: [
    "projects",
    () => {
      const project = getCurrentProject(store.getState())?.project;

      return project?.metadata?.uid;
    },
  ],
  schema: ProjectSchema,
  fetchData([_, uid]) {
    return nonProjectApi.get(`v1/projects/${uid}`);
  },
});

export const configureAlertsFormActions = createFormActions({
  async init() {
    await store.dispatch(currentProjectFetcher.fetch());
    const project = getStoreEntity(
      configureAlertsModal.data.guid,
      ProjectSchema
    );
    await store.dispatch(alertTypesFetcher.fetch());
    const response = alertTypesFetcher.selector(store.getState());

    return {
      alerts: response.result.components.map((alertType) => {
        const projectAlert = (project.spec.alerts || []).find(
          (alert) => alert.component === alertType.name
        );

        return {
          ...alertType,
          ...(projectAlert || {}),
          channels: projectAlert?.channels || [],
          isEnabled: !!projectAlert?.channels?.length,
        };
      }),
    };
  },
  async submit(data) {
    const project = getStoreEntity(
      configureAlertsModal.data.guid,
      ProjectSchema
    );
    const promises = data.alerts.map((alert) => {
      if (alert.isEnabled) {
        return nonProjectApi.put(
          `v1/projects/${project.metadata.uid}/alerts/${alert.name}`,
          {
            channels: alert.channels,
          }
        );
      } else {
        return nonProjectApi.delete(
          `v1/projects/${project.metadata.uid}/alerts/${alert.name}`
        );
      }
    });

    try {
      await Promise.all(promises);
      notifications.success({
        message: i18next.t(
          "Alerts for project '{{projectName}}' have been configured successfully",
          {
            projectName: project.metadata.name,
          }
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while configuring alerts for '{{projectName}}' project",
          {
            projectName: project.metadata.name,
          }
        ),
        description: error.message,
      });
    }
  },
});

export function initCpuCoresChart() {
  return async function thunk(dispatch) {
    const today = moment();
    const oneWeekAgo = moment(today).subtract(6, "day");

    const query = {
      startTime: oneWeekAgo,
      endTime: today,
    };
    const filter = {
      value: 7,
      unit: "days",
    };

    await dispatch(
      organizationFilterFormActions.batchChange({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        updates: {
          query,
          filter,
        },
      })
    );

    dispatch(projectsConsumptionFetcher.fetch(query));
  };
}
