import React, { useMemo } from "react";
import i18next from "i18next";
import { createSelector } from "reselect";
import { createSelectComponent } from "modules/dataFetcher";
import {
  helmPacksFetcher,
  helmPackVersionFetcher,
  helmRepositoriesFetcher,
} from "modules/profileIDE/state/packs";
import { useFormContext } from "modules/form";
import Fields from "components/ui/Fields";
import { createConnector } from "modules/binder";

import store from "services/store";
import { versionSort } from "utils/versionSort";
import { ConnectedManifestList } from "./Manifest";
import {
  PackContainer,
  PackLabel,
  PackLogo,
  PackLogoIcon,
} from "components/common/PackNameBlocks";
import createAutocompleteComponent from "modules/autocomplete";
import { PackSchema } from "utils/schemas";

function matchAmbiguousPatch(tag) {
  const [major, minor, patch] = tag.split(".");
  return major !== undefined && minor !== undefined && patch === "x";
}

const RepositorySelector = createSelectComponent({
  dataFetcher: helmRepositoriesFetcher,
});

const VersionSelector = createSelectComponent({
  dataFetcher: helmPackVersionFetcher,
  parseOptions(result) {
    let sortedVersions = [...(result?.tags || [])].sort((pack1, pack2) => {
      return versionSort(pack1.version, pack2.version, true);
    });

    const roots = sortedVersions
      .filter((version) => {
        return matchAmbiguousPatch(version.tag);
      })
      .map((version) => {
        return {
          title: version.tag,
          value: version.tag,
          disabled: version.disabled,
          info: version.version,
        };
      });

    sortedVersions.forEach((version) => {
      const parentTags = version?.parentTags || [];
      const parent = parentTags.find(matchAmbiguousPatch);

      if (!parent) {
        return;
      }

      const parentVersion = roots.find(
        (rootVersion) => rootVersion.title === parent
      );

      if (parentVersion) {
        parentVersion.children = parentVersion.children || [];
        parentVersion.children.push({
          title: version.tag,
          value: version.tag,
          disabled: version.disabled,
        });
      }
    });

    return roots;
  },
  Component({ options, ...rest }) {
    return <Fields.TreeSelect treeData={options} {...rest} />;
  },
});

function Helm({ protectedRegistry }) {
  const { data } = useFormContext();

  const PackSelector = useMemo(
    () =>
      createAutocompleteComponent({
        dataFetcher: helmPacksFetcher,
        identifier: data.registryUid,
        schema: [PackSchema],
        parseOptions: (packs) => {
          return (packs?.items || []).map((pack) => {
            const { name, registries, displayName } = pack.spec || {};
            const logoUrl = registries?.[0]?.logoUrl;
            let disabled = registries?.[0]?.annotations?.disabled === "true";
            let description = null;

            if (disabled) {
              description = i18next.t("Coming Soon");
            }

            return {
              label: (
                <PackContainer key={pack.guid}>
                  <PackLogo disabled={disabled}>
                    <PackLogoIcon {...pack.spec} logoUrl={logoUrl} />
                  </PackLogo>
                  <PackLabel title={displayName}>{displayName}</PackLabel>
                </PackContainer>
              ),
              value: name,
              disabled,
              description,
            };
          });
        },
      }),
    [data.registryUid]
  );

  function renderHelmNameField() {
    let fieldConfig = {
      name: "packName",
      disabled: !data.registryUid,
    };

    if (protectedRegistry) {
      return (
        <Fields.Input
          data-qa="pack-name"
          label={"Chart name"}
          placeholder={"Enter chart name"}
          {...fieldConfig}
        />
      );
    }

    return (
      <PackSelector
        identifier={data.registryUid}
        module="helmPacks"
        label={"Helm name"}
        {...fieldConfig}
      />
    );
  }

  function renderVersionField() {
    let fieldConfig = {
      name: "packVersion",
      disabled: !data.packName,
    };

    if (protectedRegistry) {
      return (
        <Fields.Input
          data-qa="pack-version"
          label={"Chart version"}
          placeholder={"Enter chart version"}
          {...fieldConfig}
        />
      );
    }

    return (
      <VersionSelector
        showAction={["focus"]}
        identifier={data.packName}
        label={"Helm Version"}
        data-qa="pack-version"
        selectableParent={true}
        {...fieldConfig}
      />
    );
  }

  return (
    <>
      <RepositorySelector label="Registry" name="registryUid" />
      {renderHelmNameField()}
      {renderVersionField()}
      <ConnectedManifestList />
    </>
  );
}

const ConnectedHelmForm = createConnector({
  selectors: {
    protectedRegistry: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.drafts,
      () => helmRepositoriesFetcher.selector(store.getState())?.result,
      (activeFile, drafts, helmRepos) => {
        const draftData = drafts?.[activeFile] || {};

        return (
          (helmRepos || []).find((repo) => {
            return repo.metadata.uid === draftData.registryUid;
          })?.spec?.isPrivate || false
        );
      }
    ),
  },
}).connect(Helm);

export default ConnectedHelmForm;
