import isEmpty from "lodash/isEmpty";

export const mergeColumns = (initialColumns = [], additionalConfigs = {}) => {
  if (isEmpty(additionalConfigs)) {
    return initialColumns;
  }

  const columns = initialColumns.slice();

  return columns.map((col) => {
    const columnState = additionalConfigs[col?.key]?.columnState;

    return {
      ...col,
      columnState,
    };
  });
};
