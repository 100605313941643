import {
  networksFetcher,
  projectsFetcher,
} from "state/cluster/services/create";
import clusterFormActions from "../form";

export function onAzsChange(value, poolIndex) {
  return (dispatch, getState) => {
    const state = getState();
    let nodePools = [...state.forms.cluster.data.nodePools];
    const currentNodePool = nodePools[poolIndex];
    const { isMaster } = currentNodePool;

    nodePools[poolIndex] = {
      ...currentNodePool,
      azs: value,
    };

    if (isMaster) {
      nodePools = nodePools.map((node) => ({
        ...node,
        azs: node.isMaster ? node.azs : [],
      }));
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
    if (isMaster) {
      const workerPoolsFieldsNames = nodePools?.reduce(
        (acc, _, idx) =>
          idx === poolIndex ? acc : [...acc, `nodePools.${idx}.azs`],
        []
      );

      dispatch(
        clusterFormActions.validateField({
          module: "cluster",
          name: [...workerPoolsFieldsNames],
          checkErrors: true,
        })
      );
    }
  };
}

export function selectNetwork(network) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "network",
        value: network,
      })
    );
  };
}

function selectCredentialEffect() {
  return function (dispatch) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          project: "",
          region: "",
          network: "",
        },
      })
    );
    dispatch(projectsFetcher.fetch());
  };
}

function selectRegionEffect() {
  return function (dispatch) {
    dispatch(networksFetcher.fetch());
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          network: "",
        },
      })
    );
  };
}

function onChangeStaticPlacement(value) {
  return (dispatch) => {
    if (!value) {
      dispatch(
        clusterFormActions.onChange({
          name: "network",
          module: "cluster",
          value: "",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "network",
          module: "cluster",
        })
      );
    }
  };
}

const effects = {
  selectCredentialEffect,
  selectRegionEffect,
  onChangeStaticPlacement,
};

export default effects;
