import React from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import Icon from "components/ui/Icon";
import styled, { css } from "styled-components";
import {
  lightMidGray,
  mediumLightGray,
  midGray,
  red,
} from "utils/constants/colors";

import Link from "../History/Link";
import permissions from "services/permissions";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";

const ActionMenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledMenu = styled(Menu)`
  padding: 9px 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  min-width: 160px;

  > li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 18px;
    padding-right: 20px;
    color: ${midGray};

    &.ant-dropdown-menu-item-disabled {
      color: ${lightMidGray};
    }

    &:hover {
      background-color: rgba(68, 50, 245, 0.05);
    }

    a {
      color: inherit;
      display: block;
    }
  }
`;

const Wrap = styled.div`
  ${(props) =>
    props.disabled &&
    css`
      .ant-dropdown-menu-title-content {
        color: ${lightMidGray};
      }

      .ant-dropdown-menu-item:hover {
        background-color: rgba(68, 50, 245, 0.05);
      }
    `}
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
  color: ${(props) => props.color || mediumLightGray};
  min-width: 16px;
`;

export default function ActionMenu({
  options = [],
  dotsColor,
  children,
  selectedKeys,
  ...rest
}) {
  if (!options?.length) {
    return null;
  }

  function renderItem(item, index) {
    let { label } = item;
    if (item.href && !item.disabled) {
      label = <Link to={item.href}>{label}</Link>;
    }
    if (!permissions.has(item.permission)) {
      return null;
    }

    const MenuItem = item.Component || Menu.Item;
    const Item = (
      <MenuItem
        danger={item.color === red}
        item={item}
        disabled={item.disabled}
        key={item.key || index}
        data-qa={item.label}
        data-qa-key={item.key}
        data-qa-index={index}
        identifier={item.identifier}
        icon={item.icon}
      >
        {label}
      </MenuItem>
    );

    if (item?.tooltip?.text) {
      return (
        <Tooltip
          placement={item.tooltip?.placement || "left"}
          title={item.tooltip.text}
        >
          <Wrap disabled={item.disabled}>{Item}</Wrap>
        </Tooltip>
      );
    }

    return Item;
  }

  const menu = (
    <StyledMenu
      onClick={({ item }) => item?.props?.item?.onClick?.()}
      selectedKeys={selectedKeys}
    >
      {options.map(renderItem)}
    </StyledMenu>
  );

  return (
    <ActionMenuContainer
      role="menu"
      tabIndex="-1"
      onKeyPress={() => {}}
      data-qa={rest["data-qa"]}
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      }}
    >
      <Dropdown overlay={menu} {...rest}>
        {children || (
          <div>
            <StyledIcon
              data-qa={`${rest["data-qa"]}-trigger`}
              awesome={faEllipsisV}
              color={dotsColor}
            />
          </div>
        )}
      </Dropdown>
    </ActionMenuContainer>
  );
}
