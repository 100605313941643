import createFormActions from "modules/form/actions";
import store from "services/store";
import { v4 as uuid } from "uuid";

function createDraft({ type, entity, tiersLength }) {
  const name = `${
    entity?.displayName?.toLowerCase()?.split(" ")?.join("-") || type
  }-${tiersLength + 1}`;

  const base = {
    name,
    guid: uuid(),
    type,
  };
  if (type === "manifest") {
    return {
      ...base,
      manifests: [],
    };
  }

  if (type === "helm") {
    return {
      ...base,
      name: `unnamed-helm-${tiersLength + 1}`,
      registryUid: "",
      manifests: [],
    };
  }

  return base;
}

export function bindFormTo(module, validator) {
  const TIER_FORM_MODULE_NAME = `${module.name}-layer-form`;

  const formActions = createFormActions({
    validator,
    async init({ guid, type, ...rest }) {
      const entity = module.state.fileList?.entities?.[guid] || rest;
      const allTiers = [
        ...(module.state.fileList?.files || []),
        ...(module.state.fileList?.deletedFiles || []),
      ];
      const draft =
        module.state?.drafts?.[guid] ||
        createDraft({ type, entity, tiersLength: allTiers.length });

      const overwrites =
        (await module?.effects?.form?.load(entity, draft, module)) || {};

      return {
        ...draft,
        ...overwrites,
      };
    },
  });

  module.form = {
    get state() {
      return store.getState().forms[TIER_FORM_MODULE_NAME];
    },
  };

  module.form.actions = Object.keys(formActions).reduce(
    (accumulator, key) => {
      if (key === "__mark") {
        return accumulator;
      }
      const action = formActions[key];
      accumulator[key] = (payload) => async (dispatch) => {
        const result = dispatch(
          action({
            ...payload,
            module: TIER_FORM_MODULE_NAME,
          })
        );

        if (key === "onChange") {
          module.effects.form.change(payload, module);

          const currentFormGuid = module.form.state.data.guid;
          const previousValidationError = (
            module.state.validations[currentFormGuid] || []
          )?.find((error) => {
            return payload.name.startsWith(error.field);
          });

          if (previousValidationError) {
            store.dispatch(
              module.form.actions.validateField({
                name: previousValidationError.field,
              })
            );
          }
        }

        if (["onChange", "batchChange"].includes(key)) {
          const form = module?.form?.state?.data;
          if (form) {
            module.dispatch({
              type: module.actions.drafts.stashForm,
              formData: form,
            });
          }
        }

        if (["validateForm", "validateField"].includes(key)) {
          const errors = await result;
          const form = module?.form?.state?.data;

          module.dispatch({
            type: module.actions.validations.stashErrors,
            guid: form.guid,
            errors,
          });
        }

        return result;
      };
      return accumulator;
    },
    { __mark: formActions.__mark }
  );
}
