import styled, { css } from "styled-components";
import { Collapse } from "antd";
import { lightWhite, silver, midGray } from "utils/constants/colors";

const Accordion = styled(Collapse)`
  border: 0 none;
  background: transparent;
  min-width: 200px;
  width: 100%;

  .ant-collapse-header-text {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .ant-collapse-item {
    background: ${(props) => props.backgroundColor || lightWhite};
    border-radius: 8px;
    border: 0 none;
    margin: 4px 0;
    :last-child {
      border-radius: 8px;
    }
  }

  > .ant-collapse-item > .ant-collapse-header {
    text-transform: uppercase;
    color: ${silver};
    font-size: 13px;
    line-height: 40px;
    height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
  }

  .ant-collapse-content {
    background-color: transparent;
    border: 0 none;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 16px 12px;
    font-size: 13px;
    line-height: 21px;
    color: ${midGray};
  }

  ${(props) =>
    props.variant === "white" &&
    css`
      > .ant-collapse-item > .ant-collapse-header {
        color: ${midGray};
        background: #f2f2f2;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.07);
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.05em;
        line-height: 32px;
        height: 32px;
        padding: 0 16px;
      }

      .ant-collapse-item {
        margin: 0;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
      }

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }
    `}
`;

Accordion.Panel = Collapse.Panel;

export default Accordion;
