import i18next from "i18next";
import moment from "moment";
import { generatePath } from "react-router";
import querystring from "query-string";
import createFormActions from "modules/form/actions";
import api from "services/api";

import { CLUSTERS } from "utils/constants/routes";
import history from "services/history";

import {
  cronjobsListingActions,
  CRONJOBS_MODULE,
} from "state/cluster/services/workloads/cronjobs";
import {
  daemonSetsListingActions,
  DAEMONSETS_MODULE,
} from "state/cluster/services/workloads/daemonsets";
import {
  deploymentsListingActions,
  DEPLOYMENTS_MODULE,
} from "state/cluster/services/workloads/deployments";
import {
  jobsListingActions,
  JOBS_MODULE,
} from "state/cluster/services/workloads/jobs";
import {
  namespacesFetcher,
  namespacesListingActions,
  namespacesNamesFetcher,
  NAMESPACES_MODULE,
} from "state/cluster/services/workloads/namespaces";
import {
  podsListingActions,
  PODS_MODULE,
} from "state/cluster/services/workloads/pods";
import {
  statefulSetsListingActions,
  STATEFULSETS_MODULE,
} from "state/cluster/services/workloads/statefulsets";
import {
  roleBindingsListingActions,
  ROLE_BINDINGS_MODULE,
} from "state/cluster/services/workloads/rolebindings";
import {
  clusterRoleBindingsListingActions,
  CLUSTER_ROLE_BINDINGS_MODULE,
} from "state/cluster/services/workloads/clusterrolebindings";
import {
  virtualClustersListingActions,
  VIRTUAL_CLUSTERS_MODULE,
} from "state/cluster/services/workloads/nestedclusters";
import { WorkloadNamespaceSchema } from "utils/schemas";
import { getArrayFromQuery } from "utils/parsers";
import { WORKLOADS_WITH_METRICS } from "utils/constants";
import { clusterWorkloadUsageFetcher } from "state/cluster/services/workloads";
import {
  selectedWorkloadUsageFetcher,
  selectedWorkloadModal,
} from "state/cluster/services/workloads";
import { getCluster } from "state/cluster/selectors/details";
import notifications from "services/notifications";
import AsyncAction from "modules/asyncAction";
import store from "services/store";

export const WORKLOADS_FILTERS_MODULE = "workloadsFilters";

export const workloadsFilterFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    const filterBy = getArrayFromQuery(query?.namespaces);

    return Promise.resolve({
      filterBy,
      workloadPeriod: "6 hours",
      workloadUsageType: "cpu",
      selectedWorkloadUsageType: "cpu",
      searchBy: "",
    });
  },
});

export function onUsageTypeChange(value) {
  return (dispatch) => {
    dispatch(
      workloadsFilterFormActions.onChange({
        module: WORKLOADS_FILTERS_MODULE,
        name: "workloadUsageType",
        value,
      })
    );
  };
}

export const workloadsModules = {
  namespaces: {
    listActions: namespacesListingActions,
    name: NAMESPACES_MODULE,
  },
  pods: {
    listActions: podsListingActions,
    name: PODS_MODULE,
  },
  deployments: {
    listActions: deploymentsListingActions,
    name: DEPLOYMENTS_MODULE,
  },
  daemonsets: {
    listActions: daemonSetsListingActions,
    name: DAEMONSETS_MODULE,
  },
  statefulsets: {
    listActions: statefulSetsListingActions,
    name: STATEFULSETS_MODULE,
  },
  jobs: {
    listActions: jobsListingActions,
    name: JOBS_MODULE,
  },
  cronjobs: {
    listActions: cronjobsListingActions,
    name: CRONJOBS_MODULE,
  },
  rolebindings: {
    listActions: roleBindingsListingActions,
    name: ROLE_BINDINGS_MODULE,
  },
  clusterrolebindings: {
    listActions: clusterRoleBindingsListingActions,
    name: CLUSTER_ROLE_BINDINGS_MODULE,
  },
  virtualclusters: {
    listActions: virtualClustersListingActions,
    name: VIRTUAL_CLUSTERS_MODULE,
  },
};

export function initializeWorkloadsTab() {
  return (dispatch, getState) => {
    const currentTab = getState().location.params.flag;
    if (!workloadsModules[currentTab]) {
      return;
    }
    dispatch(
      workloadsModules[currentTab].listActions.initialize(
        workloadsModules[currentTab].name
      )
    );
  };
}

export function onChartPeriodChange(value) {
  return (dispatch, getState) => {
    const { filterBy } =
      getState().forms?.[WORKLOADS_FILTERS_MODULE]?.data || {};
    const currentTab = getState().location.params.flag;

    dispatch(
      workloadsFilterFormActions.onChange({
        module: WORKLOADS_FILTERS_MODULE,
        name: "workloadPeriod",
        value,
      })
    );

    if (WORKLOADS_WITH_METRICS.includes(currentTab)) {
      dispatch(clusterWorkloadUsageFetcher.fetch(value, filterBy));
    }
  };
}

export function onNamespaceFilterSelect(value) {
  return (dispatch, getState) => {
    dispatch(
      workloadsFilterFormActions.onChange({
        module: WORKLOADS_FILTERS_MODULE,
        name: "filterBy",
        value,
      })
    );

    const currentTab = getState().location.params.flag;
    dispatch(
      workloadsModules[currentTab].listActions.changeQuery({
        name: "filterBy",
        value,
        module: workloadsModules[currentTab].name,
      })
    );

    if (WORKLOADS_WITH_METRICS.includes(currentTab)) {
      dispatch(
        clusterWorkloadUsageFetcher.fetch(
          getState().forms?.[WORKLOADS_FILTERS_MODULE]?.data?.workloadPeriod,
          value
        )
      );
    }
  };
}

export function fetchNamespacesConsumption() {
  return async function thunk(dispatch, getState) {
    const uid = getState().location.params.id;
    const promise = async function loadData() {
      const rawData = await api.post(
        `v1/dashboard/spectroclusters/${uid}/resources/consumption`,
        {
          filter: {
            startTime: moment().subtract(10, "minutes"),
            endTime: moment(),
            namespaces: [],
            includeControlPlaneMachines: true,
          },
          options: {
            groupBy: "namespace",
            period: 15,
          },
        }
      );

      const { result: namespaces } = namespacesFetcher.selector(getState());
      return rawData.resources.map((data) => {
        const namespace = (namespaces?.items || []).find((ns) => {
          return ns.metadata.entity.name === data.entity.name;
        });
        return {
          metadata: {
            entity: {
              ...data.entity,
              uid: namespace?.metadata?.entity?.uid,
            },
          },
          status: {
            usage: {
              total: data.total,
            },
          },
        };
      });
    };
    dispatch({
      type: "CLUSTER_WORKLOAD_NAMESPACES_CONSUMPTION",
      promise: promise(),
      schema: [WorkloadNamespaceSchema],
    });
  };
}

export function navigateToPods(namespace) {
  return (dispatch, getState) => {
    const { id, tab, clusterCategory } = getState().location.params || {};
    const podsPath = generatePath(CLUSTERS.WORKLOADS, {
      id,
      tab,
      clusterCategory,
      flag: "pods",
    });

    const query = querystring.stringify({
      namespaces: [namespace.metadata.entity.name],
    });

    history.push(`${podsPath}?${query}`);
  };
}

export function onWorkloadUsageTypeChange(value) {
  return (dispatch) => {
    dispatch(
      workloadsFilterFormActions.onChange({
        module: WORKLOADS_FILTERS_MODULE,
        name: "selectedWorkloadUsageType",
        value,
      })
    );
  };
}

export function openWorkloadDetailsModal(guid) {
  return (dispatch, getState) => {
    const { workloadPeriod, namespaces } =
      getState().forms?.[WORKLOADS_FILTERS_MODULE]?.data || {};

    selectedWorkloadModal.open({ guid });
    dispatch(onWorkloadUsageTypeChange("cpu"));
    dispatch(
      selectedWorkloadUsageFetcher.fetch({
        timeRange: workloadPeriod,
        namespaces,
      })
    );
  };
}

export const syncWorkloadAsyncAction = new AsyncAction({
  promise: async () => {
    const state = store.getState();
    const cluster = getCluster(state);
    const currentTab = state.location.params.flag;
    const workloadKind = currentTab.slice(0, -1);
    const currentModule = workloadsModules[currentTab];
    const currentPeriod =
      state.forms?.[WORKLOADS_FILTERS_MODULE]?.data?.workloadPeriod;

    const promise = api.post(
      `v1/spectroclusters/${cluster.metadata.uid}/workloads/${workloadKind}/sync`
    );

    store.dispatch({
      type: "SYNC_WORKLOAD",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t("Syncing for {{currentTab}} has been triggered", {
          currentTab,
        }),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });

      return;
    }

    store.dispatch(currentModule.listActions.fetchItems(currentModule.name));
    store.dispatch(onChartPeriodChange(currentPeriod));
    if (currentTab === "namespaces") {
      store.dispatch(namespacesNamesFetcher.fetch());
    }
  },
});

export function getClusterNamespacesPayload(formData) {
  const parseFloatNumber = (value) => parseFloat(value) || 0;
  const namespaces = (formData.namespaces || []).map((ns) => ({
    metadata: {
      name: ns.namespaceName,
      uid: ns.uid,
    },
    spec: {
      resourceAllocation: {
        cpuCores: parseFloatNumber(ns.alocCpu),
        memoryMiB: parseFloatNumber(ns.alocMemory) * 1024,
      },
      isRegex: ns.isRegex,
    },
  }));

  return {
    namespaces,
  };
}
