import api from "services/api";
import store from "services/store";
import { WorkloadNamespaceSchema, WorkspaceSchema } from "utils/schemas";

import {
  editBasicInfoFormActions,
  editClustersFormActions,
  editNamespacesFormActions,
  editContainerImagesFormActions,
} from "state/workspaces/actions/edit";
import {
  EDIT_BASIC_INFO_MODULE,
  EDIT_CLUSTERS_MODULE,
  EDIT_NAMESPACES_MODULE,
  EDIT_CONTAINER_IMAGES_MODULE,
} from "state/workspaces/services/edit";
import { getRawWorkspace } from "state/workspaces/selectors/details";
import ModalService from "services/modal";
import history from "services/history";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import createFormActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";
import i18next from "i18next";
import moment from "moment";
import { GENERAL_DATE_FORMAT } from "utils/constants";
import {
  namespacesFetcher,
  namespacesListingActions,
  NAMESPACES_MODULE,
} from "../services/details";
import { scheduleWorkspaceBackupsFormAction } from "state/workspaces/actions/backups/schedule";
import { SCHEDULE_WORKSPACE_BACKUPS_MODULE } from "state/workspaces/services/backups";

export const workspaceSettingsModal = new ModalService();
export const deleteWorkspaceModal = new ModalService();
export const DELETE_WORKSPACE_FORM_MODULE = "deleteWorkspace";

export const WORKSPACE_SETTINGS_FORMS = {
  "basic-info": {
    formActions: editBasicInfoFormActions,
    module: EDIT_BASIC_INFO_MODULE,
  },
  clusters: {
    formActions: editClustersFormActions,
    module: EDIT_CLUSTERS_MODULE,
  },
  namespaces: {
    formActions: editNamespacesFormActions,
    module: EDIT_NAMESPACES_MODULE,
  },
  schedulebackups: {
    formActions: scheduleWorkspaceBackupsFormAction,
    module: SCHEDULE_WORKSPACE_BACKUPS_MODULE,
  },
  "container-images": {
    formActions: editContainerImagesFormActions,
    module: EDIT_CONTAINER_IMAGES_MODULE,
  },
};

export function fetchWorkspace(uid) {
  return async (dispatch) => {
    const promise = api.get(`v1/workspaces/${uid}`).then((res) => ({
      ...res,
      spec: {
        ...(res.spec || {}),
        clusterRefs: (res.spec?.clusterRefs || []).map((ref) => ({
          uid: ref.clusterUid,
          name: ref.clusterName,
        })),
      },
    }));
    dispatch({
      type: "FETCH_WORKSPACE",
      promise,
      schema: WorkspaceSchema,
    });

    return promise;
  };
}

export function refreshWorkspace() {
  return async (dispatch, getState) => {
    const workspace = getRawWorkspace(getState());
    const promise = api
      .get(`v1/workspaces/${workspace.metadata.uid}`)
      .then((res) => ({
        ...res,
        spec: {
          ...(res.spec || {}),
          clusterRefs: (res.spec?.clusterRefs || []).map((ref) => ({
            uid: ref.clusterUid,
            name: ref.clusterName,
          })),
        },
      }));
    dispatch({
      type: "REFRESH_WORKSPACE",
      promise,
      schema: WorkspaceSchema,
    });

    return promise;
  };
}

export function getWorkspaceByUid(uid) {
  return (dispatch, getState) => {
    const currentWorkspace = getRawWorkspace(getState());

    if (currentWorkspace?.metadata?.uid === uid) {
      return;
    }
    return dispatch(fetchWorkspace(uid));
  };
}

export function openWorkspaceSettingsModalService(menuItem = "basic-info") {
  return () => {
    workspaceSettingsModal.open({ menuItem });
  };
}

export const deleteWorkspaceAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      deleteWorkspaceFormActions.submit({
        module: DELETE_WORKSPACE_FORM_MODULE,
      })
    );
  },
});

export function refreshWorkspaceDetails() {
  return async (dispatch) => {
    await dispatch(namespacesListingActions.refreshItems(NAMESPACES_MODULE));
    dispatch(refreshWorkspace());
  };
}

export function fetchNamespacesConsumption() {
  return async function thunk(dispatch, getState) {
    const uid = getState().location.params.id;
    const promise = async function loadData() {
      const startTime = moment()
        .subtract(1, "hour")
        .utc()
        .format(GENERAL_DATE_FORMAT);
      const endTime = moment().utc().format(GENERAL_DATE_FORMAT);

      const filters = {
        filter: {
          workspaces: [uid],
          endTime,
          startTime,
          includeControlPlaneMachines: true,
        },
        options: {
          groupBy: "namespace",
          period: 60,
        },
      };
      const rawData = await api.post(
        "v1/dashboard/spectroclusters/resources/consumption",
        filters
      );

      const { result: namespaces } = namespacesFetcher.selector(getState());

      return rawData.resources.map((resource) => {
        const namespace = (namespaces?.items || []).find((ns) => {
          return ns.metadata.entity.name === resource.entity.name;
        });

        return {
          metadata: {
            entity: {
              ...resource.entity,
              uid: namespace?.metadata.entity.uid,
            },
          },
          status: {
            usage: {
              total: resource.total,
            },
          },
        };
      });
    };

    dispatch({
      type: "CLUSTER_WORKLOAD_NAMESPACES_CONSUMPTION",
      promise: promise(),
      schema: [WorkloadNamespaceSchema],
    });
  };
}

const deleteWorkspaceValidator = new Validator();
deleteWorkspaceValidator.addRule(["workspaceName"], Missing());
deleteWorkspaceValidator.addRule(["workspaceName"], function (value) {
  const workspaceName = getRawWorkspace(store.getState())?.metadata?.name;
  if (workspaceName === value) {
    return false;
  }
  return i18next.t("Field must match the workspace name");
});

export const deleteWorkspaceFormActions = createFormActions({
  init() {
    return Promise.resolve({ workspaceName: "" });
  },
  validator: deleteWorkspaceValidator,
  submit: async () => {
    const currentWorkspace = getRawWorkspace(store.getState());
    const promise = api.delete(
      `v1/workspaces/${currentWorkspace?.metadata?.uid}`
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          `Workspace ${currentWorkspace?.metadata?.name} was successfully deleted`
        ),
      });
      workspaceSettingsModal.close();
      history.push("/workspaces/overview");
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to delete the workspace"
        ),
        description: e?.message,
      });
    }
  },
});

export function onDeleteWorkspace() {
  return function thunk(dispatch) {
    dispatch(
      deleteWorkspaceFormActions.init({
        module: DELETE_WORKSPACE_FORM_MODULE,
      })
    );
    deleteWorkspaceModal.open().then(() => {
      return deleteWorkspaceAsyncAction.trigger();
    });
  };
}
