import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import store from "services/store";

import { ResourceRolesSchema } from "utils/schemas";

import Modal from "components/ui/Modal";
import Form from "./components/Form";
import Enumeration from "components/common/Enumeration";
import ActionMenu from "components/common/ActionMenu";
import AddNewItem from "components/styled/AddNewItem";
// import InfoTooltip from "./components/InfoTooltip";

import createFormActions from "modules/form/actions";
import { getAllProjects } from "state/auth/selectors";
import {
  getSelectedResourceRoleEntity,
  // getCurrentProjectRoles,
  getSelectedTeam,
  getSelectedUser,
} from "state/roleManagement/selectors";

import { createForm } from "modules/form";
import createList, { Blocks } from "modules/list";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import {
  resourceRolesModal,
  onResourceRoleDelete,
  filtersFetcher,
} from "state/roleManagement/actions/resourceRoles";
// import { getProjectScopedRoles } from "state/roleManagement/selectors";

import { getFullName } from "utils/presenters";
import { red } from "utils/constants/colors";
import notifications from "services/notifications";
import i18next from "i18next";

// function hasProjectPermissions(role) {
//   return role.spec.permissions.includes("project.get");
// }
export default function createResourceRoleManagement({
  listActions,
  updateData,
}) {
  const moduleName = "resourceRoles";
  const resourceRolesValidator = new Validator();
  resourceRolesValidator.addRule(
    ["roles", "filters", "projectsUids"],
    Missing()
  );

  // resourceRolesValidator.addRule("roles", (_1, _2, data) => {
  //   const projectScopedRoles = getProjectScopedRoles(store.getState());
  //   const selectedRoles = projectScopedRoles.filter((role) =>
  //     data?.roles.includes(role.guid)
  //   );
  //   const alreadyHasPermissions = selectedRoles.some(hasProjectPermissions);

  //   if (alreadyHasPermissions) {
  //     return false;
  //   }

  //   const projectOptions = projectScopedRoles
  //     .filter(hasProjectPermissions)
  //     .map((role) => role.metadata.name);

  //   return `User must have at least one of these roles: ${projectOptions.join(
  //     ", "
  //   )}`;
  // });

  const formActions = createFormActions({
    validator: resourceRolesValidator,
    submit: async (data) => {
      try {
        await updateData(data);
      } catch (err) {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: err?.message,
        });

        return Promise.reject();
      }
      store.dispatch(listActions.initialize(moduleName));
    },
    init: () => {
      store.dispatch(filtersFetcher.fetch());
      const selectedResourceRole = getSelectedResourceRoleEntity(
        store.getState()
      );

      if (resourceRolesModal?.data?.selectedResourceRoleGuid) {
        return Promise.resolve({
          projectsUids: (selectedResourceRole?.projects || []).map(
            (project) => project.uid
          ),
          roles: (selectedResourceRole?.roles || []).map((role) => role.guid),
          isEdit: true,
          filters: (selectedResourceRole?.filters || []).map(
            (filter) => filter.uid
          ),
        });
      }

      return Promise.resolve({
        projectsUids: [],
        roles: [],
        filters: [],
      });
    },
  });

  const ConnectedForm = connect((state) => {
    const projects = getAllProjects(state);
    const selectedProjectRoleId =
      state.modal.data[resourceRolesModal.guid]?.selectedProjectRoleId;
    // const userProjectRoles = getCurrentProjectRoles(state);

    const parsedProjects = projects
      // .filter(
      //   (project) =>
      //     selectedProjectRoleId ||
      //     !userProjectRoles.some(
      //       (projectRole) =>
      //         projectRole.project.guid === project.guid &&
      //         !!projectRole.roles?.length
      //     )
      // )
      .map((project) => ({
        value: project.metadata.uid,
        label: project.metadata.name,
      }));

    return {
      projects: parsedProjects,
      selectedProjectRoleId,
    };
  })(Form);

  const ProjectRolesForm = createForm({
    Component: ConnectedForm,
    actions: formActions,
  });

  function openModal(selectedResourceRoleGuid) {
    return (dispatch) => {
      resourceRolesModal.open({ selectedResourceRoleGuid }).then(() => {
        return dispatch(formActions.submit({ module: moduleName }));
      });

      dispatch(formActions.init({ module: moduleName }));
    };
  }

  const ListModule = createList({
    actions: listActions,
    schema: [ResourceRolesSchema],
  });

  function TableRowActions({ selectedEntity, openModal, onRemove }) {
    const { t } = useTranslation();
    const { guid } = selectedEntity;

    let options = [
      {
        key: "edit",
        label: t("Edit"),
        onClick: openModal(guid),
      },
      {
        key: "remove",
        label: t("Remove"),
        color: red,
        onClick: onRemove(guid),
      },
    ];

    return <ActionMenu options={options} />;
  }

  const ConnectedTableRowActions = connect(null, (dispatch) => ({
    openModal: (guid) => () => dispatch(openModal(guid)),
    onRemove: (guid) => () => dispatch(onResourceRoleDelete(guid)),
  }))(TableRowActions);

  function ResourceRolesModule({ openModal, selectedEntity }) {
    const { t } = useTranslation();

    // const renderInheritedRoles = ({ roles, inheritedRoles }) => {
    //   const rolesNames = roles.map((role) => role.metadata.name);

    //   const inheritedRoleNames = [
    //     ...new Set(inheritedRoles.map((role) => role.metadata.name)),
    //   ].filter((roleName) => !rolesNames.includes(roleName));

    //   return inheritedRoleNames.length ? (
    //     <Enumeration items={inheritedRoleNames} />
    //   ) : (
    //     "-"
    //   );
    // };

    const columns = [
      {
        title: t("Projects"),
        key: "name",
        render: function renderName({ projects, roles }) {
          // const alreadyHasPermissions = roles.some(hasProjectPermissions);
          // const projectOptions = projectScopedRoles
          //   ?.filter(hasProjectPermissions)
          //   .map((role) => role.metadata.name);

          if (projects.length === 0) {
            return "-";
          }

          const projectNames = projects.map((project) => project.name);
          return <Enumeration items={projectNames} />;
          // return projects.map((project) => (
          //   <>
          //     <span>{project.name}</span>
          //     <InfoTooltip
          //       showTooltip={!alreadyHasPermissions}
          //       title={`User must have at least one of these roles: ${projectOptions.join(
          //         ", "
          //       )}`}
          //     />
          //   </>
          // ));
        },
      },
      {
        title: t("Filters"),
        key: "filters",
        render: function renderRoles({ filters }) {
          const rolesNames = filters.map((filter) => filter.name);
          return <Enumeration items={rolesNames} />;
        },
      },
      {
        title: t("Roles"),
        key: "roles",
        render: function renderRoles({ roles }) {
          const rolesNames = roles.map((role) => role.metadata.name);
          return <Enumeration items={rolesNames} />;
        },
      },
      {
        title: "",
        render: function renderTableRowActions(data) {
          return <ConnectedTableRowActions selectedEntity={data} />;
        },
        key: "actions",
      },
    ];

    return (
      <>
        <ListModule module={moduleName}>
          <Blocks.Table columns={columns} />
        </ListModule>
        <AddNewItem onClick={() => openModal(null)} data-qa="add_resource_role">
          {t("New Resource Role")}
        </AddNewItem>
        <Modal
          service={resourceRolesModal}
          title={t("Add Roles to {{name}}", {
            name: selectedEntity.metadata.name || getFullName(selectedEntity),
          })}
        >
          <ProjectRolesForm module={moduleName} />
        </Modal>
      </>
    );
  }

  return connect(
    (state) => ({
      selectedEntity:
        state.roleManagement.entityType === "user"
          ? getSelectedUser(state)
          : getSelectedTeam(state),
    }),
    {
      openModal,
    }
  )(ResourceRolesModule);
}
