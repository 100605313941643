import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatToUSCurrency } from "utils/number";

const InstanceDetails = styled.div`
  font-size: 12px;
  margin-top: -10px;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
`;

export const InstanceTypeDescription = ({
  cpu,
  memory,
  price,
  nonSupportedZones,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {cpu && <div>CPU: {cpu} vCPU</div>}
      {memory && <div>RAM: {memory} GB</div>}
      {price && (
        <div>
          {t("Price")}: ${price}/h
        </div>
      )}
      {nonSupportedZones?.length > 0 ? (
        <div>
          {t("Non Supported Zones")}: {nonSupportedZones.join(", ")}
        </div>
      ) : null}
    </div>
  );
};

export const renderInstanceTypeDescription = (props) => {
  const { cpu, memory, price, nonSupportedZones } = props;

  if (cpu || memory || price || nonSupportedZones?.length > 0) {
    return <InstanceTypeDescription {...props} />;
  }

  return null;
};

export function SelectedInstanceTypeDetails({
  type,
  price,
  cpu,
  memory,
  disk,
  showDescription = true,
}) {
  const { t } = useTranslation();

  const formattedPrice = useMemo(() => {
    return formatToUSCurrency(price, "usd");
  }, [price]);

  const instanceTypeDetails = useMemo(() => {
    if (!cpu && !memory && !disk) {
      return null;
    }

    const cpuValue = cpu !== undefined && `${cpu} vCPUs`;
    const memoryValue = memory !== undefined && `${memory} GB RAM`;
    const diskValue = disk !== undefined && `${disk} GB Disk Size`;
    const details = [cpuValue, memoryValue, diskValue]
      .filter(Boolean)
      .join(", ");

    return `(${details})`;
  }, [cpu, memory, disk]);

  const renderPrice = () => {
    if (!price) {
      return type;
    }
    return (
      <>
        {showDescription ? t("Cost of {{type}} - ", { type }) : ""}
        <strong>{t("{{price}}/hour", { price: formattedPrice })}</strong>
      </>
    );
  };

  if (!type) {
    return null;
  }

  return (
    <InstanceDetails>
      {renderPrice()} {instanceTypeDetails}
    </InstanceDetails>
  );
}
