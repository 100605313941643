import { createSelector } from "reselect";
import createApi from "./api/create";

const api = createApi();
class ApplicationEnvironment {
  _env = {};
  _cloudTypes = [];
  _featureFlags = [];

  _cloudsByTypeSelector = createSelector(() =>
    this._cloudTypes.reduce((accumulator, cloudType) => {
      accumulator[cloudType.name] = cloudType;
      return accumulator;
    }, {})
  );

  isCustomCloud(env) {
    const cloudsByType = this._cloudsByTypeSelector();
    return cloudsByType?.[env]?.isCustom;
  }

  async loadEnv() {
    let data = {};
    let versionData = {};

    try {
      data = await api.get(`app.json?${Date.now()}`);
    } catch (err) {}

    if (!data.PALETTE_VERSION) {
      try {
        versionData = await api.get(`v1/mgmt/app/version`);
        data.PALETTE_VERSION = versionData.version.latestVersion;
      } catch (err) {}
    }

    this._env = data;
  }

  async loadCloudTypes() {
    let cloudTypes = [];
    try {
      const response = await api.get("v1/clouds/cloudTypes");
      cloudTypes = response.cloudTypes.map((cloudType) => {
        return {
          ...cloudType,
          smallLogoPath: `/v1/clouds/cloudTypes/${cloudType.name}/logo`,
          logoPath: `/v1/clouds/cloudTypes/${cloudType.name}/logo`,
        };
      });
    } catch (err) {}

    this._cloudTypes = cloudTypes;
  }

  async loadFeatureFlags() {
    try {
      const response = await api.get("v1/users/system/features");
      this._featureFlags = response.items;
    } catch (err) {}
  }

  initialize() {
    const envLoader = this.loadEnv();
    const cloudTypesLoader = this.loadCloudTypes();
    const featureFlags = this.loadFeatureFlags();
    return Promise.allSettled([envLoader, cloudTypesLoader, featureFlags]);
  }

  get cloudTypes() {
    return this._cloudTypes;
  }

  get env() {
    return this._env;
  }

  get featureFlags() {
    return this._featureFlags.map((flag) => flag.metadata.name);
  }
}

const appEnv = new ApplicationEnvironment();

export default appEnv;
