import i18next from "i18next";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import Validator from "services/validator";
import { isValidAbsolutePath, Missing } from "services/validator/rules";
import { getSelectedCloudAccount } from "state/cluster/services/create";
import clusterFormActions from "../form";
import { generateCoxDeployment } from "../utils";
import notifications from "services/notifications";

export const coxNodeValidator = new Validator();
const deploymentValidator = new Validator();
deploymentValidator.addRule(["name", "pops"], Missing({ message: () => " " }));

export const volumeValidator = new Validator();
volumeValidator.addRule(["path", "size"], Missing({ message: () => " " }));
volumeValidator.addRule(["path"], isValidAbsolutePath());

coxNodeValidator.addRule(["deployments"], deploymentValidator);
coxNodeValidator.addRule(["instanceType"], Missing());
coxNodeValidator.addRule(["persistentStorages"], volumeValidator);
coxNodeValidator.addRule(["persistentStorages"], (value) => {
  if (value.length > 7) {
    return i18next.t("A maximum of 7 volumes is allowed");
  }

  return false;
});

export const coxClusterValidator = new Validator();
coxClusterValidator.addRule("sshKeys", Missing());
coxClusterValidator.addRule(
  ["coxEdgeLoadBalancerConfig", "coxEdgeWorkerLoadBalancerConfig"],
  function* (value, key) {
    yield {
      field: `${key}.pops`,
      result: Missing()(value.pops),
    };
  }
);

export const regionsFetcher = keyedDataFetcher({
  selectors: ["cluster", "cox", "regions"],
  async fetchData([_1, _2, _3, credential]) {
    const response = await api.get("v1/clouds/coxedge/regions", {
      cloudAccountUid: credential,
    });
    return response.regions;
  },
});

export const coxInstanceTypesFetcher = keyedDataFetcher({
  selectors: ["cluster", "cox", "instanceTypes"],
  async fetchData([_, _2, _3, regionData]) {
    const [region, credential] = regionData.split("-");
    const selectedRegion = regionsFetcher
      .selector(store.getState())
      [credential]?.result.find((reg) => reg.code === region);
    if (!selectedRegion) {
      return [];
    }

    let instanceTypes = [];

    try {
      const response = await api.get(
        `v1/clouds/coxedge/regions/${selectedRegion.name}/instancetypes`
      );
      instanceTypes = response.instanceTypes;
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the instance types"
        ),
        description: e.message,
      });
    }

    return instanceTypes;
  },
});

export const organizationsFetcher = keyedDataFetcher({
  selectors: ["cluster", "cox", "organizations"],
  async fetchData([_, _1, _2, credential]) {
    const response = await api.get(
      `v1/clouds/coxedge/organizations?cloudAccountUid=${credential}`
    );
    return response.organizations;
  },
});

export const environmentsFetcher = dataFetcher({
  selectors: [
    "cluster",
    "cox",
    "environments",
    (state) => state.forms?.cluster?.data?.credential,
    (state) => state.forms?.cluster?.data?.organizationId,
  ],
  async fetchData([_, _1, _2, credential, organizationId]) {
    const response = await api.get(
      `v1/clouds/coxedge/environments?cloudAccountUid=${credential}&organizationId=${organizationId}`
    );
    return response.environments;
  },
});

export function fetchNodesConfigParams({ region } = {}) {
  return function thunk(dispatch, getState) {
    const cloudAccount = getSelectedCloudAccount(getState());
    dispatch(regionsFetcher.key(cloudAccount.metadata.uid).fetch());

    if (!!region) {
      dispatch(
        coxInstanceTypesFetcher
          .key(`${region}-${cloudAccount.metadata.uid}`)
          .fetch()
      );
    }
  };
}

function fetchClusterConfigParams() {
  return function thunk(dispatch, getState) {
    const cloudAccount = getSelectedCloudAccount(getState());

    dispatch(regionsFetcher.key(cloudAccount.metadata.uid).fetch());
    dispatch(organizationsFetcher.key(cloudAccount.metadata.uid).fetch());
    dispatch(environmentsFetcher.fetch());
  };
}

function selectCredentialEffect() {
  return async (dispatch, getState) => {
    const cloudAccount = getSelectedCloudAccount(getState());

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          organizationId: cloudAccount.spec.organizationId,
          environment: cloudAccount.spec.environment,
        },
      })
    );
  };
}

const effects = {
  selectCredentialEffect,
  fetchNodesConfigParams,
  fetchClusterConfigParams,
};

export function createDeploymentsActions({
  getDeploymentsSelector,
  getAccountUidSelector,
  formActions,
  module,
  name,
}) {
  return {
    onRegionChange(name, value) {
      return function thunk(dispatch, getState) {
        const accountUid = getAccountUidSelector(getState());
        dispatch(coxInstanceTypesFetcher.key(`${value}-${accountUid}`).fetch());
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value,
          })
        );
      };
    },
    onAdd() {
      return function thunk(dispatch, getState) {
        const deployments = getDeploymentsSelector(getState());
        dispatch(
          formActions.onChange({
            module,
            name: `${name}.deployments`,
            value: [...deployments, generateCoxDeployment()],
          })
        );
      };
    },
    onDelete(path) {
      return (dispatch, getState) => {
        const deployments = [...getDeploymentsSelector(getState())];
        const pathParts = path.split(".");
        const deploymentIndex = pathParts[3];
        deployments.splice(deploymentIndex, 1);

        if (deployments.length === 0) {
          deployments.push(generateCoxDeployment());
        }

        dispatch(
          formActions.onChange({
            module,
            name: `${name}.deployments`,
            value: deployments,
          })
        );
      };
    },
  };
}

export function createNetworkRulesActions({
  getItems,
  formActions,
  module,
  name,
}) {
  return {
    onAdd(item) {
      return function thunk(dispatch, getState) {
        const items = getItems(getState());
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value: [...items, item],
          })
        );
      };
    },
    onReplace(item, index) {
      return function thunk(dispatch, getState) {
        const items = [...getItems(getState())];
        items[index] = item;
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value: items,
          })
        );
      };
    },
    onDelete(index) {
      return (dispatch, getState) => {
        const items = [...getItems(getState())];
        items.splice(index, 1);

        dispatch(
          formActions.onChange({
            module,
            name,
            value: items,
          })
        );
      };
    },
  };
}

export function createVolumeActions({ getItems, formActions, module, name }) {
  return {
    onAdd(item) {
      return function thunk(dispatch, getState) {
        const items = getItems(getState());
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value: [...items, item],
          })
        );
      };
    },
    onReplace(item, index) {
      return function thunk(dispatch, getState) {
        const items = [...getItems(getState())];
        items[index] = item;
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value: items,
          })
        );
      };
    },
    onDelete(index) {
      return (dispatch, getState) => {
        const items = [...getItems(getState())];
        items.splice(index, 1);

        dispatch(
          formActions.clearFieldErrors({
            module,
            field: name,
          })
        );

        dispatch(
          formActions.onChange({
            module,
            name,
            value: items,
          })
        );
      };
    },
  };
}

export default effects;
