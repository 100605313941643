import get from "lodash/get";

export function getTerminalKeys(obj, path = "", separator = ".") {
  if (obj === null) {
    return [];
  }

  return Object.keys(obj).reduce((accumulator, key) => {
    const value = obj[key];
    const currentPath = path ? `${path}${separator}${key}` : key;
    if (typeof value === "object") {
      if (Array.isArray(value) && value.length === 0) {
        return accumulator.concat([currentPath]);
      }
      return accumulator.concat(getTerminalKeys(value, currentPath, separator));
    }

    return accumulator.concat([currentPath]);
  }, []);
}

export function getPathForValue(obj, value) {
  const keys = getTerminalKeys(obj);

  return keys.find((key) => get(obj, key) === value);
}

export function cleanEmptyKeys(obj) {
  return Object.keys(obj).reduce((accumulator, key) => {
    const value = obj[key];
    if (!value) {
      return accumulator;
    }

    return {
      ...accumulator,
      [key]: value,
    };
  }, {});
}
