export const initialState = {
  isLoading: false,
  isAccountValidated: false,
  step: "account",
  datacenters: [],
  overlordData: null,
  configureIPPoolUid: "",
};

export function FETCH_OVERLORD_CONFIG_REQUEST(nextState) {
  nextState.configureIPPoolUid = null;
  nextState.isAccountValidated = false;
}

export function RESET_OVERLORD_CONFIG_STEP(nextState) {
  nextState.step = "account";
}

export function VALIDATE_OVERLORD_ACCOUNT_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function VALIDATE_OVERLORD_ACCOUNT_SUCCESS(nextState) {
  nextState.isLoading = false;
  nextState.isAccountValidated = true;
}

export function VALIDATE_OVERLORD_ACCOUNT_FAILURE(nextState) {
  nextState.isLoading = false;
  nextState.isAccountValidated = false;
}

export function CONFIGURE_OVERLORD_ACCOUNT_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function CONFIGURE_OVERLORD_ACCOUNT_FAILURE(nextState) {
  nextState.isLoading = false;
}

export function CONFIGURE_OVERLORD_ACCOUNT_SUCCESS(nextState) {
  nextState.isLoading = false;
  nextState.step = "config";
}

export function CONFIGURE_IPAM_STEP(nextState) {
  nextState.step = "ipam";
}

export function OVERLORD_CONFIGURE_PREV_STEP(nextState, { step }) {
  nextState.step = step;
}

export function OPEN_MODAL(nextState, { name }) {
  if (name !== "overlordConfigure") {
    return;
  }
  nextState.isAccountValidated = false;
}

export function FETCH_OVERLORD_CONFIG_SUCCESS(nextState, { result }) {
  nextState.overlordData = result;
  if (result?.spec?.cloudAccountUid) {
    nextState.isAccountValidated = true;
  }
}

export function FORM_ON_CHANGE(nextState, { module, name }) {
  if (module !== "overlordAccount") {
    return;
  }

  const cloudSpecificFields = {
    vsphere: ["vcenterServer"],
    openstack: ["identityEndpoint", "parentRegion", "defaultDomain"],
    maas: ["apiEndpoint", "apiKey"],
  };

  if (
    [
      "username",
      "password",
      ...Object.keys(cloudSpecificFields)
        .map((key) => cloudSpecificFields[key])
        .flat(),
    ].includes(name)
  ) {
    nextState.isAccountValidated = false;
  }
}

export function CONFIGURE_IPAM_EDIT_MODE(nextState, { configureIPPoolUid }) {
  nextState.configureIPPoolUid = configureIPPoolUid;
}
