import i18n from "i18next";

const CLOUD_STEP = {
  title: () => i18n.t("Cloud Type"),
  id: "cloud-type",
};

export const initialState = {
  stepId: "basic-info",
  steps: [
    {
      title: () => i18n.t("Basic Information"),
      id: "basic-info",
    },
    CLOUD_STEP,
    {
      title: () => i18n.t("Profile Layers"),
      id: "profile-layers",
    },
    {
      title: () => i18n.t("Review"),
      id: "review",
    },
  ],
  isLoadingProfileNameValidation: false,
};

export function FORM_LOAD_REQUEST(nextState, { module }) {
  if (module === "clusterprofile") {
    Object.keys(initialState).forEach((key) => {
      nextState[key] = initialState[key];
    });
  }
}

export function CLUSTER_PROFILE_NEXT_STEP(nextState, action, state) {
  const currentStep = state.steps.findIndex((step) => step.id === state.stepId);
  nextState.stepId = state.steps[currentStep + 1].id;
}

export function CLUSTER_PROFILE_PREV_STEP(nextState, action, state) {
  const currentStep = state.steps.findIndex((step) => step.id === state.stepId);
  nextState.stepId = state.steps[currentStep - 1].id;
}

export function CLUSTER_PROFILE_SET_DESCRIPTION(
  nextState,
  { stepId, description },
  state
) {
  const currentStep = state.steps.findIndex((step) => step.id === stepId);
  nextState.steps[currentStep].description = description;
}

export function CLUSTER_PROFILE_CHANGE_STEP(nextState, { stepNumber }, state) {
  nextState.stepId = state.steps[stepNumber].id;
}

export function FORM_ON_CHANGE(nextState, { name, value, module }, state) {
  if (name === "profileType" && module === "clusterprofile") {
    if (value !== "add-on") {
      if (state.steps.find((step) => step.id === CLOUD_STEP.id)) {
        return;
      }
      nextState.steps.splice(1, 0, CLOUD_STEP);
    } else {
      nextState.steps.splice(1, 1);
    }
  }
}

export function PROFILE_NAME_VALIDATION_REQUEST(nextState) {
  nextState.isLoadingProfileNameValidation = true;
}

export function PROFILE_NAME_VALIDATION_SUCCESS(nextState) {
  nextState.isLoadingProfileNameValidation = false;
}

export function PROFILE_NAME_VALIDATION_FAILURE(nextState) {
  nextState.isLoadingProfileNameValidation = false;
}
