import React from "react";
import styled from "styled-components";
import astronaut from "assets/images/appModeOverview/astronaut.png";
import clusterTypeIcon from "assets/images/appModeOverview/cluster-type.svg";
import { useTranslation } from "react-i18next";
import * as COLORS from "utils/constants/colors";
import gameBoxImage from "assets/images/appModeOverview/gamebox.png";
import marvelImage from "assets/images/appModeOverview/marvel.png";
import Button from "components/ui/Button";
import { connect } from "react-redux";
import { getDemoAppProfileUid } from "state/appModeOverview/selectors/appDeploymentsWidget";
import { generatePath } from "react-router";
import { DEPLOYMENTS } from "utils/constants/routes";
import history from "services/history";

const WidgetWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1545px) {
    flex-wrap: wrap;
  }
`;

const SpectroIntro = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 1;
  padding: 24px;
  justify-content: center;
  flex-basis: 50%;
`;

const AstroContent = styled.div`
  background-image: url(${clusterTypeIcon});
  background-size: 100% 100%;
  flex-basis: 50%;
`;

const Logo = styled.div`
  height: 130px;
  width: 130px;
  margin: auto;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled.p`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
  white-space: ${(props) => props.wrap};
  color: ${(props) => props.color};
  margin: 0;
  align-self: center;
`;

const SpectroDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  gap: 16px;
`;

const GameDemo = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 16px;
  justify-content: flex-end;
`;

const GameWidget = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #48438d;
  width: 250px;
  height: 276px;
  padding: 16px;
  border-radius: 8px;
`;

const StyledButton = styled(Button)`
  background-color: #3ab795;
`;

const TextWrapper = styled.div`
  background-image: url(${clusterTypeIcon});
  background-size: 50% 100%;
`;

const WelcomeWidget = ({ demoProfileAppData }) => {
  const { t } = useTranslation();
  const marvelAppProfileUid = demoProfileAppData["profile-marvel"] || false;
  const gameboxAppProfileUid = demoProfileAppData["profile-gamebox"] || false;
  return (
    <WidgetWrapper>
      <SpectroIntro>
        <AstroContent>
          <Logo>
            <img src={astronaut} alt="astronaut" height="130px" />
          </Logo>
          <Title>
            <StyledText
              fontSize="26px"
              fontWeight="400"
              wrap="nowrap"
              lineHeight="46px"
            >
              {t("Welcome to")}
            </StyledText>
            <StyledText
              fontSize="46px"
              fontWeight="900"
              wrap="nowrap"
              lineHeight="46px"
            >
              {t("App Mode")}
            </StyledText>
          </Title>
        </AstroContent>
        <SpectroDescription>
          <StyledText fontSize="16px" fontWeight="200" lineHeight="24px">
            {t(
              "Palette App Mode, a powerful feature set that IT Ops teams can deliver to their development teams, without any of the Kubernetes complexity, to model and deploy applications at scale."
            )}
          </StyledText>
          <StyledText fontSize="12px" fontWeight="200" lineHeight="18px">
            {t(
              "Designed to give developers the control they need, being able to manage the complete app lifecycle; from monitoring the health of applications or inspecting for errors, backing up their workloads, adding new parameters, etc."
            )}
          </StyledText>
        </SpectroDescription>
      </SpectroIntro>
      <GameDemo>
        <StyledText
          fontSize="20px"
          fontWeight="200"
          lineHeight="24px"
          wrap="nowrap"
        >
          {t("...try it yourself:")}
        </StyledText>
        <GameWidget>
          <img
            src={gameBoxImage}
            alt="GameBox"
            width="218px"
            height="98px"
          ></img>
          <TextWrapper>
            <StyledText
              fontSize="12px"
              fontWeight="200"
              lineHeight="18px"
              color={COLORS.white}
            >
              {t(
                "Palette App Mode, a powerful feature set that IT Ops teams can deliver to their development teams, without any of the Kubernetes"
              )}
            </StyledText>
          </TextWrapper>
          <StyledButton
            data-qa="gamebox-button"
            disabled={!gameboxAppProfileUid}
            onClick={() =>
              history.push(
                generatePath(DEPLOYMENTS.CREATE, {
                  appProfileId: gameboxAppProfileUid,
                })
              )
            }
          >
            {t("Deploy Gamebox App")}
          </StyledButton>
        </GameWidget>
        <GameWidget>
          <img src={marvelImage} alt="Marvel" width="218px" height="98px"></img>
          <TextWrapper>
            <StyledText
              fontSize="12px"
              fontWeight="200"
              lineHeight="18px"
              color={COLORS.white}
            >
              {t(
                "Palette App Mode, a powerful feature set that IT Ops teams can deliver to their development teams, without any of the Kubernetes complexity"
              )}
            </StyledText>
          </TextWrapper>
          <StyledButton
            data-qa="marvel-button"
            disabled={!marvelAppProfileUid}
            onClick={() =>
              history.push(
                generatePath(DEPLOYMENTS.CREATE, {
                  appProfileId: marvelAppProfileUid,
                })
              )
            }
          >
            {t("Deploy Marvel App")}
          </StyledButton>
        </GameWidget>
      </GameDemo>
    </WidgetWrapper>
  );
};

export default connect((state) => ({
  demoProfileAppData: getDemoAppProfileUid(state),
}))(WelcomeWidget);
