import { createSelector } from "reselect";

import {
  EDIT_CLUSTERS_MODULE,
  EDIT_NAMESPACES_MODULE,
} from "state/workspaces/services/edit";
import { presentNamespaces } from "./utils";
import { getEntity } from "utils/entities";
import { ClusterSchema } from "utils/schemas";

export const getSelectedClustersEntities = getEntity(
  (state) => state.forms[EDIT_CLUSTERS_MODULE]?.data?.selectedClustersGuids,
  [ClusterSchema]
);

export const getSelectedClustersList = createSelector(
  (state) => getSelectedClustersEntities(state),
  (clusters) => {
    return (clusters || []).map((item) => {
      return {
        ...item,
        key: item?.guid,
        metadata: {
          ...item?.metadata,
          name: item?.metadata?.name || "",
        },
        spec: {
          ...item?.specSummary,
          ...item?.spec,
        },
        health: item?.status.health?.state,
        status: {
          ...item?.status,
          health: item?.status?.health || {},
          state: item?.status?.state || "",
        },
        specSummary: {
          ...item?.specSummary,
          cloudConfig: item?.specSummary?.cloudConfig || {},
        },
        metrics: {
          ...item?.status?.metrics,
          cpu: {
            usage: item?.status?.metrics?.cpu?.usage || 0,
            total: item?.status?.metrics?.cpu?.total || 0,
          },
        },
        isBrownfield:
          item?.status?.clusterImport?.isBrownfield ||
          !!item?.status?.clusterImport?.state,
      };
    });
  }
);

export const getSelectedNamespaces = createSelector(
  (state) => state.forms[EDIT_NAMESPACES_MODULE]?.data.namespaces || [],
  (namespaces) => {
    return presentNamespaces(namespaces);
  }
);

export const getNamespaceSelectOptions = createSelector(
  (state) => state.forms[EDIT_NAMESPACES_MODULE]?.data?.namespaces,
  (namespaces) => {
    return (namespaces || [])
      .filter((ns) => !ns.isChild)
      .map((ns) => ({
        label: ns.namespaceName,
        value: ns.namespaceName,
      }));
  }
);
