import { createTencentFormFactory } from "modules/cluster/tencent";
import clusterFormActions from "../form";

const FORM_MODULE_NAME = "cluster";

export const tencentCloudForm = createTencentFormFactory({
  formActions: clusterFormActions,
  formModuleName: FORM_MODULE_NAME,
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

export function onTencentVpcIdChange(value) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vpcid: value,
          publicSecurityGroup: "",
          privateSubnet: "",
        },
      })
    );

    dispatch(clusterFormActions.clearErrors({ module: FORM_MODULE_NAME }));
    dispatch(tencentCloudForm.fetchers.publicSecurityGroupFetcher.fetch());
  };
}

export function onEndpointAccessChange(value) {
  return (dispatch) => {
    let updates = { endpointAccess: value };
    let field = "";

    if (value === "public") {
      updates = { ...updates, privateSubnet: "" };
      field = "privateSubnet";
    }

    if (value === "private") {
      updates = { ...updates, publicSecurityGroup: "" };
      field = "publicSecurityGroup";
    }
    dispatch(
      clusterFormActions.clearFieldErrors({ module: FORM_MODULE_NAME, field })
    );
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates,
      })
    );
  };
}

function selectCredentialEffect() {
  return async function (dispatch, _) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          sshKeys: [],
          region: "",
          vpcid: "",
        },
      })
    );
  };
}
function selectRegionEffect(region) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          sshKeys: [],
          vpcid: "",
          publicSecurityGroup: "",
          privateSubnet: "",
        },
      })
    );
    dispatch(tencentCloudForm.fetchers.sshFetcher.fetch());
    dispatch(tencentCloudForm.fetchers.vpcsFetcher.fetch());
    dispatch(tencentCloudForm.fetchers.publicSecurityGroupFetcher.fetch());
  };
}

const effects = {
  selectCredentialEffect,
  selectRegionEffect,
};

export default effects;
