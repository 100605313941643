import React from "react";
import { useParams } from "react-router";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import Switch from "components/common/Switch";

import { REGISTRIES } from "utils/constants/routes";

const Registries = React.lazy(() => import("./index"));
const CreateOrEditHelmRegistry = React.lazy(() =>
  import("pages/settings/registries/helmregistries/create")
);

const CreateOrEditOciRegistry = React.lazy(() =>
  import("pages/settings/registries/oci/create")
);

function RegistryCreateOrEdit() {
  const params = useParams();
  return (
    <Switch value={params.tab}>
      <Switch.Case value="helm">
        <CreateOrEditHelmRegistry />
      </Switch.Case>
      <Switch.Case value="oci">
        <CreateOrEditOciRegistry />
      </Switch.Case>
    </Switch>
  );
}

export default createNamespace({
  namespace: "registries",
  Component: function Main() {
    return (
      <>
        <Route devOnly path={REGISTRIES.ROOT} component={Registries} />
        <Route
          devOnly
          path={REGISTRIES.EDIT_REGISTRY}
          component={RegistryCreateOrEdit}
        />
        <Route
          devOnly
          path={REGISTRIES.CREATE_REGISTRY}
          component={RegistryCreateOrEdit}
        />
      </>
    );
  },
});
