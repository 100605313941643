import { v4 as uuid } from "uuid";
import i18next from "i18next";

import Validator from "services/validator";
import store from "services/store";
import createFormActions from "modules/form/actions";
import fetchers, {
  PROFILE_TYPES_FORM_MODULES,
} from "modules/profileBuilder/services";

import {
  getSelectedClusterProfileTemplate,
  getFormattedPayload,
} from "state/clusterprofile/selectors/layerConfig";
import { systemProfileBuilderEditModule } from "state/profiles/system/services/create";

import {
  Missing,
  areValidKubernetesTags,
  DebouncedRule,
  SemanticVersion,
  isValidTagSelection,
} from "services/validator/rules";
import historyService from "services/history";
import notifications from "services/notifications";
import api from "services/api/index";
import { PackSchema } from "utils/schemas";
import AsyncAction from "modules/asyncAction";

import { LAYERS } from "state/clusterprofile/reducers/layerConfig";
import {
  getRawClusterProfile,
  isClusterProfileUsed,
} from "state/clusterprofile/selectors/details";
import { getCurrentStep } from "state/clusterprofile/selectors/create";

import {
  repositoriesFetcher,
  profileBuilderCreateModule,
  profileBuilderEditModule,
} from "../services";
import {
  fetchClusterProfile,
  fetchResolvedValues,
} from "state/clusterprofile/actions/details";
import { appProfileFormActions } from "state/profiles/system/actions/create/form";
import { extractInstallOrder, extractPackDisplayName } from "utils/yaml";
import { getAllRepos } from "modules/profileBuilder/selectors";
import { createTwoWaySyncer } from "utils/editor/formMode";
import { COXEDGE_ENVIRONMENT } from "utils/constants";

export const CLUSTER_PROFILE_MODULE = "clusterprofile";
const reservedTags = ["microsoft", "azure", "windows"];

const validator = new Validator();

const validateClusterprofileName = async (value, key, data) => {
  let error;

  if (data.persisted) {
    return false;
  }

  const version = data.version || "1.0.0";
  const promise = api.get(
    `v1/clusterprofiles/validate/name?name=${value}&version=${version}`
  );

  try {
    await promise;
  } catch (err) {
    if (err.code === "ClusterProfileAlreadyExists") {
      error = {
        result: i18next.t(
          `A cluster profile with the name "{{ value }}" and version "{{ version }}" already exists`,
          {
            value,
            version,
          }
        ),
        field: "name",
      };
    }
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateClusterprofileName);

validator.addRule(["name", "cloudType"], Missing());
validator.addRule(["name"], (value, key, data) => {
  if (!value) {
    return;
  }
  const promise = nameValidationRule(value, key, data);
  store.dispatch({
    type: "PROFILE_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["tags"], areValidKubernetesTags());
validator.addRule(["tags"], (value) => {
  return isValidTagSelection({ reservedTags })(value);
});

validator.addRule(["version"], SemanticVersion());

export const clusterProfileFormActions = createFormActions({
  validator,
  async init() {
    const { location } = store.getState().router;
    store.dispatch(fetchers.repositoriesFetcher.flushCache());
    store.dispatch(fetchers.helmRepositoriesFetcher.flushCache());

    if (location.pathname.includes("/create")) {
      initProfileBuilder("cluster", "aws");
      return Promise.resolve({
        persisted: false,
        name: "",
        version: "",
        description: "",
        tags: [],
        layers: LAYERS,
        profileType: "cluster",
        cloudType: "aws",
      });
    }

    const clusterprofile = getRawClusterProfile(store.getState());
    await store.dispatch(fetchers.allRepositoriesFetcher.fetch());
    await store.dispatch(fetchers.ociRepositoriesFetcher.fetch());
    const repositories = getAllRepos(store.getState());
    const inUse = isClusterProfileUsed(store.getState());

    const zarfRepositories = repositories
      .filter((repo) => repo.kind === "zarf")
      .map((repo) => repo.uid);

    if (clusterprofile) {
      const { metadata } = clusterprofile;

      await store.dispatch(fetchClusterProfilePacks(metadata.uid));

      let clusterProfileTemplate = getSelectedClusterProfileTemplate(
        store.getState()
      );

      clusterProfileTemplate = getSelectedClusterProfileTemplate(
        store.getState()
      );
      const layers = clusterProfileTemplate.packs.map(async (packVersion) => {
        const { pack } = packVersion;
        let type = pack.spec.addonType || pack.spec.layer;
        let extraProps = {};

        if (pack.spec.type === "manifest") {
          type = "manifest";
          extraProps = {
            formType: "manifest",
          };
        }
        let isIntegration =
          !!pack.spec?.template?.parameters?.inputParameters?.length;
        let integrationParams = {};
        if (isIntegration) {
          type = pack.spec.addonSubType || pack.spec.layer;
          const syncer = createTwoWaySyncer({
            formMeta: pack.spec.template?.parameters?.inputParameters || [],
            values: packVersion.values,
          });
          integrationParams = syncer.populateForm();
        }
        if (
          pack.spec.type === "oci" &&
          !(pack.spec.addonType || pack.spec.layer)
        ) {
          type = "helmChart";
        }

        if (
          pack.spec.type === "oci" &&
          pack.spec.addonType === "ociHelmChart"
        ) {
          type = "helmChart";
        }

        if (zarfRepositories.includes(pack.spec.registryUid)) {
          type = "zarf";
        }

        const registryData = repositories.find(
          (repo) => repo.uid === pack.spec.registryUid
        );

        const manifestMap =
          (packVersion?.manifests
            ? packVersion.manifests
            : pack?.spec?.manifests) || [];

        if (manifestMap.length) {
          let manifestContent = [];
          try {
            manifestContent = await api.get(
              `v1/clusterprofiles/${metadata.uid}/packs/${pack.metadata.name}/manifests`
            );
          } catch (err) {}
          manifestContent.items.forEach((manifestData) => {
            const index = manifestMap.findIndex(
              (manifest) => manifest.uid === manifestData?.metadata?.uid
            );
            if (index !== -1) {
              manifestMap[index].content = manifestData.spec.published.content;
            }
          });
        }

        let accessType = "public";
        if (registryData?.kind === "helm" && registryData?.isPrivate) {
          accessType = "private";
        }

        const layer = {
          type,
          accessType,
          annotations: pack.spec.annotations || {},
          guid: pack.metadata.name,
          initialPackName: pack.metadata.name,
          readOnly:
            inUse &&
            pack.spec.layer === "k8s" &&
            clusterprofile.spec?.published?.cloudType ===
              COXEDGE_ENVIRONMENT.apiKey,
          persisted: true,
          ...extraProps,
          config: {
            ...integrationParams,
            packUid: pack.metadata.uid,
            uid: pack.metadata.uid,
            packVersionGuid: packVersion.guid,
            name: pack.metadata.name,
            tag: packVersion.tag,
            registryUid: pack.spec.registryUid,
            values: packVersion.values,
            readme: pack.spec.readme,
            logo: pack.spec.logoUrl,
            presets: pack.spec.presets,
            template: pack.spec.template,
            schema: pack.spec.schema,
            isOci: pack.spec.type === "oci",
            isIntegration,
            isInvalid: packVersion?.isInvalid || false,
            inValidReason: packVersion?.inValidReason || "",
            manifests: manifestMap,
          },
        };

        layer.config.installOrder = extractInstallOrder(layer.config);
        layer.config.displayName = extractPackDisplayName(layer.config);

        return layer;
      });

      const layersData = await Promise.allSettled(layers);

      let variables = [];
      try {
        variables =
          (await api.get(`v1/clusterprofiles/${metadata.uid}/variables`))
            ?.variables || [];
      } catch (err) {}
      profileBuilderEditModule.actions.initialize({
        layers: layersData
          .filter((data) => data.status === "fulfilled")
          .map((layer) => layer.value),
        isEditMode: true,
        isClusterProfileUsed: inUse,
        cloudType: clusterProfileTemplate?.cloudType || "",
        profileUid: metadata.uid,
        variables: variables.map((variable) => ({
          ...variable,
          useRegex: !!variable.regex,
          useDefaultValue: !!variable.defaultValue,
          isMasked: !!variable.isSensitive,
          isRequired: !!variable.required,
          isReadOnly: !!variable.immutable,
          isHidden: !!variable.hidden,
          guid: uuid(),
          isPersisted: true,
        })),
      });
      try {
        const response = await store.dispatch(
          fetchResolvedValues(metadata.uid)
        );
        profileBuilderEditModule.actions.updateResolvedValues(
          response?.resolved
        );
      } catch (err) {}

      return Promise.resolve({
        persisted: true,
        name: metadata.name,
        cloudType: clusterProfileTemplate?.cloudType || "all",
        profileType: clusterProfileTemplate?.type || "cluster",
        description: metadata.annotations?.description || "",
        tags: (metadata?.labels && Object.keys(metadata?.labels)) || [],
        layers,
      });
    }

    return {
      persisted: false,
      name: "",
      profileType: "cluster",
      description: "",
      tags: [],
      layers: LAYERS,
    };
  },
  async submit(formData) {
    const payload = getFormattedPayload(store.getState());
    let promise;
    const { metadata } = getRawClusterProfile(store.getState()) || {};
    if (formData.persisted) {
      payload.metadata.uid = metadata.uid;
      payload.metadata.labels = metadata.labels;
      promise = api.put(`v1/clusterprofiles/${metadata.uid}`, payload);
    } else {
      promise = api.post("v1/clusterprofiles", payload);
    }

    try {
      const response = await promise;
      const publishPromise = api.patch(
        `v1/clusterprofiles/${response.uid || metadata.uid}/publish`
      );

      await publishPromise;

      if (!formData.persisted) {
        historyService.push("/profiles/cluster");
      }

      notifications.withAudit({
        message: i18next.t(
          "Cluster profile {{profileName}} was saved and published",
          { profileName: formData.name }
        ),
        ...(formData.persisted && {
          promise: publishPromise,
        }),
      });
    } catch (error) {
      const message = formData.persisted
        ? i18next.t("Something went wrong when editing the cluster profile")
        : i18next.t("Something went wrong when creating the cluster profile");

      notifications.error({
        message,
        description: error.message,
      });
    }

    return promise;
  },
});

function fetchClusterProfilePacks(uid) {
  return function thunk(dispatch) {
    const promise = api
      .get(`v1/clusterprofiles/${uid}/packs?includePackMeta=schema,presets`)
      .then((res) => res.items);

    dispatch({
      type: "FETCH_SELECTED_CLUSTER_PROFILE_PACKS",
      promise,
      schema: [PackSchema],
    });

    return promise;
  };
}

function resetForwardSteps() {
  return (dispatch, getState) => {
    const state = getState();
    const steps = state.clusterprofile.create?.steps || [];
    const currentStep = getCurrentStep(state);

    steps.forEach((step, index) => {
      if (index >= currentStep) {
        dispatch({
          type: "CLUSTER_PROFILE_SET_DESCRIPTION",
          stepId: step.id,
          description: "",
        });
      }
    });
  };
}

function initProfileBuilder(profileType, cloudType = "") {
  let layers = [...LAYERS]
    .reverse()
    .map((layer) => ({ ...layer, persisted: true }));

  if (cloudType === "edge-native") {
    layers = layers.filter((layer) => layer.type !== "csi");
  }
  if (profileType === "add-on") {
    layers = [];
  }

  profileBuilderCreateModule.actions.initialize({ layers, cloudType });
}

export function onChangeField(name, value) {
  const props = { name, value, module: CLUSTER_PROFILE_MODULE };
  return (dispatch, getState) => {
    dispatch(clusterProfileFormActions.onChange(props));

    if (name === "profileType") {
      dispatch(
        clusterProfileFormActions.onChange({
          module: CLUSTER_PROFILE_MODULE,
          name: "cloudType",
          value: value === "add-on" ? "all" : "aws",
        })
      );
    }

    if (name === "cloudType" || name === "profileType") {
      const profileType = getState().forms.clusterprofile?.data?.profileType;
      const cloudType = getState().forms.clusterprofile?.data?.cloudType;
      initProfileBuilder(profileType, cloudType);
      dispatch(resetForwardSteps());
    }

    if (name === "version") {
      dispatch(
        clusterProfileFormActions.validateField({
          name: "name",
          module: CLUSTER_PROFILE_MODULE,
        })
      );
    }
  };
}

export function fetchRepositories() {
  return repositoriesFetcher.fetch();
}

export function setAvailableRepositories(repositories) {
  return (dispatch) => {
    dispatch({
      type: "SET_AVAILABLE_REPOSITORIES",
      availableRepositories: repositories,
    });
  };
}

const packSelectorValidator = new Validator();
packSelectorValidator.addRule(
  "name",
  Missing({ message: () => i18next.t("You need to select a pack") })
);
packSelectorValidator.addRule(
  "version",
  Missing({ message: () => i18next.t("You need to select a pack version") })
);

function submitClusterProfile() {
  return async function thunk(dispatch, getState) {
    const clusterprofile = getRawClusterProfile(getState());
    const profileType = clusterprofile?.spec?.published?.type;

    let builderModule = profileBuilderEditModule;
    let profileFormActions = clusterProfileFormActions;

    if (profileType === "system") {
      builderModule = systemProfileBuilderEditModule;
      profileFormActions = appProfileFormActions;
    }

    await dispatch(
      profileFormActions.submit({
        module: PROFILE_TYPES_FORM_MODULES[profileType],
      })
    );

    // NOTE: to allow navigating away immediately we will temporarily initialize
    // the profile builder with empty layers
    builderModule.actions.initialize({
      layers: [],
      isEditMode: true,
      isClusterProfileUsed: isClusterProfileUsed(store.getState()),
    });

    await dispatch(
      fetchClusterProfile(
        clusterprofile.metadata.uid,
        "REFRESH_CLUSTER_PROFILE_DETAILS"
      )
    );
    dispatch(
      profileFormActions.init({
        module: PROFILE_TYPES_FORM_MODULES[profileType],
      })
    );
  };
}

export const saveClusterProfileAsyncAction = new AsyncAction({
  promise: async () => {
    const clusterprofile = getRawClusterProfile(store.getState());
    const profileType = clusterprofile?.spec?.published?.type;

    let builderModule = profileBuilderEditModule;

    if (profileType === "system") {
      builderModule = systemProfileBuilderEditModule;
    }

    const formData =
      store.getState().forms[PROFILE_TYPES_FORM_MODULES[profileType]].data;
    await builderModule.actions.validateProfilePacks(formData, true);
    await store.dispatch(submitClusterProfile());
  },
});
