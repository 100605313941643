import store from "services/store";

import Validator from "services/validator";
import {
  Missing,
  isKubernetesName,
  MaxLength,
  ApplyIf,
} from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  cloudAccountFetcher,
  cloudAccountFormModal,
  selfHostedOverlordsFetcher,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";

const validator = new Validator();
validator.addRule(["name", "tenantId", "clientId", "clientSecret"], Missing());
validator.addRule(
  ["overlordUid"],
  ApplyIf((value, key, data) => !!data?.useSelfHostedPCG, Missing())
);
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

async function submit(data) {
  const payload = {
    metadata: {
      name: data.name,
      annotations: {
        overlordUid: data.useSelfHostedPCG ? data.overlordUid : "",
      },
    },
    spec: {
      tenantId: data.tenantId.trim(),
      clientId: data.clientId.trim(),
      clientSecret: data.clientSecret.trim(),
      tenantName: data.tenantName?.trim(),
      settings: {
        disablePropertiesRequest: data?.disablePropertiesRequest,
      },
      azureEnvironment: data.partition,
    },
  };

  return store.dispatch(createAccount("azure", payload));
}

async function init() {
  let data;
  if (cloudAccountFormModal.data.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  store.dispatch(selfHostedOverlordsFetcher.fetch());

  return Promise.resolve({
    name: data?.metadata.name || "",
    tenantId: data?.spec.tenantId || "",
    clientId: data?.spec.clientId || "",
    clientSecret: data?.spec?.clientSecret || "",
    tenantName: data?.spec?.tenantName || "",
    overlordUid: data?.metadata?.annotations?.overlordUid,
    useSelfHostedPCG: !!data?.metadata?.annotations?.overlordUid,
    disablePropertiesRequest: !!data?.spec?.settings?.disablePropertiesRequest,
    partition: data?.spec?.azureEnvironment || "AzurePublicCloud",
  });
}

export const azureAccountFormActions = createActions({
  submit,
  validator,
  init,
});
