import i18next from "i18next";
import omit from "lodash/omit";

import createFormActions from "modules/form/actions";
import ListActions from "modules/list/actions";
import AsyncAction from "modules/asyncAction";

import { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store, { getStoreEntity } from "services/store";
import { AlertChannelSchema } from "utils/schemas";
import {
  alertTypesFetcher,
  deleteWebhookAlertModal,
  webhookAlertModal,
  WEBHOOK_ALERTS_MODULE,
} from "./services";
import Validator from "services/validator";
import {
  ApplyIf,
  isJSONFormat,
  isValidUrl,
  Missing,
  areValidEmailTags,
} from "services/validator/rules";
import { currentProjectFetcher } from "state/project/actions/list";
import { getCurrentProject } from "state/auth/selectors/common";

const emailAlertsValidator = new Validator();
const singleEmailAlertValidator = new Validator();

singleEmailAlertValidator.addRule(["identifiers"], areValidEmailTags());
emailAlertsValidator.addRule(["alerts"], singleEmailAlertValidator);

export const projectEmailAlertsFormActions = createFormActions({
  validator: emailAlertsValidator,
  init: async () => {
    await Promise.allSettled([
      store.dispatch(currentProjectFetcher.fetch()),
      store.dispatch(alertTypesFetcher.fetch()),
    ]);

    const state = store.getState();
    const alerts =
      alertTypesFetcher.selector(store.getState())?.result?.components || [];
    const project = getCurrentProject(state)?.project;

    return {
      alerts: alerts.map((alertType) => {
        const projectAlert = (project?.spec?.alerts || []).find(
          (alert) => alert.component === alertType.name
        );

        const emailAlert =
          projectAlert &&
          projectAlert.channels.find((channel) => channel.type === "email");

        if (emailAlert) {
          return {
            alertName: alertType.name,
            ...emailAlert,
          };
        }

        return {
          alertName: alertType.name,
          alertAllUsers: false,
          identifiers: [],
          isActive: false,
          type: "email",
        };
      }),
    };
  },
  submit: async (data) => {
    const project = getCurrentProject(store.getState())?.project;

    const promises = data.alerts.map((dataAlert) => {
      const projectAlert = (project?.spec?.alerts || []).find(
        (alert) => alert.component === dataAlert.alertName
      );

      let channels = [...(projectAlert?.channels || [])];
      const emailAlertIndex = channels.findIndex(
        (channel) => channel.type === "email"
      );

      if (emailAlertIndex > -1) {
        channels[emailAlertIndex] = { ...dataAlert };
      } else {
        channels = [
          ...channels,
          {
            ...omit(dataAlert, ["alertName"]),
          },
        ];
      }
      return nonProjectApi.put(
        `v1/projects/${project.metadata.uid}/alerts/${dataAlert.alertName}`,
        { channels }
      );
    });

    try {
      await Promise.all(promises);
      notifications.success({
        message: i18next.t(
          "Alerts for project '{{projectName}}' have been configured successfully",
          {
            projectName: project.metadata.name,
          }
        ),
      });
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while configuring alerts for '{{projectName}}' project",
          {
            projectName: project.metadata.name,
          }
        ),
        description: error.message,
      });
    }
  },
});

export const webhooksAlertsListing = new ListActions({
  fetchData: () => {
    const project = getCurrentProject(store.getState())?.project;

    return Promise.resolve({
      items: (project?.spec?.alerts || [])
        .map((alert) =>
          alert.channels
            .map((channel) => ({ ...channel, alertType: alert.component }))
            .filter((channel) => channel.type === "http")
        )
        .flat(),
    });
  },
  schema: [AlertChannelSchema],
});

const bodyMethods = ["POST", "PUT"];
const projectWebhookAlertsValidator = new Validator();
projectWebhookAlertsValidator.addRule(["url", "alertType"], Missing());
projectWebhookAlertsValidator.addRule(["url"], isValidUrl());
projectWebhookAlertsValidator.addRule(
  ["body"],
  ApplyIf((value, key, data) => bodyMethods.includes(data.method), Missing())
);
projectWebhookAlertsValidator.addRule(
  ["body"],
  ApplyIf(
    (value, key, data) => bodyMethods.includes(data.method),
    isJSONFormat()
  )
);

projectWebhookAlertsValidator.addRule(
  ["headers"],
  function* (value, key, data) {
    for (const index in value) {
      const duplicates = value.filter(
        (header) => header.key && header.key === value[index].key
      );

      yield {
        result:
          duplicates.length > 1 ? i18next.t("Keys should be unique") : false,
        field: `headers.${index}.key`,
      };
    }
  }
);

export const projectWebhookAlertsFormActions = createFormActions({
  validator: projectWebhookAlertsValidator,
  init: async () => {
    await store.dispatch(alertTypesFetcher.fetch());

    const alertData = getStoreEntity(
      webhookAlertModal?.data?.guid,
      AlertChannelSchema
    );
    const defaultHeadersValue = [{ key: "", value: "" }];

    if (alertData) {
      const { method, url, body, headers } = alertData.http;

      return Promise.resolve({
        method: method,
        url: url,
        body: body,
        headers: headers
          ? Object.keys(headers).map((key) => ({ key, value: headers[key] }))
          : defaultHeadersValue,
        alertType: alertData.alertType,
        isActive: alertData.isActive,
      });
    }

    return Promise.resolve({
      method: "POST",
      url: "",
      body: '{ "text": "{{message}}" }',
      headers: defaultHeadersValue,
      alertType: "",
      isActive: true,
    });
  },
  submit: async (data) => {
    const alertData = getStoreEntity(
      webhookAlertModal?.data?.guid,
      AlertChannelSchema
    );

    const parsedHeaders = data.headers.reduce((acc, header) => {
      if (!(header.key && header.value)) {
        return acc;
      }
      acc[header.key] = header.value;

      return acc;
    }, {});

    const newAlertChannel = {
      type: "http",
      isActive: data.isActive,
      alertType: data.alertType,
      http: {
        ...omit(data, ["alertType", "isActive", "guid"]),
        body: bodyMethods.includes(data.method) ? data.body : undefined,
        headers:
          Object.keys(parsedHeaders).length > 0 ? parsedHeaders : undefined,
      },
    };

    const projectEntity = getCurrentProject(store.getState());
    const project = projectEntity.project;

    let projectAlert = (project?.spec?.alerts || []).find(
      (alert) => alert.component === data.alertType
    );
    let channels = [...(projectAlert?.channels || [])];

    if (alertData) {
      const webhookAlertIndex = channels.findIndex(
        (alert) => alert.guid === alertData.guid
      );

      channels[webhookAlertIndex] = {
        ...newAlertChannel,
      };
    } else {
      channels = [...(projectAlert?.channels || []), newAlertChannel];
    }

    const promise = nonProjectApi.put(
      `v1/projects/${project.metadata.uid}/alerts/${data.alertType}`,
      { channels }
    );

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to create the webhook alert"
        ),
        description: err?.message,
      });
      webhookAlertModal.close();
      return;
    }

    webhookAlertModal.close();
    notifications.success({
      message: i18next.t('Webhook alert for "{{alertType}}" has been created', {
        alertType: data.alertType,
      }),
    });
    await store.dispatch(currentProjectFetcher.fetch());
    store.dispatch(webhooksAlertsListing.fetchItems(WEBHOOK_ALERTS_MODULE));
  },
});

export const activateWebhookAlertAsyncAction = new AsyncAction({
  promise: async ({ checked, alertType, http }) => {
    const project = getCurrentProject(store.getState())?.project;
    const projectAlert = (project?.spec?.alerts || []).find(
      (alert) => alert.component === alertType
    );

    let channels = [...projectAlert.channels];

    const webhookAlertIndex = channels.findIndex(
      (channel) =>
        channel?.http?.url === http.url && channel?.http?.method === http.method
    );

    channels[webhookAlertIndex].isActive = checked;

    const promise = nonProjectApi.put(
      `v1/projects/${project.metadata.uid}/alerts/${alertType}`,
      { channels }
    );

    const activatedMessage = i18next.t(
      'Webhook alert "{{alertType}} has been activated',
      { alertType }
    );
    const deactivatedMessage = i18next.t(
      'Webhook alert "{{alertType}}" has been deactivated',
      { alertType }
    );
    const message = checked ? activatedMessage : deactivatedMessage;

    try {
      await promise;
      notifications.success({
        message,
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
      return;
    }

    store.dispatch(webhooksAlertsListing.fetchItems(WEBHOOK_ALERTS_MODULE));
  },
});

export function editWebhookAlert(guid) {
  return (dispatch) => {
    webhookAlertModal.open({ guid }).then(() => {
      return dispatch(
        projectWebhookAlertsFormActions.submit({
          module: WEBHOOK_ALERTS_MODULE,
        })
      );
    });

    dispatch(
      projectWebhookAlertsFormActions.init({ module: WEBHOOK_ALERTS_MODULE })
    );
  };
}

export const deleteWebhookAlertAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const alertData = getStoreEntity(guid, AlertChannelSchema);

    const projectEntity = getCurrentProject(store.getState());
    const project = projectEntity.project;

    const projectAlert = (project?.spec?.alerts || []).find(
      (alert) => alert.component === alertData.alertType
    );

    let channels = [...projectAlert.channels];

    const webhookAlertIndex = channels.findIndex(
      (alert) => alert.guid === alertData.guid
    );

    channels.splice(webhookAlertIndex, 1);

    const promise = nonProjectApi.put(
      `v1/projects/${project.metadata.uid}/alerts/${alertData.alertType}`,
      {
        channels,
      }
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t("Webhook alert has been deleted"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to delete the alert"
        ),
        description: err?.message,
      });
      return;
    }

    deleteWebhookAlertModal.close();
    await store.dispatch(currentProjectFetcher.fetch());
    store.dispatch(webhooksAlertsListing.fetchItems(WEBHOOK_ALERTS_MODULE));
  },
});

export function onWebhookAlertDelete(guid) {
  deleteWebhookAlertModal
    .open({ guid })
    .then(() => deleteWebhookAlertAsyncAction.trigger(guid));
}

export function onWebhookAlertHeaderRemove(index) {
  return (dispatch, getState) => {
    const state = getState();
    const formHeaders = state.forms[WEBHOOK_ALERTS_MODULE].data?.headers;

    const newHeaders = [...formHeaders];
    newHeaders.splice(index, 1);

    dispatch(
      projectWebhookAlertsFormActions.onChange({
        module: [WEBHOOK_ALERTS_MODULE],
        name: "headers",
        value: newHeaders,
      })
    );

    dispatch(
      projectWebhookAlertsFormActions.clearFieldErrors({
        module: WEBHOOK_ALERTS_MODULE,
        field: "headers",
      })
    );

    dispatch(
      projectWebhookAlertsFormActions.validateField({
        module: WEBHOOK_ALERTS_MODULE,
        name: "headers",
      })
    );
  };
}
