export const initialState = {
  isLoading: false,
  list: [],
  currentClusterId: "",
  currentCloudConfig: "",
  unassignedNodes: [],
  addedNodes: [],
  tableIsLoading: false,
  configuredNode: "",
  currentNodeOverview: null,
  nodePoolToAddGuid: null,
  nodePoolToConfigureGuid: null,
  openedModal: "",
  maxMasterNodes: 5,
  temporaryNodePoolSizes: {},
  desiredNodePoolSizes: {},
  cloudConfigSubnets: [],
  selectedNode: null,
  metricsTimeline: "1 hours",
  totalRates: null,
  nodePoolRates: null,
  isRateLoading: false,
};

export function FETCH_CLUSTER_CLOUD_CONFIG_SUCCESS(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTER_CLOUD_CONFIG_FAILURE(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTER_CLOUD_CONFIG_NORMALIZATION(
  nextState,
  { result, entities }
) {
  nextState.currentCloudConfig = result;
  if (!entities.nodePools) {
    return;
  }

  Object.keys(entities.nodePools).forEach((nodePoolGuid) => {
    const nodePool = entities.nodePools[nodePoolGuid];
    nextState.temporaryNodePoolSizes[nodePool.name] = nodePool.size;
    nextState.desiredNodePoolSizes[nodePool.name] = 0;
  });
}

export function FETCH_UNASSIGNED_NODES_REQUEST(nextState) {
  nextState.tableIsLoading = true;
  nextState.addedNodes = [];
}

export function FETCH_UNASSIGNED_NODES_SUCCESS(nextState) {
  nextState.tableIsLoading = false;
}

export function FETCH_UNASSIGNED_NODES_FAILURE(nextState) {
  nextState.tableIsLoading = false;
}

export function FETCH_UNASSIGNED_NODES_NORMALIZATION(nextState, { result }) {
  nextState.unassignedNodes = result;
}

export function INSERT_NODE(nextState, { nodePoolName, nodesToAdd }, state) {
  const updatedSize = state.temporaryNodePoolSizes[nodePoolName] + nodesToAdd;
  nextState.temporaryNodePoolSizes[nodePoolName] = updatedSize;
  nextState.desiredNodePoolSizes[nodePoolName] = updatedSize;
}

export function ADD_CLOUD_NODE_REQUEST(nextState) {
  nextState.loadingAddingNode = true;
}

export function ADD_CLOUD_NODE_SUCCESS(nextState) {
  nextState.loadingAddingNode = false;
}

export function ADD_CLOUD_NODE_FAILURE(nextState) {
  nextState.loadingAddingNode = false;
}

export function CONNECT_NODES_REQUEST(nextState) {
  nextState.tableIsLoading = true;
}

export function CONNECT_NODES_SUCCESS(nextState, { addedNodes }) {
  nextState.unassignedNodes = nextState.unassignedNodes.filter(
    (node) => !addedNodes.includes(node)
  );
  nextState.tableIsLoading = false;
  nextState.addedNodes = [];
}

export function CONNECT_NODES_FAILURE(nextState) {
  nextState.tableIsLoading = false;
}

export function CONFIGURE_NODE_REQUEST(nextState, { configuredNode }) {
  nextState.configuredNode = configuredNode;
}

export function CONFIGURE_NODE_SUCCESS(nextState) {
  nextState.configuredNode = "";
}

export function CONFIGURE_NODE_FAILURE(nextState) {
  nextState.configuredNode = "";
}

export function SET_NODEPOOL_TO_CONFIGURE(
  nextState,
  { nodePoolToConfigureGuid }
) {
  nextState.nodePoolToConfigureGuid = nodePoolToConfigureGuid;
}

export function UPDATE_DESIRED_NODE_POOL_SIZE(
  nextState,
  { nodePoolName, size }
) {
  nextState.temporaryNodePoolSizes[nodePoolName] = size;
}

export function UPDATE_NODE_POOL_SIZE(nextState, { nodePoolName }, state) {
  nextState.desiredNodePoolSizes[nodePoolName] =
    state.temporaryNodePoolSizes[nodePoolName];
}

export function UPDATE_CLUSTER_REQUEST(nextState) {
  nextState.loadingAddingNode = true;
}

export function UPDATE_CLUSTER_SUCCESS(nextState) {
  nextState.loadingAddingNode = false;
}

export function UPDATE_CLUSTER_FAILURE(nextState) {
  nextState.loadingAddingNode = false;
}

export function SELECT_NODE(nextState, { selectedNode }) {
  nextState.selectedNode = selectedNode;
}

export function CLUSTER_CLOUD_SETTINGS_SUBNETS_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.cloudConfigSubnets = result;
}

export function CHANGE_NODE_METRICS_TIMELINE(nextState, { time }) {
  nextState.metricsTimeline = time;
}

export function NODES_FETCH_RATES_SUCCESS(nextState, { result }) {
  nextState.totalRates = result;
}

export function FETCH_NODE_ESTIMATED_RATE_SUCCESS(nextState, { result }) {
  nextState.isRateLoading = false;
  nextState.nodePoolRates = result;
}

export function FETCH_NODE_ESTIMATED_RATE_FAILURE(nextState) {
  nextState.isRateLoading = false;
}

export function RESET_ESTIMATED_RATE(nextState) {
  nextState.nodePoolRates = null;
  nextState.isRateLoading = true;
}
