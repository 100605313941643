import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  clientToken: import.meta.env.SPECTRO_DATADOG_CLIENT_TOKEN,
  sampleRate: 100,
  service: "optic",
  env: import.meta.env.MODE,
});

const logger = datadogLogs.logger;

export default logger;
