import styled, { css } from "styled-components";
import { winterMist } from "utils/constants/colors";

export const Pill = styled.div`
  color: #0d1440;
  border-radius: 13px;
  max-height: 18px;
  padding: 4px 12px;
  display: ${(props) => (props.inline ? "inline" : "flex")};
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  margin: 0 4px;
  background: ${(props) => (props.color ? props.color : winterMist)};

  ${(props) =>
    props.compressed &&
    css`
      padding: 3px 6px;
      font-size: 10px;
      line-height: 1;
      margin: 0 2px;
    `}
`;
