import store, { getStoreEntity } from "services/store";
import i18n from "i18next";

import api from "services/api";
import history from "services/history";
import ModalService from "services/modal";
import Validator from "services/validator";
import * as rules from "services/validator/rules";
import notifications from "services/notifications";

import { TeamSchema } from "utils/schemas";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";
import { projectRolesListActions } from "state/roleManagement/actions/projectRoles";
import { tenantRolesListActions } from "state/roleManagement/actions/tenantRoles";
import { getSelectedTeam } from "state/roleManagement/selectors";

//

export const teamFormModal = new ModalService();
export const teamDeleteService = new ModalService("teamDelete");
export const teamDetailsModal = new ModalService();

export const teamListActions = new ListActions({
  async fetchData(query) {
    const { continue: continueToken } = query;
    const data = await api.get(
      `v1/teams/summary${continueToken ? `?continue=${continueToken}` : ""}`,
      query
    );

    return data;
  },
  schema: [TeamSchema],
});

const validator = new Validator();
validator.addRule(["name"], rules.Missing());

export const teamFormActions = createFormActions({
  validator,
  init() {
    const state = store.getState();
    const selectedTeam = getSelectedTeam(state);
    const modalType = state.modal.data[teamFormModal.guid].type;

    if (modalType === "create") {
      return Promise.resolve({
        name: null,
        users: [],
      });
    }

    return Promise.resolve({
      name: selectedTeam?.metadata.name || null,
      users:
        selectedTeam?.spec.users.map((user) => {
          return user.metadata.uid;
        }) || [],
    });
  },
  async submit(data) {
    const state = store.getState();
    const modalType = state.modal.data[teamFormModal.guid].type;
    const selectedTeam = getSelectedTeam(state);

    let payload = {
      metadata: {
        name: data.name,
      },
      spec: {
        users: data.users,
      },
      status: {},
    };

    const promise =
      modalType === "create"
        ? api.post("v1/teams", payload)
        : api.put(`v1/teams/${selectedTeam.metadata.uid}`, {
            ...payload,
            spec: {
              ...payload.spec,
              roles: selectedTeam.spec.roles.map((role) => role.metadata.uid),
            },
            metadata: { ...payload.metadata, uid: selectedTeam.metadata.uid },
          });

    let response;

    try {
      response = await promise;
      store.dispatch(teamListActions.initialize("teams"));
    } catch (err) {
      const message =
        modalType === "create"
          ? i18n.t("Something went wrong when creating the team")
          : i18n.t("Something went wrong when editing the team");

      notifications.error({
        message,
        description: err.message,
      });
      return Promise.reject(err);
    }

    if (!response) {
      return;
    }

    notifications.success({
      message: i18n.t('Team "{{teamName}}" has been created successfully', {
        teamName: data.name,
      }),
    });

    history.push("/management/teams");
    store.dispatch(setSelectedTeam(null));
    store.dispatch(teamListActions.fetchItems("teams"));
  },
});

export function openTeamFormModal(type) {
  return function thunk(dispatch) {
    teamFormModal.open({ type }).then(
      () => {
        return dispatch(teamFormActions.submit({ module: "createTeam" }));
      },
      () => history.push("/management/teams")
    );
  };
}

export function setSelectedTeam(team) {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_ENTITY",
      selectedEntityId: team?.guid || null,
      entityType: "team",
    });

    if (team) {
      teamDetailsModal
        .open()
        .then(null, () => history.push("/management/teams"));
      dispatch(projectRolesListActions.initialize("projectRoles"));
      dispatch(tenantRolesListActions.initialize("tenantRoles"));
    }
  };
}

export function onTeamRemove() {
  return (dispatch, getState) => {
    const selectedTeamGuid = getSelectedTeam(getState());
    teamDeleteService.open({ guid: selectedTeamGuid }).then(async () => {
      const team = getStoreEntity(teamDeleteService.data.guid, TeamSchema);
      const promise = api.delete(`v1/teams/${team.metadata.uid}`);

      dispatch({
        type: "DELETE_TEAM",
        promise,
        schema: TeamSchema,
      });

      try {
        await promise;
      } catch (err) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying to delete the team"
          ),
          description: err.message,
        });
      }

      notifications.success({
        message: i18n.t('Team "{{teamName}}" has been deleted successfully', {
          teamName: team.metadata.name,
        }),
      });
      dispatch(setSelectedTeam(""));
      dispatch(
        teamListActions.removeItems({
          module: "teams",
          items: [team],
        })
      );
    });
  };
}
