export function generateDomain({ domain } = {}) {
  let base = domain;
  if (!domain) {
    base = {
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
      dns: {},
    };
  }

  return { ...base };
}

export function generateCoxDeployment() {
  return {
    name: "",
    pops: "",
    instancesPerPop: 1,
    enableAutoScaling: false,
    minInstancesPerPop: 1,
    maxInstancesPerPop: 3,
  };
}
