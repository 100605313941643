import axios from "axios";

export const initialState = {
  isLoading: true,
  currentClusterId: "",
  currentPackGuid: null,
  scopePackValues: [],
  areNodesLoading: true,
  includeMasters: false,
  packsValidationErrors: [],
  profiles: [],
  isClusterProfileLoading: true,
  logFetcherUid: null,
  logClusterGuid: null,
  notification: {
    divergences: {},
    selectedLayer: null,
  },
  arePacksValidating: false,
  showAllMetrics: false,
  attachedProfileUid: null,
};

export function FORM_LOAD_REQUEST(nextState, { module }) {
  if (module === "clusterConfig") {
    nextState.packsValidationErrors = [];
  }
}

export function FETCH_CLUSTER_REQUEST(nextState) {
  nextState.isLoading = true;
  nextState.error = null;
}

export function FETCH_CLUSTER_FAILURE(nextState, { error }) {
  nextState.currentClusterId = null;
  nextState.isLoading = false;
  if (axios.isCancel(error)) {
    return;
  }
  nextState.error = error;
}

export function FETCH_CLUSTER_NORMALIZATION(nextState, { result }) {
  nextState.currentClusterId = result;
  nextState.isLoading = false;
}

export function REFRESH_CLUSTER_NORMALIZATION(nextState, { result }) {
  nextState.currentClusterId = result;
}

export function REFRESH_CLUSTER_PACKS_SUCCESS(nextState, { result }) {
  nextState.currentPackGuid = result.spec.clusterProfileTemplates.find(
    (template) => template.uid === result.spec?.clusterProfileRef?.uid
  )?.packs?.[0]?.guid;
}

export function CLUSTER_DETAILS_NEXT_TAB(nextState, { activeKey }) {
  nextState.activeKey = activeKey;
}

export function FETCH_CLOUD_CONFIG_PARAMS_REQUEST(nextState) {
  nextState.loadingParams = true;
}

export function FETCH_CLOUD_CONFIG_PARAMS_SUCCESS(nextState, { result }) {
  nextState.loadingParams = false;
  nextState.cloudConfigParams = result;
}

export function FETCH_CLOUD_CONFIG_PARAMS_FAILURE(nextState) {
  nextState.loadingParams = false;
}

export function GETTING_CLUSTER_NODES_REQUEST(nextState) {
  nextState.areNodesLoading = true;
}

export function GETTING_CLUSTER_NODES_SUCCESS(nextState) {
  nextState.areNodesLoading = false;
}

export function GETTING_CLUSTER_NODES_FAILURE(nextState) {
  nextState.areNodesLoading = false;
}

export function TOGGLE_GAUGE_METRICS(nextState, _, state) {
  nextState.includeMasters = !state.includeMasters;
}

export function TOGGLE_METRICS(nextState, _, state) {
  nextState.showAllMetrics = !state.showAllMetrics;
}

export function SET_CLUSTER_PACK_DIVERGENCES(nextState, { value }) {
  nextState.notification.divergences = value;
}

export function FETCH_CLUSTER_PACK_DIVERGENCE_INFO_SUCCESS(
  nextState,
  { profileUid, packName, result },
  state
) {
  nextState.notification.divergences[profileUid] =
    state.notification.divergences[profileUid] || {};
  nextState.notification.divergences[profileUid][packName] = result;
}

export function VALIDATE_EDIT_CLUSTER_PROFILE_PACKS_REQUEST(nextState) {
  nextState.arePacksValidating = true;
}

export function VALIDATE_EDIT_CLUSTER_PROFILE_PACKS_FAILURE(nextState) {
  nextState.arePacksValidating = false;
}

export function VALIDATE_EDIT_CLUSTER_PROFILE_PACKS_SUCCESS(
  nextState,
  { result }
) {
  nextState.packsValidationErrors = result || [];
  nextState.arePacksValidating = false;
}

export function CONFIGURE_CLUSTER_PROFILES_REQUEST(nextState) {
  nextState.isSubmittingProfile = true;
}

export function CONFIGURE_CLUSTER_PROFILES_SUCCESS(nextState) {
  nextState.isSubmittingProfile = false;
}

export function CONFIGURE_CLUSTER_PROFILES_FAILURE(nextState) {
  nextState.isSubmittingProfile = false;
}

export function FETCH_CLUSTER_BUNDLE_NORMALIZATION(nextState, { result }) {
  nextState.profiles = result;
}

export function FETCH_CLUSTER_BUNDLE_FAILURE(nextState) {
  nextState.isClusterProfileLoading = false;
}

export function FETCH_CLUSTER_PROFILE_INITIALIZE(nextState) {
  nextState.isClusterProfileLoading = true;
}

export function FETCH_CLUSTER_PROFILE_INITIALIZE_SUCCESS(nextState) {
  nextState.isClusterProfileLoading = false;
}

export function LOGS_DOWNLOAD_SUCCESS(nextState, { result, clusterGuid }) {
  nextState.logFetcherUid = result.uid;
  nextState.logClusterGuid = clusterGuid;
}

export function NOTIFICATION_SELECT_LAYER(nextState, { layer }) {
  nextState.notification.selectedLayer = layer.guid;
}

export function NOTIFICATION_SELECT_MANIFEST(nextState, { layer, manifest }) {
  nextState.notification.selectedLayer = manifest.guid;
}

export function UPDATE_CLUSTER_MANIFEST_DIVERGENCE(
  nextState,
  { layer, manifest, profile, content },
  state
) {
  const manifests =
    state.notification.divergences[profile.metadata.uid][layer.metadata.name]
      ?.items?.[1]?.spec?.manifests || [];
  const indexOfManifest = manifests.findIndex(
    (packManifest) => packManifest.uid === manifest.parentUid
  );

  if (indexOfManifest !== -1) {
    nextState.notification.divergences[profile.metadata.uid][
      layer.metadata.name
    ].items[1].spec.manifests[indexOfManifest].content = content;
  }
}

export function FETCH_ATTACHED_PROFILE_NORMALIZATION(nextState, { result }) {
  nextState.attachedProfileUid = result;
}
