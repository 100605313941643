import React from "react";
import { useTranslation } from "react-i18next";
import createList, { Blocks } from "modules/list";
import { AppDeploymentSchema } from "utils/schemas";
import Loader from "components/ui/Loader";
import CreateDeploymentButton from "pages/dev/appdeployments/components/CreateDeploymentButton";
import {
  appDeploymentsListActionsForDashboard,
  DEPLOYMENTS_LIST_MODULE,
} from "state/appdeployments/services/listings";
import styled from "styled-components";
import { connect } from "react-redux";
import { getTotalCountsOfAppDeployments } from "state/appModeOverview/selectors/appDeploymentsWidget";
import { concreteWhite, darkGray, white } from "utils/constants/colors";
import { DEPLOYMENTS } from "utils/constants/routes";
import { WidgetHeader } from "pages/dev/overview/components/Status";
import { ViewAllRedirectButton } from "pages/dev/overview/components/ViewAllRedirectButton";
import { generatePath } from "react-router";
import { useAppDeploymentsColumns } from "components/common/AppDeployments";
import history from "services/history";

const LoaderWrap = styled(Loader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 100%;
  width: 100%;
`;

const CardBorder = styled.div`
  border: 2px solid ${concreteWhite};
  border-radius: 3px;
  overflow: hidden;
  height: 248px;
  background: #f7f8f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -5px;
`;

const StyledLink = styled.span`
  background-color: ${white};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${concreteWhite};
  width: 100%;
  position: sticky;
  bottom: -20px;
  z-index: 99;
  font-size: 14px;
  color: ${darkGray};
  line-height: 32px;
`;

const ListModule = createList({
  hasPagination: false,
  actions: appDeploymentsListActionsForDashboard,
  schema: [AppDeploymentSchema],
  parseItems(items = []) {
    return items.slice(0, 5);
  },
});

const DeploymentsListing = ({ isPageEmpty }) => {
  const { t } = useTranslation();
  const columns = useAppDeploymentsColumns({ dashboardColumn: true });

  return (
    <>
      <CardBorder>
        <ListModule module={DEPLOYMENTS_LIST_MODULE} preventInitOnMount>
          <Blocks.Table
            columns={columns}
            slim
            hasEmptyPage
            onRowClick={(record) =>
              history.push(
                generatePath(DEPLOYMENTS.OVERVIEW, { uid: record.metadata.uid })
              )
            }
          />
          <Blocks.EmptyResult
            size="medium"
            description={
              <>
                <p>{t("No Apps to show yet.")}</p>
                <p>{t("Add one by clicking New App.")}</p>
              </>
            }
          />
          {isPageEmpty && (
            <StyledLink>
              <ViewAllRedirectButton path={generatePath(DEPLOYMENTS.ROOT)} />
            </StyledLink>
          )}
        </ListModule>
      </CardBorder>
    </>
  );
};

const AppWidget = ({ appDeploymentCount, isAppListInitializing }) => {
  const { t } = useTranslation();
  if (isAppListInitializing) {
    return <LoaderWrap />;
  }

  return (
    <>
      <WidgetHeader
        totalCount={appDeploymentCount}
        createButton={<CreateDeploymentButton />}
        title={t("Apps")}
      ></WidgetHeader>
      <DeploymentsListing isPageEmpty={!!appDeploymentCount} />
    </>
  );
};

export default connect((state) => ({
  isAppListInitializing: state.list?.[DEPLOYMENTS_LIST_MODULE]?.isInitializing,
  appDeploymentCount: getTotalCountsOfAppDeployments(state),
}))(AppWidget);
