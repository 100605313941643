import { createSelector } from "reselect";
import { getCurrentStep } from "modules/wizard/selectors";
import i18n from "i18next";
import { getEntity } from "utils/entities";
import { MemberSchema, UserSchema } from "utils/schemas";

export const getFormError = createSelector(
  getCurrentStep("project"),
  (state) => state.forms.project,
  (currentStep, formState) => {
    if (!formState) {
      return null;
    }
    const { errors } = formState;

    if (!errors.length) {
      return null;
    }

    let message = i18n.t(
      "Please resolve the errors on the page in order to continue"
    );

    return message;
  }
);

export const getLogoUrl = createSelector(
  (state) => state.forms?.project?.data?.logo?.file.response?.url,
  (imageUrl) => {
    if (!imageUrl) {
      return null;
    }

    return imageUrl;
  }
);

export const isLogoUploading = createSelector(
  (state) => state.forms?.project?.data?.logo?.file.status,
  (fileStatus) => {
    if (!fileStatus) {
      return false;
    }

    return fileStatus === "uploading";
  }
);

export const getMembers = getEntity(
  (state) => state.project.create.members,
  [MemberSchema]
);

export const getSelectedMembers = getEntity(
  (state) =>
    state.forms.newMembers.data?.selectedMembers.map((member) => member.key),
  [UserSchema]
);
