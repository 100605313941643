export function DIFF_VIEWER_SELECT_LAYER(
  nextState,
  { module, layerGuid, profileGuid }
) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].selectedLayer = layerGuid;
  nextState[module].selectedProfile = profileGuid;
}

export function DIFF_VIEWER_UPDATE_EXPANDED(
  nextState,
  { module, profileGuids }
) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].expanded = profileGuids;
}

export function DIFF_VIEWER_SET_DIF_SNAPSHOT(nextState, { module, snapshot }) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].diffSnapshot = snapshot;
}
