import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import ModalService from "services/modal";
import store from "services/store";
import { AppDeploymentSchema } from "utils/schemas";

export function fetchAppDeploymentByUid(uid) {
  return (dispatch) => {
    const promise = api.get(`v1/appDeployments/${uid}`);

    dispatch({
      type: "FETCH_APP_DEPLOYMENT",
      promise,
      schema: AppDeploymentSchema,
    });

    dispatch({
      type: "FETCH_APP_VERSIONS",
      promise: api
        .get(`v1/appDeployments/${uid}/profile/versions`)
        .then((data) => ({
          spec: {
            profileVersions: data,
          },
          metadata: {
            uid,
          },
        })),
      schema: AppDeploymentSchema,
    });

    return promise;
  };
}

export function refreshAppDeployment() {
  return (dispatch, getState) => {
    const uid = getState().location?.params?.uid;
    if (!uid) {
      return;
    }
    const promise = api.get(`v1/appDeployments/${uid}`);

    dispatch({
      type: "REFRESH_APP_DEPLOYMENT",
      promise,
      schema: AppDeploymentSchema,
    });

    return promise;
  };
}

export const applicationNotificationsFetcher = dataFetcher({
  selectors: [
    "application",
    "notifications",
    (state) => state.location.params.uid,
  ],
  fetchData([_1, _2, uid]) {
    return api.get(`v1/notifications/appdeployment/${uid}?isDone=false`);
  },
});

export const updateNotificationModal = new ModalService(
  "application-update-notification"
);
export const versionChangeModal = new ModalService(
  "application-version-change-notification"
);

export const requestAppLogs = (appName, appTiers, clusterRef) => {
  const payload = {
    noOfLines: 10000,
    duration: 36000,
    k8s: {
      namespaces: appTiers.reduce((acc, tier) => {
        return acc.concat([
          `${appName}-${tier.name}`,
          `${appName}-${tier.name}-ns`,
        ]);
      }, []),
    },
    mode: "app",
  };

  const promise = api.post(
    `v1/spectroclusters/${clusterRef.uid}/features/logFetcher`,
    payload
  );

  store.dispatch({
    type: "APP_LOGS_DOWNLOAD",
    promise,
    clusterRef,
    appName,
  });

  return promise;
};

export const checkLogFetchingStatus = (logAppClusterGuid, fileName) => {
  return api.get(
    `v1/spectroclusters/${logAppClusterGuid}/features/logFetcher?fileName=${fileName}`
  );
};
