import React, { createRef } from "react";
import { createTeleporter } from "react-teleporter";
import Tabs from ".";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import Link from "components/common/History/Link";
import { midnightBlue, greenGray, white } from "utils/constants/colors";
import styled, { css } from "styled-components";
import { BackArrow } from "../layouts/App/PageHeader";
import { watchBounds } from "utils/hooks";

const TabBarWrap = styled.div`
  background: ${white};
  z-index: 9;
  padding: 0 32px;

  .ant-tabs-nav-wrap {
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "auto")};
  }
`;

const TabsWrap = styled.div`
  height: calc(100% + 48px);
  overflow-y: hidden;
  margin: -24px;

  > .ant-tabs {
    overflow: visible;
    height: 100%;

    > ${TabBarWrap} > .ant-tabs-nav {
      margin: 0;

      &:before {
        display: none;
      }
    }

    > .ant-tabs-content-holder {
      height: 100%;
      overflow: hidden;

      .ant-tabs-content {
        height: 100%;
      }
    }
  }

  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 0;
    color: ${greenGray};
  }

  ${(props) =>
    props.noScroll &&
    css`
      > .ant-tabs {
        > .ant-tabs-content-holder {
          overflow: hidden;
          display: flex;
        }

        .ant-tabs-content {
          overflow: hidden;
        }
      }
    `}
`;

const TabsContent = styled.div`
  height: 100%;
  padding: 24px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const TitleWrap = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.0025em;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${midnightBlue};
  margin-right: 32px;
`;

const BeforeSlot = createTeleporter();
const AfterSlot = createTeleporter();

export const DetailPageBounds = watchBounds();

const DetailPageTabsBefore = ({ backlink, children }) => {
  function renderBackArrow() {
    if (!backlink) {
      return null;
    }

    return (
      <Link to={backlink}>
        <BackArrow icon={faArrowLeft} />
      </Link>
    );
  }

  return (
    <BeforeSlot.Source>
      <TitleWrap>
        {renderBackArrow()}
        {children}
      </TitleWrap>
    </BeforeSlot.Source>
  );
};

function DetailPageTabsAfter({ children }) {
  return <AfterSlot.Source>{children}</AfterSlot.Source>;
}

const Content = ({ children }) => {
  const contentRef = DetailPageBounds.useRef();

  return <TabsContent ref={contentRef}>{children}</TabsContent>;
};

const DetailPageTabs = ({
  noScroll = false,
  tabBarMaxWidth,
  children,
  ...rest
}) => {
  return (
    <DetailPageBounds.Provider>
      <TabsWrap noScroll={noScroll}>
        <Tabs
          {...rest}
          tabBarExtraContent={{
            left: <BeforeSlot.Target />,
            right: <AfterSlot.Target />,
          }}
          renderTabBar={(tabProps, TabBar) => (
            <TabBarWrap maxWidth={tabBarMaxWidth}>
              <TabBar {...tabProps} />
            </TabBarWrap>
          )}
        >
          <Content>{children}</Content>
        </Tabs>
      </TabsWrap>
    </DetailPageBounds.Provider>
  );
};

DetailPageTabs.After = DetailPageTabsAfter;
DetailPageTabs.Before = DetailPageTabsBefore;

export default DetailPageTabs;
