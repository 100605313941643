import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return (2 ** retryCount - 1) * 500;
  },
  retryCondition: (error) => {
    return error.response.status === 429;
  },
});

export default function createApi({
  baseUrl = "",
  catchMissingAuth,
  getDefaultHeaders = () => ({}),
} = {}) {
  let abortController = new AbortController();
  let shouldCollect = false;

  function promisify(request) {
    function handleError(err) {
      if (axios.isCancel(err)) {
        throw err;
      }
      if (!err.response) {
        return;
      }
      if (err.response.status === 401 && catchMissingAuth) {
        catchMissingAuth(err);
      }
      throw err.response.data;
    }

    const promise = request.then((res) => res.data).catch(handleError);

    Object.defineProperties(promise, {
      status: {
        get() {
          return request
            .then((res) => res.status)
            .catch((err) => {
              return err?.response?.status;
            });
        },
      },
      headers: {
        get() {
          return request
            .then((res) => res.headers)
            .catch((err) => {
              return err?.response?.headers;
            });
        },
      },
    });

    return promise;
  }

  function getOptions(options = {}) {
    const defaultHeaders = getDefaultHeaders();
    return {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...defaultHeaders,
        ...(options.headers || {}),
      },
      ...(shouldCollect ? { signal: abortController.signal } : {}),
    };
  }

  return {
    get(url, query, options) {
      const request = axios.get(
        `${baseUrl}/${url}`,
        getOptions({ ...options, params: query })
      );
      return promisify(request);
    },

    put(url, body, options) {
      const request = axios.put(`${baseUrl}/${url}`, body, getOptions(options));
      return promisify(request);
    },

    post(url, body, options) {
      const request = axios.post(
        `${baseUrl}/${url}`,
        body,
        getOptions(options)
      );
      return promisify(request);
    },

    patch(url, body, options) {
      const request = axios.patch(
        `${baseUrl}/${url}`,
        body,
        getOptions(options)
      );
      return promisify(request);
    },

    delete(url, body = {}, options) {
      const request = axios.delete(
        `${baseUrl}/${url}`,
        getOptions({ ...options, data: body })
      );
      return promisify(request);
    },

    collect() {
      shouldCollect = true;
    },

    stopCollect() {
      shouldCollect = false;
    },

    abortAllPending() {
      abortController.abort();
      shouldCollect = false;
      abortController = new AbortController();
    },
  };
}
