import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { DEPLOYMENTS } from "utils/constants/routes";

const DeploymentsIndex = React.lazy(() => import("./index"));
const CreateDeployment = React.lazy(() => import("./create"));
const DeploymentOverview = React.lazy(() => import("./overview"));

export default createNamespace({
  namespace: "deployments",
  Component: function Main() {
    return (
      <>
        <Route
          exact
          devOnly
          path={DEPLOYMENTS.ROOT}
          component={DeploymentsIndex}
        />
        <Route
          exact
          devOnly
          path={DEPLOYMENTS.CREATE}
          component={CreateDeployment}
        />
        <Route
          exact
          devOnly
          path={DEPLOYMENTS.OVERVIEW}
          component={DeploymentOverview}
        />
      </>
    );
  },
});
