import React from "react";
import { useTranslation } from "react-i18next";

import { Blocks } from "modules/list";
import Filters, { InputFieldWrap } from "components/styled/Filters";

function UsersFiltersForm({ displayedFields = ["search"] }) {
  const { t } = useTranslation();

  return (
    <Filters.FormWrap>
      {displayedFields.includes("search") && (
        <InputFieldWrap secondary>
          <Blocks.FilterFields.Input
            data-qa="search-filter"
            name="search"
            placeholder={t("Filter by name")}
            allowClear
            searchPrefix
          />
        </InputFieldWrap>
      )}
    </Filters.FormWrap>
  );
}

export default UsersFiltersForm;
