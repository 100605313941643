import store from "services/store";

import Validator from "services/validator";
import { isKubernetesName, Missing } from "services/validator/rules";
import api from "services/api";

import createActions from "modules/form/actions";
import {
  CLOUD_ACCOUNTS_MODULE,
  cloudAccountFormModal,
  cloudAccountFetcher,
} from "state/cloudaccounts/services";
import dataFetcher from "modules/dataFetcher";
import { createAccount } from "state/cloudaccounts/actions/create";
import { getSystemPrivateGatewayUid } from "state/cloudaccounts/selectors";
import { EmailType } from "services/validator/rules";

const validator = new Validator();
validator.addRule(
  ["name", "username", "password", "overlordUid", "vcenterServer"],
  Missing()
);
validator.addRule(["name"], isKubernetesName());
validator.addRule(["username"], EmailType());

export const vmWareAccountFormActions = createActions({
  validator,
  init: async () => {
    let data;
    if (cloudAccountFormModal.data?.uid) {
      data = await store.dispatch(cloudAccountFetcher.fetch());

      store.dispatch({
        type: "SET_ACCOUNT_AS_VALID",
      });

      return Promise.resolve({
        type: "vmware",
        useSystemGateway:
          data?.metadata?.annotations?.overlordType === "system" || false,
        name: data?.metadata.name || "",
        overlordUid: data?.metadata?.annotations?.overlordUid || "",
        username: data?.spec.username || "",
        password: data?.spec.password || "",
        insecure: data?.spec?.insecure,
        vcenterServer: data?.spec?.vcenterServer || "",
      });
    }

    return Promise.resolve({
      name: "",
      overlordUid: "",
      username: "",
      password: "",
    });
  },
  submit: async (data) => {
    const {
      useSystemGateway,
      name,
      vcenterServer,
      insecure,
      username,
      password,
      overlordUid,
    } = data;

    const payload = {
      metadata: {
        name,
        annotations: {
          overlordUid: overlordUid,
          overlordType: useSystemGateway ? "system" : undefined,
        },
      },
      spec: {
        vcenterServer,
        insecure,
        username,
        password,
      },
    };

    return store.dispatch(createAccount("vsphere", payload));
  },
});

const selectedOverlordFetcher = dataFetcher({
  selectors: [
    "overlords",
    (state) => state.forms[CLOUD_ACCOUNTS_MODULE]?.data?.overlordUid,
  ],
  async fetchData([_, id]) {
    const overlord = await api.get(`v1/overlords/${id}`);
    const cloudAccount = api.get(
      `v1/cloudaccounts/vsphere/${overlord.spec.cloudAccountUid}`
    );

    return cloudAccount;
  },
});

export function onOverlordSelect(value) {
  return async function thunk(dispatch) {
    dispatch(
      vmWareAccountFormActions.onChange({
        name: "overlordUid",
        value,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    dispatch({ type: "SET_ACCOUNT_AS_INVALID" });

    const cloudAccount = await dispatch(selectedOverlordFetcher.fetch());
    dispatch(
      vmWareAccountFormActions.batchChange({
        module: CLOUD_ACCOUNTS_MODULE,
        updates: {
          insecure: cloudAccount?.spec?.insecure,
          vcenterServer: cloudAccount?.spec?.vcenterServer,
        },
      })
    );
  };
}

export function onToggleSystemGateway(toggled) {
  return async (dispatch, getState) => {
    dispatch(
      vmWareAccountFormActions.batchChange({
        module: CLOUD_ACCOUNTS_MODULE,
        updates: {
          overlordUid: toggled ? getSystemPrivateGatewayUid(getState()) : "",
          useSystemGateway: toggled,
        },
      })
    );
  };
}
