export const initialState = {
  isLoadingNameValidation: false,
  profileStackInitialized: false,
};

export function VIRTUAL_CLUSTER_NAME_VALIDATION_REQUEST(nextState) {
  nextState.isLoadingNameValidation = true;
}

export function VIRTUAL_CLUSTER_NAME_VALIDATION_SUCCESS(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function VIRTUAL_CLUSTER_NAME_VALIDATION_FAILURE(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function PROFILE_STACK_INITIALIZATION(nextState, { value }) {
  nextState.profileStackInitialized = value;
}
