import i18n from "i18next";
import { generatePath } from "react-router";
import isEqual from "fast-deep-equal";
import createFormActions from "modules/form/actions";

import ModalService from "services/modal";
import api from "services/api";
import Validator from "services/validator";
import store from "services/store";
import historyService from "services/history";
import notifications from "services/notifications";
import {
  Missing,
  DebouncedRule,
  isKubernetesName,
  MinLength,
  MaxLength,
  isBelowLimit,
} from "services/validator/rules";

import {
  clusterGroupsFetcher,
  clusterGroupsFetcherWithProjectId,
} from "state/clustergroups/services/listing";
import { getVirtualClusterPayload } from "state/devnestedcluster/selectors/create";
import {
  developerQuotaUsageFetcher,
  sandboxClusterQuotaUsageFetcher,
} from "state/devnestedcluster/services";

import { CLUSTERS, VIRTUAL_CLUSTER } from "utils/constants/routes";
import { generateEntityName } from "utils/entities";
import { constellationsGalaxiesAndStars } from "utils/constants/dictionaries";
import { hasTenantLevelClusterGroup } from "state/auth/selectors";
import { getVirtualClusterDefaultLimit } from "state/appdeployments/actions/create";

export const VIRTUAL_CLUSTER_FORM_MODULE = "dev-virtual-cluster";
export const cancelCreateVirtualClusterModal = new ModalService();

export const validator = new Validator();
const validateClusterName = async (value) => {
  let error;

  const promise = api.get(`v1/spectroclusters/validate/name?name=${value}`);

  try {
    await promise;
  } catch (err) {
    let message;
    if (err.code === "InvalidName") {
      message = i18n.t(
        `Spectrocluster name must consist of lower case alphanumeric characters and must start with an alphabet`
      );
    }

    if (err.code === "ResourceAlreadyExists") {
      message = i18n.t(
        `A spectrocluster with the name "{{ value }}" already exists`,
        {
          value,
        }
      );
    }

    error = {
      result: message,
      field: "name",
    };
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateClusterName);

validator.addRule(["name"], Missing());
validator.addRule(["name"], (value) => {
  if (!value || value.length < 3 || value.length > 32) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "DEV_VIRTUAL_CLUSTER_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));
validator.addRule(["name"], MinLength(3));
validator.addRule("clusterGroupUid", Missing());

validator.addRule(["cpuLimits"], isBelowLimit("CPU", 3));
validator.addRule(["memoryLimits"], isBelowLimit("Memory", 3));

export const virtualClusterFormActions = createFormActions({
  validator,
  async init() {
    const clusterGroups = await store.dispatch(
      clusterGroupsFetcherWithProjectId.fetch()
    );
    const selectedClusterGroup = (clusterGroups?.items || [])?.[0];

    store.dispatch(
      sandboxClusterQuotaUsageFetcher.fetch(selectedClusterGroup?.scope)
    );

    const defaultLimits = await getVirtualClusterDefaultLimit(
      selectedClusterGroup.uid,
      selectedClusterGroup.scope
    );

    const name = generateEntityName({
      prefix: "virtual-cluster",
      dictionaries: [constellationsGalaxiesAndStars],
      length: 1,
      maxLength: 32,
    });
    const route = store.getState().location.route;
    return Promise.resolve({
      clusterGroupUid: selectedClusterGroup?.uid,
      name: route === "VIRTUAL_CLUSTER.CREATE_CLUSTER" ? name : "",
      ...defaultLimits,
    });
  },
  async submit(data) {
    const state = store.getState();
    const payload = getVirtualClusterPayload(state);

    const pathToPush = state?.auth?.devMode
      ? generatePath(VIRTUAL_CLUSTER.ROOT)
      : generatePath(CLUSTERS.ROOT, { tab: "virtual" });

    const promise = api.post("v1/spectroclusters/virtual", payload);

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t("Failed to create virtual cluster"),
        description: error.message,
      });
      return;
    }

    notifications.success({
      message: `Virtual cluster "${data.name}" has been created successfully. Deployment is in progress...`,
    });
    historyService.push(pathToPush);
  },
});

export function onClusterFormSubmit() {
  return async (dispatch, getState) => {
    await dispatch(
      virtualClusterFormActions.submit({ module: VIRTUAL_CLUSTER_FORM_MODULE })
    );

    const tenantLevelClusterGroup = hasTenantLevelClusterGroup(getState());

    if (tenantLevelClusterGroup) {
      dispatch(developerQuotaUsageFetcher.fetch("tenant"));
    } else {
      dispatch(developerQuotaUsageFetcher.fetch("system"));
    }
  };
}

export function onClusterGroupChange(clusterGroupUid) {
  return async (dispatch) => {
    const clusterGroups =
      clusterGroupsFetcher.selector(store.getState())?.result?.items || [];
    const selectedClusterGroupScope = clusterGroups.find(
      (clusterGroup) => clusterGroup.uid === clusterGroupUid
    )?.scope;

    await dispatch(
      sandboxClusterQuotaUsageFetcher.fetch(selectedClusterGroupScope)
    );

    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        value: clusterGroupUid,
        name: "clusterGroupUid",
      })
    );

    const defaultLimits = await getVirtualClusterDefaultLimit(
      clusterGroupUid,
      selectedClusterGroupScope
    );

    dispatch(
      virtualClusterFormActions.batchChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        updates: defaultLimits,
      })
    );
  };
}

export function onWizardModalClose() {
  const data = store.getState().forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data || {};
  const initialData =
    store.getState().forms[VIRTUAL_CLUSTER_FORM_MODULE]?.initialData || {};
  const returnPath = generatePath(VIRTUAL_CLUSTER.ROOT);

  if (isEqual(data, initialData)) {
    historyService.push(returnPath);
    return;
  }

  cancelCreateVirtualClusterModal.open().then(() => {
    historyService.push(returnPath);
  });
}
