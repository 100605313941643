import moment from "moment";
import ModalService from "services/modal";
import dataFetcher from "modules/dataFetcher";
import ProfileStackModule from "modules/profileStack";
import ListActions from "modules/list/actions";
import {
  ClusterProfileSchema,
  EdgeMachineSchema,
  EventSchema,
} from "utils/schemas";
import api from "services/api";
import store from "services/store";

export const EDGE_MACHINES_MODULE = "edgeMachines";
export const EDIT_HOST_PAIRING_KEY = "hostPairingKey";
export const addEdgeMachinesDrawer = new ModalService("addEdgeMachine");
export const deleteEdgeMachine = new ModalService("deleteEdgeMachine");
export const editHostPairingKeyModal = new ModalService(
  "editHostPairingKeyModal"
);

export const disksDetailsModal = new ModalService("disksDetails");
export const nicsDetailsModal = new ModalService("nicsDetails");

export const applianceProfilesStackModule = new ProfileStackModule({
  name: "appliance-details",
});
export const APPLIANCE_EVENTS_LIST_MODULE = "applianceEventsList";

export const edgeMachineDetailsFetcher = dataFetcher({
  schema: EdgeMachineSchema,
  selectors: ["edgeMachineDetails"],
  async fetchData(_, applianceId) {
    const data = await api.get(`v1/edgehosts/${applianceId}`);
    return data;
  },
});

export const edgeMachineProfilesFetcher = dataFetcher({
  schema: [ClusterProfileSchema],
  selectors: ["edgeMachineDetails", "profiles"],
  async fetchData(_, applianceId) {
    const profiles = await api
      .get(`v1/edgehosts/${applianceId}/profiles`)
      .then(({ profiles }) =>
        profiles.map((profile) => ({
          ...profile,
          spec: { published: { ...profile.spec } },
        }))
      );

    return profiles || [];
  },
});

function mapApplianceEventsFilters(query) {
  const filters = [];

  if (query.status && query.status.length > 0) {
    filters.push(`severity=${query.status.join("ORseverity=")}`);
  }

  if (query.interval && query.interval !== "all-time") {
    const [time, unit] = query.interval.split(" ");
    if (time && unit) {
      const startTime = moment().subtract(time, unit).utc().format();
      filters.push(`metadata.creationTimestamp>${startTime}`);
    }
  }

  return filters.join("AND");
}

export const applianceEventsListActions = new ListActions({
  schema: [EventSchema],
  initialQuery() {
    return {
      limit: 100,
      interval: "all-time",
      status: [],
    };
  },
  fetchData(query) {
    const edgeHostUid = store.getState().location.params.id;
    const filters = mapApplianceEventsFilters(query);

    return api.get(`v1/events/components/edgehost/${edgeHostUid}`, {
      continue: query.continue,
      limit: query.limit,
      filters,
    });
  },
});

export const tagsCache = dataFetcher({
  selectors: ["edgehost", "tags", "cache"],
  fetchData() {
    return api.get("v1/edgehosts/tags").then((response) => response.tags);
  },
});

export const tagsFetcher = dataFetcher({
  selectors: ["edgehost", "tags"],
  async fetchData(selectors, filters) {
    let tags = tagsCache.selector(store.getState())?.result;
    if (!tags) {
      tags = await store.dispatch(tagsCache.fetch());
    }

    if (filters?.search) {
      return Promise.resolve(
        tags.filter((tag) =>
          tag.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase())
        )
      );
    }

    return tags;
  },
});
