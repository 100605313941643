export function findLocationData({ entity, type, property }) {
  let value;

  if (entity?.id?.includes(type)) {
    value = entity.properties?.[property] || entity?.text;
  } else {
    value = (entity?.context || []).find((context) =>
      context.id?.includes(type)
    )?.[property];
  }

  if (type === "region") {
    value = value?.includes("-") ? value.split("-")[1] : value;
  }

  return value;
}
