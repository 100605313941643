import i18n from "i18next";

import ListActions from "modules/list/actions";
import { nonProjectApi } from "services/api";
import dataFetcher from "modules/dataFetcher";
import createFormActions from "modules/form/actions";
import { ipamFormModal, IPAM_MODULE, ipamDeleteModal } from "../services/ipam";
import { IPPoolSchema } from "utils/schemas";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import { getIpamFormPayload } from "../selectors/ipam";
import Validator from "services/validator";
import {
  Missing,
  isValidIPRange,
  isValidIP,
  isValidPrefix,
  isValidDomain,
  isValidGateway,
} from "services/validator/rules";
import i18next from "i18next";

const validator = new Validator();

validator.addRule(["name", "gateway"], Missing());

validator.addRule(["nameserverAddresses"], isValidIP());
validator.addRule(["gateway"], isValidGateway());
validator.addRule(["nameserverSearch"], isValidDomain());

validator.addRule(
  ["start", "end", "prefix"],
  NetworkTypeRule("range", Missing())
);
validator.addRule(["start", "end"], NetworkTypeRule("range", isValidIP()));
validator.addRule(["prefix"], NetworkTypeRule("range", isValidPrefix()));

validator.addRule(
  ["subnet", "cidrPrefix"],
  NetworkTypeRule("subnet", Missing())
);
validator.addRule(["cidrPrefix"], NetworkTypeRule("subnet", isValidPrefix()));
validator.addRule(["subnet"], NetworkTypeRule("subnet", isValidIPRange()));

function NetworkTypeRule(mode, validationFnc = () => {}) {
  return (value, key, data) => {
    if (data.mode === mode) {
      return validationFnc(value, key, data);
    }
    return false;
  };
}

export const ipamPageFetcher = dataFetcher({
  selectors: [
    "overlordPools",
    (state) => state.location?.params?.type,
    (state) => state.location?.params?.uid,
  ],
  fetchData([_, type, uid], query) {
    return nonProjectApi.get(`v1/overlords/${type}/${uid}/pools`, query);
  },
});

export const ipamListActions = new ListActions({
  dataFetcher: ipamPageFetcher,
  schema: [IPPoolSchema],
});

export const ipamFormActions = createFormActions({
  validator,
  init() {
    if (ipamFormModal?.data?.guid) {
      const ipPool = getStoreEntity(ipamFormModal.data.guid, IPPoolSchema);
      return Promise.resolve({
        ...(ipPool?.spec || {}),
        ...(ipPool?.spec?.pool || {}),
        mode: ipPool?.spec?.pool?.subnet ? "subnet" : "range",
        name: ipPool?.metadata?.name,
        nameserverAddresses: ipPool?.spec?.pool?.nameserver?.addresses || [],
        nameserverSearch: ipPool?.spec?.pool?.nameserver?.search || [],
        cidrPrefix: ipPool?.spec?.pool?.prefix,
      });
    }
    return Promise.resolve({
      mode: "range",
      name: "",
      start: "",
      end: "",
      prefix: "",
      cidrPrefix: "",
      subnet: "",
      gateway: "",
      nameserverAddresses: [],
      nameserverSearch: [],
      restrictToSingleCluster: false,
    });
  },
  async submit(data) {
    const state = store.getState();
    const urlParams = state.location.params;
    const urlBase = `v1/overlords/${urlParams.type}/${urlParams.uid}/pools`;
    const method = ipamFormModal.data?.guid ? "put" : "post";

    let url = urlBase;
    if (ipamFormModal.data?.guid) {
      const ipPool = getStoreEntity(ipamFormModal.data.guid, IPPoolSchema);
      url = `${urlBase}/${ipPool.metadata.uid}`;
    }

    try {
      await nonProjectApi[method](url, getIpamFormPayload(state));
    } catch (err) {
      notifications.error({
        message: ipamFormModal.data?.guid
          ? i18next.t("Something went wrong while updating the IP pool")
          : i18next.t("Something went wrong while creating the IP pool"),
        description: err.message,
      });

      return Promise.reject();
    }
  },
});

export function deleteIpam(guid) {
  return function thunk(dispatch, getState) {
    ipamDeleteModal.open({ guid }).then(async () => {
      const state = getState();
      const urlParams = state.location.params;
      const ipPool = getStoreEntity(guid, IPPoolSchema);
      const promise = nonProjectApi.delete(
        `v1/overlords/${urlParams.type}/${urlParams.uid}/pools/${ipPool.metadata.uid}`
      );

      try {
        await promise;
        dispatch(ipamListActions.initialize(IPAM_MODULE));
        notifications.success({
          message: i18n.t('"{{poolName}}" has been successfully deleted.', {
            poolName: ipPool.metadata.name,
          }),
        });
      } catch (err) {
        notifications.error({
          details: err.message,
          message: i18n.t("Unable to delete the IP pool."),
        });
      }
    });
  };
}

export function openIpamModal(guid) {
  return function thunk(dispatch, getState) {
    function fallback() {
      dispatch(ipamListActions.initialize(IPAM_MODULE));
    }
    ipamFormModal.open({ guid }).then(async () => {
      await dispatch(ipamFormActions.submit({ module: IPAM_MODULE }));
      fallback();
    }, fallback);
    dispatch(ipamFormActions.init({ module: IPAM_MODULE }));
  };
}

export function onSubnetChange(value) {
  return function thunk(dispatch, getState) {
    dispatch(
      ipamFormActions.onChange({ module: IPAM_MODULE, name: "subnet", value })
    );
  };
}
