import React from "react";
import { useTranslation } from "react-i18next";

import { APP_PROFILES } from "utils/constants/routes";
import CreateButton from "components/common/CreateButton";
import allPermissions from "services/permissions";

export default function CreateAppProfileButton() {
  const { t } = useTranslation();

  if (!allPermissions.has("appProfile.create")) {
    return null;
  }

  return (
    <CreateButton
      linkTo={APP_PROFILES.CREATE}
      label={t("New App Profile")}
      permissions={[]}
    />
  );
}
