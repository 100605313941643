import { getPair, mergeYaml } from "utils/yaml";
import * as YAML from "yaml";

function updateNodePoolYaml(yaml, yamlToMerge) {
  let yamlDocs;
  try {
    yamlDocs = YAML.parseAllDocuments(yaml);
  } catch (error) {
    return yaml;
  }
  const yamlDocIndex = yamlDocs.findIndex((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return (
      kindConstruct &&
      ["MachineDeployment", "KubeadmControlPlane"].includes(
        kindConstruct.value.value
      )
    );
  });

  if (yamlDocIndex === -1) {
    return yaml;
  }

  const yamlDoc = yamlDocs[yamlDocIndex];
  const updatedDoc = mergeYaml(yamlDoc.toString(), yamlToMerge);

  return yamlDocs
    .map((doc, index) => {
      if (index === yamlDocIndex) {
        return updatedDoc;
      }

      return doc.toString();
    })
    .join("\n");
}

export function updateNodePoolNameInYAML(yaml, name) {
  const updatedYaml = updateNodePoolYaml(
    yaml,
    `
metadata:
  name: "${name}"
`
  );

  let yamlDocs = [];
  try {
    yamlDocs = YAML.parseAllDocuments(updatedYaml);
  } catch (error) {
    return yaml;
  }

  // now we update only worker pool
  // name sync has some addition side-effects
  // because a worker pool is not a singleton
  // and the resources it creates must be unique
  // in the cluster
  const yamlDoc = yamlDocs.find((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return kindConstruct && kindConstruct.value.value === "MachineDeployment";
  });
  if (!yamlDoc) {
    return updatedYaml;
  }
  const referecedResources = [
    "spec.template.spec.bootstrap.configRef",
    "spec.template.spec.infrastructureRef",
  ];

  referecedResources.forEach((resourcePath) => {
    const resource = getPair(yamlDoc, resourcePath.split("."));
    if (!resource) {
      return;
    }
    const resourceKind = getPair(resource, ["kind"]);
    if (!resourceKind) {
      return false;
    }

    const resourceDoc = yamlDocs.find((doc) => {
      const kindConstruct = getPair(doc, ["kind"]);
      return (
        kindConstruct && kindConstruct.value.value === resourceKind.value.value
      );
    });

    if (!resourceDoc) {
      return;
    }

    const nameConstruct = getPair(resourceDoc, ["metadata", "name"]);
    const resourceName = getPair(resource, ["name"]);
    if (!nameConstruct || !resourceName) {
      return;
    }

    resourceName.value.value = `${name}-resource`;
    nameConstruct.value.value = `${name}-resource`;
  });

  return yamlDocs.map((doc) => doc.toString()).join("\n");
}

export function updateNodePoolSizeInYAML(yaml, size) {
  return updateNodePoolYaml(
    yaml,
    `
spec:
  replicas: ${size}
`
  );
}

export function populateNodePoolYAML({ yaml, poolName, size }) {
  const withName = updateNodePoolNameInYAML(yaml, poolName);
  const withSize = updateNodePoolSizeInYAML(withName, size);

  return withSize;
}

export function extractNodePoolFieldsFromYAML(yaml) {
  let yamlDocs;
  try {
    yamlDocs = YAML.parseAllDocuments(yaml);
  } catch (error) {
    return {};
  }
  const yamlDocIndex = yamlDocs.findIndex((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return (
      kindConstruct &&
      ["MachineDeployment", "KubeadmControlPlane"].includes(
        kindConstruct.value.value
      )
    );
  });

  if (yamlDocIndex === -1) {
    return {};
  }

  const yamlDoc = yamlDocs[yamlDocIndex];
  const nameConstruct = getPair(yamlDoc, ["metadata", "name"]);
  const sizeConstruct = getPair(yamlDoc, ["spec", "replicas"]);
  return {
    poolName: nameConstruct && nameConstruct.value.value,
    size: sizeConstruct && sizeConstruct.value.value,
  };
}

export function populateClusterConfig({ yaml, masterPoolName }) {
  let yamlDocs;
  try {
    yamlDocs = YAML.parseAllDocuments(yaml);
  } catch (error) {
    return yaml;
  }
  const yamlDocIndex = yamlDocs.findIndex((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return kindConstruct?.value?.value === "Cluster";
  });

  if (yamlDocIndex === -1) {
    return yaml;
  }

  const yamlDoc = yamlDocs[yamlDocIndex];
  const updatedDoc = mergeYaml(
    yamlDoc.toString(),
    `
spec:
  controlPlaneRef:
    name: "${masterPoolName}"
`
  );
  return yamlDocs
    .map((doc, index) => {
      if (index === yamlDocIndex) {
        return updatedDoc;
      }

      return doc.toString();
    })
    .join("\n");
}
