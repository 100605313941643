export const initialState = {
  cardSelectionIsLoading: false,
};

export function SET_DEFAULT_PAYMENT_REQUEST(nextState) {
  nextState.cardSelectionIsLoading = true;
}

export function SET_DEFAULT_PAYMENT_SUCCESS(nextState) {
  nextState.cardSelectionIsLoading = false;
}

export function SET_DEFAULT_PAYMENT_FAILURE(nextState) {
  nextState.cardSelectionIsLoading = false;
}
