import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import Icon from "components/ui/Icon";
import DocLink from "components/common/DocLink";

const Link = styled.a`
  color: black;
`;

//more sizes can be added in the future
const sizes = {
  small: "14px",
  medium: "16px",
  large: "20px",
};

const HelpIconWrapper = styled(Icon)`
  ${(props) =>
    props.size &&
    css`
      font-size: ${props.size};
  `}
`;

export default function HelpIcon({size, title, docLocation}) {
  const { t } = useTranslation();
  const fontSize = size ? sizes[size] : sizes.medium;
  return (
    <>
      <DocLink color="black" location={docLocation}>
        <Tooltip title={title}>
          <HelpIconWrapper size={fontSize} awesome={faCircleQuestion} />
        </Tooltip>
      </DocLink>
    </>
  );
}
