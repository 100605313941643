import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { TextButton } from "components/ui/Button";
import { FieldWrap } from "components/styled/Field";
import { faPlusCircle, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

const FieldsWrapper = styled.div``;

const MultiInputsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const RemoveFieldButton = styled(TextButton)`
  font-size: 15px;
  margin-left: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  > ${FieldWrap} {
    margin-top: 0;
    width: 428px;

    .ant-input {
      padding-right: 36px;
    }
  }

  > ${RemoveFieldButton} {
    margin-top: 4px;
    margin-left: -34px;
  }
`;

const AddNewButton = styled.div`
  background-color: transparent;
  min-width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 5px;
  border: 1px dashed #dae9fd;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

function MultipleFields(Component) {
  function FieldsComponent(
    { name, value = [""], onChange, disabled, readOnly, ...rest },
    ref
  ) {
    const onAdd = () => onChange([...value, ""]);
    const onRemove = (index) => () => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    };

    const inputs = value.map((_, index) => {
      return (
        <InputWrapper
          key={index}
          data-qa-type="multiple-input"
          data-qa-index={index}
        >
          <Component
            ref={ref}
            {...rest}
            name={`${name}.${index}`}
            data-qa={`${name}_${index}`}
            disabled={disabled}
          />
          {value.length > 1 && (
            <RemoveFieldButton
              secondary
              data-qa={`remove_field_${index}`}
              onClick={onRemove(index)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </RemoveFieldButton>
          )}
          {index === value.length - 1 && (
            <AddNewButton
              data-qa="add_field"
              onClick={onAdd}
              disabled={disabled || readOnly}
              secondary
            >
              <FontAwesomeIcon icon={faPlusCircle} color="#3575CF" />
            </AddNewButton>
          )}
        </InputWrapper>
      );
    });

    return (
      <MultiInputsWrapper>
        <FieldsWrapper>{inputs}</FieldsWrapper>
      </MultiInputsWrapper>
    );
  }

  FieldsComponent.displayName = `FieldsComponent(${Component.displayName})`;

  return React.forwardRef(FieldsComponent);
}

export default MultipleFields;
