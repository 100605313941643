import { AppDeploymentSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { createSelector } from "reselect";
import { applicationNotificationsFetcher } from "../services/details";
import { PermissionService } from "services/permissions";

export const getAppDeploymentEntity = getEntity(
  (state) => state.appDeployments?.appDeploymentGuid,
  AppDeploymentSchema
);

export const getAppDeploymentNotification = createSelector(
  applicationNotificationsFetcher.selector,
  (data) => {
    if (!data.result) {
      return null;
    }

    return (data.result?.items || []).find(
      (notification) => !notification.action.isDone
    );
  }
);

export const getAppDeploymentNotificationDetails = createSelector(
  getAppDeploymentNotification,
  (notification) => {
    if (!notification) {
      return [];
    }

    const events = notification.action.events;
    return Object.keys(events)
      .sort((a, b) => a - b)
      .map((key) => {
        const event = events[key];

        return {
          message: event.message,
          type: event.type,
          tierName: event.appTierName,
          uid: key,
        };
      });
  }
);

export const canDeleteAppDeployment = createSelector(
  getAppDeploymentEntity,
  (deployment) => {
    const { permissions } = deployment?.metadata?.annotations || {};
    const appDeploymentPermissions = new PermissionService(
      permissions?.split(",")
    );
    return appDeploymentPermissions.has("appDeployment.delete");
  }
);
