export const initialState = {
  isLoading: true,
  serviceMetadata: null,
};

export function FETCH_SAML_METADATA_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function FETCH_SAML_METADATA_NORMALIZATION(nextState, { result }) {
  nextState.isLoading = false;
  nextState.serviceMetadata = result;
}

export function FETCH_SAML_METADATA_SUCCESS(nextState) {
  nextState.isLoading = false;
}
