import i18n from "i18next";

import store from "services/store";
import api from "services/api";
import Validator from "services/validator";
import { Missing, EmptyString } from "services/validator/rules";
import historyService from "services/history";
import notifications from "services/notifications";

import createActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import { trim } from "utils/parsers";

import {
  sshKeyFormModal,
  SSH_KEYS_MODULE,
  sshKeyFetcher,
  sshKeysFetcher,
} from "../services";

const validator = new Validator();
validator.addRule(["name", "sshKey"], Missing());
validator.addRule(["name", "sshKey"], EmptyString());

export const sshKeyFormActions = createActions({
  init: async () => {
    let data;
    if (sshKeyFormModal?.data?.uid) {
      data = await store.dispatch(sshKeyFetcher.fetch());
    }
    return Promise.resolve({
      name: data?.metadata?.name || "",
      sshKey: data?.spec?.publicKey || "",
    });
  },
  submit: async (data) => {
    const { name, sshKey } = trim(data, ["name", "sshKey"]);
    const payload = {
      metadata: {
        name,
      },
      spec: {
        publicKey: sshKey,
      },
    };
    const sshKeyUid = sshKeyFormModal.data?.uid;
    const promise = sshKeyUid
      ? api.put(`v1/users/assets/sshkeys/${sshKeyUid}`, payload)
      : api.post("v1/users/assets/sshkeys", payload);
    let response;

    try {
      response = await promise;
    } catch (err) {
      const message = sshKeyUid
        ? i18n.t("Something went wrong when editing the SSH key")
        : i18n.t("Something went wrong when creating the SSH key");

      notifications.error({
        message,
        description: err.message,
      });
      return Promise.reject(err);
    }

    sshKeyFormModal.close();

    notifications.success({
      message: `SSH key "${name}" has been ${
        sshKeyUid ? "updated" : "created"
      } successfully`,
    });
    return response;
  },
  validator,
});

export const sshKeyAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      sshKeyFormActions.submit({ module: SSH_KEYS_MODULE })
    );
  },
});

export function openSSHKeyModal(uid) {
  return (dispatch) => {
    sshKeyFormModal.open({ uid }).then(
      async () => {
        await sshKeyAsyncAction.trigger();
        historyService.push("/settings/sshkeys");
      },
      () => historyService.push("/settings/sshkeys")
    );
    dispatch(sshKeyFormActions.init({ module: SSH_KEYS_MODULE }));
  };
}

export function openInlineCreateModal(onCreate = () => {}) {
  return function thunk(dispatch, getState) {
    dispatch(sshKeyFormActions.init({ module: SSH_KEYS_MODULE }));
    sshKeyFormModal.open().then(async () => {
      const response = await sshKeyAsyncAction.trigger();
      await dispatch(sshKeysFetcher.fetch());

      const entities = sshKeysFetcher.selector(getState());
      const selectedEntity = entities?.result?.find(
        (entity) => entity.metadata.uid === response?.uid
      );
      selectedEntity && onCreate(selectedEntity);
    });
  };
}
