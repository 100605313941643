import React from "react";

import Route, {
  ADMIN_PREFIX_WITH_PROJECT,
} from "components/common/History/Route";
import ProjectContextProvider from "components/common/History/ProjectContextProvider";

import createNamespace from "services/language";
import { CLUSTER_GROUPS } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));
const Create = React.lazy(() => import("./create"));
const Details = React.lazy(() => import("./details"));
const CreateNestedCluster = React.lazy(() =>
  import("components/common/NestedClusters/create")
);
const ClusterDetails = React.lazy(() => import("pages/clusters/details"));
const Certificates = React.lazy(() => import("pages/clusters/certificates"));

export default createNamespace({
  namespace: "clustergroups",
  Component: function Main() {
    return (
      <>
        <Route
          adminEnabled
          exact
          path={CLUSTER_GROUPS.ROOT}
          component={Index}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_GROUPS.CREATE_GROUP}
          component={Create}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_GROUPS.DETAILS_OVERVIEW}
          component={Details}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTER_GROUPS.CREATE_VIRTUAL_CLUSTER}
          component={CreateNestedCluster}
        />
        <Route
          exact
          adminOnly
          path={`${ADMIN_PREFIX_WITH_PROJECT.replace("/admin", "")}${
            CLUSTER_GROUPS.CLUSTER_GROUP_DETAILS
          }`}
          component={(props) => {
            return (
              <ProjectContextProvider>
                <ClusterDetails {...props} />
              </ProjectContextProvider>
            );
          }}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTER_GROUPS.CLUSTER_GROUP_DETAILS}
          component={(props) => {
            return <ClusterDetails {...props} />;
          }}
        />
        <Route
          exact
          adminOnly
          path={`${ADMIN_PREFIX_WITH_PROJECT.replace("/admin", "")}${
            CLUSTER_GROUPS.CERTIFICATES
          }`}
          component={(props) => (
            <ProjectContextProvider>
              <Certificates {...props} />
            </ProjectContextProvider>
          )}
        />
        <Route
          exact
          adminOnly
          path={`${ADMIN_PREFIX_WITH_PROJECT.replace("/admin", "")}${
            CLUSTER_GROUPS.SCAN_DETAILS
          }`}
          component={(props) => (
            <ProjectContextProvider>
              <ClusterDetails {...props} />
            </ProjectContextProvider>
          )}
        />
      </>
    );
  },
});
