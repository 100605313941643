import i18next from "i18next";
import { createSelector } from "reselect";

import api from "services/api";
import notifications from "services/notifications";
import dataFetcher from "modules/dataFetcher";
import { presentInstanceTypesAsOptions, presentAzsAsOptions } from "./utils";

export function createTencentFormFactory(
  {
    formModuleName,
    formActions,
    getCloudAccountUid,
    getClusterConfig = (state) => state.forms?.[formModuleName]?.data || {},
  },
  { isOverlord = false, isNodes = false } = {}
) {
  const regionSelect = (state) => getClusterConfig(state).region;

  const azsFetcher = dataFetcher({
    selectors: ["tencent", "azs", getCloudAccountUid, regionSelect],
    fetchData: async ([_1, _2, cloudAccountUid, region]) => {
      try {
        const azs = await api.get(
          `v1/clouds/tencent/regions/${region}/zones?cloudAccountUid=${cloudAccountUid}`
        );
        return azs?.zones || [];
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the availability zones"
          ),
        });
      }
    },
  });

  const regionsFetcher = dataFetcher({
    selectors: ["tke", "regions", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid]) => {
      try {
        const regions = await api.get(
          `v1/clouds/tencent/regions?cloudAccountUid=${cloudAccountUid}`
        );

        return regions;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to fetch the regions"
          ),
          description: err?.message,
        });
      }
    },
  });

  const instanceTypesFetcher = dataFetcher({
    selectors: ["tencent", "instanceTypes", getCloudAccountUid, regionSelect],

    fetchData: async ([_1, _2, cloudAccountUid, region]) => {
      const response = await api.get(
        `v1/clouds/tencent/regions/${region}/instancetypes?cloudAccountUid=${cloudAccountUid}`
      );
      return response?.instanceTypes || [];
    },
  });

  const getTencentInstanceTypes = createSelector(
    instanceTypesFetcher.selector,
    ({ result }) => {
      return presentInstanceTypesAsOptions(result);
    }
  );

  const getSelectedInstance = (index) =>
    createSelector(
      instanceTypesFetcher.selector,
      (state) => getClusterConfig(state),
      ({ result }, config) => {
        const selectedInstance =
          config?.instanceType || config?.nodePools?.[index]?.instanceType;
        if (!result || !selectedInstance) {
          return {};
        }
        return (
          (result || []).find(
            (instanceType) => instanceType.type === selectedInstance
          ) || {}
        );
      }
    );

  const getAvailabilityZones = (index) =>
    createSelector(
      azsFetcher.selector,
      instanceTypesFetcher.selector,
      (state) => getClusterConfig(state),
      ({ result: azs }, { result: instanceTypes }, config) => {
        const selectedInstance =
          config?.instanceType || config?.nodePools?.[index]?.instanceType;
        return presentAzsAsOptions(azs, instanceTypes, selectedInstance);
      }
    );

  const fetchNodesConfigParams = () => {
    return (dispatch, getState) => {
      const config = getClusterConfig(getState());
      if (!config.region) {
        return;
      }
      return Promise.allSettled([
        dispatch(azsFetcher.fetch()),
        dispatch(instanceTypesFetcher.fetch()),
        dispatch(vpcsFetcher.fetch()),
      ]);
    };
  };

  const sshFetcher = dataFetcher({
    selectors: [
      "tke",
      "ssh",
      (state) => getClusterConfig(state)?.region,
      getCloudAccountUid,
    ],
    async fetchData([_1, _2, region, cloudAccountUid]) {
      try {
        const response = await api.get(
          `v1/clouds/tencent/regions/${region}/keypairs?cloudAccountUid=${cloudAccountUid}`
        );
        return response?.keypairs || [];
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to get the SSH keys"
          ),
          description: err?.message,
        });
      }
    },
  });

  const vpcsFetcher = dataFetcher({
    selectors: [
      "tke",
      "vpcs",
      (state) => getClusterConfig(state)?.region,
      getCloudAccountUid,
    ],
    async fetchData([_1, _2, region, cloudAccountUid]) {
      try {
        const response = await api.get(
          `v1/clouds/tencent/regions/${region}/vpcs?cloudAccountUid=${cloudAccountUid}`
        );
        return response?.vpcs || [];
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to get the VPCS"
          ),
          description: err?.message,
        });
      }
    },
  });

  const getSelectedVpcIdSubnets = createSelector(
    vpcsFetcher.selector,
    (state) => getClusterConfig(state)?.vpcid || "",
    ({ result }, selectedVpcId) =>
      (result || []).find((item) => item.vpcId === selectedVpcId)?.subnets || []
  );

  const publicSecurityGroupFetcher = dataFetcher({
    selectors: [
      "tke",
      "publicSecurityGroup",
      (state) => getClusterConfig(state)?.region,
      getCloudAccountUid,
    ],
    async fetchData([_1, _2, region, cloudAccountUid]) {
      try {
        const response = await api.get(
          `v1/clouds/tencent/regions/${region}/securitygroups?cloudAccountUid=${cloudAccountUid}`
        );
        return response?.groups || [];
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to get the Public Security Groups"
          ),
          description: err?.message,
        });
      }
    },
  });

  function fetchClusterConfigParams() {
    return (dispatch) => {
      dispatch(
        formActions.onChange({
          module: formModuleName,
          name: "staticPlacement",
          value: true,
        })
      );
      dispatch(regionsFetcher.fetch());
    };
  }

  function onInstanceTypeChange({ value, getName, isEdit }) {
    return (dispatch) => {
      dispatch(
        formActions.onChange({
          module: formModuleName,
          name: getName("instanceType"),
          value,
        })
      );
      if (!isEdit) {
        dispatch(
          formActions.onChange({
            module: formModuleName,
            name: getName("azs"),
            value: [],
          })
        );
      }
    };
  }

  const getSubnetsForSelectedVpcId = createSelector(
    vpcsFetcher.selector,
    (state) => getClusterConfig(state)?.vpcid,
    ({ result: vpcids }, selectedVpcId) => {
      const currentAzVpc = (vpcids || []).find(
        (vpc) => vpc.vpcId === selectedVpcId
      );
      if (!currentAzVpc) {
        return [];
      }
      return (currentAzVpc.subnets || []).map((subnet) => ({
        label: subnet?.subnetId,
        value: subnet?.subnetId,
      }));
    }
  );

  return {
    actions: {
      onInstanceTypeChange,
    },
    effects: {
      fetchClusterConfigParams,
      fetchNodesConfigParams,
    },
    fetchers: {
      regionsFetcher,
      sshFetcher,
      vpcsFetcher,
      azsFetcher,
      instanceTypesFetcher,
      publicSecurityGroupFetcher,
    },
    selectors: {
      getSelectedVpcIdSubnets,
      getTencentInstanceTypes,
      getSelectedInstance,
      getAvailabilityZones,
      getSubnetsForSelectedVpcId,
    },
  };
}
