import i18n from "i18next";

import api from "services/api";
import notifications from "services/notifications";
import store from "services/store";

import dataFetcher from "modules/dataFetcher";
import { WorkspaceSchema } from "utils/schemas";
import {
  getCurrentWorkspaceRoles,
  getSelectedWorkspaceRoleEntity,
} from "./selectors";

export const projectWorkspacesFetcher = dataFetcher({
  selectors: [
    "project-workspaces",
    (state) => state.forms.workspaceRoles.data?.projectUid,
  ],
  async fetchData([_, projectUid]) {
    const currentWorkspaceRoles = getCurrentWorkspaceRoles(store.getState());
    const selectedWorkspaceRoleId = getSelectedWorkspaceRoleEntity(
      store.getState()
    );

    try {
      const res = await api.get(`v1/dashboard/workspaces`, null, {
        headers: { ProjectUid: projectUid },
      });

      return (res?.items || []).filter(
        (workspace) =>
          selectedWorkspaceRoleId ||
          !currentWorkspaceRoles.some(
            (workspaceRole) =>
              workspaceRole.workspaces[0].metadata.uid ===
              workspace.metadata.uid
          )
      );
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to retrieve the required workspaces"
        ),
        duration: 0,
        description: err.message,
      });
    }
  },
  schema: [WorkspaceSchema],
});
