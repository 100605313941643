import React, { useMemo } from "react";
import { Divider } from "antd";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

import Switch from "components/common/Switch";
import { createConnector } from "modules/binder";
import { ThreeColumnsLayout } from "modules/profileIDE/Layout";
import EditorLayout from "modules/profileIDE/components/Editor/layout";

import store from "services/store";
import {
  concreteWhite,
  lightMidGray,
  lightWhite,
  regentGray,
} from "utils/constants/colors";
import { SERVICE_ICONS } from "modules/profileIDE/connectors/fileList";
import { comingSoonCards } from "assets/images/appprofile/comingsoon";
import Icon from "components/ui/Icon";
import { operatorsFetcher } from "modules/profileIDE/state/operators";
import {
  containersFetcher,
  getLatestVersionRegistry,
} from "modules/profileIDE/state/containers";
import { messagingSystemsFetcher } from "modules/profileIDE/state/messagingSystems";
import { storageOptionsFetcher } from "modules/profileIDE/state/storage";
import { securityOptionsFetcher } from "modules/profileIDE/state/security";
import Loader from "components/ui/Loader";

const TIER_OPTIONS = [
  {
    label: "Container Deployment",
    value: "container",
  },
  {
    label: "Helm",
    value: "helm",
  },
  {
    label: "Manifest",
    value: "manifest",
  },
];

const ServiceTypeTitle = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 12px;
  }
`;

const ServiceSelectorWrapper = styled.div`
  border-left: 1px solid ${lightMidGray};
  height: 100%;

  h3 {
    margin-bottom: 0;
    margin-right: 5px;
  }
`;

const CardOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
  margin-bottom: 40px;
`;

const CardWrapper = styled.div`
  width: 260px;
  height: 50px;

  display: flex;
  align-items: center;
  position: relative;

  padding: 8px 16px;
  margin-right: 16px;
  margin-top: 16px;

  border: 1px solid ${concreteWhite};
  border-radius: 4px;
  cursor: pointer;

  svg {
    margin-right: 16px;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${regentGray};
      background: ${lightWhite};
      cursor: not-allowed;
    `}
`;

const ComingSoon = styled.div`
  position: absolute;
  font-size: 8px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  background: #64748b;
  padding: 0px 4px;
  right: -1px;
  top: -1px;
`;

function ServiceCard({ onClick, label, type, subType, disabled }) {
  const { t } = useTranslation();
  const Icon = SERVICE_ICONS[subType] || SERVICE_ICONS[type];

  return (
    <CardWrapper onClick={onClick} disabled={disabled}>
      {disabled ? <ComingSoon>{t("COMING SOON")}</ComingSoon> : null}
      <Icon />
      <div>{label}</div>
    </CardWrapper>
  );
}

const connector = createConnector({
  selectors: {
    currentTab: (state) => state.toolbar.activeSelection, // to take in consideration active file selection
    hideBackArrow: (state) => (state?.fileList?.files || []).length === 0,
  },
  actions: {
    addLayer:
      ({ type, name, ...rest }) =>
      async (dispatch, module) => {
        dispatch({
          type: module.actions.toolbar.onTabSelect,
          tab: "edit",
        });

        dispatch({
          type: module.actions.editor.switchMode,
          mode: "form",
        });

        const data = await store.dispatch(
          module.form.actions.init({ type, name, ...rest })
        );

        dispatch({
          type: module.actions.drafts.stashForm,
          formData: data,
        });

        dispatch({
          type: module.actions.fileList.addRootFile,
          file: data,
        });

        dispatch({
          type: module.actions.fileList.onFileSelect,
          fileGuid: data.guid,
        });
      },
    backToServicesScreen: () => (dispatch, module) => {
      dispatch({
        type: module.actions.toolbar.onTabSelect,
        tab: "edit",
      });
    },
  },
});

export default connector.connect(
  ({ currentTab, addLayer, backToServicesScreen, hideBackArrow }) => {
    const { t } = useTranslation();
    const databaseOperators = useSelector(operatorsFetcher.selector);
    const containers = useSelector(containersFetcher.selector);
    const messagingSystems = useSelector(messagingSystemsFetcher.selector);
    const objectStorageOptions = useSelector(storageOptionsFetcher.selector);
    const security = useSelector(securityOptionsFetcher.selector);

    const mappedTierOptions = useMemo(
      () =>
        TIER_OPTIONS.map((tierOption) => {
          if (tierOption.value === "container") {
            if (!containers?.result || containers?.result?.length === 0) {
              return null;
            }

            const containerPack = containers?.result?.[0];

            return {
              ...tierOption,
              type: "container",
              layer: {
                type: "container",
                registryUid: getLatestVersionRegistry(containerPack?.registries)
                  ?.uid,
                displayName: containerPack.displayName,
                template: containerPack?.template || {},
                packUid: containerPack?.packUid || "",
                version: containerPack?.version || "",
                values: containerPack?.values || "",
              },
            };
          }

          return {
            ...tierOption,
            type: tierOption.value,
            layer: { type: tierOption.value },
          };
        })
          .filter(Boolean)
          .sort((a, b) => a?.label?.localeCompare(b?.label)),
      [containers]
    );

    const mappedDatabasesOptions = useMemo(() => {
      return (databaseOperators.result || [])
        .map((operator) => {
          return {
            label: operator.displayName,
            type: "operator-instance",
            subType: operator.name,
            layer: {
              type: "operator-instance",
              displayName: operator.displayName,
              template: operator?.template || {},
              packUid: operator?.packUid || "",
              version: operator?.version || "",
              subType: operator?.name,
            },
          };
        })
        .filter(Boolean)
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    }, [databaseOperators]);

    const mappedMessagingOptions = useMemo(
      () =>
        (messagingSystems.result || [])
          .map((system) => {
            return {
              label: system.displayName,
              type: "operator-instance",
              subType: system.name,
              layer: {
                type: "operator-instance",
                displayName: system.displayName,
                template: system?.template || {},
                packUid: system?.packUid || "",
                version: system?.version || "",
                subType: system?.name || "",
              },
            };
          })
          .filter(Boolean)
          .sort((a, b) => a?.label?.localeCompare(b?.label)),
      [messagingSystems]
    );

    const mappedObjectStorageOptions = useMemo(
      () =>
        (objectStorageOptions.result || [])
          .map((option) => {
            return {
              label: option.displayName,
              type: "operator-instance",
              subType: option.name,
              description: `Configure a ${option.displayName} layer`,
              layer: {
                type: "operator-instance",
                displayName: option.displayName,
                template: option?.template || {},
                packUid: option?.packUid || "",
                version: option?.version || "",
                subType: option?.name || "",
              },
            };
          })
          .filter(Boolean)
          .sort((a, b) => a?.label?.localeCompare(b?.label)),
      [objectStorageOptions]
    );

    const mappedSecurityOptions = useMemo(
      () =>
        (security.result || [])
          .map((option) => {
            return {
              label: option.displayName,
              type: "operator-instance",
              subType: option.name,
              description: `Configure a ${option.displayName} layer`,
              layer: {
                type: "operator-instance",
                displayName: option.displayName,
                template: option?.template || {},
                packUid: option?.packUid || "",
                version: option?.version || "",
                subType: option?.name || "",
              },
            };
          })
          .filter(Boolean)
          .sort((a, b) => a?.label?.localeCompare(b?.label)),
      [security]
    );

    const CONFIG_SERVICES = useMemo(
      () => [
        {
          title: t("Services"),
          options: mappedTierOptions,
          isLoading: containers.isLoading,
        },
        {
          title: t("Databases"),
          options: [...mappedDatabasesOptions, ...comingSoonCards.database],
          isLoading: databaseOperators.isLoading,
        },
        {
          title: t("Object Storage"),
          options: [...mappedObjectStorageOptions, ...comingSoonCards.storage],
          isLoading: objectStorageOptions.isLoading,
        },
        {
          title: t("Messaging Systems"),
          options: mappedMessagingOptions,
          isLoading: messagingSystems.isLoading,
        },
        {
          title: t("Security"),
          options: [...mappedSecurityOptions, ...comingSoonCards.security],
          isLoading: security.isLoading,
        },
      ],
      [
        mappedDatabasesOptions,
        mappedMessagingOptions,
        mappedTierOptions,
        mappedObjectStorageOptions,
        mappedSecurityOptions,
        t,
        containers.isLoading,
        messagingSystems.isLoading,
        databaseOperators.isLoading,
        objectStorageOptions.isLoading,
        security.isLoading,
      ]
    );

    function renderService({ title, options, isLoading }) {
      return !!options.length ? (
        <>
          <Divider orientation="left" orientationMargin="0">
            <ServiceTypeTitle>
              <h3>{title} </h3>
              {isLoading ? (
                <span>
                  <Loader small />
                </span>
              ) : null}
            </ServiceTypeTitle>
          </Divider>
          <CardOptionsWrapper>
            {options.map((tier) => (
              <ServiceCard
                key={tier.label}
                onClick={() => {
                  if (tier.disabled) {
                    return;
                  }
                  return addLayer(tier.layer);
                }}
                {...tier}
              />
            ))}
          </CardOptionsWrapper>
        </>
      ) : null;
    }

    return (
      <Switch value={currentTab}>
        <Switch.Case value="add">
          <ThreeColumnsLayout.Editor>
            <ServiceSelectorWrapper>
              <EditorLayout />
              {!hideBackArrow && (
                <EditorLayout.BackArrow>
                  <Icon awesome={faArrowLeft} onClick={backToServicesScreen} />
                </EditorLayout.BackArrow>
              )}
              <EditorLayout.HeaderName>Add Service</EditorLayout.HeaderName>
              <EditorLayout.Content>
                <p>Select a service to start configuration</p>

                {CONFIG_SERVICES.map(renderService)}
              </EditorLayout.Content>
            </ServiceSelectorWrapper>
          </ThreeColumnsLayout.Editor>
          <ThreeColumnsLayout.Files />
        </Switch.Case>
      </Switch>
    );
  }
);
