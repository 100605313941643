import React, { useEffect, createContext, useContext } from "react";
import { connect } from "react-redux";
import get from "lodash/get";

import createActions, { areFormActions } from "modules/form/actions";
import { getFieldErrors } from "modules/form/selectors";
import Loader from "components/ui/Loader";

export const Context = createContext({});
export const ReadOnlyContext = createContext();

export function createForm({
  Component,
  actions,
  smallLoader = false,
  ...rest
} = {}) {
  let formActions = createActions({ ...rest });
  if (actions && areFormActions(actions)) {
    formActions = actions;
  }

  function Form({ initForm, isLoading, ...rest }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initForm, []);

    if (isLoading || isLoading === undefined) {
      return <Loader small={smallLoader} />;
    }

    return (
      <Context.Provider value={rest}>
        <Component {...rest} />
      </Context.Provider>
    );
  }

  return connect(
    (state, ownProps) => {
      const moduleState = state.forms[ownProps.module] || {};
      let isSubmitDisabled = false;
      if (moduleState.errors) {
        isSubmitDisabled =
          !!moduleState.errors.length ||
          moduleState.validating ||
          moduleState.submitting;
      }
      // ownProps will overwrite the module's state to be able to send
      // a different(presented) form data to the fields

      return {
        ...moduleState,
        fieldErrors: getFieldErrors(moduleState),
        isSubmitDisabled,
        ...ownProps,
      };
    },
    (dispatch, ownProps) => ({
      initForm: () => {
        // TODO: This should be removed when we are no longer using useEffect
        if (ownProps.preventInitOnMount) {
          return;
        }
        dispatch(formActions.init({ module: ownProps.module }));
      },
      onFieldChange: (name, value) => {
        dispatch(
          formActions.onChange({ module: ownProps.module, name, value })
        );
      },
      onFieldBlur: (name) =>
        dispatch(formActions.validateField({ module: ownProps.module, name })),
      onSubmit: () => dispatch(formActions.submit({ module: ownProps.module })),
      revalidateField: (name) =>
        dispatch(
          formActions.revalidateField({ module: ownProps.module, name })
        ),
      validateForm: () =>
        dispatch(formActions.validateForm({ module: ownProps.module })),
    })
  )(Form);
}

export function ReadOnlyProvider({ isActive, children }) {
  return (
    <ReadOnlyContext.Provider value={isActive}>
      {children}
    </ReadOnlyContext.Provider>
  );
}

export function useFormContext() {
  return useContext(Context);
}

export function connectField(Component, isReadOnlyComponent = false) {
  function ConnectedField(props, ref) {
    const name = props.name;
    const context = useContext(Context);
    const readOnly = useContext(ReadOnlyContext);

    if (Object.keys(context).length === 0) {
      console.trace(
        "Missing field context. All Fields must be inside a Form component"
      );
      return null;
    }

    const fieldError = context.fieldErrors[name];
    const isDisabled = (readOnly && !isReadOnlyComponent) || props.disabled;

    return (
      <Component
        ref={ref}
        value={get(context.data, name)}
        onChange={context.onFieldChange.bind(null, name)}
        onBlur={context.onFieldBlur.bind(null, name)}
        validation={fieldError}
        readOnly={readOnly && isReadOnlyComponent}
        status={fieldError ? "error" : ""}
        {...props}
        disabled={isDisabled}
      />
    );
  }
  return React.forwardRef(ConnectedField);
}

export function SyncFormValues({ onChange }) {
  const { data, onSubmit } = useFormContext();

  useEffect(() => {
    const onFormChange = onChange || onSubmit || (() => {});
    onFormChange(data);
  }, [data, onSubmit, onChange]);

  return null;
}

export function SyncFormChanges({ onChange }) {
  const context = useFormContext();
  const data = context.data;

  useEffect(() => {
    onChange && onChange(data);
  }, [data, onChange]);

  return null;
}
