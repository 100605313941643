import { combineReducers } from "redux";
import createReducer from "utils/createReducer";

import * as configure from "./configure";
import * as list from "./list";

export default combineReducers({
  configure: createReducer(configure),
  list: createReducer(list),
});
