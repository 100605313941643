export function FETCH_DATA_REQUEST(nextState, { path, keepStale }, state) {
  if (keepStale && state[path]) {
    nextState[path].failure = false;
  }

  nextState[path] = {
    result: state?.[path]?.result || null,
    normalized: state?.[path]?.normalized || null,
    isLoading: true,
    failure: null,
  };
}

export function FETCH_DATA_NORMALIZATION(nextState, { path, result }) {
  nextState[path].normalized = result;
}

export function FETCH_DATA_SUCCESS(nextState, { path, result }, state) {
  nextState[path].result = result;
  nextState[path].isLoading = false;
  nextState[path].failure = null;
}

export function FETCH_DATA_FAILURE(nextState, { path, error }, state) {
  nextState[path].result = null;
  nextState[path].failure = error;
  nextState[path].isLoading = false;
}

export function FLUSH_DATA(nextState, { path }) {
  if (nextState[path]?.result) {
    nextState[path].result = null;
    nextState[path].normalized = null;
  }
}
