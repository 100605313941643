import React from "react";
import { v4 as uuid } from "uuid";
import api from "services/api";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import * as YAML from "yaml";
import ModalService from "services/modal";

import {
  PackSchema,
  RepositorySchema,
  HelmRepositorySchema,
  PackVersionSchema,
  HelmRegistriesSchema,
  OCIRegistriesSchema,
} from "utils/schemas";
import { createContext } from "react";
import i18next from "i18next";
import notifications from "services/notifications";
import Validator from "services/validator";
import { generateEditorYamlSchema } from "utils/presenters";
import { ApplyIf, Missing, isValidName } from "services/validator/rules";
import { isBasicLayer } from "./utils";
import store from "services/store";
import Icon from "components/ui/Icon";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";

export const ProfileBuilderContext = createContext();
export const selectPackRegistryModal = new ModalService(
  "selectPackRegistryModal"
);

export const PROFILE_TYPES_FORM_MODULES = {
  cluster: "clusterprofile",
  infra: "clusterprofile",
  "add-on": "clusterprofile",
  system: "systemProfile",
};

export const MODULES = {
  PACK_SEARCH_MODULE: "packSearch",
  PACK_MODULE: "layerPack",
  IMPORT_MODULE: "importedPacks",
  MANIFEST_MODULE: "manifest",
  PACKS_EDITOR_MODULE: "packsEditor",
  PACK_LIST_MODULE: "packsList",
  SYSTEM_MODULE: "systemPacks",
};

export const FORM_TYPES = {
  PACK: MODULES.PACK_MODULE,
  IMPORT: MODULES.IMPORT_MODULE,
  MANIFEST: MODULES.MANIFEST_MODULE,
  SYSTEM: MODULES.SYSTEM_MODULE,
};

function sortPacks(a, b) {
  const aDisabled = a.spec?.registries?.[0]?.annotations?.disabled === "true";
  const bDisabled = b.spec?.registries?.[0]?.annotations?.disabled === "true";

  const aName = a.spec?.displayName || a.metadata?.name || "";
  const bName = b.spec?.displayName || b.metadata?.name || "";

  return aDisabled - bDisabled || aName.localeCompare(bName);
}

const clusterPacksFetcher = keyedDataFetcher({
  selectors: ["selectedCluster"],
  async fetchData([_, selectedClusterId]) {
    try {
      const response = await api.get(
        `v1/spectroclusters/${selectedClusterId}/features/helmCharts`
      );
      return response.charts?.reduce((acc, layer) => {
        return [
          ...acc,
          {
            guid: uuid(),
            type: "helmChart",
            isDraft: true,
            formType: MODULES.PACK_MODULE,
            matchedRegistries: layer.matchedRegistries,
            config: {
              registryUid:
                layer.matchedRegistries.length === 1
                  ? layer.matchedRegistries[0]?.uid
                  : null,
              name: layer.name,
              packUid: layer.uid,
              uid: layer.uid,
              tag: layer.version,
              values: layer.values || "",
              selectedPresets: [],
              schema: [],
              manifests: [],
            },
          },
        ];
      }, []);
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the helm charts"
        ),
        description: e.message,
      });
    }
  },
});

const repositoriesFetcher = dataFetcher({
  selectors: ["repositories"],
  schema: [RepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/pack");
    return response?.items || [];
  },
});

const allRepositoriesFetcher = dataFetcher({
  selectors: ["allRepositories"],
  optimizeSelector: true,
  schema: [RepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/metadata");
    return response?.items || [];
  },
});

const helmRepositoriesFetcher = dataFetcher({
  selectors: ["helmRepositories"],
  schema: [HelmRepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/helm");
    return response?.items || [];
  },
});

function parseOciRepos(repos = []) {
  return repos.map((repo) => {
    const { spec = {}, status = {} } = repo;
    const isSyncSupported = status.sync?.isSyncSupported;
    const newSpec = {
      ...spec,
      isPrivate: !isSyncSupported,
    };
    return {
      ...repo,
      spec: newSpec,
    };
  });
}

const ociRepositoriesFetcher = dataFetcher({
  selectors: ["ociRepositories"],
  optimizeSelector: true,
  schema: [OCIRegistriesSchema],
  async fetchData() {
    const response = await api.get("v1/registries/oci/summary");
    return parseOciRepos(response?.items);
  },
});

async function fetchPackItems({ filter, sort, continueToken, limit }) {
  const response = await api.post(
    `v1/packs/search?limit=${limit}${
      continueToken ? `&continue=${continueToken}` : ""
    }`,
    { filter, sort }
  );

  return response.items.sort(sortPacks);
}

export const allPacksListActions = new ListActions({
  debounceDelay: 400,
  schema: [PackSchema],
  fetchData: async (query) => {
    const {
      continue: continueToken,
      limit,
      search,
      cloudType,
      registry,
      packType,
      allowedAddOnTypes,
      verified,
      fips,
    } = query || {};

    const isAddon =
      !packType || !["os", "cni", "csi", "k8s"].includes(packType);

    const areStorageLayersIncluded = allowedAddOnTypes.includes("csi");

    const filter = {
      ignoreCase: true,
      type: ["helmChart", "ociHelmChart"].includes(packType)
        ? ["helm"]
        : ["spectro", "oci"],
      layer: [isAddon ? "addon" : packType],
      environment: [cloudType || "all"],
    };

    if (search) {
      filter.displayName = { contains: search };
    }

    const isOci = registry
      ? !!(ociRepositoriesFetcher.selector(store.getState()).result || []).find(
          (repo) => repo.metadata.uid === registry
        )
      : false;

    if (registry && registry !== "all") {
      if (isOci) {
        filter.type = ["oci"];
      }
      filter.registryUid = [registry];
    }

    if (isAddon) {
      const addonType = ["helmChart", "ociHelmChart"].includes(packType)
        ? isOci
          ? "ociHelmChart"
          : "helmChart"
        : packType;
      filter.addOnType = [
        ...(packType ? [addonType] : allowedAddOnTypes),
        "integration",
      ];
    }

    if (
      isAddon &&
      (!packType || packType === "csi") &&
      areStorageLayersIncluded
    ) {
      filter.layer.push("csi");
      filter?.addOnType?.push("");
    }
    if (verified) {
      filter.source = ["spectrocloud"];
    }
    filter.isFips = false;
    if (fips) {
      filter.isFips = true;
    }

    const { items, listmeta } = await api.post(
      `v1/packs/search?limit=${limit}${
        continueToken ? `&continue=${continueToken}` : ""
      }`,
      {
        filter,
        sort: [
          {
            field: "addOnType",
            order: "asc",
          },
          {
            field: "displayName",
            order: "asc",
          },
        ],
      }
    );

    const filteredItems = items.filter(({ spec: pack }) => {
      const {
        displayName,
        name,
        type,
        registries,
        addonType,
        cloudTypes,
        layer,
        addonSubType,
      } = pack || {};

      // Check if packs is valid
      if (
        !displayName ||
        !name ||
        !registries?.length ||
        !cloudTypes?.length ||
        !layer ||
        !type
      ) {
        return false;
      }

      if (
        packType &&
        isAddon &&
        addonType === "integration" &&
        addonSubType !== packType
      ) {
        return false;
      }

      // Check if pack have any real repo
      registries.forEach((registry) => {
        const isOci = !!(
          ociRepositoriesFetcher.selector(store.getState()).result || []
        ).find((repo) => repo.metadata.uid === registry.uid);
        if (isOci && registry.latestPackUid) {
          registry.logoUrl =
            registry.logoUrl || `/v1/packs/${registry.latestPackUid}/logo`;
          registry.kind = "oci";
        }
      });
      return registries.some((registry) => registry.uid);
    });

    return {
      items: filteredItems,
      listmeta,
      cloudType,
    };
  },
});

const packsListSearchFetcher = dataFetcher({
  selectors: [
    "packsListSearch",
    (state) => state.forms.clusterprofile?.data?.cloudType,
    (state) => state.forms.layerPack?.data?.packType,
    (state) => state.forms.layerPack?.data?.repository,
  ],
  async fetchData(
    [_, cloudType, layerType, registryUid],
    { limit, continue: continueToken, search }
  ) {
    const isAddon = !["os", "cni", "csi", "k8s"].includes(layerType);
    const layer = isAddon ? "addon" : layerType;
    const isOci = !!(
      ociRepositoriesFetcher.selector(store.getState()).result || []
    ).find((repo) => repo.metadata.uid === registryUid);

    const filter = {
      displayName: {
        contains: search,
      },
      ignoreCase: true,
      type: [layerType === "helmChart" ? "helm" : isOci ? "oci" : "spectro"],
      layer: [layer],
      environment: [isAddon ? "all" : cloudType],
      addOnType: isAddon ? [layerType] : undefined,
      registryUid: [registryUid],
    };
    const sort = [
      {
        field: "displayName",
        order: "asc",
      },
    ];

    let items = await fetchPackItems({
      filter,
      sort,
      limit,
      continueToken,
    });

    let integrations = [];

    if (isAddon) {
      try {
        integrations = await fetchPackItems({
          filter: {
            displayName: {
              contains: search,
            },
            ignoreCase: true,
            type: ["spectro"],
            layer: [layer],
            environment: ["all"],
            addOnType: ["integration"],
            registryUid: [registryUid],
            addonSubType: [layerType],
          },
          sort,
          limit,
          continueToken,
        });
      } catch (e) {
        integrations = [];
      }
    }

    if (isOci) {
      items = items.map((item) => ({
        ...item,
        spec: {
          ...item.spec,
          registries: item.spec.registries.map((registry) => ({
            ...registry,
            isOci: true,
            logoUrl:
              item.spec?.registries?.[0]?.logoUrl ||
              `/v1/packs/${item.spec?.registries?.[0]?.latestPackUid}/logo`,
          })),
        },
      }));
    }

    return { items: [...items, ...integrations].sort(sortPacks), cloudType };
  },
});

const systemChartNamesFetcher = dataFetcher({
  selectors: [
    "systemPacksListSearch",
    (state) => state.forms[PROFILE_TYPES_FORM_MODULES.system]?.data?.cloudType,
    (state) => state.forms[MODULES.SYSTEM_MODULE]?.data?.repository,
  ],
  async fetchData(
    [_, cloudType, registryUid],
    { limit, continue: continueToken, search }
  ) {
    const filter = {
      displayName: {
        contains: search,
      },
      ignoreCase: true,
      type: ["helm"],
      layer: ["addon"],
      environment: ["all"],
      addOnType: ["helmChart"],
      registryUid: [registryUid],
    };
    const sort = [
      {
        field: "displayName",
        order: "asc",
      },
    ];
    const items = await fetchPackItems({
      sort,
      filter,
      limit,
      continueToken,
    });

    return { items, cloudType };
  },
});

const packVersionsFetcher = dataFetcher({
  selectors: [
    "packVersions",
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  schema: [PackVersionSchema],
  async fetchData([_, cloudType], { repositoryUid, packName, layerType }) {
    const promise = api.get(
      `v1/packs/${encodeURIComponent(
        packName
      )}/registries/${repositoryUid}?cloudType=${
        isBasicLayer(layerType) ? cloudType : "all"
      }&layer=${layerType}`
    );

    const response = await promise;

    function getTagDetails(tag) {
      const details = response?.packValues?.find(
        (value) => value.packUid === tag.packUid
      );

      return details || {};
    }

    return response?.tags.map((tag) => ({
      ...tag,
      logoUrl: response.logoUrl,
      name: tag.packUid,
      packName: response.name,
      ...getTagDetails(tag),
    }));
  },
});

const clustersFetcher = dataFetcher({
  selectors: ["selectedClusterId"],
  async fetchData(_, { search = "" } = {}) {
    const filters = {
      filter: {
        name: {
          contains: search,
        },
        state: "Running",
        isImported: true,
      },
      sort: "name",
    };

    const response = await api.post(
      `v1/dashboard/spectroclusters/metadata`,
      filters
    );

    return response?.items || [];
  },
});

export const helmRegistryFetcher = dataFetcher({
  selectors: ["helmRegistryList"],
  async fetchData() {
    const response = await api.get("v1/registries/helm/summary");
    return response.items;
  },
  schema: [HelmRegistriesSchema],
});

const packValuesFetcher = dataFetcher({
  selectors: [
    "packValues",
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  schema: [PackSchema],
  async fetchData(_, packUid) {
    const response = await api.get(`v1/packs/${packUid}`);

    return (
      response?.packValues?.map(({ packUid, ...spec }) => {
        //TO DO REFACTOR THIS: spec.values !== specValues causing changes in the editor
        return {
          metadata: { uid: packUid, name: packUid },
          spec: { ...spec, values: spec.values },
        };
      }) || []
    );
  },
});

export const packListActions = new ListActions({
  debounceDelay: 200,
  dataFetcher: packsListSearchFetcher,
  schema: [PackSchema],
  initialQuery: () => ({
    limit: 100,
    search: "",
  }),
});

export const systemChartsListActions = new ListActions({
  debounceDelay: 200,
  dataFetcher: systemChartNamesFetcher,
  schema: [PackSchema],
  initialQuery: () => ({
    limit: 100,
    search: "",
  }),
});

async function validateYamlSchema(value, _, data) {
  const schema = generateEditorYamlSchema(data.schema, {
    ignorePasswords: false,
  });
  if (typeof value === "undefined") {
    return false;
  }

  const yamlFiles = `${value}\n`
    .replace(/\n---([\s]+)?\n/g, "\n---\n")
    .split("\n---\n");

  const Ajv = (await import("ajv/dist/2019")).default;
  const ajv = new Ajv({ strict: false, allErrors: true });

  const errors = [];
  for (const file of yamlFiles) {
    try {
      const valuesAsJson = YAML.parse(file, { strict: false });
      if (schema) {
        const validate = ajv.compile({ ...schema, $async: true });
        try {
          await validate(valuesAsJson);
        } catch (err) {
          if (err instanceof Ajv.ValidationError) {
            if (err.errors) {
              const yamlErrors = err.errors.reduce((acc, error) => {
                const existingErrorIndex = acc.findIndex(
                  (e) => e.instancePath === error.instancePath
                );

                if (existingErrorIndex !== -1) {
                  acc[existingErrorIndex].errors.push(error);
                } else {
                  acc.push({ ...error, errors: [error] });
                }

                return acc;
              }, []);

              errors.push(
                <>
                  YAML values don&apos;t match the provided schema{" "}
                  <Tooltip
                    title={yamlErrors.map((error) => (
                      <div key={error.instancePath}>
                        YAML Path{' "'}
                        {error.instancePath
                          .replace(/^\//, "")
                          .replace(/\//g, ".")}
                        {'": '}
                        <ul>
                          {error.errors.map((error) => (
                            <li key={error.propertyName}>
                              {error.message}{" "}
                              {error.keyword === "enum"
                                ? error.params.allowedValues.join(", ")
                                : ""}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  >
                    <Icon awesome={faExclamationCircle} />
                  </Tooltip>
                </>
              );
            }
          }
        }
      }
    } catch (err) {
      errors.push(`Invalid YAML configuration`);
    }
  }

  return errors.length ? errors[0] : false;
}

function isSystemManifest(data) {
  return data.formType === MODULES.SYSTEM_MODULE;
}

export const configValidator = new Validator();
export const importPacksValidator = new Validator();
export const manifestsValidator = new Validator();
export const packActionsValidator = new Validator();
export const editorValidator = new Validator();

importPacksValidator.addRule("clusterId", Missing());

packActionsValidator.addRule(
  ["repository", "pack", "version"],
  ApplyIf(
    (value, key, data) => data.formType !== MODULES.SYSTEM_MODULE,
    Missing()
  )
);

packActionsValidator.addRule(
  ["packType"],
  ApplyIf(
    (value, key, data) => data.formType !== MODULES.SYSTEM_MODULE,
    Missing()
  )
);

packActionsValidator.addRule(
  ["name"],
  ApplyIf((value, key, data) => isSystemManifest(data), Missing())
);

packActionsValidator.addRule(
  ["manifests"],
  ApplyIf((value, key, data) => isSystemManifest(data), Missing())
);

packActionsValidator.addRule(
  ["content"],
  ApplyIf((value, key, data) => isSystemManifest(data), Missing())
);

editorValidator.addRule(["values"], validateYamlSchema);

configValidator.addRule(
  "values",
  ApplyIf((value, key, data) => data.type !== "manifest", validateYamlSchema)
);
manifestsValidator.addRule("content", validateYamlSchema);
manifestsValidator.addRule("content", Missing());
manifestsValidator.addRule("name", Missing());
manifestsValidator.addRule("name", isValidName());
manifestsValidator.addRule(["manifests"], Missing());

configValidator.addRule("manifests", manifestsValidator);
editorValidator.addRule("manifests", manifestsValidator);

const fetchers = {
  clustersFetcher,
  clusterPacksFetcher,
  helmRegistryFetcher,
  packVersionsFetcher,
  packsListSearchFetcher,
  systemChartNamesFetcher,
  repositoriesFetcher,
  allRepositoriesFetcher,
  helmRepositoriesFetcher,
  ociRepositoriesFetcher,
  packValuesFetcher,
};

export default fetchers;
