import dataFetcher from "modules/dataFetcher";
import api, { nonProjectApi } from "services/api";
import ModalService from "services/modal";

export const PLAN_PAYMENT_MODULE = "planPayment";

export const cardPayModal = new ModalService("cardPayForm");
export const paymentSuccessModal = new ModalService("paymentSuccess");
export const monthlyCancellationModal = new ModalService("monthlyCancellation");
export const annualCancellationModal = new ModalService("annualCancellation");
export const annualUpgradeModal = new ModalService("annualUpgrade");
export const pendingCancellationModal = new ModalService("pendingCancellation");
export const cancelDeactivationModal = new ModalService("cancelDeactivation");

export const paymentPlansFetcher = dataFetcher({
  selectors: ["plandetails", "paymentplans"],
  async fetchData() {
    return await api.get("v1/payments/stripe/plans");
  },
});

export const customerFetcher = dataFetcher({
  selectors: ["customer"],
  async fetchData() {
    return await nonProjectApi.get("v1/payments/stripe/customer");
  },
});

export const subscriptionFetcher = dataFetcher({
  selectors: ["subscription"],
  async fetchData() {
    return nonProjectApi.get("v1/payments/stripe/subscriptions");
  },
});
