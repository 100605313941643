import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import { getCluster } from "state/cluster/selectors/details";
import { WorkloadRoleBindingSchema } from "utils/schemas";

export const CLUSTER_ROLE_BINDINGS_MODULE = "clusterrolebindings";

const clusterRoleBindingsFetcher = dataFetcher({
  selectors: ["clusterrolebindings"],
  async fetchData(_, { filterBy = [] } = {}) {
    const cluster = getCluster(store.getState());

    const data = await api.post(
      `v1/dashboard/spectroclusters/${cluster.metadata.uid}/workloads/clusterrolebinding`,
      {
        filter: {
          namespaces: filterBy,
        },
      }
    );

    return {
      items: (data?.bindings || []).sort((binding1, binding2) =>
        binding1.metadata.namespace > binding2.metadata.namespace ? 1 : -1
      ),
    };
  },
});

export const clusterRoleBindingsListingActions = new ListActions({
  dataFetcher: clusterRoleBindingsFetcher,
  initialQuery: () => ({
    filterBy: [],
  }),
  schema: [WorkloadRoleBindingSchema],
});
