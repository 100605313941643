export const initialState = {
  appDeploymentGuid: null,
  isLoading: false,
};

export function FETCH_APP_DEPLOYMENT_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function FETCH_APP_DEPLOYMENT_NORMALIZATION(nextState, { result }) {
  nextState.appDeploymentGuid = result;
  nextState.isLoading = false;
}

export function REFRESH_APP_DEPLOYMENT_NORMALIZATION(nextState, { result }) {
  nextState.appDeploymentGuid = result;
}

export function APP_LOGS_DOWNLOAD_SUCCESS(
  nextState,
  { result, clusterRef, appName }
) {
  nextState.logFetcherUid = result.uid;
  nextState.logClusterRef = clusterRef;
  nextState.logAppName = appName;
}
