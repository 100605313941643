import { createSelector } from "reselect";
import { ADMIN_GUID } from "utils/constants";
import { getEntity } from "utils/entities";
import { ProjectPermissionsSchema } from "utils/schemas";

export const getCurrentProject = getEntity((state) => {
  return state.auth.currentProjectId;
}, ProjectPermissionsSchema);

export const getCurrentContext = createSelector(
  getCurrentProject,
  (state) => state.auth.currentProjectId,
  (currentProject, currentProjectId) => {
    if (currentProjectId === ADMIN_GUID) {
      return { guid: ADMIN_GUID, isAdmin: true };
    }

    if (currentProject) {
      return { ...currentProject, isAdmin: false };
    }

    return currentProject;
  }
);

export const getCurrentProjectID = createSelector(
  [getCurrentProject],
  (currentProject) =>
    currentProject?.projectUid ? currentProject.projectUid : ADMIN_GUID
);
