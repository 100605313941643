export const initialState = {
  selectedProfileGuid: null,
  action: "start",
  finishedTour: false,
  ootbStep: 1,
};

export function SET_SELECTED_CLUSTER_PROFILE(
  nextState,
  { selectedProfileGuid }
) {
  nextState.selectedProfileGuid = selectedProfileGuid;
}

export function SET_OOTB_STEP(nextState, { step }) {
  if (step === 0) {
    nextState.action = "start";
  }
  nextState.ootbStep = step;
}

export function SET_NEW_CLUSTER_ACTION(nextState, { key }) {
  if (key === "ootb") {
    nextState.ootbStep = 1;
  }
  nextState.action = key;
}

export function SET_FINISHED_TOUR(nextState, { finishedTour }) {
  nextState.finishedTour = finishedTour;
}
