import { createModuleReducer } from "modules/binder";

import toolbarReducer from "modules/profileIDE/state/toolbar";
import fileListReducer from "modules/profileIDE/state/fileList";
import editorReducer from "modules/profileIDE/state/editor/reducer";
import draftsReducer from "modules/profileIDE/state/drafts";
import { validationsReducer } from "modules/profileIDE/state/validations";

export const appEditReducer = createModuleReducer({
  moduleName: "applicationProfileEditor",
  submodules: [
    toolbarReducer,
    fileListReducer({}),
    editorReducer(),
    draftsReducer(),
    validationsReducer(),
  ],
});
