import React from "react";
import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { PROJECTS } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));

const namespace = "projects";

export default createNamespace({
  namespace,
  Component: function Main() {
    return (
      <>
        <Route adminOnly exact path={PROJECTS.ROOT} component={Index} />
        <Route
          adminOnly
          exact
          path={PROJECTS.CREATE_PROJECT}
          component={Index}
        />
        <Route adminOnly exact path={PROJECTS.EDIT_PROJECT} component={Index} />
      </>
    );
  },
});
