import create from "./create";
import loginService from "services/loginService";
import history from "services/history";
import store from "services/store";
import { getCurrentContext } from "state/auth/selectors/common";
import { matchPath } from "react-router";
import { ADMIN_PREFIX_WITH_PROJECT } from "components/common/History/Route";
import { getCurrentUser } from "state/auth/selectors";

function getDefaultHeaders() {
  const state = store.getState();
  const projectUid = getCurrentContext(state)?.project?.metadata?.uid;
  const pathname = history.location.pathname;

  const match = matchPath(pathname, {
    path: `${ADMIN_PREFIX_WITH_PROJECT}/*`,
    exact: true,
    strict: true,
  });

  const headers = {};

  if (projectUid && !state.auth.isLoading) {
    headers["ProjectUid"] = projectUid;
  }

  if (match?.params?.projectUid) {
    headers["ProjectUid"] = match.params.projectUid;
  }

  return headers;
}

async function catchMissingAuth() {
  const state = store.getState();
  const pathname = history.location.pathname + history.location.search;
  const returnTo = !pathname.startsWith("/auth") ? pathname : "";
  const inactive = !!state.auth?.me;
  const currentUser = getCurrentUser(state);
  const orgName = currentUser?.status?.tenant?.orgName;

  const data = await loginService.logout();
  let query = new URLSearchParams();
  query.append("returnTo", returnTo);
  query.append("inactive", inactive);
  if (inactive) {
    query.append("org", orgName);
  }
  if (data?.redirectUrl) {
    window.location.href = data.redirectUrl;
    return;
  }
  if (pathname.startsWith("/auth")) return;
  history.push(`/auth?${query.toString()}`);
}

const api = create({ getDefaultHeaders, catchMissingAuth });

export const nonProjectApi = create({ catchMissingAuth });

export default api;
