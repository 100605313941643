import { useMemo } from "react";
import { connect } from "react-redux";
import flags, {
  Flags as FlagsUtil,
  getDemoFlags,
  getFeatureFlags,
} from "services/flags";

const Flags = connect(() => ({
  demoFlags: getDemoFlags(),
  featureFlags: getFeatureFlags(),
}))(({ demoFlags, featureFlags, ...rest }) => {
  let shouldRender = false;
  let combinedFlags = useMemo(
    () => [...flags.params, ...featureFlags.params, ...demoFlags.params],
    [demoFlags, featureFlags]
  );
  let loadedFlags = useMemo(
    () => new FlagsUtil(combinedFlags),
    [combinedFlags]
  );

  if (rest.has) {
    shouldRender = loadedFlags.has(rest.has);
  }

  if (rest.is) {
    shouldRender = loadedFlags.is(rest.is);
  }

  if (!shouldRender) {
    return null;
  }

  return rest.children || null;
});

export default Flags;
