import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import { parseAppliances } from "utils/parsers";
import { EdgeMachineSchema } from "utils/schemas";

export const edgeMachinesFetcher = dataFetcher({
  selectors: ["edgeMachines"],
  schema: [EdgeMachineSchema],
  fetchData: async (_, isEdit) => {
    const data = await api.get(
      "v1/edgehosts/metadata?quickFilter=unusedEdgeHosts"
    );

    let initialHosts = [];

    if (isEdit) {
      const initialEdgeHosts = (
        store.getState().forms.nodePool?.initialData?.edgeHosts || []
      ).map((host) => host.hostUid);
      const initialHostPromises = initialEdgeHosts.map((hostUid) => {
        return store.dispatch(edgeHostDetailsFetcher.key(hostUid).fetch());
      });
      const initialHostResults = await Promise.allSettled(initialHostPromises);
      initialHosts = initialHostResults.map((result) => result.value);
    }

    return [
      ...initialHosts,
      ...(data?.edgeHosts || []).map((host) => ({
        metadata: host,
        spec: {
          device: {
            archType: host.archType,
          },
          type: host.edgeHostType,
        },
        status: {
          state: host.state,
          health: {
            state: host.healthState,
          },
        },
      })),
    ];
  },
});

export const edgeHostDetailsFetcher = keyedDataFetcher({
  selectors: ["edge-machine", "details"],
  schema: EdgeMachineSchema,
  async fetchData([_1, _2, hostUid]) {
    if (!hostUid || hostUid === "undefined") {
      return;
    }
    const response = await api.get(`v1/edgehosts/${hostUid}`);
    const parsedResponse = parseAppliances([response]);
    return parsedResponse[0];
  },
});
