import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import Button, { TextButton } from "components/ui/Button";
import Icon from "components/ui/Icon";
import ActionMenu from "components/common/ActionMenu";

import Split from "assets/icons/split.svg?react";
import { blue, winterMist } from "utils/constants/colors";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

export const EditorToolbar = styled.div`
  min-height: 45px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 24px;
  z-index: 9;

  ${({ color, theme }) =>
    css`
      box-shadow: inset 0px -1px 0px ${theme === "dark" ? "#30365b" : color};
      background: ${theme === "dark" ? "#30365b" : color};
      color: ${theme === "dark" ? "#fff" : "#555"};
      opacity: ${theme === "dark" ? 1 : 0.9};
    `}

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    width: 100%;
  }
`;

const CloseIconWrap = styled.div`
  margin-left: 10px;
  opacity: 0.6;
  width: 18px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiffButton = styled(TextButton)`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  svg path {
    fill: #78909c;
  }

  ${(props) =>
    props.diffDisplay === true &&
    css`
      &,
      &:hover,
      &:focus {
        background: ${winterMist};
        svg path {
          fill: ${blue};
        }
      }
    `}
`;

const ToolbarMenuWrap = styled.div`
  display: flex;
  align-items: center;

  > ${Button} {
    margin: 0 10px;
  }
`;

export default function Toolbar({
  title,
  headerColor = "#0D1440",
  theme = "dark",
  editorTheme,
  toggleTheme,
  customToolbarButtons,
  showActions,
  showDiffToggle,
  showRevertOption,
  onRevert,
  onClose,
  notifications = [],
  renderNotificationIcons,
  editorRef = null,
  diffDisplay,
  setDiffDisplay,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <EditorToolbar color={headerColor} theme={theme}>
      <span>{title}</span>
      {customToolbarButtons}
      {showActions && (
        <ToolbarMenuWrap>
          {showDiffToggle && (
            <DiffButton
              data-qa="diff"
              diffDisplay={diffDisplay}
              onClick={() => setDiffDisplay(!diffDisplay)}
            >
              <Split />
            </DiffButton>
          )}
          {showRevertOption && (
            <Button
              data-qa="revert-pack"
              secondary
              size="small"
              onClick={onRevert}
              disabled={rest.defaultValues === rest.value}
            >
              {t("Use defaults")}
            </Button>
          )}
          {notifications.length !== 0
            ? notifications.map(renderNotificationIcons)
            : null}
          <ActionMenu
            options={[
              {
                key: "theme",
                label:
                  editorTheme === "light" ? t("Dark mode") : t("Light mode"),
                onClick: toggleTheme,
              },
              editorRef && {
                key: "format",
                label: t("Format Document"),
                onClick: () =>
                  editorRef.current.runCommand("editor.action.formatDocument"),
              },
            ].filter(Boolean)}
          />
          {onClose && (
            <CloseIconWrap onClick={onClose}>
              <Icon awesome={faTimes} />
            </CloseIconWrap>
          )}
        </ToolbarMenuWrap>
      )}
    </EditorToolbar>
  );
}
