import { createSelector } from "reselect";

export function parseRoleBindingsFormData(roleBindings, type) {
  return (roleBindings || []).map(
    ({ roleType, roleName, subjects, namespace }) => {
      return {
        role: {
          kind: roleType,
          name: roleName,
        },
        namespace,
        subjects: subjects || [],
        type,
      };
    }
  );
}

export function parseRoleBindingsResponse(roleBindings) {
  return (roleBindings || []).map((binding) => ({
    namespace: binding.namespace,
    subjects: binding.subjects,
    roleName: binding.role?.name,
    roleType: binding.role?.kind || "ClusterRole",
  }));
}

export const getRoleBindingsPayload = createSelector(
  (state) => state.forms?.cluster?.data,
  (data) => {
    if (!data) {
      return [];
    }

    const hasClusterRoleBindings = data.clusterRoleBindings?.length > 0;
    const hasRoleBindings = data.roleBindings?.length > 0;

    return [
      hasClusterRoleBindings && {
        spec: {
          bindings: parseRoleBindingsFormData(
            data.clusterRoleBindings,
            "ClusterRoleBinding"
          ),
        },
      },
      hasRoleBindings && {
        spec: {
          bindings: parseRoleBindingsFormData(data.roleBindings, "RoleBinding"),
        },
      },
    ].filter(Boolean);
  }
);
