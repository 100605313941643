import { createSelector } from "reselect";
import { presentLayer, formatTags } from "utils/presenters";
import { getRawClusterProfile } from "./details";
import {
  repositoriesFetcher,
  profileBuilderCreateModule,
  profileBuilderEditModule,
} from "../services";
import { updateInstallOrder } from "utils/yaml";
import { presentVariableToPayload } from "modules/profileBuilder/actions/createProfileForm";

export const getRepositories = createSelector(
  repositoriesFetcher.selector,
  (fetchStatus) => fetchStatus.result
);

export const getSelectedClusterProfileTemplate = createSelector(
  getRawClusterProfile,
  (clusterprofile) =>
    clusterprofile.status.isPublished
      ? clusterprofile.spec.published
      : clusterprofile.spec.draft
);

export const getProfileLayersPreview = createSelector(
  () => profileBuilderCreateModule.state,
  (profileBuilder) => {
    return profileBuilder?.layers?.map((layer) => {
      return presentLayer({ ...layer, ...layer.config });
    });
  }
);

function presentLayersForAPI(pack) {
  return {
    ...pack,
    values: updateInstallOrder(pack),
    ...(pack?.type === "manifest" && {
      uid: pack?.uid || "spectro-manifest-pack",
    }),
    ...(pack?.layer === "ociHelmChart" && { type: "oci" }),
  };
}

export const getFormattedPayload = createSelector(
  (state) => state.forms.clusterprofile?.data || {},
  () => profileBuilderCreateModule.payload,
  () => profileBuilderEditModule.payload,
  (formData, createPayload, editPayload) => {
    return {
      metadata: {
        name: formData.name,
        annotations: {
          description: formData.description,
        },
        labels: formatTags(formData.tags),
      },
      spec: {
        template: {
          cloudType:
            formData.profileType === "add-on" ? "all" : formData.cloudType,
          type: formData.profileType,
          packs: formData.persisted
            ? (editPayload?.layers || []).map(presentLayersForAPI)
            : (createPayload?.layers || []).map(presentLayersForAPI),
        },
        variables: formData.persisted
          ? (editPayload?.variables || []).map(presentVariableToPayload)
          : (createPayload?.variables || []).map(presentVariableToPayload),
        version: formData.version,
      },
    };
  }
);

const areAllLayersConfigured = createSelector(
  (state) => state.forms.clusterprofile.data.layers,
  (layers = []) => layers.every((layer) => layer.config?.version)
);

const areSomeLayersConfigured = createSelector(
  (state) => state.forms.clusterprofile.data.layers,
  (layers = []) => layers.some((layer) => layer.config?.version)
);

export const getLayerConfigurationStatus = createSelector(
  areAllLayersConfigured,
  areSomeLayersConfigured,
  (state) => state.clusterprofile.layerConfig.validationErrors,
  (isComplete, isIncomplete, validationErrors) => {
    if (validationErrors.length > 0) {
      return "errors";
    }

    if (isComplete) {
      return "complete";
    }

    if (isIncomplete) {
      return "incomplete";
    }

    return "not-started";
  }
);
