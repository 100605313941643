export const initialState = {
  resources: [],
  displayResourceNameDropdown: false,
  auditUid: null,
};

export function FETCH_RESOURCE_NAMES(nextState, { resources }) {
  nextState.resources = resources;
  nextState.displayResourceNameDropdown = true;
}

export function DISPLAY_RESOURCE_NAMES_DROPDOWN(nextState, { value }) {
  nextState.displayResourceNameDropdown = value;
}

export function AUDIT_USER_MESSAGE(nextState, { auditUid }) {
  nextState.auditUid = auditUid;
}
