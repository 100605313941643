import i18next from "i18next";

export function presentAwsSubnet({ disableOnMissingAutoIp = false } = {}) {
  return function presenter(subnet) {
    let missingAutoIp = false;
    if (disableOnMissingAutoIp) {
      missingAutoIp = subnet.isPrivate ? false : !subnet.mapPublicIpOnLaunch;
    }
    let description = null;
    if (missingAutoIp) {
      description = i18next.t(
        "Auto Assign Public IP is not enabled on this subnet"
      );
    }

    if (subnet.isPrivate) {
      description = i18next.t("Private");
    }

    return {
      label: subnet.name || subnet.subnetId,
      description,
      isPrivate: subnet.isPrivate,
      value: subnet.subnetId,
      disabled: missingAutoIp,
    };
  };
}
