import React from "react";
import { Route as ReactRouterRoute } from "react-router-dom";

export const PROJECT_PREFIX = "/projects/:projectUid";
export const ADMIN_PREFIX = "/admin";
export const ADMIN_PREFIX_WITH_PROJECT = `${ADMIN_PREFIX}/project/:projectUid`;
export const DEV_PREFIX_WITH_PROJECT = "/dev/projects/:projectUid";

export default function Route({
  path,
  adminEnabled = false,
  adminOnly = false,
  devEnabled = false,
  devOnly = false,
  ...rest
}) {
  if (adminOnly) {
    return <ReactRouterRoute path={`${ADMIN_PREFIX}${path}`} {...rest} />;
  }

  if (devOnly) {
    return (
      <ReactRouterRoute path={`${DEV_PREFIX_WITH_PROJECT}${path}`} {...rest} />
    );
  }

  if (!adminEnabled && !devEnabled) {
    return <ReactRouterRoute path={`${PROJECT_PREFIX}${path}`} {...rest} />;
  }

  // TODO: Might think of a better way
  if (!adminEnabled && devEnabled) {
    return (
      <>
        <ReactRouterRoute path={`${PROJECT_PREFIX}${path}`} {...rest} />
        <ReactRouterRoute
          path={`${DEV_PREFIX_WITH_PROJECT}${path}`}
          {...rest}
        />
      </>
    );
  }

  if (adminEnabled && !devEnabled) {
    return (
      <>
        <ReactRouterRoute path={`${PROJECT_PREFIX}${path}`} {...rest} />
        <ReactRouterRoute path={`${ADMIN_PREFIX}${path}`} {...rest} />
      </>
    );
  }

  return (
    <>
      <ReactRouterRoute path={`${PROJECT_PREFIX}${path}`} {...rest} />
      <ReactRouterRoute path={`${ADMIN_PREFIX}${path}`} {...rest} />
      <ReactRouterRoute path={`${DEV_PREFIX_WITH_PROJECT}${path}`} {...rest} />
    </>
  );
}
