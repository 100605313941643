export default function editorReducer() {
  return {
    name: "editor",
    initialState: {
      mode: "form",
    },
    switchMode(nextState, { mode }) {
      nextState.mode = mode;
    },
  };
}
