import React from "react";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import { HelmRepositorySchema } from "utils/schemas";

export const helmRepositoriesFetcher = dataFetcher({
  selectors: ["profileIDE", "helmRepositories"],
  schema: [HelmRepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/helm");
    return (response?.items || []).map((repo) => ({
      ...repo,
      label: repo.metadata.name,
      value: repo.metadata.uid,
      description: (
        <>
          <strong>Scope:</strong> {repo.spec.scope}
          <br />
          <strong>Protected:</strong> {`${repo.spec.isPrivate}`}
        </>
      ),
    }));
  },
});

export const ociRepositoriesFetcher = dataFetcher({
  selectors: ["profileIDE", "ociRepositories"],
  schema: [HelmRepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/oci/summary");

    return (response?.items || []).map((repo) => ({
      ...repo,
      label: repo.metadata.name,
      value: repo.metadata.uid,
      description: (
        <>
          <strong>Scope:</strong> {repo.spec.scope}
        </>
      ),
    }));
  },
});

export const helmPacksFetcher = keyedDataFetcher({
  selectors: ["profileIDE", "pack-search"],
  async fetchData([_1, _2, registryUid], query) {
    const response = await api.post("v1/packs/search?limit=100", {
      filter: {
        displayName: {
          contains: query?.search,
        },
        ignoreCase: true,
        type: ["helm"],
        environment: ["all"],
        registryUid: [registryUid],
      },
      sort: [
        {
          field: "displayName",
          order: "asc",
        },
      ],
    });

    return response;
  },
});

export const helmPackVersionFetcher = keyedDataFetcher({
  selectors: ["profileIDE", "pack-versions"],
  async fetchData([_1, _2, packName], registryUid) {
    const response = await api.get(
      `v1/packs/${packName}/registries/${registryUid}`
    );

    return response;
  },
});
