import { createSelector } from "reselect";
import querystring from "query-string";
import { generatePath } from "react-router";

import { getEntity } from "utils/entities";
import { WorkspaceSchema } from "utils/schemas";

import { FILTERS_FORM_MODULE } from "../actions/listing";
import { WORKSPACES } from "utils/constants/routes";

export const getWorkspacesList = getEntity(
  (state) => state.list?.workspaces?.items?.initial,
  [WorkspaceSchema]
);

export const getNamespacesOptions = createSelector(
  getWorkspacesList,
  (data) => {
    return (data || []).reduce((acc, workspace) => {
      const namespaces = (workspace.spec?.namespaces || []).map((ns) => ({
        label: ns,
        value: ns,
      }));
      acc.push(...namespaces);
      return acc;
    }, []);
  }
);

export const getFilteredWorkspaces = createSelector(
  getWorkspacesList,
  (state) => state.forms[FILTERS_FORM_MODULE]?.data,
  (workspaces, filters) => {
    const { workspace: name, namespaces, clusters } = filters || {};

    if (!name && !namespaces?.length && !clusters?.length) {
      return workspaces || [];
    }

    return (workspaces || []).filter((workspace) => {
      const nameIsMatched =
        !name ||
        workspace.metadata?.name
          ?.toLowerCase()
          ?.startsWith(name?.toLowerCase());
      const namespaceIsMatched =
        !namespaces?.length ||
        namespaces.some((ns) => workspace.spec?.namespaces?.includes(ns));
      const clustersIsMatched =
        !clusters?.length ||
        clusters?.some((cluster) =>
          workspace.spec?.clusterRefs?.find(
            (clusterRef) => clusterRef.name === cluster
          )
        );

      return nameIsMatched && namespaceIsMatched && clustersIsMatched;
    });
  }
);

export const isInitialWorkspacesListEmpty = createSelector(
  (state) => state.list?.workspaces?.items?.initial?.length,
  (state) => state.list.workspaces?.isLoading,
  (state) => state.list.workspaces?.isInitializing,
  (listLength, isLoading, isInitializing) => {
    return !listLength && !isLoading && !isInitializing;
  }
);

export const getWorkspacesLink = createSelector(
  (state) => state.forms[FILTERS_FORM_MODULE]?.data,
  (data) => {
    const ROOT = generatePath(WORKSPACES.ROOT, { tab: "overview" });

    if (!data) {
      return ROOT;
    }
    const query = querystring.stringify({
      workspace: data?.workspace || "",
      namespaces: data?.namespaces || [],
      clusters: data?.clusters || [],
    });

    return `${ROOT}?${query}`;
  }
);
