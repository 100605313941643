export const initialState = {
  packDifferences: {},
  targetProfile: [],
  originalPacks: {},
};

export function FETCH_CLUSTER_PROFILE_PACK_DIFFS_SUCCESS(
  nextState,
  { packName, result }
) {
  nextState.packDifferences[packName] = result;
}

export function SET_CLUSTER_PROFILE_UPDATES_TARGET_PROFILE(
  nextState,
  { result }
) {
  nextState.targetProfile = result;
}

export function FETCH_CLUSTER_PROFILE_ORIGINAL_PACKS_VALUES_SUCCESS(
  nextState,
  { packName, result },
  state
) {
  nextState.originalPacks[packName] = state.originalPacks[packName] || {};
  nextState.originalPacks[packName] = result;
}
