import React from "react";
import { useTranslation } from "react-i18next";

import Fields from "components/ui/Fields";
import { ResourceRoleSelector } from "components/common/ScopedRolesSelector";
import { createSelectComponent } from "modules/dataFetcher";
import { filtersFetcher } from "state/roleManagement/actions/resourceRoles";
import { FieldWrap } from "components/styled/Field";

const FiltersSelector = createSelectComponent({
  dataFetcher: filtersFetcher,
  parseOptions: (filters) => {
    return filters.map((filter) => ({ label: filter.name, value: filter.uid }));
  },
});

function Form({ projects, data }) {
  const { t } = useTranslation();

  return (
    <>
      <Fields.Select
        label={t("Projects")}
        options={projects}
        name="projectsUids"
        data-qa="select_projects"
        mode="multiple"
      />
      <FieldWrap>
        <FiltersSelector name="filters" label={t("Filters")} mode="multiple" />
      </FieldWrap>
      <ResourceRoleSelector name="roles" value={data.roles} />
    </>
  );
}

export default Form;
