import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { createForm } from "modules/form";
import Modal from "components/ui/Modal";

import Form from "./components/Form";
import { apiKeyModal, API_KEYS_FORM_MODULE } from "state/apikeys/services";
import { apikeysFormActions } from "state/apikeys/actions";
import Alert from "components/common/Alert";
import Clipboard from "components/common/Clipboard";
import GreyContainer from "components/styled/GreyContainer";
const ConnectedForm = connect((state) => ({
  location: state.router.location.pathname,
}))(Form);

const FormModule = createForm({
  Component: ConnectedForm,
  actions: apikeysFormActions,
});

function ApiKeyModal({ modalData, errors }) {
  const { t } = useTranslation();
  const isValid = useMemo(() => errors.some((err) => err.result), [errors]);
  const title = modalData?.uid ? t("Edit API key") : t("Add API key");
  const footerProps = modalData?.response ? { footer: null } : {};
  return (
    <Modal
      width="600px"
      title={title}
      service={apiKeyModal}
      disabledConfirm={isValid}
      confirmLabel={modalData?.uid ? t("Save") : t("Generate")}
      {...footerProps}
    >
      {modalData?.response ? (
        <>
          <Alert type="warning">
            {t(
              "API key has been created. Make sure to copy this API key now as you will not be able to see this again."
            )}
          </Alert>
          <Clipboard text={modalData.response.apiKey}>
            <GreyContainer>
              <span>{modalData.response.apiKey}</span>
            </GreyContainer>
          </Clipboard>
        </>
      ) : (
        <FormModule module={API_KEYS_FORM_MODULE} preventInitOnMount />
      )}
    </Modal>
  );
}

export default connect((state) => ({
  modalData: apiKeyModal.data,
  errors: state.forms[API_KEYS_FORM_MODULE]?.errors || [],
}))(ApiKeyModal);
