import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";

import Button from "components/ui/Button";

import Switch from "components/common/Switch";
import StageSlider from "./StageSlider";
import productsTourImages from "assets/images/productTour";
import humanPath from "assets/images/login/human.png";
import tourModalBackground from "assets/images/productTour/background.png";
import TourModal from "./Modal";

import { productTourModal } from "state/productTour/services";
import { setTourNextStep } from "state/productTour/actions";

import LogoWhite from "assets/icons/spectro_logo_white.svg?react";
import { platinum, white } from "utils/constants/colors";
import { media } from "utils/responsive";
import DevTourModal from "./DevTourModal";
import { useTheme } from "components/common/Theme";

const FirstScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const WelcomeMessage = styled.div`
  width: 100%;
  margin-top: 100px;
  margin-top: 70px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  color: ${white};

  > img,
  > svg {
    height: 48px;
    margin-left: 12px;
  }

  ${media.laptop} {
    margin-top: 100px;
    margin-bottom: 32px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 22px;

    > svg {
      height: 66px;
      margin-left: 18px;
    }
  }
`;

const Description = styled.div`
  width: 350px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
  color: #f6f5fa;
  margin-bottom: 24px;

  > span {
    :first-child {
      font-weight: 500;
    }

    :last-child {
      font-weight: 200;
    }
  }

  ${media.laptop} {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`;

const StartTourButton = styled(Button)`
  width: 200px;
  height: 48px;
  background: ${(props) => props.background};
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  z-index: 100;
  font-size: 16px;
  font-weight: 500;

  :hover {
    background: ${(props) => props.background};
  }

  ${media.laptop} {
    height: 56px;
    width: 300px;
  }
`;

const SpectroLogo = styled(LogoWhite)`
  margin: 30px;
`;

const Environment = styled.img`
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 25vw;

  max-height: 450px;
`;

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const Human = styled.img`
  position: absolute;
  bottom: 40px;
  z-index: 10;
  animation: ${float} 6s ease-in-out infinite;
  transform: translatey(0px);
  height: 11vw;
  max-height: 180px;
`;

const SkipTour = styled.div`
  margin: 24px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: ${platinum};
`;

function FirstScreen({ setTourNextStep }) {
  const { t } = useTranslation();
  const { buttonBackground, productTourBackgroundPath, ColoredLogoWhiteText } =
    useTheme();

  return (
    <FirstScreenWrapper>
      <WelcomeMessage>
        {t("Welcome to")} <ColoredLogoWhiteText />
      </WelcomeMessage>
      <Description>
        <span>{t("Full-stack end-to-end management")}</span>{" "}
        <span>{t("for any cluster")}</span>
      </Description>
      <StartTourButton
        data-qa="start-tour"
        size="large"
        onClick={setTourNextStep}
        background={buttonBackground}
      >
        {t("Start Product Tour")}
      </StartTourButton>
      <SpectroLogo />
      <Environment
        src={productTourBackgroundPath}
        alt="park environment illustration"
      />
      <Human src={humanPath} alt="human doing yoga pose" />
    </FirstScreenWrapper>
  );
}

function SecondScreen() {
  const { t } = useTranslation();

  return (
    <StageSlider
      stageName={t("Clusters")}
      description={t("How and where you want them")}
      docsHref="https://docs.spectrocloud.com/clusters/"
      options={[
        {
          key: "clusters1",
          image: productsTourImages.clusters1,
          tab: t(
            "Deploy clusters into public clouds, private clouds, on bare metal or edge."
          ),
        },
        {
          key: "clusters2",
          image: productsTourImages.clusters2,
          blurredImage: productsTourImages.blurred_clusters2,
          tab: t("Centralized cloud accounts management."),
        },
        {
          key: "clusters3",
          image: productsTourImages.clusters3,
          blurredImage: productsTourImages.blurred_clusters3,

          tab: t(
            "Monitor clusters’ health, pack updates, events, CPU and memory usage."
          ),
        },
      ]}
    />
  );
}

function ThirdScreen() {
  const { t } = useTranslation();

  return (
    <>
      <StageSlider
        stageName={t("Cluster Profiles")}
        description={t("Flexibility and Consistency at Scale")}
        docsHref="https://docs.spectrocloud.com/cluster-profiles/"
        options={[
          {
            key: "profiles1",
            image: productsTourImages.profiles1,
            tab: t(
              "Define whole Kubernetes infrastructure stacks with declarative models."
            ),
          },
          {
            key: "profiles2",
            image: productsTourImages.profiles2,
            blurredImage: productsTourImages.blurred_profiles2,
            tab: t("Keep configuration consistent across tenant."),
          },
          {
            key: "profiles3",
            image: productsTourImages.profiles3,
            blurredImage: productsTourImages.blurred_profiles3,
            tab: t("Avoid unmanageable sprawl with day 2 operations."),
          },
        ]}
      />
    </>
  );
}

function ForthScreen() {
  const { t } = useTranslation();

  return (
    <StageSlider
      stageName={t("Workspaces")}
      description={t("Multi-cluster management and governance capabilities")}
      docsHref="https://docs.spectrocloud.com/workspace/"
      options={[
        {
          key: "workspaces1",
          image: productsTourImages.workspaces1,
          tab: t(
            "Central management and governance in a multi-cluster distributed environment"
          ),
        },
        {
          key: "workspaces2",
          image: productsTourImages.workspaces2,
          blurredImage: productsTourImages.blurred_workspaces2,
          tab: t(
            "Usage quota in terms of CPU and memory usage limits within the namespaces."
          ),
        },
        {
          key: "workspaces3",
          image: productsTourImages.workspaces3,
          blurredImage: productsTourImages.blurred_workspaces3,
          tab: t("Centralized RBAC in every cluster within the workspaces."),
        },
      ]}
    />
  );
}

function FifthScreen() {
  const { t } = useTranslation();

  return (
    <StageSlider
      stageName={t("Tenant Mode")}
      description={t("Manage users and teams, roles and SSO settings")}
      // TODO: update if tenants docs available in the future
      docsHref="https://docs.spectrocloud.com/user-management/"
      options={[
        {
          key: "tenant1",
          image: productsTourImages.tenant1,
          blurredImage: productsTourImages.blurred_tenant1,
          tab: t("Organize clusters in projects and monitor core-hours usage."),
        },
        {
          key: "tenant2",
          image: productsTourImages.tenant2,
          blurredImage: productsTourImages.blurred_tenant2,
          tab: t(
            "Create teams to ease the role management throughout projects."
          ),
        },
        {
          key: "tenant3",
          image: productsTourImages.tenant3,
          blurredImage: productsTourImages.blurred_tenant3,
          tab: t(
            "Keep track and easily identify activities through Audit Log functionality."
          ),
        },
      ]}
    />
  );
}

function ProductTourModal({ isDevMode, stepNumber, setTourNextStep }) {
  const { t } = useTranslation();
  if (isDevMode) {
    return <DevTourModal />;
  }

  return (
    <TourModal
      modalBackground={tourModalBackground}
      footer={null}
      service={productTourModal}
      closeIcon={<SkipTour>{t("Skip Tour")}</SkipTour>}
      width="1600px"
    >
      <Switch value={stepNumber}>
        <Switch.Case value={1}>
          <FirstScreen setTourNextStep={setTourNextStep} />
        </Switch.Case>
        <Switch.Case value={2}>
          <SecondScreen />
        </Switch.Case>
        <Switch.Case value={3}>
          <ThirdScreen />
        </Switch.Case>
        <Switch.Case value={4}>
          <ForthScreen />
        </Switch.Case>
        <Switch.Case value={5}>
          <FifthScreen />
        </Switch.Case>
      </Switch>
    </TourModal>
  );
}

export default connect(
  (state) => ({
    stepNumber: state.productTour.stepNumber,
    isDevMode: state.auth.devMode,
  }),
  { setTourNextStep }
)(ProductTourModal);
