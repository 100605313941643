import i18next from "i18next";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import { CloudAccountSchema, OverlordSchema } from "utils/schemas";

import ModalService from "services/modal";
import api from "services/api";
import notifications from "services/notifications";
import appEnv from "services/app";

export const CLOUD_ACCOUNTS_MODULE = "cloudAccounts";

export const cloudAccountFormModal = new ModalService("cloudAccountForm");

export const cloudAccountFetcher = dataFetcher({
  selectors: [
    "cloudaccounts",
    () => cloudAccountFormModal.data?.cloudType || "",
    () => cloudAccountFormModal.data?.uid || "",
  ],
  schema: CloudAccountSchema,
  async fetchData([_, cloudType, uid]) {
    const isCustom = appEnv.isCustomCloud(cloudType);

    if (isCustom) {
      return api.get(`v1/cloudaccounts/cloudTypes/${cloudType}/${uid}`);
    }

    return await api.get(`v1/cloudaccounts/${cloudType}/${uid}`);
  },
});

function filterOverlordsByCloud(cloudType) {
  return (item) => {
    const { kind, status, spec } = item;
    if (spec?.isSystem && status?.state === "Active") {
      return true;
    }

    if (cloudType === "vsphere" && !kind) {
      return true;
    }

    return kind === cloudType && status?.state === "Running";
  };
}

export const vmWareOverlordsFetcher = dataFetcher({
  selectors: ["vmWare", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await api.get("v1/overlords");
    return response?.items?.filter(filterOverlordsByCloud("vsphere")) || [];
  },
});

export const maasOverlordsFetcher = dataFetcher({
  selectors: ["maas", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await api.get("v1/overlords");
    return response?.items?.filter(filterOverlordsByCloud("maas")) || [];
  },
});

export const openstackOverlordsFetcher = dataFetcher({
  selectors: ["openstack", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await api.get("v1/overlords");
    return response?.items?.filter(filterOverlordsByCloud("openstack")) || [];
  },
});

export const selfHostedOverlordsFetcher = dataFetcher({
  selectors: [
    "self-hosted",
    "overlords",
    () => cloudAccountFormModal?.data?.cloudType,
  ],
  schema: [OverlordSchema],
  async fetchData([_1, _2, cloudType]) {
    const response = await api.get("v1/overlords");
    return (
      response?.items?.filter(
        (overlord) =>
          overlord.kind === cloudType &&
          overlord.spec?.isSelfHosted &&
          overlord.status?.isActive
      ) || []
    );
  },
});

export const awsPolicesFetcher = dataFetcher({
  selectors: ["aws", "policies"],
  async fetchData(_, { payload, cloudAccountUid }) {
    let policies = [];

    const rootApiEndpoint = "v1/clouds/aws/policies";
    const promise = api.post(
      `${rootApiEndpoint}?${
        cloudAccountUid ? `cloudAccountUid=${cloudAccountUid}` : ""
      }`,
      payload
    );

    try {
      const awsPolices = await promise;
      policies = [...(awsPolices?.policies || [])];
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
    }

    return policies;
  },
});

export const cloudAccountsMetadataFetcher = dataFetcher({
  selectors: ["cloudAccountsMetadata"],
  async fetchData() {
    try {
      return await api.get("v1/dashboard/cloudaccounts/metadata");
    } catch (err) {
      return [];
    }
  },
});

export const coxEdgeEndpointsFetcher = dataFetcher({
  selectors: ["coxedge", "endpoints"],
  async fetchData() {
    const data = await api.get("v1/clouds/coxedge/default/baseurls");
    return data.baseUrls;
  },
});

export const coxEdgeEnvironmentsFetcher = keyedDataFetcher({
  selectors: [
    "coxedge",
    "account",
    "environments",
    (state) => state.forms.cloudAccounts?.data?.apiBaseUrl,
    (state) => state.forms.cloudAccounts?.data?.apiKey,
  ],
  async fetchData([_1, _2, _3, apiBaseUrl, apiKey, organizationId]) {
    const data = await api.post("v1/clouds/coxedge/environments", {
      credentials: { apiBaseUrl, apiKey },
      organizationId,
    });
    return data.environments;
  },
});

export const coxEdgeOrganizationsFetcher = dataFetcher({
  selectors: [
    "coxedge",
    "account",
    "organizations",
    (state) => state.forms.cloudAccounts?.data?.apiBaseUrl,
    (state) => state.forms.cloudAccounts?.data?.apiKey,
  ],
  async fetchData([_1, _2, _3, apiBaseUrl, apiKey]) {
    const data = await api.post("v1/clouds/coxedge/organizations", {
      apiBaseUrl,
      apiKey,
    });
    return data.organizations;
  },
});

export const coxEdgeServicesFetcher = dataFetcher({
  selectors: [
    "coxedge",
    "account",
    "services",
    (state) => state.forms.cloudAccounts?.data?.apiBaseUrl,
    (state) => state.forms.cloudAccounts?.data?.apiKey,
  ],
  async fetchData([_1, _2, _3, apiBaseUrl, apiKey]) {
    const data = await api.post("v1/clouds/coxedge/services", {
      apiBaseUrl,
      apiKey,
    });
    return data.services;
  },
});

export const cloudAccountKeysFetcher = keyedDataFetcher({
  selectors: ["cloudAccountKeys"],
  fetchData: async ([_, cloudType]) => {
    const response = await api.get(
      `v1/clouds/cloudTypes/${cloudType}/cloudAccountKeys`
    );
    return response.keys;
  },
});
