import querystring from "query-string";

import { WorkspaceSchema } from "utils/schemas";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import api from "services/api";
import history from "services/history";
import store from "services/store";
import { workspacesClustersMetadataFetcher } from "../services/create";

export const FILTERS_FORM_MODULE = "workspacesFilters";

export const workspaceListActions = new ListActions({
  schema: [WorkspaceSchema],
  fetchData() {
    return api.get(`v1/dashboard/workspaces`);
  },
});

function getQueryFormData(query) {
  const namespaces =
    query?.namespaces && !Array.isArray(query.namespaces)
      ? [query.namespaces]
      : query.namespaces;
  const clusters =
    query?.clusters && !Array.isArray(query.clusters)
      ? [query.clusters]
      : query.clusters;

  return {
    namespaces,
    clusters,
    workspace: query?.workspace || "",
  };
}

export const filtersFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    const data = getQueryFormData(query);
    store.dispatch(workspacesClustersMetadataFetcher.fetch());

    return Promise.resolve({
      workspace: data?.workspace || "",
      namespaces: data?.namespaces || [],
      clusters: data?.clusters || [],
    });
  },
  submit: (data) => {
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);
  },
});
