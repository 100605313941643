import React from "react";
import { createTeleporter } from "react-teleporter";
import styled from "styled-components";

const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ContentWrap = styled.div`
  display: flex;
  height: 100%;
  min-height: 570px;
  align-items: stretch;
`;

const SidebarWrap = styled.div`
  max-width: 300px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid #ddd;
`;

const FooterWrap = styled.div``;

const SidebarContentWrap = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const HeaderWrap = styled.div``;

const EditorWrap = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: hidden;

  > div {
    width: 100%;
  }
`;

export const SidebarSlot = createTeleporter();
export const EditorSlot = createTeleporter();
export const SidebarFooterSlot = createTeleporter();
export const FooterSlot = createTeleporter();
export const HeaderSlot = createTeleporter();

export default function EditorLayout() {
  return (
    <MainWrap>
      <ContentWrap>
        <SidebarWrap>
          <HeaderWrap>
            <HeaderSlot.Target />
          </HeaderWrap>
          <SidebarContentWrap>
            <SidebarSlot.Target />
          </SidebarContentWrap>
          <FooterWrap>
            <SidebarFooterSlot.Target />
          </FooterWrap>
        </SidebarWrap>
        <EditorWrap>
          <EditorSlot.Target />
        </EditorWrap>
      </ContentWrap>
      <FooterSlot.Target />
    </MainWrap>
  );
}
