import { useMenu } from "components/common/providers/Menu";
import React from "react";
import styled, { css } from "styled-components";

import { white } from "utils/constants/colors";

const Wrap = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: ${white};
  opacity: 0.6;
  margin: 8px 12px 0 12px;
  transition: all 0.3s ease-in;

  ${(props) =>
    props.expanded &&
    css`
      margin-left: 24px;
    `};
`;

const AppVersion = ({ version }) => {
  const { expanded } = useMenu();
  if (!version) {
    return null;
  }
  return (
    <Wrap expanded={expanded} dangerouslySetInnerHTML={{ __html: version }} />
  );
};

export default AppVersion;
