import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const StyledIcon = styled(PlusCircleOutlined)`
  margin-left: 5px;
`;

const SeeMoreTooltip = ({ placement = "right", title }) => {
  return (
    <Tooltip placement={placement} title={title}>
      <StyledIcon />
    </Tooltip>
  );
};

export default SeeMoreTooltip;
