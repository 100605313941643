import moment from "moment";
import { createSelector } from "reselect";
import { getAllProjects } from "state/auth/selectors";
import { v4 as uuid } from "uuid";
import { BAREMETAL_ENVS, getEnvironments } from "utils/constants";
import { parseResourcesChartData } from "utils/parsers";
import {
  ORGANIZATION_OVERVIEW_FILTERS_MODULE,
  projectsCostFetcher,
  tenantLevelClustersCostFetcher,
  projectsConsumptionFetcher,
  tenantLevelClustersConsumptionFetcher,
} from "./services";
import { TENANT_LEVEL_CLUSTERS_PROJECT } from "utils/constants";

export const getChartClouds = createSelector(
  getEnvironments,
  (ENVIRONMENTS) => {
    return ENVIRONMENTS.filter(
      (env) => !BAREMETAL_ENVS.includes(env.apiKey)
    ).map((env) => ({ metadata: { uid: env.apiKey, name: env.name } }));
  }
);

export const getGroupedResources = createSelector(
  getChartClouds,
  getAllProjects,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (CHART_CLOUDS, projects, { groupBy, enableTenantScopedCluster } = {}) => {
    return [
      ...(groupBy === "project"
        ? [
            ...projects,
            enableTenantScopedCluster && {
              metadata: {
                kind: "tenant",
                uid: TENANT_LEVEL_CLUSTERS_PROJECT.uid,
                name: TENANT_LEVEL_CLUSTERS_PROJECT.name,
              },
              guid: uuid(),
              persisted: true,
            },
          ].filter(Boolean)
        : CHART_CLOUDS),
    ];
  }
);

export const groupOrganizationCosts = createSelector(
  projectsCostFetcher.selector,
  tenantLevelClustersCostFetcher.selector,
  getGroupedResources,
  (
    { result: projectsCosts },
    { result: tenantLevelClustersCosts },
    groupedResources
  ) => {
    if (!projectsCosts || !tenantLevelClustersCosts) {
      return {};
    }

    const projectsWithTenantClustersData = {
      total: tenantLevelClustersCosts?.total,
      resources: [
        ...projectsCosts?.resources,
        ...tenantLevelClustersCosts?.resources?.map((tenantResource) => ({
          ...tenantResource,
          entity: {
            ...tenantResource?.entity,
            uid: TENANT_LEVEL_CLUSTERS_PROJECT.uid,
            name: TENANT_LEVEL_CLUSTERS_PROJECT.name,
          },
        })),
      ],
    };

    return {
      chartResources: groupedResources,
      projectsWithTenantClustersData,
    };
  }
);

const getProjectResourcesCostsDataPoints = createSelector(
  groupOrganizationCosts,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (
    { chartResources, projectsWithTenantClustersData } = {},
    { filter, query } = {}
  ) => {
    if (!chartResources || !projectsWithTenantClustersData) {
      return [];
    }

    return chartResources.reduce((resourcesAcc, resource) => {
      const existingData = (
        projectsWithTenantClustersData?.resources || []
      ).find(
        (data) =>
          (data.entity?.uid || data.entity.name) === resource.metadata.uid
      );

      let totalTenantCostsUntilCurrDay = 0;
      const isTenantResource = resource?.metadata?.kind === "tenant";

      const data = Array.from({ length: filter?.value || 0 }).reduce(
        (acc, _, index) => {
          const currentXValue = moment(query.startTime).add(index, "days");
          const currentXValueToMs = currentXValue.valueOf() * 1000000;

          const isPastCurrentDay = moment().diff(currentXValue) < 0;

          if (isPastCurrentDay) {
            acc[index] = {
              timestamp: currentXValueToMs,
              total: null,
            };
            return acc;
          }

          if (!existingData || existingData?.total?.total === 0) {
            acc[index] = {
              timestamp: currentXValueToMs,
              total: 0,
            };
            return acc;
          }

          const dayCost =
            (existingData.data || []).find((data) =>
              moment(data.timestamp / 1000000).isSame(currentXValue, "day")
            )?.total || 0;

          if (isTenantResource) {
            totalTenantCostsUntilCurrDay =
              totalTenantCostsUntilCurrDay + dayCost;
            const totalProjectsCostsUntilCurrDay = resourcesAcc?.reduce(
              (totalProjectsCostsAcc, resource) =>
                totalProjectsCostsAcc + resource.data[index].total,
              0
            );

            acc[index] = {
              timestamp: currentXValueToMs,
              total:
                totalTenantCostsUntilCurrDay - totalProjectsCostsUntilCurrDay,
            };
            return acc;
          }

          acc[index] = {
            timestamp: currentXValueToMs,
            total: index > 0 ? acc[index - 1]?.total + dayCost : dayCost,
          };
          return acc;
        },
        []
      );

      return [
        ...resourcesAcc,
        {
          data,
          entity: {
            name: resource.metadata.name,
            uid: resource.metadata.uid,
          },
        },
      ];
    }, []);
  }
);

export const filterProjectsCostDataPoints = createSelector(
  getProjectResourcesCostsDataPoints,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (resourcesCosts, { filterBy, enableTenantScopedCluster } = {}) => {
    if (!resourcesCosts) {
      return [];
    }

    if (filterBy?.length > 0) {
      return resourcesCosts.filter((resource) => {
        const isProjectIncluded = filterBy.includes(resource.entity.uid);
        const isTenantEnabled =
          enableTenantScopedCluster &&
          resource.entity.uid === TENANT_LEVEL_CLUSTERS_PROJECT.uid;

        return isProjectIncluded || isTenantEnabled;
      });
    }

    return resourcesCosts;
  }
);

export const getOrganizationResourcesCostsChartData = createSelector(
  filterProjectsCostDataPoints,
  (filteredResourcesCosts) => {
    const dailyUsage =
      parseResourcesChartData({
        result: filteredResourcesCosts,
        getY: (item) => item?.total,
        isCumulative: true,
      }) || [];

    const tenantLevelCostsProjectIdx = dailyUsage?.findIndex(
      (projectChartData) =>
        projectChartData?.id === TENANT_LEVEL_CLUSTERS_PROJECT.name
    );

    if (
      tenantLevelCostsProjectIdx > -1 &&
      tenantLevelCostsProjectIdx !== dailyUsage?.length - 1
    ) {
      return [
        ...dailyUsage.slice(0, tenantLevelCostsProjectIdx),
        ...dailyUsage.slice(tenantLevelCostsProjectIdx + 1, dailyUsage?.length),
        dailyUsage[tenantLevelCostsProjectIdx],
      ];
    }

    return dailyUsage;
  }
);

const getResourcesConsumptionDataPoints = createSelector(
  projectsConsumptionFetcher.selector,
  tenantLevelClustersConsumptionFetcher.selector,
  getGroupedResources,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (
    { result: projectsConsumption } = {},
    { result: tenantLevelClustersConsumption } = {},
    groupedChartResources,
    { filter, query, consumptionType } = {}
  ) => {
    if (
      !groupedChartResources ||
      !projectsConsumption ||
      !tenantLevelClustersConsumption
    ) {
      return [];
    }

    return groupedChartResources.reduce((resourcesAcc, resource) => {
      const isTenantResource = resource?.metadata?.kind === "tenant";
      const existingData = [
        ...(projectsConsumption?.resources || []),
        ...(tenantLevelClustersConsumption?.resources?.[0]
          ? [
              {
                ...tenantLevelClustersConsumption?.resources?.[0],
                entity: {
                  ...tenantLevelClustersConsumption.resources[0].entity,
                  name: TENANT_LEVEL_CLUSTERS_PROJECT.name,
                  uid: TENANT_LEVEL_CLUSTERS_PROJECT.uid,
                },
              },
            ]
          : []),
      ].find((data) => {
        return (data.entity?.uid || data.entity.name) === resource.metadata.uid;
      });

      return [
        ...resourcesAcc,
        {
          entity: {
            name: resource.metadata.name,
            uid: resource.metadata.uid,
          },
          data: Array.from({ length: filter?.value || 0 }).map((_, index) => {
            const currentXValue = moment(query.startTime).add(index, "days");
            const currentXValueToMs = currentXValue.valueOf() * 1000000;

            const isPastCurrentDay = moment().diff(currentXValue) < 0;

            if (isPastCurrentDay) {
              return {
                timestamp: currentXValueToMs,
                usage: null,
              };
            }

            if (!existingData) {
              return {
                timestamp: currentXValueToMs,
                usage: 0,
              };
            }

            const conversion = consumptionType === "cpu" ? 1000000 : 1048576;
            const dayConsumption =
              (existingData?.data || []).find((dayUsage) =>
                moment(dayUsage.timestamp / 1000000).isSame(
                  currentXValue,
                  "day"
                )
              )?.[consumptionType] / conversion || 0;

            if (isTenantResource) {
              const totalProjectsConsumptionForCurrDay = resourcesAcc?.reduce(
                (totalProjectsConsumptionAcc, entityResource) =>
                  totalProjectsConsumptionAcc +
                  entityResource.data[index].usage,
                0
              );

              return {
                timestamp: currentXValueToMs,
                usage: dayConsumption - totalProjectsConsumptionForCurrDay,
              };
            }

            return {
              timestamp: currentXValueToMs,
              usage: dayConsumption,
            };
          }),
        },
      ];
    }, []);
  }
);

export const filterConsumptionDataPoints = createSelector(
  getResourcesConsumptionDataPoints,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (resourcesConsumption, { filterBy, enableTenantScopedCluster } = {}) => {
    if (!resourcesConsumption) {
      return;
    }

    if (filterBy?.length > 0) {
      return resourcesConsumption.filter((resource) => {
        const isProjectIncluded = filterBy.includes(resource.entity.uid);
        const isTenantEnabled =
          enableTenantScopedCluster &&
          resource.entity.uid === TENANT_LEVEL_CLUSTERS_PROJECT.uid;

        return isProjectIncluded || isTenantEnabled;
      });
    }

    return resourcesConsumption;
  }
);

export const getOrganizationResourcesConsumptionChartData = createSelector(
  filterConsumptionDataPoints,
  (filteredResourcesConsumption) => {
    const dailyUsage = parseResourcesChartData({
      result: filteredResourcesConsumption,
      getY: (item) => item?.usage,
    });

    return dailyUsage;
  }
);

export const getOrganizationGroupBySelector = createSelector(
  getChartClouds,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
  getAllProjects,
  (CHART_CLOUDS, groupBy, projects) => {
    const getOptions = (items) => {
      return (items || []).map((item) => ({
        label: item.metadata.name,
        value: item.metadata.uid,
      }));
    };

    if (groupBy === "project") {
      return getOptions(projects);
    }

    return getOptions(CHART_CLOUDS);
  }
);
