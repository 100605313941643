import React, { useState } from "react";
import styled from "styled-components";
import { Popover, Input } from "antd";
import { FieldWrap } from "components/styled/Field";

const TriggerWrap = styled(FieldWrap)`
  display: inline-block;
`;

export default function RoleSelector({
  value,
  roles,
  onChange = () => {},
  readOnly,
}) {
  const [visible, setVisible] = useState(false);
  const popoverRef = React.createRef();
  function renderRole({ name, guid }, index) {
    function onSelect() {
      setVisible(false);
      onChange(guid);
    }
    function handleKeyPress(ev) {
      if (ev.which === 13) {
        onSelect();
      }
    }

    return (
      <div
        tabIndex="0"
        role="button"
        onClick={onSelect}
        onKeyPress={handleKeyPress}
        data-qa="role-select"
        data-qa-index={index}
      >
        {name}
      </div>
    );
  }

  function renderContent() {
    return <div>{roles.map(renderRole)}</div>;
  }

  return (
    <Popover
      content={renderContent()}
      trigger="click"
      visible={visible}
      getPopupContainer={(ref) => (popoverRef.current = ref)}
      overlayClassName="as-dropdown"
      onVisibleChange={setVisible}
    >
      <TriggerWrap>
        <Input value={value} readOnly={readOnly} />
      </TriggerWrap>
    </Popover>
  );
}
