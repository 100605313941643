export function round(num, decimals) {
  return +(Math.round(num + `e+${decimals}`) + `e-${decimals}`);
}

export function formatToKilocoreHours(cores, decimals = 3) {
  return round(cores / 1000, decimals);
}

export function formatToUSCurrency(
  amount,
  currency,
  maximumFractionDigits = 4
) {
  if (amount === undefined || currency === undefined) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    maximumFractionDigits,
  }).format(amount);
}

export function allowIntegerOnly(value) {
  const parsedValue = parseInt(value);

  if (/^[0-9]+$/.test(parsedValue)) {
    return parsedValue;
  }

  return "";
}

export const parseMicroCoresToCores = (value, decimals = 5) =>
  round(value / (1000 * 1000), decimals) || 0;

export const parseKiBToGB = (value, decimals = 5) =>
  round(value / 976562, decimals) || 0;

export const parseMiBToGB = (value, decimals = 5) =>
  round(value / 1024, decimals) || 0;
