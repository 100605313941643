import ModalService from "services/modal";
import history from "services/history";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import AsyncAction from "modules/asyncAction";
import api from "services/api";
import store from "services/store";
import createFormActions from "modules/form/actions";
import notifications from "services/notifications";
import i18next from "i18next";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import isEmpty from "lodash/isEmpty";

export const createOverlordModal = new ModalService("createOverlord");
export const overlordPin = keyedDataFetcher({
  selectors: ["overlord", "pin"],
  async fetchData([_1, _2, cloudType]) {
    try {
      return await api.get(`v1/overlords/pairing/code?cloudType=${cloudType}`);
    } catch (error) {
      return { ...error, isError: true };
    }
  },
});

export const overlordOva = dataFetcher({
  selectors: ["overlord", "ova"],
  async fetchData() {
    return await api.get(`v1/overlords/vsphere/ova`);
  },
});

export const SELF_HOSTED_MODULE = "overlords/create/selfhosted";

const validator = new Validator();
validator.addRule(["name", "cloudType"], Missing());

export const selfHostedFormActions = createFormActions({
  validator,
  async init() {
    return {
      name: "",
      cloudType: "",
    };
  },
  submit(data) {
    return api.post("v1/pcg/selfHosted", data);
  },
});

export function onCreateOverlordOpen(cloudType) {
  return function (dispatch) {
    const cloudTypeDispatcher = {
      vsphere: () => {
        dispatch(overlordPin.key(cloudType).fetch());
      },
      selfhosted: () => {
        dispatch(selfHostedFormActions.init({ module: SELF_HOSTED_MODULE }));
      },
    };

    cloudTypeDispatcher?.[cloudType]?.();

    createOverlordModal.open({ cloudType }).then(
      async () => {
        if (cloudType === "selfhosted") {
          let response;
          try {
            response = await dispatch(
              selfHostedFormActions.submit({ module: SELF_HOSTED_MODULE })
            );
          } catch (err) {
            if (Array.isArray(err) && !isEmpty(err)) {
              return Promise.reject();
            }

            notifications.error({
              message: i18next.t(
                "Something went wrong while creating the Self Hosted Private Cloud Gateway"
              ),
              description: err.message,
            });
            return Promise.reject();
          }
          if (response?.overlordUid) {
            const overlords = await api.get("v1/overlords");
            const overlord = (overlords?.items || []).find(
              (o) => o.metadata.uid === response.overlordUid
            );

            if (!overlord) {
              history.push("/settings/privatecloudgateways");
            }

            history.push(
              `/privatecloudgateways/${overlord.spec.spectroClusterUid}/overview`
            );
            return;
          }

          history.push("/settings/privatecloudgateways");
        }
      },
      () => history.push("/settings/privatecloudgateways")
    );
  };
}

export const retryOverlordPin = new AsyncAction({
  promise: async (cloudType) => {
    return store.dispatch(overlordPin.key(cloudType).fetch());
  },
});

export function onOverlordPinRetry(cloudType) {
  retryOverlordPin.key(cloudType).trigger();
}
