import React, { useContext } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { appendContext } from "services/history/context";
import { ProjectContext } from "./ProjectContextProvider";

export default function Link({ to, ...rest }) {
  const projectContext = useContext(ProjectContext);
  const link = appendContext(to, projectContext);

  return <ReactRouterLink to={link} {...rest} />;
}
