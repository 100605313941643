import i18next from "i18next";
import api from "services/api";
import notifications from "services/notifications";
import { AppDeploymentSchema } from "utils/schemas";
import {
  getAppDeploymentEntity,
  getAppDeploymentNotification,
} from "../selectors/details";
import {
  checkLogFetchingStatus,
  requestAppLogs,
  updateNotificationModal,
  versionChangeModal,
} from "../services/details";
import { pollAppLogStatus } from "utils/tasks";
import { TextButton } from "components/ui/Button";
import React from "react";

export function openAppNotificationConfirmation() {
  return function thunk(dispatch, getState) {
    updateNotificationModal.open().then(async () => {
      const notification = getAppDeploymentNotification(getState());
      try {
        await api.patch(`v1/${notification.action.link}`);
        notifications.success({
          message: i18next.t(
            "Successfully applied changes to the current version. Your application will update shortly"
          ),
        });
      } catch (err) {
        notifications.error({
          message: i18next.t("Unable to apply changes to the current version"),
          description: err.message,
        });
      }
    });
  };
}

export function onVersionChange({ uid, version }) {
  return function thunk(dispatch, getState) {
    const deployment = getAppDeploymentEntity(getState());
    versionChangeModal.open({ newVersion: version }).then(async () => {
      try {
        await api.put(`v1/appDeployments/${deployment.metadata.uid}/profile`, {
          appProfileUid: uid,
        });
        notifications.success({
          message: i18next.t("Version successfully updated to {{version}}", {
            version,
          }),
        });
      } catch (err) {
        notifications.error({
          message: i18next.t("Unable to update the version"),
          description: err.message,
        });

        return Promise.reject();
      }

      dispatch({
        type: "FETCH_APP_VERSIONS",
        promise: api
          .get(`v1/appDeployments/${deployment.metadata.uid}/profile/versions`)
          .then((data) => ({
            metadata: {
              uid: deployment.metadata.uid,
            },
            spec: {
              profileVersions: data,
            },
          })),
        schema: AppDeploymentSchema,
      });
    });
  };
}

export const downloadAppLogs = async ({ appName, appTiers, clusterRef }) => {
  const appLogPromise = requestAppLogs(appName, appTiers, clusterRef);

  try {
    await appLogPromise;
    pollAppLogStatus.start();
    notifications.success({
      message: i18next.t(
        "The request was sent successfully. The download will be available soon."
      ),
    });
  } catch (error) {
    notifications.error({
      message: i18next.t("An error occurred while preparing the archive"),
      description: error.message,
    });
  }

  return appLogPromise;
};

export const fetchAppLogStatus = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const logAppClusterGuid = state.appDeployments.logClusterRef.uid;
    const logFetcherUid = state.appDeployments.logFetcherUid;
    const appName = state.appDeployments.logAppName;
    const fileName = `${appName}-logs-${Date.now()}`;

    const logFetchingStatusPromise = checkLogFetchingStatus(
      logAppClusterGuid,
      fileName
    );
    let response;

    try {
      response = await logFetchingStatusPromise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while trying to get the log status"
        ),
        description: error?.message,
      });
      return Promise.resolve();
    }

    if (response?.status?.state === "Completed") {
      const downloadLink = `/v1/spectroclusters/features/logFetcher/${logFetcherUid}/download`;

      notifications.success({
        duration: 0,
        message: i18next.t(
          "The logs archive for {{appName}} was created successfully",
          {
            appName,
          }
        ),
        description: (
          <TextButton
            data-qa="spectro-logs"
            style={{
              padding: "0",
            }}
            as="a"
            href={downloadLink}
            download={fileName}
          >
            {i18next.t('Download "{{appName}}" logs', { appName })}
          </TextButton>
        ),
      });
      return Promise.resolve();
    }
    return Promise.reject();
  };
};
