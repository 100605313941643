import uniqWith from "lodash/uniqWith";
import isEqual from "fast-deep-equal";
import base64 from "base-64";
import api from "services/api";
import ModalService from "services/modal";
import store from "services/store";
import { clustersFiltersCache } from "services/localstorage/cache";

import ListActions from "modules/list/actions";
import dataFetcher from "modules/dataFetcher";
import { getCurrentProjectUidFromUrl } from "state/auth/selectors";

import { parseFilterGroup } from "utils/parsers";
import { ClusterSchema } from "utils/schemas";
import {
  getCurrentContext,
  getCurrentProjectID,
} from "state/auth/selectors/common";
import { ADMIN_GUID } from "utils/constants";
import { clusterFiltersFetcher } from "state/cluster/services/listing";
import { createListingFiltersSelector } from "modules/list/selectors";

export const clusterDependency = new ModalService("clusterDependency");

const API_URL_MAPPING = {
  list: "v1/dashboard/spectroclusters/search",
  map: "v1/dashboard/spectroclusters/metadata/search",
};

function isDeletedClusterState(condition) {
  const { values } = condition;
  const selectedValues = Array.isArray(values) ? [...values] : [values];

  return selectedValues.some((value) =>
    ["Deleting", "Deleted"].includes(value)
  );
}

function hasDeletedFilterAlreadyApplied(filterGroups) {
  return filterGroups.some((filterGroup) => {
    return (filterGroup.conditions || []).some(
      (condition) =>
        condition.property === "isDeleted" ||
        (condition.property === "clusterState" &&
          isDeletedClusterState(condition))
    );
  });
}

const formatClusterFilters = (filtersMap = {}) => {
  const currentContext = getCurrentProjectID(store.getState());
  const filtersData = createListingFiltersSelector(
    `clusters/${currentContext}`
  )(store.getState());
  const {
    config: { data = {} },
  } = filtersData;
  return Object.keys(filtersMap).reduce((accum, key) => {
    const value = filtersMap[key];
    const { operator = "" } = data[key];
    if (value && value.length !== 0) {
      accum.push({
        conjunction: "and",
        conditions: [
          {
            property: key,
            operator: operator || "eq",
            values: value.length ? value : value.toString(),
            type: value === true ? "bool" : "string",
          },
        ],
      });
    }
    return accum;
  }, []);
};

export function mapClusterQuery({
  filterGroups = [],
  sortField,
  sortOrder,
  checkForDeletedFilter = true,
  mainConjunction = "and",
  ...remainingFilters
} = {}) {
  const formattedFilterGroups = formatClusterFilters(remainingFilters);
  const hasDeletedFilter = checkForDeletedFilter
    ? hasDeletedFilterAlreadyApplied(formattedFilterGroups)
    : false;

  const defaultFilters = [
    {
      conjunction: "and",
      conditions: [
        {
          property: "environment",
          values: ["nested"],
          operator: "neq",
          displayName: "Environment",
          type: "string",
        },
        !hasDeletedFilter && {
          property: "isDeleted",
          values: false,
          operator: "eq",
          displayName: "Deleted",
          type: "bool",
        },
      ].filter(Boolean),
    },
  ];

  const filters = [...formattedFilterGroups, ...defaultFilters].map(
    (group) => ({
      conjunction: group.conjunction,
      filters: group.conditions.map((condition) => parseFilterGroup(condition)),
    })
  );
  const uniqueFilters = uniqWith(filters, isEqual);

  return {
    filter: {
      conjunction: mainConjunction,
      filterGroups: uniqueFilters,
    },
    sort: [
      {
        field: sortField || "lastModifiedTimestamp",
        order: sortOrder || "desc",
      },
    ],
  };
}

export function refreshClustersList() {
  return (dispatch, getState) => {
    const query = getState().list?.cluster?.query;
    const { offset, limit, continue: continueToken, listView, ...rest } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const payload = mapClusterQuery(rest);
    let apiUrl = API_URL_MAPPING[listView];

    if (listView === "list") {
      apiUrl = `${apiUrl}?limit=${limit}&offset=${offset}${continueQueryParam}`;
    }

    const promise = api.post(apiUrl, payload);
    dispatch({
      type: "REFRESH_CLUSTERS",
      promise,
      schema: {
        items: [ClusterSchema],
      },
    });
  };
}

export function refreshClusters() {
  return (dispatch, getState) => {
    const currentContext = getCurrentContext(getState());
    const cacheKey = currentContext?.isAdmin
      ? ADMIN_GUID
      : currentContext?.projectUid;
    dispatch(clusterListActions.refreshItems(`clusters/${cacheKey}`));
  };
}

export function generateClustersDownloadLink(query) {
  const { offset, limit, continue: continueToken, listView, ...rest } = query;
  const utf8Bytes = new TextEncoder().encode(
    JSON.stringify(mapClusterQuery(rest))
  );
  const payload = base64.encode(String.fromCharCode(...utf8Bytes));
  const projectUid = getCurrentProjectUidFromUrl(store.getState());
  const downloadLink = `/v1/dashboard/spectroclusters/search/export?format=csv&encodedFilter=${payload}`;

  return projectUid ? `${downloadLink}&ProjectUid=${projectUid}` : downloadLink;
}

export const clusterListActions = new ListActions({
  hasPagination: true,
  persistFilters: true,
  schema: [ClusterSchema],
  defaultQuery: {
    filterGroups: [],
    limit: 25,
    listView: "list",
  },
  initialQuery: () => {
    const currentContext = getCurrentContext(store.getState());
    const cacheKey = currentContext?.isAdmin
      ? "ADMIN"
      : currentContext?.projectUid;
    const filters = clustersFiltersCache.get(cacheKey) || [];

    return {
      filterGroups: filters,
      limit: 25,
      listView: "list",
    };
  },
  async fetchData(query) {
    const { offset, limit, continue: continueToken, listView, ...rest } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const payload = mapClusterQuery(rest);
    let apiUrl = API_URL_MAPPING[listView];

    if (listView === "list") {
      apiUrl = `${apiUrl}?limit=${limit}&offset=${offset}${continueQueryParam}`;
    }

    return api.post(apiUrl, payload);
  },
  filtersFetcher: clusterFiltersFetcher,
  filtersConfig: {
    data: {
      clusterProfiles: {
        locked: true,
        componentType: "input",
      },
      tags: {
        componentType: "tags",
      },
      imported: {
        componentType: "boolean",
        options: [{ label: "Yes", value: true }],
      },
      isDeleted: {
        componentType: "boolean",
        options: [{ label: "Yes", value: true }],
      },
      isUpdatesPending: {
        componentType: "boolean",
        options: [{ label: "Yes", value: true }],
      },
      clusterState: {
        locked: true,
        componentType: "checkbox",
      },
      repaveState: {
        componentType: "checkbox",
      },
      healthState: {
        componentType: "checkbox",
      },
      clusterImportMode: {
        componentType: "checkbox",
      },
      architecture: {
        componentType: "checkbox",
      },
      fips: {
        componentType: "checkbox",
      },
      cloudAccounts: {
        componentType: "dropdown",
      },
      countryName: {
        componentType: "dropdown",
      },
      regionName: {
        componentType: "dropdown",
      },
      projects: {
        componentType: "dropdown",
      },
      environment: {
        locked: true,
        componentType: "dropdown",
      },
      clusterName: {
        componentType: "search",
        search: true,
        placeholder: "Filter by cluster name",
        operator: "contains",
      },
    },
    order: [
      "isDeleted",
      "isUpdatesPending",
      "imported",
      "environment",
      "clusterState",
      "clusterProfiles",
      "tags",
      "fips",
      "healthState",
      "cloudAccounts",
      "projects",
      "repaveState",
      "clusterImportMode",
      "architecture",
      "countryName",
      "regionName",
    ],
  },
});

export const cloudAccountsSummaryFetcher = dataFetcher({
  selectors: ["cloud-accounts-list"],
  async fetchData([_]) {
    return await api.get("v1/cloudaccounts/summary").then((res) => res.items);
  },
});
