import * as FullStory from "@fullstory/browser";
import { deleteCookie, getCookie, setCookie } from "utils/cookies";
import history from "services/history";
import appEnv from "services/app";

//

let _initialized = false;
const FLAG_NAME = "disableFullStory";
const FLAG_VALUE = "true";

export function initializeFullStory() {
  const searchQuery = history.getQuery();
  const token =
    appEnv.env.FULLSTORY_CLIENT_TOKEN ||
    import.meta.env.SPECTRO_FULLSTORY_CLIENT_TOKEN;
  if (
    token &&
    searchQuery[FLAG_NAME] === undefined &&
    getCookie(FLAG_NAME) !== FLAG_VALUE
  ) {
    FullStory.init({
      orgId: token,
    });
    _initialized = true;
  }
}

export function updateFullStoryCookie() {
  const searchQuery = history.getQuery();
  if (searchQuery[FLAG_NAME] === undefined) {
    deleteCookie(FLAG_NAME);
  } else {
    setCookie(FLAG_NAME, FLAG_VALUE);
  }
}

export function syncFullStoryWithUser(currentUser) {
  if (!currentUser || !_initialized) {
    return;
  }

  FullStory.identify(currentUser?.metadata?.uid, {
    ...(currentUser.spec || {}),
    email: currentUser?.spec?.emailId,
    displayName: [
      currentUser.spec.firstName || "",
      currentUser.spec.lastName || "",
    ]
      .join(" ")
      .trim(),
    tenant: currentUser?.status?.tenant,
  });
}

export function terminateFullstorySession() {
  if (!_initialized) {
    return;
  }

  FullStory.anonymize();
}
