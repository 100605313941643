import { createSelector } from "reselect";
import debounce from "lodash/debounce";

import dataFetcher from "modules/dataFetcher";
import createApi from "services/api/create";
import store from "services/store";
import appEnv from "services/app";

import { getEntity } from "utils/entities";
import { GeocodingLocationSchema } from "utils/schemas";

import { findLocationData } from "./utils";

export function createGeocodingFormFactory({ formActions, formModuleName }) {
  const geocodingDataFetcher = dataFetcher({
    selectors: ["geocoding"],
    schema: { features: [GeocodingLocationSchema] },
    async fetchData(_, search) {
      const accessToken = appEnv.env.MAPBOX_ACCESS_TOKEN;
      const api = createApi({ baseUrl: "https://api.mapbox.com" });
      const promise = api.get(
        `geocoding/v5/mapbox.places/${search}.json?access_token=${accessToken}`
      );
      let response;

      try {
        response = await promise;
      } catch (error) {
        console.error(error);
      }

      return response || { features: [] };
    },
  });

  const reverseGeocodingDataFetcher = dataFetcher({
    selectors: ["reverseGeocoding"],
    schema: GeocodingLocationSchema,
    async fetchData(_, { coordinates, countryCode }) {
      const [lng, lat] = coordinates || [];
      const country = countryCode ? `country=${countryCode}&` : "";
      const accessToken = appEnv.env.MAPBOX_ACCESS_TOKEN;

      const api = createApi({ baseUrl: "https://api.mapbox.com" });
      const promise = api.get(
        `geocoding/v5/mapbox.places/${lng},${lat}.json?${country}limit=1&&access_token=${accessToken}`
      );
      let response;

      try {
        response = await promise;
      } catch (error) {
        console.error(error);
      }

      return response?.features?.[0];
    },
  });

  const getGeocodingOptions = createSelector(
    geocodingDataFetcher.selector,
    ({ result }) => {
      return (result?.features || []).map((result) => ({
        value: result?.place_name,
        guid: result?.guid,
      }));
    }
  );

  const getLocationEntity = getEntity(
    (state) => state.forms[formModuleName]?.data?.geocoding?.markerGuid,
    GeocodingLocationSchema
  );

  const getSelectedLocationMarker = createSelector(
    getLocationEntity,
    (locationEntity) => {
      if (!locationEntity) {
        return null;
      }
      const [lng, lat] = locationEntity.center || [];
      return { lat, lng };
    }
  );

  const getPayload = createSelector(
    (state) => state.forms[formModuleName]?.data?.geocoding,
    getLocationEntity,
    (geocoding, locationEntity) => {
      const { location } = geocoding || {};

      if (!locationEntity || !location) {
        return null;
      }
      const [longitude, latitude] = locationEntity.center || [];

      return {
        geoLoc: { latitude, longitude },
        countryCode: findLocationData({
          entity: locationEntity,
          type: "country",
          property: "short_code",
        })?.toUpperCase(),
        countryName: findLocationData({
          entity: locationEntity,
          type: "country",
          property: "text",
        }),
        regionCode: findLocationData({
          entity: locationEntity,
          type: "region",
          property: "short_code",
        })?.toUpperCase(),
        regionName: findLocationData({
          entity: locationEntity,
          type: "region",
          property: "text",
        }),
      };
    }
  );

  function onGeocodingSearch(value) {
    store.dispatch(geocodingDataFetcher.fetch(value));
    store.dispatch(
      formActions.onChange({
        module: formModuleName,
        name: "geocoding.markerGuid",
        value: "",
      })
    );
  }

  const debouncedGeocodingSearch = debounce(onGeocodingSearch, 500);

  function onGeocodingSelect(value, option) {
    store.dispatch(
      formActions.onChange({
        module: formModuleName,
        name: "geocoding.markerGuid",
        value: option.guid,
      })
    );
  }

  return {
    actions: {
      debouncedGeocodingSearch,
      onGeocodingSelect,
    },
    selectors: {
      getGeocodingOptions,
      getPayload,
      getLocationEntity,
      getSelectedLocationMarker,
    },
    fetchers: {
      geocodingDataFetcher,
      reverseGeocodingDataFetcher,
    },
  };
}
