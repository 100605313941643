import React from "react";
import styled from "styled-components";
import {
  PackContainer,
  PackLabel,
  PackLogo,
  PackLogoIcon,
} from "../PackNameBlocks";
import Icon from "components/ui/Icon";
import { Tooltip } from "antd";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: 14px;

  > * {
    width: 50%;
    margin-left: 50px;
  }

  > *:first-child {
    margin-left: 0;
  }

  ${PackContainer} {
    justify-content: space-between;
  }
`;

const HelperText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const DetailsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export function PackTitle({
  logoUrl,
  layer,
  addonType,
  displayName,
  tag,
  helperText,
  tooltipText,
}) {
  const title = `${displayName} ${tag}`;
  return (
    <PackContainer>
      <DetailsWrap>
        <PackLogo>
          <PackLogoIcon {...{ logoUrl, layer, addonType }} />
        </PackLogo>
        <PackLabel title={title}>
          {title}
          <HelperText>{helperText}</HelperText>
        </PackLabel>
      </DetailsWrap>
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <Icon awesome={faInfoCircle} />
        </Tooltip>
      )}
    </PackContainer>
  );
}

export default function EditorSplitTitle({ from, to }) {
  return (
    <Wrap>
      <PackTitle {...from} />
      <PackTitle {...to} />
    </Wrap>
  );
}
