import { nonProjectApi } from "services/api";
import ModalService from "services/modal";

import dataFetcher from "modules/dataFetcher";

export const REGISTRATION_TOKENS_LIST_MODULE = "registrationtokens";
export const REGISTRATION_TOKENS_FORM_MODULE = "registrationtokens";

export const registrationTokenModal = new ModalService(
  "registrationTokenModal"
);

export const registrationTokensFetcher = dataFetcher({
  selectors: ["registrationtokens"],
  async fetchData() {
    let response;

    try {
      response = await nonProjectApi.get(`v1/edgehosts/tokens`);
    } catch (e) {
      return {
        items: [],
      };
    }
    return response;
  },
});
