import i18next from "i18next";
import { createSelector } from "reselect";

import { getEntity } from "utils/entities";
import { getCheckpointStep, getCurrentStep } from "modules/wizard/selectors";
import {
  WORKSPACES_FORM_MODULE,
  WIZARD_MODULE,
} from "state/workspaces/services/create";

import { presentNamespaces } from "./utils";
import { ClusterSchema } from "utils/schemas";

export const getSelectedClustersEntities = getEntity(
  (state) => state.forms[WORKSPACES_FORM_MODULE]?.data?.selectedClustersGuids,
  [ClusterSchema]
);

export const getSelectedClustersList = createSelector(
  (state) => getSelectedClustersEntities(state),
  (clusters) => {
    return (clusters || []).map((item) => {
      return {
        ...item,
        key: item?.guid,
        health: item?.status.health?.state,
        specSummary: {
          ...item?.specSummary,
          cloudConfig: item?.specSummary?.cloudConfig || {},
        },
        metrics: {
          ...item?.status?.metrics,
          cpu: {
            usage: item?.status?.metrics?.cpu?.usage || 0,
            total: item?.status?.metrics?.cpu?.total || 0,
          },
        },
        isBrownfield:
          item?.status?.clusterImport?.isBrownfield ||
          !!item?.status?.clusterImport?.state,
      };
    });
  }
);

export const getSelectedNamespaces = createSelector(
  (state) => state.forms.workspace.data.namespaces || [],
  (namespaces) => {
    return presentNamespaces(namespaces);
  }
);

export const getNamespaceSelectOptions = createSelector(
  (state) => state.forms[WORKSPACES_FORM_MODULE]?.data?.namespaces,
  (namespaces) => {
    return (namespaces || [])
      .filter((ns) => !ns.isChild)
      .map((ns) => ({
        label: ns.namespaceName,
        value: ns.namespaceName,
      }));
  }
);

const RESET_STEPS = [1, 2];

export const showResetFieldsWarning = createSelector(
  getCurrentStep(WIZARD_MODULE),
  getCheckpointStep(WIZARD_MODULE),
  (currentStep, checkpoint) => {
    if (currentStep >= checkpoint) {
      return false;
    }
    return RESET_STEPS.includes(currentStep);
  }
);

export const getWorkspaceFormError = createSelector(
  (state) => state.forms.workspace,
  (formState) => {
    if (!formState) {
      return null;
    }

    const { errors } = formState;

    if (!errors.length) {
      return null;
    }

    let message = i18next.t(
      "Please resolve the errors on the page in order to continue"
    );

    return message;
  }
);
