import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

export const ViewAllRedirectButton = ({ path }) => {
  return (
    <Link to={path}>
      <Button data-qa="app_page_redirect_button" type="link">
        View all
      </Button>
    </Link>
  );
};
