import api from "services/api";
import { DNSMappingSchema } from "utils/schemas";
import ListActions from "modules/list/actions";
import store from "services/store";

export const dnsListActions = new ListActions({
  schema: [DNSMappingSchema],
  fetchData(query) {
    return api.get("v1/users/assets/vsphere/dnsMappings", query);
  },
  initialQuery() {
    return {
      limit: 50,
      filters: `spec.privateGatewayUid=${store.getState().location.params.uid}`,
    };
  },
});
