import { VpcidSchema } from "utils/schemas";

import { isSupervizedEnvironment } from "state/cluster/selectors/create";
import api from "services/api";
import { fetchAwsCloudConfigParams } from "../../nodes";
import {
  eksKmskeysFetcher,
  eksVolumeFetcher,
  regionsFetcher,
  sshFetcher,
} from "state/cluster/services/create";

import clusterFormActions from "../form";

export function onVpcIdChange(value) {
  return function thunk(dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vpcid: value,
          controlPlane: {
            azs: [],
          },
        },
      })
    );

    const formErrors = getState().forms.cluster.errors;
    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: formErrors.map((error) => {
          if (error.field.startsWith("controlPlane")) {
            return {
              ...error,
              result: false,
            };
          }

          return error;
        }),
      })
    );
  };
}

function onChangeStaticPlacement(value) {
  return (dispatch) => {
    if (!value) {
      dispatch(onVpcIdChange(""));
      dispatch(
        clusterFormActions.validateField({
          name: "vpcid",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "ssh",
          module: "cluster",
        })
      );
    }

    dispatch(
      clusterFormActions.validateField({
        name: "vpcid",
        module: "cluster",
      })
    );
    dispatch(
      clusterFormActions.validateField({
        name: "ssh",
        module: "cluster",
      })
    );
  };
}

function selectCredentialEffect() {
  return async (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          ssh: "",
          region: "",
          vpcid: "",
        },
      })
    );

    dispatch(regionsFetcher.fetch());
  };
}

function selectRegionEffect(region) {
  return (dispatch, getState) => {
    const accountUid = getState().forms.cluster.data.credential;
    const isEncryptionEnabled = getState().forms.cluster.data.enableEncryption;
    const selectedCloud = getState().cluster.create.selectedCloud;

    const isSupervized = isSupervizedEnvironment(getState());

    const vpcids = api
      .get(
        `v1/clouds/${selectedCloud}/regions/${region}/vpcs?cloudAccountUid=${accountUid}`
      )
      .then((res) => res.vpcs);

    if (isSupervized) {
      dispatch(fetchAwsCloudConfigParams({ type: "create" }));

      if (isEncryptionEnabled) {
        dispatch(eksKmskeysFetcher.key(region).fetch());
      }

      dispatch(eksVolumeFetcher.key(region).fetch());
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          ssh: "",
          vpcid: "",
          provider: "",
        },
      })
    );

    dispatch({
      type: "CLUSTER_CLOUD_SETTINGS_VPCS",
      promise: vpcids,
      schema: [VpcidSchema],
    });

    dispatch(sshFetcher.fetch());
  };
}

export function onAwsEndpointAccessChange(value) {
  return (dispatch) => {
    if (value === "private") {
      dispatch(
        clusterFormActions.clearFieldErrors({
          module: "cluster",
          field: "publicAccessCidrs",
        })
      );
      dispatch(
        clusterFormActions.onChange({
          module: "cluster",
          name: "publicAccessCidrs",
          value: ["0.0.0.0/0"],
        })
      );
    }
    if (value === "public") {
      dispatch(
        clusterFormActions.clearFieldErrors({
          module: "cluster",
          field: "privateAccessCidrs",
        })
      );
      dispatch(
        clusterFormActions.onChange({
          module: "cluster",
          name: "privateAccessCidrs",
          value: ["0.0.0.0/0"],
        })
      );
    }
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "endpointAccess",
        value,
      })
    );
  };
}

export function onNodePoolAzToggle(value, index) {
  return function thunk(dispatch, getState) {
    const nodePools = [...getState().forms.cluster.data.nodePools];

    if (index === 0) {
      const resetNodePools = nodePools.map((pool, index) =>
        index === 0
          ? { ...pool, azs: value }
          : {
              ...pool,
              azs: pool.azs.filter((az) => value.includes(az)),
            }
      );

      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            nodePools: resetNodePools,
          },
        })
      );
      return null;
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${index}.azs`,
        value,
      })
    );
  };
}

export function onAzToggle(value) {
  return function thunk(dispatch, getState) {
    const fieldData = getState().forms.cluster.data.controlPlane;
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          controlPlane: {
            ...fieldData,
            azs: value,
          },
        },
      })
    );

    const formErrors = getState().forms.cluster.errors;
    const acceptedErrors = value.map((val) => `controlPlane.subnet_${val}`);
    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: formErrors.map((error) => {
          if (
            error.field.startsWith("controlPlane.subnet_") &&
            !acceptedErrors.includes(error.field)
          ) {
            return {
              ...error,
              result: false,
            };
          }

          return error;
        }),
      })
    );
  };
}

export function onInstanceOptionChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      instanceOption: value,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}.maxPricePercentage`,
      })
    );
  };
}

export function onLaunchTemplateChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      launchTemplateEnabled: value,
      amiId: nodePool.amiId || "",
      rootVolume: nodePool.rootVolume || {
        type: "",
        iops: "",
        throughput: "",
      },
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
  };
}

export function onRootVolumeTypeChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      rootVolume: {
        ...nodePool.rootVolume,
        type: value,
      },
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.rootVolume.throughput`,
      })
    );
  };
}

export function onRootVolumeIOPSChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      rootVolume: {
        ...nodePool.rootVolume,
        iops: value,
      },
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.rootVolume.throughput`,
      })
    );
  };
}

export function onAMIChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      amiId: value,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.disk`,
      })
    );
  };
}

const effects = {
  selectCredentialEffect,
  selectRegionEffect,
  onChangeStaticPlacement,
};

export default effects;
