import i18next from "i18next";
import { starWars } from "unique-names-generator";

import createFormActions from "modules/form/actions";
import historyService from "services/history";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import api, { nonProjectApi } from "services/api";
import {
  Missing,
  isKubernetesName,
  MinLength,
  MaxLength,
  ApplyIf,
  isBelowLimit,
} from "services/validator/rules";

import { DEPLOYMENTS } from "utils/constants/routes";

import {
  appProfileSelectionModal,
  cancelCreateDeploymentModal,
  clusterGroupsFetcher,
  clusterGroupsFetcherWithProjectId,
  DEPLOYMENT_FORM_MODULE,
  appProfilesListActions,
  APP_PROFILE_LIST_MODULE,
  appProfileFetcher,
  VIRTUAL_CLUSTERS_LIST_MODULE,
} from "state/appdeployments/services/create";
import { getPayload } from "state/appdeployments/selectors/create";
import { healthyVirtualClustersListActions } from "state/cluster/actions/list/nestedclusters";
import { generateEntityName } from "utils/entities";
import { constellationsGalaxiesAndStars } from "utils/constants/dictionaries";
import { sandboxClusterQuotaUsageFetcher } from "state/devnestedcluster/services";
import { parseMiBToGB } from "utils/number";

export function onDeploymentModalClose() {
  const { name } = store.getState().forms[DEPLOYMENT_FORM_MODULE]?.data || {};
  const returnPath = DEPLOYMENTS.ROOT;

  if (!name) {
    historyService.push(returnPath);
    return;
  }

  cancelCreateDeploymentModal.open().then(() => {
    historyService.push(returnPath);
  });
}

const validator = new Validator();

export const DEPLOY_TARGET_URL = {
  newVirtualCluster: "v1/appDeployments/clusterGroup",
  existingVirtualCluster: "v1/appDeployments",
};

validator.addRule(["name", "selectedAppProfile"], Missing());
validator.addRule(
  ["clusterName"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    Missing()
  )
);

validator.addRule(
  ["clusterName"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    isKubernetesName()
  )
);

validator.addRule(
  ["clusterName"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    MaxLength(32)
  )
);

validator.addRule(
  ["clusterName"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    MinLength(3)
  )
);

validator.addRule(
  ["clusterGroup"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    Missing()
  )
);

validator.addRule(
  ["selectedVirtualCluster"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "existingVirtualCluster",
    Missing()
  )
);

validator.addRule(
  ["cpuLimits"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    isBelowLimit("CPU", 3)
  )
);

validator.addRule(
  ["memoryLimits"],
  ApplyIf(
    (_1, _2, data) => data.deployTargetValue === "newVirtualCluster",
    isBelowLimit("Memory", 3)
  )
);

export const deploymentFormActions = createFormActions({
  validator,
  init: async () => {
    const appProfileId = store.getState().location?.params?.appProfileId;
    let selectedAppProfile;
    let selectedProfileVersion;

    if (appProfileId) {
      await store.dispatch(appProfileFetcher.fetch(appProfileId));
      selectedAppProfile = appProfileFetcher.selector(store.getState())?.result
        ?.guid;
      selectedProfileVersion = selectedAppProfile;
    }

    const clusterGroups = await store.dispatch(
      clusterGroupsFetcherWithProjectId.fetch()
    );
    const selectedClusterGroup = clusterGroups?.[0];
    store.dispatch(
      sandboxClusterQuotaUsageFetcher.fetch(selectedClusterGroup?.scope)
    );

    const defaultLimits = await getVirtualClusterDefaultLimit(
      selectedClusterGroup.uid,
      selectedClusterGroup.scope
    );

    return Promise.resolve({
      name: generateEntityName({
        prefix: "app",
        dictionaries: [starWars],
        length: 1,
      }),
      selectedAppProfile: selectedAppProfile || null,
      selectedAppProfileVersion: selectedProfileVersion || null,
      pendingAppProfileSelection: null,
      clusterGroup: selectedClusterGroup?.uid,
      clusterName: generateEntityName({
        prefix: "virtual-cluster",
        dictionaries: [constellationsGalaxiesAndStars],
        length: 1,
        maxLength: 32,
      }),
      ...defaultLimits,
      deployTargetValue: "newVirtualCluster",
      selectedVirtualCluster: null,
      externalTrafficPolicy: "Cluster",
    });
  },
  submit: async (data) => {
    const payload = getPayload(store.getState());
    let response;

    try {
      response = await api.post(
        DEPLOY_TARGET_URL[data.deployTargetValue],
        payload
      );
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while creating the app deployment"
        ),
        description: err.message,
      });
    }

    if (response) {
      historyService.push(DEPLOYMENTS.ROOT);
      notifications.success({
        message: `Deployment "${data.name}" has been created successfully`,
      });
    }
  },
});

export function initDeploymentCreationForm() {
  return (dispatch) => {
    dispatch(deploymentFormActions.init({ module: DEPLOYMENT_FORM_MODULE }));
  };
}

export function openAppProfileSelectionDrawer() {
  return (dispatch, getState) => {
    appProfileSelectionModal.open().then(() => {
      const profileGuid =
        getState().forms[DEPLOYMENT_FORM_MODULE]?.data
          ?.pendingAppProfileSelection;
      dispatch(
        deploymentFormActions.batchChange({
          module: DEPLOYMENT_FORM_MODULE,
          updates: {
            selectedAppProfile: profileGuid,
            selectedAppProfileVersion: profileGuid,
          },
        })
      );
      dispatch(
        deploymentFormActions.validateField({
          module: DEPLOYMENT_FORM_MODULE,
          name: ["selectedAppProfile", "selectedAppProfileVersion"],
        })
      );
    });
    dispatch(appProfilesListActions.initialize(APP_PROFILE_LIST_MODULE));
  };
}

export function onFieldChange(name, value) {
  return (dispatch) => {
    dispatch(
      deploymentFormActions.onChange({
        module: DEPLOYMENT_FORM_MODULE,
        name,
        value,
      })
    );

    if (name === "deployTargetValue") {
      const formErrors = store.getState().forms[DEPLOYMENT_FORM_MODULE]?.errors;
      const updatedErrors = formErrors.map((error) => {
        if (error.field !== "name") {
          return { ...error, result: false };
        }
        return error;
      });

      dispatch(
        deploymentFormActions.updateErrors({
          module: DEPLOYMENT_FORM_MODULE,
          errors: updatedErrors,
        })
      );
    }

    if (name === "deployTargetValue" && value === "existingVirtualCluster") {
      dispatch(
        healthyVirtualClustersListActions.initialize(
          VIRTUAL_CLUSTERS_LIST_MODULE
        )
      );
    }
  };
}

export async function getVirtualClusterDefaultLimit(uid, clusterScope) {
  try {
    const isScopeProject = clusterScope === "project";
    let data = !isScopeProject
      ? await nonProjectApi.get(`v1/clustergroups/${uid}`)
      : await api.get(`v1/clustergroups/${uid}`);

    return {
      cpuLimits: Math.min(4, data.spec.clustersConfig.limitConfig.cpu),
      memoryLimits: Math.min(
        4,
        parseMiBToGB(data.spec.clustersConfig.limitConfig.memory)
      ),
      storageLimits: Math.min(
        2,
        data.spec.clustersConfig.limitConfig.storageGiB
      ),
    };
  } catch (err) {
    return {
      cpuLimits: 4,
      memoryLimits: 4,
      storageLimits: 2,
    };
  }
}

export function onClusterGroupChange(clusterGroupUid) {
  return async (dispatch) => {
    const clusterGroups =
      clusterGroupsFetcher.selector(store.getState())?.result || [];
    const selectedClusterGroupScope = clusterGroups.find(
      (clusterGroup) => clusterGroup.uid === clusterGroupUid
    )?.scope;

    await dispatch(
      sandboxClusterQuotaUsageFetcher.fetch(selectedClusterGroupScope)
    );

    dispatch(
      deploymentFormActions.onChange({
        module: DEPLOYMENT_FORM_MODULE,
        value: clusterGroupUid,
        name: "clusterGroup",
      })
    );

    const defaultLimits = await getVirtualClusterDefaultLimit(
      clusterGroupUid,
      selectedClusterGroupScope
    );

    dispatch(
      deploymentFormActions.batchChange({
        module: DEPLOYMENT_FORM_MODULE,
        updates: defaultLimits,
      })
    );
  };
}
