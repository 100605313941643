import { createSelector } from "reselect";

import { ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { MARKER_STATES } from "components/common/Map/utils";
import * as L from "leaflet";

import { getClustersListState } from "./list";

export const getAllClustersList = getEntity(
  (state) => {
    return state?.cluster.list.mapData;
  },
  [ClusterSchema]
);

function parseMapClustersData(clusters) {
  return (clusters || [])
    .filter((cluster) => {
      const coords = cluster?.specSummary?.location?.coordinates;
      const [lng, lat] = coords || [];
      return !!coords && !(lng === 0 && lat === 0);
    })
    .map((cluster) => {
      const [lng, lat] = cluster.specSummary.location.coordinates;
      const state = {
        unhealthy: MARKER_STATES.DEGRADED,
        healthy: MARKER_STATES.HEALTHY,
      };
      let markerState = state[cluster?.status?.health?.state?.toLowerCase()];
      const isFailed = cluster?.status?.health?.isHeartBeatFailed;
      const isUpdatesPending = cluster?.status?.updates?.isUpdatesPending;
      if (isFailed) {
        markerState = MARKER_STATES.FAILED;
      }
      if (isUpdatesPending) {
        markerState = MARKER_STATES.UPDATES_PENDING;
      }

      return {
        ...cluster,
        markerState:
          cluster?.status?.state === "Running"
            ? markerState
            : MARKER_STATES.DISABLED,
        lng,
        lat,
      };
    });
}

export const getSelectedMapFilterTab = createSelector(
  (state) => state.cluster?.list?.mapFilterTab,
  (mapFilterTab) => {
    return mapFilterTab || "total";
  }
);

const filterByTab = (list, tab) => {
  if (tab === "total") {
    return list;
  }
  return list.filter((cluster) => cluster?.markerState === tab);
};

export const getOverviewMapClusters = createSelector(
  getAllClustersList,
  (clusters) => {
    return parseMapClustersData(clusters);
  }
);

const getAllMapClusters = getEntity(
  createSelector(getClustersListState, (clustersList) => {
    const items = Array.from(clustersList?.pages || new Set()).flatMap(
      (key) => clustersList?.items?.[key]
    );
    return [...new Set(items)];
  }),
  [ClusterSchema]
);

export const getMapClusters = createSelector(
  getAllMapClusters,
  getSelectedMapFilterTab,
  (clusters, mapFilterTab) => {
    return filterByTab(parseMapClustersData(clusters), mapFilterTab);
  }
);

export const getCurrentSelectedCluster = getEntity(
  (state) => state.cluster?.list?.mapSelectedClusterGuid,
  ClusterSchema
);

const getMapVisibleClusters = createSelector(
  getMapClusters,
  (state) => state.cluster?.list?.bounds,
  (clusters, mapBounds) => {
    const mapClusters = parseMapClustersData(clusters) || [];
    if (mapBounds) {
      const { northEast, southWest } = mapBounds || {};
      const northEastPoint = L.latLng(northEast?.lat, northEast?.lng);
      const southWestPoint = L.latLng(southWest?.lat, southWest?.lng);
      const bounds = L.latLngBounds(northEastPoint, southWestPoint);

      return mapClusters.filter((cluster) => {
        const latLng = new L.latLng(cluster.lat, cluster.lng);
        return bounds.contains(latLng);
      });
    }

    return mapClusters;
  }
);

export const getMapFilteredVisibleClusters = createSelector(
  getMapVisibleClusters,
  getSelectedMapFilterTab,
  (allVisibleClusters, mapFilterTab) => {
    return filterByTab(allVisibleClusters, mapFilterTab);
  }
);

export const getMapAllVisibleClustersCount = createSelector(
  getMapVisibleClusters,
  (visibleClusters) => {
    return visibleClusters.length;
  }
);
