import api from "services/api";
import store from "services/store";
import { v4 as uuid } from "uuid";
import ProfileStackActions from "./actions";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import createList from "modules/list";
import { ClusterProfileSchema } from "utils/schemas";
import { getMacrosSuggestions } from "state/macros/selectors";

export default class ProfileStackModule {
  constructor({ name = uuid() } = {}) {
    this.guid = name;
    this.profileSelectorModal = new ModalService(
      "profile-stack-profile-selector"
    );
    this.profileRemovalConfirm = new ModalService();
    this.listingActions = new ListActions({
      hasPagination: true,
      fetchData({
        offset,
        limit,
        sort,
        search = "",
        sortField,
        sortOrder,
        cloudTypes = [],
        profileTypes = [],
        continue: continueToken,
      }) {
        const filters = {
          filter: {
            profileName: { contains: search },
            profileType: profileTypes,
            environment: cloudTypes,
          },
          sort: sortField ? [{ field: sortField, order: sortOrder }] : sort,
        };
        const continueQueryParam = continueToken
          ? `&continue=${continueToken}`
          : "";

        return api.post(
          `v1/dashboard/clusterprofiles?limit=${limit}&offset=${offset}${continueQueryParam}`,
          filters
        );
      },
      schema: [ClusterProfileSchema],
    });

    this.actions = new ProfileStackActions({
      guid: this.guid,
      onChange: this.emitChange,
      profileSelectorModal: this.profileSelectorModal,
      profileRemovalConfirm: this.profileRemovalConfirm,
      listingActions: this.listingActions,
    });

    this.ListingModule = createList({
      actions: this.listingActions,
      schema: [ClusterProfileSchema],
    });
  }

  get state() {
    const state = store.getState().profileStack[this.guid];

    if (!state) {
      return null;
    }

    return {
      ...state,
      macros: getMacrosSuggestions(store.getState()),
    };
  }

  get payload() {
    const state = this.state;
    const isOnClusterGroupRoute = store
      .getState()
      .location?.route?.includes("CLUSTER_GROUPS");

    if (!state) {
      return [];
    }

    const values = state.values;
    const markedForDeletion = state.markForDeletion;
    return state.profiles
      .filter((profile) => !markedForDeletion.includes(profile.guid))
      .filter((profile) =>
        isOnClusterGroupRoute
          ? true
          : profile.spec?.relatedObject?.kind !== "clustergroup"
      )
      .map((profile) => {
        return {
          uid: profile.metadata.uid,
          packValues: profile.spec.published.packs.map((pack) => {
            let type = "spectro";
            if (pack.spec?.type === "manifest") {
              type = "manifest";
            }
            if (["helm", "helmChart"].includes(pack.spec?.type)) {
              type = "helm";
            }

            if (pack.spec?.type === "oci") {
              type = "oci";
            }

            // TODO: Refactor pack and pack version schemas
            const packManifests =
              pack?.manifests?.length > 0 && pack?.manifests;
            const manifests =
              packManifests ||
              pack.spec.manifests ||
              pack?.pack?.manifests ||
              [];

            const packValues = values[pack.guid];

            return {
              tag: pack?.spec?.tag || pack.tag,
              version: pack?.spec?.version || pack.version,
              name: pack?.spec?.name,
              type,
              values: packValues,
              fips: pack?.annotations?.fips === "true",
              manifests: manifests.map((manifest) => ({
                uid: manifest.uid,
                name: manifest.name,
                content: values?.[manifest.guid],
              })),
            };
          }),
        };
      });
  }
}
