export const initialState = {
  selectedUserId: null,
  selectedProjectRoleId: null,
};

export function SET_SELECTED_USER(nextState, { selectedUserId }) {
  nextState.selectedUserId = selectedUserId;
}

export function SET_SELECTED_PROJECT_ROLES(
  nextState,
  { selectedProjectRoleId }
) {
  nextState.selectedProjectRoleId = selectedProjectRoleId;
}
