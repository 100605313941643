import produce from "immer";

function DEFAULT(state) {
  return state;
}

export default function createReducer(reducers, initialState, options = {}) {
  const useImmer = options.useImmer || true;

  const reducerInitialState = reducers.initialState || initialState || {};
  return function reducerFn(state = reducerInitialState, action) {
    const { type } = action;

    if (!type) {
      console.warn("Action without type", action);
      return DEFAULT(state);
    }

    const reducer = reducers[type];

    if (!reducer) {
      return DEFAULT(state);
    }

    if (typeof reducer !== "function") {
      console.warn(`Reducer for ${type} is not a function`);
      return DEFAULT(state);
    }

    if (!useImmer) {
      return reducer(state, action);
    }

    return produce(state, (nextState) => {
      return reducer(nextState, action, state);
    });
  };
}
