import React from "react";
import { Empty } from "antd";
import noResults from "assets/images/no-results.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 16px 24px;
`;

const EmptyPanel = styled(Empty)`
  width: 100%;
  padding: 45px 0;
  background: #f7f8f9;

  .ant-empty-description {
    margin-top: 25px;
    color: #9fa9b7;
  }
  .ant-empty-image {
    height: 84px;
  }
`;

const EmptyResults = (props) => {
  const { t } = useTranslation();
  return (
    <PanelWrapper>
      <EmptyPanel
        image={noResults}
        description={t("No data to display at the moment")}
        {...props}
      />
    </PanelWrapper>
  );
};

export default EmptyResults;
