import { generatePath } from "react-router";
import querystring from "query-string";

import createFormActions from "modules/form/actions";
import history from "services/history";

import { CLUSTER_GROUPS } from "utils/constants/routes";
import { getArrayFromQuery } from "utils/parsers";

import {
  clusterWorkspaceSelectedWorkloadModal,
  clusterWorkspaceSelectedWorkloadUsageFetcher,
  clusterWorkspaceWorkloadUsageFetcher,
  clusterGroupsWorkloadsModules,
} from "../../services/workloads";

import { WORKLOADS_WITH_METRICS } from "utils/constants";

export const CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE =
  "clusterGroupWorkloadsFilters";

export const clusterGroupWorkloadsFilters = createFormActions({
  init: () => {
    const query = history.getQuery();
    const namespaces = getArrayFromQuery(query?.namespaces);
    const clusters = getArrayFromQuery(query?.clusters);

    return Promise.resolve({
      namespaces,
      clusters,
      workloadPeriod: "6 hours",
      workloadUsageType: "cpu",
      selectedWorkloadUsageType: "cpu",
    });
  },
});

export function navigateToPods(namespace) {
  return (dispatch, getState) => {
    const { id } = getState().location.params || {};
    const podsPath = generatePath(
      CLUSTER_GROUPS.CLUSTER_GROUP_WORKLOADS_DETAILS,
      {
        id,
        flag: "pods",
        tab: "workloads",
      }
    );

    const query = querystring.stringify({
      namespaces: [namespace.metadata.entity.name],
    });

    history.push(`${podsPath}?${query}`);
  };
}

export function onUsageTypeChange(value) {
  return (dispatch) => {
    dispatch(
      clusterGroupWorkloadsFilters.onChange({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
        name: "workloadUsageType",
        value,
      })
    );
  };
}

export function onWorkloadUsageTypeChange(value) {
  return (dispatch) => {
    dispatch(
      clusterGroupWorkloadsFilters.onChange({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
        name: "selectedWorkloadUsageType",
        value,
      })
    );
  };
}

export function openWorkspaceWorkloadDetailsModal(guid) {
  return (dispatch, getState) => {
    const { workloadPeriod, clusters, namespaces } =
      getState().forms?.[CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE]?.data || {};

    clusterWorkspaceSelectedWorkloadModal.open({ guid });
    dispatch(onWorkloadUsageTypeChange("cpu"));
    dispatch(
      clusterWorkspaceSelectedWorkloadUsageFetcher.fetch({
        timeRange: workloadPeriod,
        clusters,
        namespaces,
      })
    );
  };
}

export function onClusterGroupPodsPeriodChange(value) {
  return (dispatch, getState) => {
    const { namespaces, clusters } =
      getState().forms?.[CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE]?.data || {};

    dispatch(
      clusterGroupWorkloadsFilters.onChange({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
        name: "workloadPeriod",
        value,
      })
    );

    const currentTab = getState().location.params.flag;

    if (WORKLOADS_WITH_METRICS.includes(currentTab)) {
      dispatch(
        clusterWorkspaceWorkloadUsageFetcher.fetch({
          timeRange: value,
          namespaces,
          clusters,
        })
      );
    }
  };
}

export function onNamespaceFilterSelect(value) {
  return (dispatch, getState) => {
    const { workloadPeriod, clusters } =
      getState().forms?.[CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE]?.data || {};

    dispatch(
      clusterGroupWorkloadsFilters.onChange({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
        name: "namespaces",
        value,
      })
    );
    const currentTab = getState().location.params.flag;
    if (clusterGroupsWorkloadsModules[currentTab]) {
      dispatch(
        clusterGroupsWorkloadsModules[currentTab].listActions.changeQuery({
          name: "namespaces",
          value,
          module: clusterGroupsWorkloadsModules[currentTab].module,
        })
      );
    }

    if (WORKLOADS_WITH_METRICS.includes(currentTab)) {
      dispatch(
        clusterWorkspaceWorkloadUsageFetcher.fetch({
          timeRange: workloadPeriod,
          namespaces: value,
          clusters,
        })
      );
    }
  };
}

export function onClusterFilterSelect(value) {
  return (dispatch, getState) => {
    const { workloadPeriod, namespaces } =
      getState().forms?.[CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE]?.data || {};

    dispatch(
      clusterGroupWorkloadsFilters.onChange({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
        name: "clusters",
        value,
      })
    );
    const currentTab = getState().location.params.flag;
    if (clusterGroupsWorkloadsModules[currentTab]) {
      dispatch(
        clusterGroupsWorkloadsModules[currentTab].listActions.changeQuery({
          name: "clusters",
          value,
          module: clusterGroupsWorkloadsModules[currentTab].module,
        })
      );
    }

    if (WORKLOADS_WITH_METRICS.includes(currentTab)) {
      dispatch(
        clusterWorkspaceWorkloadUsageFetcher.fetch({
          timeRange: workloadPeriod,
          namespaces,
          clusters: value,
        })
      );
    }
  };
}

export function onPodsChartPeriodChange(value) {
  return (dispatch, getState) => {
    const { namespaces, clusters } =
      getState().forms?.[CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE]?.data || {};

    dispatch(
      clusterGroupWorkloadsFilters.onChange({
        module: CLUSTER_GROUP_WORKLOADS_FILTERS_MODULE,
        name: "workloadPeriod",
        value,
      })
    );

    const currentTab = getState().location.params.flag;

    if (WORKLOADS_WITH_METRICS.includes(currentTab)) {
      dispatch(
        clusterWorkspaceWorkloadUsageFetcher.fetch({
          timeRange: value,
          namespaces,
          clusters,
        })
      );
    }
  };
}
