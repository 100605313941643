import { createSelector } from "reselect";
import {
  availableClusterFiltersFetcher,
  clusterFiltersOptionsFetcher,
} from "state/cluster/services";
import { presentFilterPropertiesSchema } from "utils/presenters";

const getAvailableClusterFilters = createSelector(
  availableClusterFiltersFetcher.selector,
  ({ result }) => {
    return result?.schema?.properties || [];
  }
);

export const getPresentedClusterFilters = createSelector(
  getAvailableClusterFilters,
  (properties) => {
    return presentFilterPropertiesSchema(properties);
  }
);

export const getSelectedFilterTag = createSelector(
  (state) => state.cluster?.list?.selectedFilterTag,
  (selectedFilterTag) => {
    return selectedFilterTag;
  }
);

export const getClusterFiltersAvailableOptions = createSelector(
  clusterFiltersOptionsFetcher.selector,
  ({ result }) => {
    return result?.inputs;
  }
);
