import React from "react";
import styled, { css } from "styled-components";
import * as colors from "utils/constants/colors";
import { Progress } from "antd";

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 20px;

  > span {
    margin-top: 6px;
    color: ${colors.silver};
    font-size: 12px;
  }
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "4px")};
  background-color: ${colors.lightMidGray};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : "4px"};
`;

const Bar = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "4px")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : "4px"};
  width: ${(props) => `${props.percent}%`};
  background-color: ${(props) => props.color};
  ${(props) =>
    props.percent !== 100 &&
    css`
      border-radius: 4px 0 0 4px;
    `}
`;

export const LoadingProgressBar = styled.div`
  background: linear-gradient(
    90deg,
    ${colors.lightWhite} 1.79%,
    ${colors.concreteWhite} 75%
  );
  height: 8px;
  width: 100%;
`;

export const UsageBarWrapper = styled.div`
  position: relative;
`;

const UsageBar = styled(Progress)`
  .ant-progress-inner {
    width: 130px;
  }
  .ant-progress-bg {
    border-radius: 0px;
  }
  .ant-progress-bg,
  .ant-progress-inner {
    height: 22px !important;
  }
`;

const UsageBarLabel = styled.span`
  width: 100%;
  position: absolute;
  text-align: right;
  left: 0;
  top: 1px;
  line-height: 23px;
  padding-right: 8px;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: #818ea1;
`;

export const UsageProgressBar = ({
  label = null,
  percent = 0,
  strokeColor = colors.PROGRESS_BAR_COLORS.paleGreen,
  trailColor = colors.concreteWhite,
}) => {
  return (
    <UsageBarWrapper>
      <UsageBar
        showInfo={false}
        percent={percent}
        strokeColor={strokeColor}
        trailColor={trailColor}
      />
      <UsageBarLabel>{label}</UsageBarLabel>
    </UsageBarWrapper>
  );
};

const ProgressBar = ({ percent = 0, color = colors.green, label, ...rest }) => {
  let value = percent;

  if (value < 0 || value > 100) {
    value = 0;
  }

  return (
    <BarContainer>
      <ProgressBarWrapper {...rest}>
        <Bar percent={value} color={color} {...rest} />
      </ProgressBarWrapper>
      {label && <span>{label}</span>}
    </BarContainer>
  );
};

export default ProgressBar;
