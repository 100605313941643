import styled, { css } from "styled-components";
import { Checkbox as AntCheckbox } from "antd";
import { blueberry, lightGray } from "utils/constants/colors";

const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${blueberry};
    border-color: ${blueberry};
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${blueberry};
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${lightGray};
        border-color: ${lightGray};
      }
    `}
  }
`;

const CheckboxGroup = styled(AntCheckbox.Group)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${blueberry};
    border-color: ${blueberry};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${blueberry};
  }

  .ant-checkbox-wrapper-disabled {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${lightGray};
        border-color: ${lightGray};
      }
    }
  }
`;

export { Checkbox, CheckboxGroup };
