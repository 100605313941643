import { TechPreview } from "components/ui/Flags/TechPreview";
import React from "react";
import { useSelector } from "react-redux";
import appEnv from "services/app";
import styled from "styled-components";
import {
  getEnvironments,
  LOCATION_PROVIDERS,
  EXTRA_KNOWN_ENVIRONMENTS,
} from "utils/constants";

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  img {
    width: 100%;
  }
`;

const EnvironmentLabel = ({
  envType,
  withLabel = true,
  fallback = "-",
  withTechPreview = false,
} = {}) => {
  const ENVIRONMENTS = useSelector(getEnvironments);
  let envTypeApiKey = envType === "vmware" ? "vsphere" : envType;
  let env = [
    ...ENVIRONMENTS,
    ...LOCATION_PROVIDERS,
    ...EXTRA_KNOWN_ENVIRONMENTS,
  ].find((environment) => environment.apiKey === envTypeApiKey);
  if (!env) {
    env = appEnv.cloudTypes.find((cloudType) => cloudType.name === envType);
  }

  if (!env) {
    return <Label>{fallback}</Label>;
  }

  const isPublicCloud = env.cloudType === "public-clouds";

  return (
    <Label>
      <ImageContainer>
        <img src={env.smallLogoPath} alt={`${env.name} environment`} />
      </ImageContainer>
      {withLabel ? (isPublicCloud ? env.cloudAccountLabel : env.name) : null}
      {withTechPreview && env.isCustom ? <TechPreview /> : null}
    </Label>
  );
};

export default EnvironmentLabel;
