export function ACTION_LOAD_REQUEST(nextState, { guid }) {
  if (!nextState[guid]) {
    nextState[guid] = {};
  }
  nextState[guid].isLoading = true;
}

export function ACTION_LOAD_SUCCESS(nextState, { guid }) {
  nextState[guid].isLoading = false;
}

export function ACTION_LOAD_FAILURE(nextState, { guid }) {
  nextState[guid].isLoading = false;
}
