import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { AUDIT } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));

export default createNamespace({
  namespace: "audit",
  Component: function Main() {
    return (
      <>
        <Route adminEnabled exact path={AUDIT.ROOT} component={Index} />
      </>
    );
  },
});
