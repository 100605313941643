import { createSelector } from "reselect";
import querystring from "query-string";

import { getEntity } from "utils/entities";
import { ClusterSchema } from "utils/schemas";
import { CLUSTERS } from "utils/constants/routes";
import { generatePath } from "react-router";
import { VIRTUAL_CLUSTERS_LIST_MODULE } from "state/cluster/actions/list/nestedclusters";
import { getCurrentProjectID } from "state/auth/selectors/common";
import { ADMIN_GUID } from "utils/constants";

export const getClustersListState = createSelector(
  (state) => getCurrentProjectID(state),
  (state) => state.list,
  (projectID, lists) => {
    return lists[`clusters/${projectID}`];
  }
);

export const getClusters = getEntity(
  createSelector(getClustersListState, (state) =>
    Object.keys(state?.items || {})
      .map((key) => state?.items[key])
      .flat()
  ),
  [ClusterSchema]
);

export const hasActiveFilters = createSelector(
  getClustersListState,
  (state = {}) => {
    const { query = {} } = state;
    const filterKeys = state.filters?.config?.data || {};
    return Object.keys(filterKeys).some((key) => {
      const filterValue = query[key];
      if (Array.isArray(filterValue) || typeof filterValue === "string") {
        return filterValue.length;
      }

      return filterValue;
    });
  }
);

export const isInitialClusterListEmpty = createSelector(
  getClustersListState,
  (state) => !state?.isLoading && !state?.isInitializing && !state?.count
);

export const getClustersLink = createSelector(
  getClustersListState,
  (state) => state.auth.currentProjectId,
  (state, context) => {
    let rootPath = generatePath(CLUSTERS.ROOT, {
      tab: "overview",
    });
    if (context === ADMIN_GUID) {
      rootPath = `/admin${rootPath}`;
    }
    if (!state) {
      return rootPath;
    }
    const { deleted, sortField, sortOrder } = state?.query || {};
    const query = querystring.stringify({
      search: "",
      deleted,
      sortField,
      sortOrder,
    });

    return `${rootPath}?${query}`;
  }
);

export const isInitialVirtualClustersListEmpty = createSelector(
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.count,
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.isLoading,
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.isInitializing,
  (count, isLoading, isInitializing) => {
    return !isLoading && !isInitializing && !count;
  }
);

export const getCurrentListView = createSelector(
  getClustersListState,
  (state) => state?.query.listView || "list"
);

export const getClustersListQuery = createSelector(
  getClustersListState,
  (state) => state?.query || {}
);
