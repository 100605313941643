import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { AppProfileSchema } from "utils/schemas";
import ModalService from "services/modal";

export const settingsModalService = new ModalService();
export const deleteModalService = new ModalService();
export const createNewVersionModal = new ModalService("app-new-version");

export const appProfileDetailFetcher = dataFetcher({
  selectors: ["app-profiles", "details", (state) => state.location.params?.uid],
  schema: AppProfileSchema,
  fetchData([_1, _2, uid]) {
    return api.get(`v1/appProfiles/${uid}`);
  },
});
