import { createSelector } from "reselect";
import i18n from "i18next";
import { getEntity } from "utils/entities";
import { generatePath, matchPath } from "react-router";
import {
  UserSchema,
  ProjectPermissionsSchema,
  ProjectSchema,
} from "utils/schemas";
import { round } from "utils/number";
import * as PATHS from "utils/constants/routes";
import { FLAGS } from "utils/constants/flags";
import { getClustersLink } from "state/cluster/selectors/list";
import { getClusterProfilesLink } from "state/clusterprofile/selectors/list";
import { getWorkspacesLink } from "state/workspaces/selectors/listing";

import {
  freemiumFetcher,
  cloudCreditFetcher,
  projectsMetadataFetcher,
} from "../actions";

import { faUserLock, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faTools } from "@fortawesome/pro-light-svg-icons";
import ClusterProfileIcon from "assets/icons/clusterprofiles.svg?react";
import ClustersIcon from "assets/icons/clusters.svg?react";
import AuditLogsIcon from "assets/icons/audit_logs.svg?react";
import SettingsIcon from "assets/icons/project_settings.svg?react";
import AdminProjectsIcon from "assets/icons/admin_projects.svg?react";
import OverviewIcon from "assets/icons/overview.svg?react";
import WorkspaceIcon from "assets/icons/workspaces.svg?react";
import ClusterGroupsIcon from "assets/icons/cluster_groups.svg?react";

import permissionsService from "services/permissions";
import flags from "services/flags";
import {
  ADMIN_PREFIX_WITH_PROJECT,
  DEV_PREFIX_WITH_PROJECT,
  PROJECT_PREFIX,
} from "components/common/History/Route";
import { faServer } from "@fortawesome/pro-regular-svg-icons";
import {
  developerQuotaUsageFetcher,
  developerQuotaUsageFetcherSystemScope,
  developerQuotaUsageFetcherTenantScope,
} from "state/devnestedcluster/services";
import { clusterGroupsFetcher } from "state/clustergroups/services/listing";
import { oidcOrgFetcher } from "state/sso/providers/services";
import { getCurrentContext } from "./common";
import { ADMIN_GUID } from "utils/constants";

const DEV_WHITELIST = [
  "appmodeoverview",
  "app-profiles",
  "virtual-cluster",
  "deployments",
  "registries",
];

const TENANT_BLACKLIST = [
  "dashboard",
  "projectoverview",
  "workspaces",
  "tenantsettings",
  ...DEV_WHITELIST,
];
const PROJECT_BLACKLIST = [
  "overview",
  "projects",
  "management",
  "roles",
  "overlords",
  ...DEV_WHITELIST,
];

export const getUserProjects = getEntity(
  (state) => state.auth.projects,
  [ProjectPermissionsSchema]
);

export const getUserProjectsSorted = createSelector(
  projectsMetadataFetcher.selector,
  ({ result }) => {
    return [...(result || [])].sort((a, b) =>
      a?.metadata?.name?.localeCompare(b?.metadata?.name)
    );
  }
);

export const getCurrentUser = getEntity((state) => state.auth.me, UserSchema);

export const getUserContexts = createSelector(
  (state) => state.auth.permissions,
  getUserProjects,
  (permissions, projects) => {
    let updatedProjects = [...projects];
    if (permissions.length) {
      updatedProjects = [
        { guid: ADMIN_GUID, projectUid: ADMIN_GUID },
        ...updatedProjects,
      ];
    }

    return updatedProjects;
  }
);

export const getCurrentProjectUidFromUrl = createSelector(
  (state) => state.router.location.pathname,
  (pathname) => {
    let match = null;

    match = matchPath(pathname, {
      path: `${PROJECT_PREFIX}/*`,
      exact: true,
    });

    if (match) {
      return match.params.projectUid;
    }

    match = matchPath(pathname, {
      path: `${ADMIN_PREFIX_WITH_PROJECT}/*`,
      exact: true,
    });

    if (match) {
      return match.params.projectUid;
    }

    match = matchPath(pathname, {
      path: `${DEV_PREFIX_WITH_PROJECT}/*`,
      exact: true,
    });

    if (match) {
      return match.params.projectUid;
    }

    return null;
  }
);

export const getBackToProject = getEntity(
  (state) =>
    state.auth.projects.includes(state.auth.backToProjectUid)
      ? state.auth.backToProjectUid
      : null,
  ProjectPermissionsSchema
);

export const getAllPermissions = createSelector(
  (state) => state.auth.permissions,
  getCurrentContext,
  (permissions, currentContext) => {
    let contextPermissions = currentContext?.permissions || [];
    if (currentContext?.isAdmin) {
      contextPermissions = permissions;
    }
    if (permissions.length) {
      return [...contextPermissions, "ADMIN"];
    }

    return contextPermissions;
  }
);

export const getAllProjects = getEntity(
  (state) => state.auth.allProjects,
  [ProjectSchema]
);

export const getProjectsDropdownData = createSelector(
  getAllProjects,
  (projects) => {
    const items = projects?.map((project) => ({
      label: project.metadata.name,
      value: project.metadata.uid,
    }));

    return [{ label: "All", value: "all" }, ...items];
  }
);

export function getDevModePath(currentContext, path, params = {}) {
  return currentContext?.projectUid
    ? generatePath(`${DEV_PREFIX_WITH_PROJECT}${path}`, {
        projectUid: currentContext?.projectUid,
        ...params,
      })
    : path;
}

// TODO: add permissions for audit
export const getUserMenu = createSelector(
  getCurrentContext,
  () => permissionsService.perms,
  getClustersLink,
  getClusterProfilesLink,
  getWorkspacesLink,
  getCurrentUser,
  (state) => state.auth.devMode,
  (
    currentContext,
    permissions,
    clustersLink,
    clusterProfilesLink,
    workspacesLink,
    user,
    isDevMode
  ) => {
    const menuItems = [
      {
        key: "overview",
        path: PATHS.ORGANIZATION_OVERVIEW.ROOT,
        component: OverviewIcon,
        label: () => i18n.t("Overview"),
        permissions: [
          "project.get",
          "project.list",
          "project.edit_info",
          "project.delete",
          "project.roles.get",
          "project.roles.list",
          "project.roles.create",
        ],
        flags: [],
      },
      {
        key: "projectoverview",
        path: PATHS.PROJECT_OVERVIEW.ROOT,
        permissions: ["project.get"],
        component: OverviewIcon,
        flags: [],
        label: () => i18n.t("Project Overview"),
      },
      {
        key: "projects",
        path: PATHS.PROJECTS.ROOT,
        permissions: [
          "project.get",
          "project.list",
          "project.edit_info",
          "project.delete",
          "project.roles.get",
          "project.roles.list",
          "project.roles.create",
        ],
        component: AdminProjectsIcon,
        flags: [],
        label: () => i18n.t("Projects"),
      },
      {
        key: "profiles",
        path: clusterProfilesLink,
        permissions: [
          "clusterProfile.create",
          "clusterProfile.edit",
          "clusterProfile.list",
          "clusterProfile.get",
          "clusterProfile.publish",
        ],
        component: ClusterProfileIcon,
        flags: [],
        label: () => i18n.t("Profiles"),
      },
      {
        key: "clusters",
        path: clustersLink,
        permissions: ["cluster.create", "cluster.list", "cluster.get"],
        component: ClustersIcon,
        flags: [],
        label: () => i18n.t("Clusters"),
      },
      {
        key: "clustergroups",
        path: PATHS.CLUSTER_GROUPS.ROOT,
        permissions: [
          "clusterGroup.list",
          "clusterGroup.get",
          "clusterGroup.create",
        ],
        component: ClusterGroupsIcon,
        flags: [FLAGS.DEVX],
        label: () => i18n.t("Cluster Groups"),
      },
      {
        key: "workspaces",
        path: workspacesLink,
        permissions: ["workspace.list", "workspace.get"],
        component: WorkspaceIcon,
        flags: [FLAGS.WORKSPACES],
        label: () => i18n.t("Workspaces"),
      },
      {
        key: "roles",
        path: generatePath(PATHS.ROLES.ROOT, { tab: "project" }),
        permissions: ["role.list"],
        awesome: faUserLock,
        flags: [FLAGS.BETA],
        label: () => i18n.t("Roles"),
      },
      {
        key: "management",
        path: generatePath(PATHS.MANAGEMENT.ROOT, { tab: "users" }),
        permissions: ["user.list"],
        awesome: faUserFriends,
        flags: [FLAGS.BETA],
        label: () => i18n.t("Users & Teams"),
      },
      {
        key: "auditlogs",
        path: PATHS.AUDIT.ROOT,
        permissions: ["audit.get", "audit.list"],
        component: AuditLogsIcon,
        flags: [FLAGS.AUDIT],
        label: () => i18n.t("Audit Logs"),
      },
      {
        key: "appmodeoverview",
        path: PATHS.APP_MODE_OVERVIEW.ROOT,
        permissions: [],
        component: OverviewIcon,
        label: () => i18n.t("Overview"),
        flags: [],
      },
      {
        key: "app-profiles",
        path: PATHS.APP_PROFILES.ROOT,
        permissions: ["appProfile.list", "appProfile.get"],
        component: ClusterProfileIcon,
        label: () => i18n.t("App Profiles"),
        flags: [],
      },
      {
        key: "deployments",
        path: PATHS.DEPLOYMENTS.ROOT,
        permissions: ["appDeployment.list", "appDeployment.get"],
        awesome: faServer,
        label: () => i18n.t("Apps"),
        flags: [],
      },
      {
        key: "virtual-cluster",
        path: PATHS.VIRTUAL_CLUSTER.ROOT,
        component: ClustersIcon,
        permissions: ["virtualCluster.list", "virtualCluster.get"],
        label: () => i18n.t("Virtual Clusters"),
        flags: [],
      },
      {
        key: "registries",
        path: generatePath(PATHS.REGISTRIES.ROOT, { tab: "helm" }),
        component: SettingsIcon,
        permissions: [
          "packRegistry.create",
          "packRegistry.list",
          "packRegistry.get",
          "appProfile.create",
          "appProfile.update",
        ],
        label: () => i18n.t("Registries"),
        flags: [],
      },
      {
        key: "settings",
        path: PATHS.SETTINGS.CLOUD_ACCOUNTS,
        permissions: currentContext?.isAdmin ? [] : ["project.get"],
        component: SettingsIcon,
        theme: "filled",
        flags: [],
        label: () =>
          currentContext?.isAdmin
            ? i18n.t("Tenant Settings")
            : i18n.t("Project Settings"),
      },
      {
        key: "tenantsettings",
        path: `/admin/settings/cloudaccounts`,
        permissions: ["ADMIN"],
        awesome: faTools,
        placement: "bottom",
        flags: [],
        label: () => i18n.t("Tenant Settings"),
      },
    ];

    return menuItems.filter((item) => {
      const projectBlacklisted =
        !currentContext?.isAdmin &&
        !isDevMode &&
        PROJECT_BLACKLIST.includes(item.key);

      if (
        item.key === "clusters" &&
        user?.spec?.emailId === "saad+a1@spectrocloud.com"
      ) {
        return false;
      }

      if (projectBlacklisted) {
        return false;
      }

      const tenantBlacklisted =
        currentContext?.isAdmin && TENANT_BLACKLIST.includes(item.key);

      if (tenantBlacklisted) {
        return false;
      }

      const devBlacklisted = isDevMode && !DEV_WHITELIST.includes(item.key);

      if (devBlacklisted) {
        return false;
      }

      if (!permissionsService.has(item.permissions)) {
        return false;
      }

      return true;
    });
  }
);

export const getTenantPlanType = createSelector(
  (state) => state.auth?.plan,
  (plan) => {
    return plan?.spec?.type;
  }
);

export const getTenantPlanName = createSelector(
  (state) => state.auth.plan,
  (plan) => {
    const planTypes = {
      Trial: () => i18n.t("Trial"),
      MonthlyOnDemand: () => i18n.t("Monthly on demand"),
      AnnualSubscription: () => i18n.t("Annual subscription"),
    };

    return plan?.spec?.type ? planTypes[plan?.spec?.type] : "";
  }
);

export const getCurrentTenantUid = createSelector(
  getCurrentUser,
  (currentUser) => {
    return currentUser?.metadata?.annotations?.tenantUid || "";
  }
);

export const canUpgradePlan = createSelector(
  (state) => state.auth.plan,
  (plan) => {
    return permissionsService.isAdmin && plan?.spec?.type === "Trial";
  }
);

export const getOidcLoginOrg = createSelector(
  (state) => state.location.params.token,
  oidcOrgFetcher.selector,
  (token, { result: org }) => {
    if (!token) {
      return null;
    }

    if (org) {
      return {
        appEnv: "oidc",
        ...org.tenantLogin,
        ssoLogins: org.systemLogins || [],
      };
    }

    return null;
  }
);

export const getFreemiumUsage = createSelector(
  freemiumFetcher.selector,
  ({ result }) => {
    if (!result || !result.isFreemium || result.isUnlimited) {
      return null;
    }

    const customerUsage = round(result.usage?.usage, 2) || 0;
    const usageLimit = result?.limit.usage;
    const usagePercent = (customerUsage / usageLimit) * 100;
    return {
      isFreemium: result.isFreemium,
      customerUsage,
      usageLimit,
      usagePercent,
    };
  }
);

export const getCloudCreditUsage = createSelector(
  cloudCreditFetcher.selector,
  ({ result }) => {
    if (!result) {
      return null;
    }
    const cloudCreditUsed = round(result.creditUsedInDollars, 2);
    const cloudCreditLimit = result.creditLimitInDollars;
    const cloudCreditPercent = (cloudCreditUsed / cloudCreditLimit) * 100;
    return {
      cloudCreditUsed,
      cloudCreditLimit,
      cloudCreditPercent,
    };
  }
);

export const getApplicationEnv = (state) =>
  state.auth?.currentOrganization?.appEnv;

export const isOnPremApplication = createSelector(getApplicationEnv, (appEnv) =>
  ["quick-start", "enterprise", "enterprise-cli", "airgap"].includes(appEnv)
);

export const isSelfHosted = createSelector(
  getApplicationEnv,
  (appEnv) => appEnv === "self-hosted"
);

export const isAirgapEnv = createSelector(
  getApplicationEnv,
  (appEnv) => appEnv === "airgap"
);

export const isSecurityModeFips = createSelector(
  (state) => state.auth?.currentOrganization?.securityMode,
  (securityMode) => securityMode === "fips"
);

export const restrictOnPremPublicClouds = createSelector(
  isOnPremApplication,
  () => flags.has(FLAGS.ENABLE_ONPREM_PUBLIC_CLOUDS),
  (isOnPrem, hasFlag) => isOnPrem && !hasFlag
);

export const hasTenantLevelClusterGroup = createSelector(
  (state) =>
    (clusterGroupsFetcher.selector(state)?.result?.items || []).find(
      (clusterGroup) => clusterGroup?.scope === "tenant"
    ),
  (tenantClusterGroup) => {
    return (
      tenantClusterGroup &&
      tenantClusterGroup?.allocatedCredit?.virtualClustersLimit !== 0
    );
  }
);

const createSelectorDevQuota = (selector) =>
  createSelector(selector, ({ result }) => {
    if (!result) {
      return null;
    }

    const { allocatedCredit, usedCredit } = result;

    return {
      nesterClusters: {
        used: usedCredit?.virtualClustersLimit || 0,
        allocated: allocatedCredit?.virtualClustersLimit || 0,
        percent: round(
          (usedCredit?.virtualClustersLimit /
            allocatedCredit?.virtualClustersLimit) *
            100,
          2
        ),
      },
      cpu: {
        used: usedCredit?.cpu || 0,
        allocated: allocatedCredit?.cpu || 0,
        percent: round((usedCredit?.cpu / allocatedCredit?.cpu) * 100, 2),
      },
      memory: {
        used: usedCredit?.memoryGiB || 0,
        allocated: allocatedCredit?.memoryGiB || 0,
        percent: round(
          (usedCredit?.memoryGiB / allocatedCredit?.memoryGiB) * 100,
          2
        ),
      },
      storage: {
        used: usedCredit?.storageGiB || 0,
        allocated: allocatedCredit?.storageGiB || 0,
        percent: round(
          (usedCredit?.storageGiB / allocatedCredit?.storageGiB) * 100,
          2
        ),
      },
    };
  });

export const getDevUsedQuota = createSelectorDevQuota(
  developerQuotaUsageFetcher.selector
);

export const getDevUsedQuotaTenant = createSelectorDevQuota(
  developerQuotaUsageFetcherTenantScope.selector
);

export const getDevUsedQuotaSystem = createSelectorDevQuota(
  developerQuotaUsageFetcherSystemScope.selector
);

export const getBannerInfo = createSelector(
  (state) => state?.auth?.tenantBannerInfo,
  (state) => state?.auth?.systemBannerInfo,
  (tenantBannerInfo, systemBannerInfo) => ({
    title:
      tenantBannerInfo?.title || systemBannerInfo?.title || "Sign in Message",
    message: tenantBannerInfo?.Message || systemBannerInfo?.Message,
  })
);
