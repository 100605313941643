import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";

import { gray } from "utils/constants/colors";
import ConfirmationModal from "components/common/ConfirmationModal";
import Button from "components/ui/Button";

import { pendingCancellationModal } from "state/plandetails/services";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 375px;
  margin: auto;
`;

const Description = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${gray};
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`;

function PendingCancellationModal({ modalData }) {
  const { t } = useTranslation();
  const { daysLeft } = modalData;

  const footer = ({ resolve }) => (
    <Button data-qa="ok" onClick={resolve}>
      {t("OK")}
    </Button>
  );

  return (
    <ConfirmationModal
      type="info"
      width="480px"
      service={pendingCancellationModal}
      footer={footer}
      maskClosable
    >
      <ModalWrapper>
        <Trans>
          <Description>
            Your account will be deactivated end of the month and your account
            will be charged for the last month&apos;s usage.
            {daysLeft && (
              <div>
                You have <strong>{{ daysLeft }} days</strong> left.
              </div>
            )}
          </Description>
          <Description>
            Go to{" "}
            <Link
              to="/admin/settings/plandetails"
              onClick={() => pendingCancellationModal.close()}
            >
              Plans Page
            </Link>{" "}
            if you wish to cancel the cancellation.
          </Description>
        </Trans>
      </ModalWrapper>
    </ConfirmationModal>
  );
}

export default connect(() => ({
  modalData: pendingCancellationModal.data || {},
}))(PendingCancellationModal);
