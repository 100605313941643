import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";

import { DEPLOYMENTS } from "utils/constants/routes";
import CreateButton from "components/common/CreateButton";
import allPermissions from "services/permissions";

export default function CreateDeploymentButton({ appProfileId, label }) {
  const { t } = useTranslation();

  if (!allPermissions.has("appDeployment.create")) {
    return null;
  }

  return (
    <CreateButton
      linkTo={generatePath(DEPLOYMENTS.CREATE, {
        appProfileId,
      })}
      label={label || t("New app")}
      permissions={[]}
    />
  );
}
