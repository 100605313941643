import i18n from "i18next";

export const QUOTAS_FIELDS = {
  requests: [
    {
      label: () => i18n.t("CPU"),
      value: "requestsCpu",
      yamlKey: "requests.cpu",
      suffix: "CPU",
    },
    {
      label: () => i18n.t("Memory"),
      value: "requestsMemory",
      yamlKey: "requests.memory",
      suffix: "Gi",
    },
    {
      label: () => i18n.t("Storage"),
      value: "requestsStorage",
      yamlKey: "requests.storage",
      suffix: "Gi",
    },
  ],

  limits: [
    {
      label: () => i18n.t("CPU"),
      value: "limitsCpu",
      yamlKey: "limits.cpu",
      suffix: "CPU",
    },
    {
      label: () => i18n.t("Memory"),
      value: "limitsMemory",
      yamlKey: "limits.memory",
      suffix: "Gi",
    },
  ],

  count: [
    {
      label: () => i18n.t("Endpoints"),
      value: "endpoints",
      yamlKey: "count/endpoints",
    },
    {
      label: () => i18n.t("Pods"),
      value: "pods",
      yamlKey: "count/pods",
    },
    {
      label: () => i18n.t("Services"),
      value: "services",
      yamlKey: "count/services",
    },
    {
      label: () => i18n.t("Secrets"),
      value: "secrets",
      yamlKey: "count/secrets",
    },
    {
      label: () => i18n.t("Config Maps"),
      value: "configMaps",
      yamlKey: "count/configmaps",
    },
    {
      label: () => i18n.t("Persistent Volume Claims"),
      value: "persistentVolumeClaims",
      yamlKey: "count/persistentvolumeclaims",
    },
  ],
};
