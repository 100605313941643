import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { ROLES } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));

const namespace = "roles";

export default createNamespace({
  namespace,
  Component: function Main() {
    return (
      <>
        <Route adminOnly path={ROLES.ROOT} component={Index} />
      </>
    );
  },
});
