import { WorkspaceSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { createSelector } from "reselect";
import { PermissionService } from "services/permissions";

export const getRawWorkspace = getEntity(
  (state) => state.workspace?.details?.currentWorkspaceGuid,
  WorkspaceSchema
);

export const getWorkspacePermissions = createSelector(
  (state) => getRawWorkspace(state),
  ({ metadata }) => {
    return new PermissionService(
      metadata?.annotations?.permissions?.split(",")
    );
  }
);
