import moment from "moment";
import { createSelector } from "reselect";

import { parseKiBToGB, parseMicroCoresToCores } from "utils/number";
import { parseResourcesChartData } from "utils/parsers";
import {
  workspacesCostsFetcher,
  workspacesUsageFetcher,
  USAGE_COST_FILTERS,
} from "../services/usagecost";

export const getSelectedKey = createSelector(
  (state) => state.forms?.[USAGE_COST_FILTERS]?.data?.filter,
  (filter) => {
    return filter.type === "calendar"
      ? filter.type
      : `${filter.value} ${filter.unit}`;
  }
);

export const getWorkspacesUsageChartData = createSelector(
  workspacesUsageFetcher.selector,
  (state) => state.forms?.[USAGE_COST_FILTERS]?.data,
  (
    { result },
    { filter = {}, query = {}, filterBy = [], consumptionType = "cpu" } = {}
  ) => {
    if (!result) {
      return null;
    }

    let filteredResult = [...(result || [])];

    if (filterBy.length > 0) {
      filteredResult = filteredResult.filter((resource) =>
        filterBy.includes(resource?.entity?.uid)
      );
    }

    const resources = (filteredResult || []).map(({ entity, data }) => ({
      entity,
      data: Array.from({ length: filter?.value || 0 }).map((_, index) => {
        const currentXValue = moment(query.startTime).add(index, "days");
        const currentXValueToMs = currentXValue.valueOf() * 1000000;

        const isPastCurrentDay = moment().diff(currentXValue) < 0;

        if (isPastCurrentDay) {
          return {
            timestamp: currentXValueToMs,
            usage: null,
          };
        }

        const dayUsage = data.find((clusterUsage) =>
          moment(clusterUsage.timestamp / 1000000).isSame(currentXValue, "day")
        );

        if (!dayUsage) {
          return {
            timestamp: currentXValueToMs,
            usage: 0,
          };
        }

        return {
          timestamp: currentXValueToMs,
          usage:
            consumptionType === "cpu"
              ? parseMicroCoresToCores(dayUsage?.cpu, 5)
              : parseKiBToGB(dayUsage?.memory, 5),
        };
      }),
    }));

    return parseResourcesChartData({
      result: resources,
      getY: (item) => item.usage,
    });
  }
);

export const getWorkspacesCostsData = createSelector(
  workspacesCostsFetcher.selector,
  (state) => state.forms?.[USAGE_COST_FILTERS]?.data,
  ({ result }, { filter = {}, query = {}, filterBy = [] } = {}) => {
    if (!result) {
      return null;
    }

    let filteredResult = [...(result || [])];

    if (filterBy.length > 0) {
      filteredResult = filteredResult.filter((resource) =>
        filterBy.includes(resource?.entity?.uid)
      );
    }

    const resources = (filteredResult || []).map(({ entity, data }) => ({
      entity,
      data: Array.from({ length: filter?.value || 0 }).reduce(
        (acc, _, index) => {
          const currentXValue = moment(query.startTime).add(index, "days");
          const currentXValueToMs = currentXValue.valueOf() * 1000000;

          const isPastCurrentDay = moment().diff(currentXValue) < 0;

          if (isPastCurrentDay) {
            acc[index] = {
              timestamp: currentXValueToMs,
              total: null,
            };
            return acc;
          }

          const dayCost =
            data.find((clusterCost) =>
              moment(clusterCost.timestamp / 1000000).isSame(
                currentXValue,
                "day"
              )
            )?.total || 0;

          acc[index] = {
            timestamp: currentXValueToMs,
            total: index > 0 ? acc[index - 1]?.total + dayCost : dayCost,
          };
          return acc;
        },
        []
      ),
    }));

    return parseResourcesChartData({
      result: resources,
      getY: (item) => item.total,
    });
  }
);
