export const cellTypes = {
  textSingle: {
    //for small text that fits in a single line. i.e. an ip
    defaultWidth: 188,
    minWidth: 96,
    maxWidth: 350,
  },
  textMultiple: {
    //for larget text that can strectch to multiple lines. i.e. a resource name
    defaultWidth: 188,
    minWidth: 128,
    maxWidth: 350,
  },
  linkSingle: {
    //for single link. i.e. a cluster name
    defaultWidth: 188,
    minWidth: 128,
    maxWidth: 350,
  },
  linkMultiple: {
    //for multiple links;
    defaultWidth: 188,
    minWidth: 96,
    maxWidth: 350,
  },
  tags: {
    //for tags
    defaultWidth: 250,
    minWidth: 128,
    maxWidth: 450,
  },
  badge: {
    //for small encapsulated text. i.e. a status or state
    defaultWidth: 150,
    minWidth: 64,
    maxWidth: 300,
  },
  icon: {
    //for icons. i.e. trusted boot or cluster environment
    defaultWidth: 100,
    minWidth: 32,
    maxWidth: 200,
  },
  button: {
    //for actions
    defaultWidth: 50,
    minWidth: 48,
    maxWidth: 100,
  },
  checkbox: {
    //for checkboxes
    defaultWidth: 50,
    minWidth: 48,
    maxWidth: 100,
  },
};
