import React from "react";
import SeeMoreTooltip from "../SeeMoreTooltip";

export default function Enumeration({ items }) {
  const firstRoles = items.slice(0, 2);
  const lastRoles = items.slice(2, items.length);
  return (
    <div>
      {firstRoles.join(", ")}
      {items.length > 2 && (
        <SeeMoreTooltip
          title={lastRoles.map((role) => (
            <div key={role}>{role}</div>
          ))}
        />
      )}
    </div>
  );
}
