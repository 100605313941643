import ConfirmationModal from "components/common/ConfirmationModal";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { deleteEdgeMachine } from "state/cluster/services/edgemachines";

function MachineDeleteConfirmationModal({ edgeMachineToDeleteName }) {
  const { t } = useTranslation();

  return (
    <ConfirmationModal type="error" service={deleteEdgeMachine}>
      <ConfirmationModal.Question>
        {t('Are you sure you want to delete "{{name}}" edge machine?', {
          name: edgeMachineToDeleteName,
        })}
      </ConfirmationModal.Question>
    </ConfirmationModal>
  );
}

export default connect(() => ({
  edgeMachineToDeleteName: deleteEdgeMachine?.data?.machineName,
}))(MachineDeleteConfirmationModal);
