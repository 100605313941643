import React from "react";
import styled, { css } from "styled-components";

import { midGray, STATUS_COLOR } from "utils/constants/colors";
import Loader from "components/ui/Loader";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
`;

const LoaderWrap = styled.div`
  margin-right: 8px;
  max-height: 12px;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  min-width: 0;
  line-height: 16px;
  align-items: center;
  color: ${(props) => STATUS_COLOR[props.status] || midGray};

  ${(props) =>
    props.hasBackground &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        ${(props) => STATUS_COLOR[props.status] || midGray};
      border-radius: 11px;
      padding: 3px 8px;
    `}
`;

const StatusWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function Status({
  status = "",
  hasBackground = true,
  hasLoader = true,
  children,
  loadingStatuses = ["inprogress"],
}) {
  return (
    <Wrapper data-qa-type="status" data-qa="status">
      <ContentWrapper
        title={status.charAt(0).toUpperCase() + status.slice(1)}
        status={status?.toLowerCase()}
        hasBackground={hasBackground}
      >
        {hasLoader && loadingStatuses.includes(status?.toLowerCase()) ? (
          <LoaderWrap>
            <Loader small />
          </LoaderWrap>
        ) : null}
        <StatusWrapper>{children || status}</StatusWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}
