import dataFetcher from "modules/dataFetcher";
import createFormActions from "modules/form/actions";
import ListActions from "modules/list/actions";
import api from "services/api";
import ModalService from "services/modal";
import { ClusterProfileSchema } from "utils/schemas";

export const newClusterModal = new ModalService("newCluster");
export const ENVIRONMENT_SELECTOR_MODULE = "environment-selector";

const systemProfilesFetcher = dataFetcher({
  selectors: ["system-profiles"],
  async fetchData(_, query) {
    const response = await api.post(
      `v1/dashboard/clusterprofiles?limit=20${
        query.continue ? `&continue=${query.continue}` : ""
      }`,
      {
        filter: query?.filter,
      }
    );

    return {
      ...response,
      items: response.items.filter(
        (item) => item.metadata?.annotations?.scope === "system"
      ),
    };
  },
});

export const clusterProfileListActions = new ListActions({
  dataFetcher: systemProfilesFetcher,
  schema: [ClusterProfileSchema],
});

export const environmentSelectFormActions = createFormActions({
  init: () => Promise.resolve({ cloudType: "aws", clusterType: "cloud" }),
});
