import i18next from "i18next";
import createFormActions from "modules/form/actions";
import api from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { areValidDomainTags } from "services/validator/rules";
import { getCurrentUser } from "state/auth/selectors";

const validator = new Validator();
validator.addRule(["domains"], areValidDomainTags());

export const domainsFormActions = createFormActions({
  validator,
  init: async () => {
    const currentUser = getCurrentUser(store.getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

    const data = await api.get(`v1/tenants/${tenantUid}/domains`);

    return Promise.resolve({
      domains: data.domains || [],
    });
  },
  submit: async (data) => {
    const currentUser = getCurrentUser(store.getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

    try {
      await api.post(`v1/tenants/${tenantUid}/domains`, {
        domains: data.domains,
      });

      notifications.success({
        message: i18next.t("The domains have been set successfully"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("The domains couldn't be set"),
        description: err?.message,
      });
    }
  },
});
