export const initialState = {
  coresProject: {
    filter: null,
    query: null,
    selectedKey: "",
  },
  monthlyCoreHours: {
    filter: null,
    query: null,
  },
};

export function CORES_CHART_TIME_PERIOD_INIT(nextState, { selectedKey }) {
  nextState.coresProject.selectedKey = selectedKey;
}
export function CORES_CHART_TIME_PERIOD_CHANGE(
  nextState,
  { filter, query, selectedKey }
) {
  nextState.coresProject.filter = filter;
  nextState.coresProject.selectedKey = selectedKey;
  nextState.coresProject.query = query;
}

export function MONTHLY_CORE_HOURS_CHART_CALENDAR_CHANGE(
  nextState,
  { filter, query }
) {
  nextState.monthlyCoreHours.filter = filter;
  nextState.monthlyCoreHours.query = query;
}
