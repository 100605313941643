import pick from "lodash/pick";
import createFormActions from "modules/form/actions";
import api from "services/api";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import {
  cloudAccountFetcher,
  cloudAccountFormModal,
  cloudAccountKeysFetcher,
} from "state/cloudaccounts/services";

async function init({ env }) {
  const state = store.getState();
  const keys = cloudAccountKeysFetcher.selector(state)[env]?.result || [];
  store.dispatch({
    type: "SET_ACCOUNT_AS_VALID",
  });
  let data;

  if (cloudAccountFormModal.data.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  return keys.reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: data?.spec?.credentials?.[key] || "",
      };
    },
    {
      name: data?.metadata?.name || "",
      cloudType: env,
      overlordUid: data?.metadata?.annotations?.overlordUid || "",
      uid: cloudAccountFormModal.data.uid,
    }
  );
}

async function submit({ cloudType, overlordUid, uid, ...data }) {
  const state = store.getState();
  const keys = cloudAccountKeysFetcher.selector(state)[cloudType]?.result || [];
  const credentials = pick(data, keys);

  const payload = {
    metadata: {
      name: data.name,
      annotations: {
        overlordUid,
      },
    },
    spec: {
      credentials,
    },
  };

  if (uid) {
    return api.put(`v1/cloudaccounts/cloudTypes/${cloudType}/${uid}`, payload);
  }

  return api.post(`v1/cloudaccounts/cloudTypes/${cloudType}`, payload);
}

const validator = new Validator();
validator.addRule(["name", "overlordUid"], Missing());

export const customAccountFormActions = createFormActions({
  validator,
  init,
  submit,
});
