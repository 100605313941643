import React from "react";
import styled from "styled-components";
import { createSlots } from "modules/profileIDE/Layout";
import { darkGray, lightMidGray, white } from "utils/constants/colors";

const EditorWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${darkGray};
  border-bottom: 1px solid ${lightMidGray};
  background: ${white};
`;

const HeaderTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  margin: 12px 0;
  border-right: 1px solid ${lightMidGray};
`;

const ContentWrap = styled.div`
  flex-grow: 1;
  background: white;
  height: calc(100% - 50px);

  > div {
    overflow-y: auto;
    padding: 24px;
    width: 100%;
    height: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  div:first-child {
    margin-right: 5px;
  }
`;

const EditorSlots = createSlots([
  "Header",
  "BackArrow",
  "HeaderName",
  "DeleteFile",
  "HeaderTabs",
  "Content",
]);

export default function Editor() {
  return (
    <EditorWrap>
      <EditorSlots.Slot.Header>
        <HeaderWrap>
          <HeaderTitleWrap>
            <TitleWrapper>
              <EditorSlots.Slot.BackArrow />
              <EditorSlots.Slot.HeaderName />
            </TitleWrapper>
            <EditorSlots.Slot.DeleteFile />
          </HeaderTitleWrap>
          <EditorSlots.Slot.HeaderTabs />
        </HeaderWrap>
      </EditorSlots.Slot.Header>

      <ContentWrap>
        <EditorSlots.Slot.Content />
      </ContentWrap>
    </EditorWrap>
  );
}

EditorSlots.addFills(Editor);
