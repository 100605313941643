import dataFetcher from "modules/dataFetcher";

import api, { nonProjectApi } from "services/api";
import store from "services/store";

import { getCurrentTenantUid } from "state/auth/selectors";
import { getCurrentContext } from "state/auth/selectors/common";

export const platformClusterSettingsFetcher = dataFetcher({
  selectors: ["preferences/clusterSettings"],
  async fetchData() {
    const currentContext = getCurrentContext(store.getState());

    let response;
    if (currentContext?.isAdmin) {
      const tenantUid = getCurrentTenantUid(store.getState());
      response = await nonProjectApi.get(
        `v1/tenants/${tenantUid}/preferences/clusterSettings`
      );
    } else {
      response = await api.get(
        `v1/projects/${currentContext?.projectUid}/preferences/clusterSettings`
      );
    }

    return response;
  },
});
