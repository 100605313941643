import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled, { css } from "styled-components";

import Toolbar from "components/common/Editor/Toolbar";
import { userSettingsCache } from "services/localstorage/cache";

const Wrapper = styled.div`
  height: 100%;
`;

const MarkdownWrapper = styled.div`
  padding: 10px 24px;
  height: calc(100% - 45px);
  overflow-y: auto;
  background: #fff;
  ${(props) =>
    props.theme === "dark" &&
    css`
      background: #011627;
      > * {
        color: #fff;
      }
    `}
`;

export default function PackReadMe({
  title = "Readme",
  markdownContent = "",
  customToolbarButtons = null,
  headerColor,
  theme,
  hasToolbar = true,
  onClose,
  ...rest
}) {
  const [editorTheme, setTheme] = useState(
    userSettingsCache.get("editorTheme") || "light"
  );

  function toggleTheme() {
    const newTheme = editorTheme === "light" ? "dark" : "light";
    userSettingsCache.set("editorTheme", newTheme);
    setTheme(newTheme);
  }

  const toolbarProps = {
    title,
    headerColor,
    theme,
    customToolbarButtons,
    onClose,
    showActions: true,
    editorTheme,
    toggleTheme,
  };

  return (
    <Wrapper {...rest}>
      {hasToolbar ? <Toolbar {...toolbarProps} /> : null}

      <MarkdownWrapper theme={editorTheme}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            a: (props) => (
              <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
              </a>
            ),
          }}
        >
          {markdownContent}
        </ReactMarkdown>
      </MarkdownWrapper>
    </Wrapper>
  );
}
