import styled from "styled-components";

import { red } from "utils/constants/colors";

const MAX_INPUT_WIDTH = 428;

export const FieldLabel = styled.label`
  margin-bottom: 7px;
  white-space: nowrap;
  display: block;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.02em;
`;

export const FieldWrap = styled.div`
  margin: 20px 0;
  width: 100%;

  > .ant-select,
  > .ant-input-group-wrapper > .ant-input-wrapper.ant-input-group,
  > .ant-input {
    width: 100%;
    max-width: ${MAX_INPUT_WIDTH}px;
  }

  > input.ant-input,
  .ant-select-selector {
    min-height: 40px;
  }

  > .ant-checkbox-wrapper {
    .ant-checkbox-input {
      width: 16px;
      height: 16px;
    }

    .ant-checkbox-checked::after {
      border: 0;
    }
  }

  .ant-select-selection--single {
    height: 40px;
    .ant-select-selection__rendered {
      height: 40px;
      line-height: 40px;
    }
  }

  .ant-select.ant-select-show-search .ant-select-selection-search-input,
  .ant-input-number-input {
    height: 38px;
  }

  .ant-select-multiple .ant-select-selection-overflow-item {
    height: 36px;
    max-width: 99%;
  }

  .ant-select-selection-overflow-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selection--multiple {
    min-height: 37px;
    .ant-select-search--inline {
      height: 34px;
    }
  }
`;

export const Error = styled.div`
  font-size: 12px;
  line-height: 22px;
  color: ${red};
  margin-top: 7px;
`;
