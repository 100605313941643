import api from "services/api";
import ModalService from "services/modal";

import dataFetcher from "modules/dataFetcher";

import { getCurrentUser } from "state/auth/selectors";

export const AUDIT_TRAILS_LIST_MODULE = "auditTrails";
export const AUDIT_TRAILS_FORM_MODULE = "auditTrails";

export const deleteConfirmService = new ModalService("deleteModal");
export const auditTrailsModal = new ModalService("auditTrailsModal");

export const auditTrailsFetcher = dataFetcher({
  selectors: [
    "audittrails",
    (state) => getCurrentUser(state)?.metadata?.annotations?.tenantUid,
  ],
  async fetchData([_, tenantUid]) {
    const promise = api.get(`v1/tenants/${tenantUid}/assets/dataSinks`);
    let response;
    try {
      response = await promise;
    } catch (e) {
      return {
        items: [],
      };
    }
    return {
      items: (response?.spec?.auditDataSinks || []).map((data) => ({
        ...data,
        metadata: response.metadata,
      })),
    };
  },
});
