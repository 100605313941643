import Loader from "components/ui/Loader";
import React from "react";
import { Redirect, Route, Switch } from "react-router";

import createNamespace from "services/language";
import { AUTH } from "utils/constants/routes";
const Root = React.lazy(() => import("./index"));
const SetPassword = React.lazy(() => import("./flows/SetPassword"));
const ForgotPassword = React.lazy(() => import("./flows/ForgotPassword"));
const PasswordUpdate = React.lazy(() => import("./flows/PasswordUpdate"));
const Register = React.lazy(() => import("./flows/Register"));
const OrgSelect = React.lazy(() => import("./flows/OrgSelect"));
const OidcSuccess = React.lazy(() => import("./flows/OidcSuccess"));

export default createNamespace({
  namespace: "auth",
  Component: function Main({ isLoading }) {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <Route path={[AUTH.ROOT, `${AUTH.ROOT}/*`, `/dev/auth`]}>
        <Switch>
          <Route exact path={AUTH.ROOT} component={Root} />
          <Route exact path={AUTH.DEVX_SIGNUP} component={Register} />
          <Route path={AUTH.ACTIVATE_PASSWORD} component={SetPassword} />
          <Route path={AUTH.RESET_PASSWORD} component={SetPassword} />
          <Route path={AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={AUTH.UPDATE_PASSWORD} component={PasswordUpdate} />
          <Route path={AUTH.ORG_SELECT} component={OrgSelect} />
          <Route path={AUTH.OIDC_LOGIN} component={Root} />
          <Route
            exact
            strict
            path={AUTH.OIDC_SUCCESS}
            component={OidcSuccess}
          />
          <Route path="*">
            <Redirect to={AUTH.ROOT} />
          </Route>
        </Switch>
      </Route>
    );
  },
});
