import ListActions from "modules/list/actions";

import { DeploymentSchema } from "utils/schemas";

export const DEPLOYMENTS_MODULE = "clusterGroupDeployments";

export const deploymentsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    //TODO
    return {
      items: [],
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [DeploymentSchema],
});
