import React from "react";
import styled from "styled-components";
import { lightMidGray } from "utils/constants/colors";
import Header from "./Header";
import ListTree from "./ListTree";

const FileListWrapper = styled.div`
  width: 260px;
  height: 100%;
  border-left: 1px solid ${lightMidGray};
  border-right: 1px solid ${lightMidGray};
  display: flex;
  flex-direction: column;
`;

function FileList({
  allFiles,
  filesTreeData = [],
  selectedKey,
  onSelect,
  onDrop,
}) {
  return (
    <FileListWrapper>
      <Header>Configure tier</Header>
      <ListTree
        treeData={filesTreeData}
        selectedKey={selectedKey}
        onSelect={onSelect}
        onDrop={onDrop}
        expandedKeys={allFiles}
      />
    </FileListWrapper>
  );
}

export default FileList;
