import React from "react";
import styled from "styled-components";
import Tags from "components/common/Tags";
import {
  appProfilesListActions,
  APP_PROFILES_MODULE,
} from "state/appprofiles/actions/listing";
import { concreteWhite, gray } from "utils/constants/colors";
import { APP_PROFILES } from "utils/constants/routes";
import { AppProfileSchema } from "utils/schemas";
import { WidgetHeader } from "pages/dev/overview/components/Status";
import createList, { connectComponent } from "modules/list";
import { ProfileCardTags } from "components/common/ClusterProfileCard";
import { ClusterProfileType } from "components/common/ClusterProfiles";
import { ViewAllRedirectButton } from "pages/dev/overview/components/ViewAllRedirectButton";
import CreateAppProfileButton from "pages/dev/appprofiles/components/CreateAppProfileButton";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import { getTotalCountsOfAppProfiles } from "state/appModeOverview/selectors/appDeploymentsWidget";
import history from "services/history";

const StyledWidgetWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCardsWrapper = styled.div`
  gap: 10px;
  display: flex;
  height: 100%;
  overflow-x: scroll;
`;

const StyledCardWrapper = styled.span`
  min-height: 80px;
  min-width: 271px;
  border: 2px solid ${concreteWhite};
  border-radius: 7px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  :hover {
    cursor: pointer;
  }
`;

const ProfileHeadingWrapper = styled.div`
  display: flex;
  align-content: flex-end;
  justify-items: center;
  gap: 10px;
  align-items: flex-end;
`;

const ProfileFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledDescription = styled.div`
  color: ${gray};
`;

const ListModule = createList({
  hasPagination: true,
  actions: appProfilesListActions,
  schema: [AppProfileSchema],
});

const AppProfileListing = () => {
  return (
    <>
      <ListModule module={APP_PROFILES_MODULE} preventInitOnMount>
        <ConnectedCards></ConnectedCards>
      </ListModule>
    </>
  );
};

const Cards = ({ items = [] }) => {
  return items.map((item) => {
    return (
      <StyledCardWrapper
        key={item?.guid}
        onClick={() => {
          history.push(
            generatePath(APP_PROFILES.DETAILS, { uid: item.metadata.uid })
          );
        }}
      >
        <ProfileHeadingWrapper>
          <ClusterProfileType data={item} />
          <span>{item?.metadata?.name}</span>
        </ProfileHeadingWrapper>
        <StyledDescription>
          {item?.metadata?.annotations?.description}
        </StyledDescription>
        <ProfileFooterWrapper>
          <ProfileCardTags scope={item.metadata?.annotations?.scope} />
          <Tags tags={item?.metadata?.labels} />
        </ProfileFooterWrapper>
      </StyledCardWrapper>
    );
  });
};

const ConnectedCards = connectComponent(Cards);

const AppProfileWidget = ({ count }) => {
  return (
    <>
      <WidgetHeader
        totalCount={count}
        createButton={<CreateAppProfileButton />}
        title="App Profiles"
      />
      <StyledWidgetWrapper>
        <StyledCardsWrapper>
          <AppProfileListing />
        </StyledCardsWrapper>
        <ViewAllRedirectButton path={generatePath(APP_PROFILES.ROOT)} />
      </StyledWidgetWrapper>
    </>
  );
};

export default connect((state) => ({
  count: getTotalCountsOfAppProfiles(state),
}))(AppProfileWidget);
