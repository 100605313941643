import React from "react";
import Switch from "components/common/Switch";

import Helm from "./Helm";
import Manifest from "./Manifest";
import DBOperatorForm from "./DBOperator";
import ContainerForm from "./Container";

import { useBinder } from "modules/binder";

export default function Forms() {
  const module = useBinder();
  const type = module.form.state.initialData.type;

  return (
    <Switch value={type}>
      <Switch.Case value="helm">
        <Helm />
      </Switch.Case>
      <Switch.Case value="manifest">
        <Manifest />
      </Switch.Case>
      <Switch.Case value="operator-instance">
        <DBOperatorForm />
      </Switch.Case>
      <Switch.Case value="container">
        <ContainerForm />
      </Switch.Case>
    </Switch>
  );
}
