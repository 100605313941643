import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SVG_CACHE = {};

const SVGWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  svg {
    height: 20px;
    width: 20px;
    rect {
      display: none;
    }
    path {
      fill: ${(props) => props.color};
    }
  }
`;

export function SVGLayerIcon({ path, color }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!path) {
      return;
    }

    async function getIcon() {
      if (SVG_CACHE[path]) {
        return setData(SVG_CACHE[path]);
      }
      const data = await fetch(path);
      const text = await data.text();
      SVG_CACHE[path] = text;
      setData(text);
    }
    getIcon();
  }, [path]);

  if (!data) {
    return <SVGWrap />;
  }

  return <SVGWrap dangerouslySetInnerHTML={{ __html: data }} color={color} />;
}
