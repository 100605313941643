export const initialState = {
  isLoading: true,
  currentWorkspaceGuid: null,
};

export function FETCH_WORKSPACE_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function FETCH_WORKSPACE_FAILURE(nextState) {
  nextState.isLoading = false;
  nextState.currentWorkspaceGuid = null;
}

export function FETCH_WORKSPACE_NORMALIZATION(nextState, { result }) {
  nextState.currentWorkspaceGuid = result;
  nextState.isLoading = false;
}

export function REFRESH_WORKSPACE_NORMALIZATION(nextState, { result }) {
  nextState.currentWorkspaceGuid = result;
}
