import dataFetcher from "modules/dataFetcher";
import moment from "moment";
import api from "services/api";
import { GENERAL_DATE_FORMAT } from "utils/constants";

export const USAGE_COST_FILTERS = "usagecost";

export const workspacesCostsFetcher = dataFetcher({
  selectors: ["workspacesCosts"],
  async fetchData(
    _,
    query = { startTime: moment().subtract(7, "days"), endTime: moment() }
  ) {
    const startTime = moment(query.startTime)
      .startOf("day")
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const endTime = moment(query.endTime)
      .endOf("day")
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const periodPerDay = 60 * 24;
    const payload = {
      filter: {
        startTime,
        endTime,
        includeControlPlaneMachines: true,
      },
      options: {
        groupBy: "workspace",
        period: periodPerDay,
      },
    };

    const response = await api.post(
      "v1/dashboard/spectroclusters/resources/cost",
      payload
    );

    return response?.resources || [];
  },
});

export const workspacesUsageFetcher = dataFetcher({
  selectors: ["workspacesUsage"],
  async fetchData(
    _,
    query = { startTime: moment().subtract(7, "days"), endTime: moment() }
  ) {
    const startTime = moment(query.startTime)
      .startOf("day")
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const endTime = moment(query.endTime)
      .endOf("day")
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const periodPerDay = 60 * 24;
    const payload = {
      filter: {
        startTime,
        endTime,
      },
      options: {
        groupBy: "workspace",
        period: periodPerDay,
      },
    };

    const response = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      payload
    );

    return response?.resources || [];
  },
});

export const workspacesFetcher = dataFetcher({
  selectors: ["workspaces", "meta"],
  async fetchData() {
    const response = await api.get("v1/dashboard/workspaces");
    return (response?.items || []).map((item) => ({
      label: item.metadata?.name,
      value: item.metadata?.uid,
    }));
  },
});
