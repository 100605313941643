import { v4 as uuid } from "uuid";

import createFormActions from "modules/form/actions";
import store from "services/store";

import {
  getPresentedClusterFilters,
  getClusterFiltersAvailableOptions,
} from "state/cluster/selectors/filters";
import { getSelectedFilterTag } from "state/cluster/selectors/filters";
import { clusterListActions } from "state/cluster/actions/list/clusters";

import { createListFiltersFormFactory } from "modules/listFilters";
import { getClustersListState } from "../selectors/list";
import { getCurrentProjectID } from "state/auth/selectors/common";

export const ADD_FILTER_FORM_MODULE = "addFilter";

const getReadableValues = (availableOptions = {}, condition = {}) => {
  const selectedProperty = availableOptions[condition.property];
  if (!selectedProperty) {
    return;
  }

  const availableValues = selectedProperty?.values || [];
  const conditionValues = condition.value || condition.values;

  return conditionValues.map((value) => {
    return availableValues.find(({ uid }) => uid === value)?.name;
  });
};

export const addFilterFormActions = createFormActions({
  init() {
    return Promise.resolve({
      conjunction: "and",
      conditions: [
        {
          property: "",
          operator: "",
          values: "",
        },
      ],
    });
  },
  async submit(data) {
    const state = store.getState();
    const presentedFilters = getPresentedClusterFilters(state);
    const filtersList = getClustersListState(state)?.query?.filterGroups || [];
    const availableOptions = getClusterFiltersAvailableOptions(state);
    const currentProjectId = getCurrentProjectID(state);
    const module = `clusters/${currentProjectId}`;

    const filterGroup = {
      guid: uuid(),
      conjunction: data.conjunction,
      conditions: data.conditions.map((condition) => ({
        ...condition,
        displayName: presentedFilters[condition.property].displayName,
        type: presentedFilters[condition.property].type,
        readableValues: getReadableValues(availableOptions, condition),
      })),
    };

    store.dispatch(
      clusterListActions.changeQuery({
        name: "filterGroups",
        value: [...filtersList, filterGroup],
        module,
      })
    );
  },
});

export const clustersListFiltersForm = createListFiltersFormFactory({
  formModule: ADD_FILTER_FORM_MODULE,
  formActions: addFilterFormActions,
  listModule: (state) => {
    const currentProjectId = getCurrentProjectID(state);
    return `clusters/${currentProjectId}`;
  },
  listActions: clusterListActions,
});

export const EDIT_CLUSTER_FILTER_FORM_MODULE = "editFilter";

export const editClusterFilterFormActions = createFormActions({
  init() {
    const filterGuid = getSelectedFilterTag(store.getState());
    const filtersList =
      getClustersListState(store.getState())?.query?.filterGroups || [];
    const filterToBeEdited = filtersList.find(
      (filter) => filter.guid === filterGuid
    );

    return Promise.resolve(filterToBeEdited);
  },
  async submit(data) {
    const state = store.getState();
    const presentedFilters = getPresentedClusterFilters(state);
    const availableOptions = getClusterFiltersAvailableOptions(state);
    const filtersList = getClustersListState(state)?.query?.filterGroups || [];
    const currentProjectId = getCurrentProjectID(state);
    const module = `clusters/${currentProjectId}`;

    const filterIndex = filtersList.findIndex(
      (filter) => filter.guid === data.guid
    );

    filtersList[filterIndex] = {
      guid: data.guid,
      conjunction: data.conjunction,
      conditions: data.conditions.map((condition) => ({
        ...condition,
        displayName: presentedFilters[condition.property].displayName,
        type: presentedFilters[condition.property].type,
        readableValues: getReadableValues(availableOptions, condition),
      })),
    };

    store.dispatch(
      clusterListActions.changeQuery({
        name: "filterGroups",
        value: filtersList,
        module,
      })
    );

    store.dispatch({
      type: "SELECT_FILTER_TAG",
      selectedFilterTag: null,
    });
  },
});

export const editClustersListFiltersForm = createListFiltersFormFactory({
  formModule: EDIT_CLUSTER_FILTER_FORM_MODULE,
  formActions: editClusterFilterFormActions,
  listModule: (state) => {
    const currentProjectId = getCurrentProjectID(state);
    return `clusters/${currentProjectId}`;
  },
  listActions: clusterListActions,
});

export function onEditFilterTag(filterGuid) {
  return (dispatch) => {
    dispatch({
      type: "SELECT_FILTER_TAG",
      selectedFilterTag: filterGuid,
    });

    dispatch(
      editClusterFilterFormActions.init({
        module: EDIT_CLUSTER_FILTER_FORM_MODULE,
      })
    );
  };
}

export function onRepaveStatusRedirect(repaveStatus) {
  const currentProjectId = getCurrentProjectID(store.getState());
  const module = `clusters/${currentProjectId}`;

  const filterGroup = {
    guid: uuid(),
    conjunction: "and",
    conditions: [
      {
        property: "repaveState",
        values: [repaveStatus],
        operator: "eq",
        displayName: "Repave Status",
        type: "string",
      },
    ],
  };

  store.dispatch(
    clusterListActions.changeQuery({
      name: "filterGroups",
      value: [filterGroup],
      module,
    })
  );
}
