import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Cascader, Input, Menu } from "antd";
import { useTranslation } from "react-i18next";

//

const StyledCascader = styled(Cascader)`
  width: 100%;
  max-width: 428px;
  input {
    padding: 8px 13px;
  }

  .ant-cascader-picker-disabled {
    padding: 0;
    input {
      padding: 8px 13px;
    }
  }
`;

const StyledMenu = styled(Menu)`
  max-height: 180px;
  overflow-y: auto;
`;

const DropdownContent = styled.div`
  position: relative;
  margin-top: 47px;
  .ant-input-search.ant-input-affix-wrapper {
    width: auto;
  }
`;

const SearchWrap = styled.div`
  padding: 7px;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  position: absolute;
  top: -47px;
  width: 100%;
`;

function getSubpath(rootValue, subfolder) {
  if (!subfolder.includes("/")) {
    return { label: "/", value: "" };
  }

  const subpath = subfolder.replace(rootValue, "");
  return { label: subpath, value: subpath };
}

function extractPaths(options = []) {
  return options.reduce((acc, folder) => {
    const rootValue = folder.value.split("/")[1];

    if (rootValue === undefined) {
      return [...acc, folder];
    }

    if (acc.find((folder) => folder.value === rootValue)) {
      return acc;
    }

    const subfolders = options
      .filter((folder) => folder.value.startsWith("/" + rootValue))
      .reduce((acc, subfolder) => {
        return [
          ...acc,
          getSubpath(rootValue, subfolder.value.replace(/^\/|\/$/g, "")),
        ];
      }, []);

    return [
      ...acc,
      {
        label: rootValue,
        value: rootValue,
        ...(subfolders.length > 1 && { children: extractPaths(subfolders) }),
      },
    ];
  }, []);
}

function getRootOptions(options = []) {
  return options?.reduce((acc, folder) => {
    const rootValue = folder.value.split("/")[0];
    if (acc.find((folder) => folder.value === rootValue)) {
      return acc;
    }

    const subfolders = options
      .filter((option) => option.value.split("/")[0] === rootValue)
      .reduce(
        (acc, subfolder) => [...acc, getSubpath(rootValue, subfolder.value)],
        []
      );

    return [
      ...acc,
      {
        label: folder.label,
        value: rootValue,
        ...(subfolders.length > 1 && { children: extractPaths(subfolders) }),
      },
    ];
  }, []);
}

function displayRender(_, selectedOptions) {
  const labelOptions = selectedOptions.reduce(
    (acc, option) => [...acc, option?.value].filter(Boolean),
    []
  );
  if (
    selectedOptions.length &&
    selectedOptions[selectedOptions.length - 1]?.value === ""
  ) {
    return labelOptions.slice(0, selectedOptions.length - 1).join("/");
  }
  return labelOptions.join("/");
}

export default function CascaderField({
  options,
  children,
  value,
  onChange,
  ...rest
}) {
  const [searchValue, onSearchChange] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);

  const { t } = useTranslation();
  const values = value === "" ? [] : value?.split("/");

  const dropdownRef = useRef();

  const parsedOptions = useMemo(() => getRootOptions(options), [options]);
  const searchedOptions = useMemo(() => {
    if (!searchValue) {
      return [];
    }

    return options.filter((option) => {
      const valueParts = option.value.split("/");
      const searchParts = [
        ...searchValue.split(" "),
        ...searchValue.split("/"),
      ];

      return searchParts
        .filter((part) => !!part)
        .some((part) => {
          return valueParts.some((valuePart) =>
            valuePart.toLowerCase().startsWith(part.toLowerCase())
          );
        });
    });
  }, [options, searchValue]);

  function onSelect({ item }) {
    onChange(item.props.value);
    setPopupVisible(false);
  }

  function renderDropdown(menus) {
    let content = menus;
    if (searchedOptions.length) {
      const selectedKeys = values.filter((value) => value).join("/");
      content = (
        <StyledMenu selectedKeys={[selectedKeys]} onClick={onSelect}>
          {searchedOptions.map((option, index) => (
            <Menu.Item
              key={option.value}
              value={option.value}
              data-qa={index}
              data-qa-value={option.label}
            >
              {option.label}
            </Menu.Item>
          ))}
        </StyledMenu>
      );
    }
    return (
      <DropdownContent ref={dropdownRef}>
        <SearchWrap>
          <Input.Search
            placeholder={t("Search Options")}
            onChange={(e) => {
              onSearchChange(e.target.value);
            }}
            value={searchValue}
          />
        </SearchWrap>
        {content}
      </DropdownContent>
    );
  }

  function onValuesChange(values) {
    const value = values.filter((value) => value).join("/");
    onChange(value);
    setPopupVisible(false);
  }

  return (
    <StyledCascader
      options={parsedOptions}
      value={values}
      onChange={onValuesChange}
      popupVisible={popupVisible}
      displayRender={displayRender}
      dropdownRender={renderDropdown}
      onPopupVisibleChange={(opened) => {
        setTimeout(() => {
          if (!opened && dropdownRef.current) {
            // HACK the dropdown root parent should have auto as width
            dropdownRef.current.parentElement.parentElement.parentElement.style.width =
              "auto";
          }
        }, 0);
        if (opened && searchValue) {
          onSearchChange("");
        }
        setPopupVisible(opened);
      }}
      {...rest}
    />
  );
}
