import React from "react";
import { useTranslation } from "react-i18next";
import { connectField } from "modules/form";
import styled from "styled-components";
import Fields, { Field } from "components/ui/Fields";
import { DatePicker } from "antd";
import moment from "moment";
import { SETTINGS } from "utils/constants/routes";
import { getAllActiveUsersFromList } from "state/users/selectors";
import { connect } from "react-redux";
import { getFullName } from "utils/presenters";

const StyledDatePicker = styled(DatePicker)`
  max-width: 428px;
  height: 40px;
`;

const ConnectedDatePicker = connectField(Field(StyledDatePicker));

function Form({ data, location, activeUsers }) {
  const { t } = useTranslation();
  const expirationDateOptions = [7, 30, 60, 90].map((period) => ({
    value: period,
    label: `${moment().add(period, "days").format("DD MMM YYYY")} (${t(
      "{{period}} days",
      { period }
    )})`,
  }));

  return (
    <>
      <Fields.Input
        data-qa="name"
        name="name"
        label={t("API Key Name")}
        placeholder={t("Type here...")}
      />
      <Fields.Input
        data-qa="description"
        name="description"
        required={false}
        placeholder={t("Type here...")}
        label={t("Description")}
      />
      {location === `/admin${SETTINGS.CREATE_API_KEY}` ? (
        <Fields.Select
          name="userUid"
          data-qa="userUid"
          placeholder={t("Select a user")}
          label={t("User Name")}
          maxTagCount={1}
          showSearch={false}
          filterOption={false}
          showArrow
          options={activeUsers}
        />
      ) : null}
      <Fields.Select
        data-qa="expiration-date"
        name="expiryDate"
        options={[
          ...expirationDateOptions,
          {
            value: "custom",
            label: t("Custom"),
          },
        ]}
        label={t("Expiration Date")}
        placeholder={t("Type here...")}
      />
      {data.expiryDate === "custom" && (
        <ConnectedDatePicker
          label={t("Custom Date")}
          name="customDate"
          disabledDate={(current) => {
            return current && current < moment();
          }}
        />
      )}
    </>
  );
}

export default connect((state) => ({
  activeUsers: getAllActiveUsersFromList(state).map((user) => ({
    label: getFullName(user),
    value: user.metadata.uid,
  })),
}))(Form);
