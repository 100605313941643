import dataFetcher from "modules/dataFetcher";
import api, { nonProjectApi } from "services/api";
import store from "services/store";
import { getClusterGroupEntity } from "../selectors/details";

export const CLUSTER_GROUPS_LIST_MODULE = "clusterGroups";
export const CLUSTER_GROUPS_FILTERS_MODULE = "clusterGroupsFilters";
export const HOST_CLUSTERS_LIST_MODULE = "hostClusters";
export const HOST_CLUSTERS_FILTERS_MODULE = "hostClustersFilters";
export const VIRTUAL_CLUSTERS_LIST_MODULE = "virtualClusters";
export const VIRTUAL_CLUSTERS_FILTERS_MODULE = "virtualClustersFilters";

export const hostClustersFetcher = dataFetcher({
  selectors: ["clusterGroup", "hostClusters"],
  async fetchData(_, query) {
    const { continue: continueToken, limit, offset } = query || {};
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const clusterGroup = getClusterGroupEntity(store.getState());
    const response = await api.post(
      `v1/dashboard/clustergroups/${clusterGroup.metadata.uid}/hostClusters?limit=${limit}&offset=${offset}${continueQueryParam}`
    );

    return response;
  },
});

export const virtualClustersFetcher = dataFetcher({
  selectors: ["clusterGroup", "virtualClusters"],
  async fetchData(_, query) {
    const { continue: continueToken, limit, offset } = query || {};
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const clusterGroup = getClusterGroupEntity(store.getState());
    const response = await api.post(
      `v1/dashboard/clustergroups/${clusterGroup.metadata.uid}/virtualClusters?limit=${limit}&offset=${offset}${continueQueryParam}`
    );

    return response;
  },
});

export const clusterGroupsFetcher = dataFetcher({
  selectors: ["clusterGroups"],
  async fetchData() {
    const response = await nonProjectApi.get(
      "v1/clustergroups/hostCluster/metadata"
    );

    return {
      items: response?.items || [],
    };
  },
});

export const clusterGroupsFetcherWithProjectId = dataFetcher({
  selectors: ["clusterGroups"],
  async fetchData() {
    const response = await api.get("v1/clustergroups/hostCluster/metadata");

    return {
      items: response?.items || [],
    };
  },
});
