import { getEntity } from "utils/entities";
import {
  ClusterGroupSchema,
  ClusterProfileTemplateSchema,
} from "utils/schemas";
import { createSelector } from "reselect";
import { PermissionService } from "services/permissions";
import { presentClusterProfileParams } from "utils/presenters";
import { COXEDGE_ENVIRONMENT } from "utils/constants";

export const getClusterGroupEntity = getEntity(
  (state) => state.clusterGroup?.details?.currentClusterGroupGuid,
  ClusterGroupSchema
);

export const getClusterGroupName = createSelector(
  getClusterGroupEntity,
  (group) => {
    return group?.metadata?.name;
  }
);

export const getClusterGroupPermissions = createSelector(
  getClusterGroupEntity,
  (group) => {
    return new PermissionService(
      group?.metadata?.annotations?.permissions?.split(",")
    );
  }
);

export const getClusterGroup = createSelector(
  (state) => state.location?.route,
  getClusterGroupEntity,
  (route, clusterGroup) => {
    if (route === "CLUSTER_GROUPS.DETAILS_OVERVIEW") {
      return clusterGroup;
    }
  }
);

export const getClusterGroupProfileParams = createSelector(
  getClusterGroup,
  (clusterGroup) => {
    const packs = (clusterGroup?.spec?.clusterProfileTemplates || []).reduce(
      (acc, template) => [...acc, ...template.packs],
      []
    );
    return presentClusterProfileParams(packs);
  }
);

export const getClusterGroupProfileTemplate = createSelector(
  getClusterGroupEntity,
  getEntity(
    (state) => state.clusterGroup.details.profiles,
    [ClusterProfileTemplateSchema]
  ),
  (clusterGroup, entityProfileTemplates) => {
    const clusterGroupProfileTemplates =
      clusterGroup.spec.clusterProfileTemplates || [];

    return entityProfileTemplates.map((profile) => {
      const profileTemplate = clusterGroupProfileTemplates.find(
        (profileTemplate) => profileTemplate.uid === profile.profileRef.uid
      );

      return {
        ...profile,
        isClusterGroupProfile: true,
        metadata: profile.profileRef,
        spec: {
          ...profile.spec,
          version: profileTemplate?.profileVersion,
          published: {
            packs: profile.spec.packs
              .map((pack) => {
                const clusterPack = (profileTemplate?.packs || []).find(
                  (templatePack) => {
                    return pack.metadata.name === templatePack.name;
                  }
                );

                if (!clusterPack) {
                  return undefined;
                }

                return {
                  ...pack,

                  isDisabled:
                    clusterGroup.spec.cloudType ===
                      COXEDGE_ENVIRONMENT.apiKey && pack.spec.layer === "k8s",

                  guid: clusterPack.guid,
                  spec: {
                    ...pack.spec,
                    manifests: clusterPack.manifests,
                    tag: clusterPack?.tag,
                  },
                };
              })
              .filter(Boolean),
          },
        },
        type: profile.spec.type,
      };
    });
  }
);
