import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import { getCluster } from "state/cluster/selectors/details";
import { StatefulSetSchema } from "utils/schemas";

export const STATEFULSETS_MODULE = "statefulsets";

export const statefulSetsListingActions = new ListActions({
  async fetchData({ filterBy = [] } = {}) {
    const cluster = getCluster(store.getState());

    const data = await api.post(
      `v1/dashboard/spectroclusters/${cluster.metadata.uid}/workloads/statefulset`,
      {
        filter: {
          namespaces: filterBy,
        },
      }
    );

    return {
      items: (data?.statefulSets || []).sort((ss1, ss2) =>
        ss1.metadata.namespace > ss2.metadata.namespace ? 1 : -1
      ),
    };
  },
  initialQuery: () => ({
    filterBy: [],
  }),
  schema: [StatefulSetSchema],
});
