import { createSelector } from "reselect";
import { nonFipsSettingsFetcher } from "./services";

export const getContextNonFipsAddonPacksEnabled = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result?.fipsPackConfig?.mode === "nonFipsEnabled"
);

export const getContextNonFipsFeaturesEnabled = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result?.fipsClusterFeatureConfig?.mode === "nonFipsEnabled"
);

export const getContextNonFipsClusterImportEnabled = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result?.fipsClusterImportConfig?.mode === "nonFipsEnabled"
);

export const getContextNonFipsSettings = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result
);

export const getNonFipsFeaturesVisibility = createSelector(
  (state) => state.auth.currentOrganization?.securityMode,
  getContextNonFipsFeaturesEnabled,
  (securityMode, isNonFipsFeaturesEnabled) => {
    if (securityMode !== "fips") return true;
    return isNonFipsFeaturesEnabled;
  }
);

export const getNonFipsClusterImportVisibility = createSelector(
  (state) => state.auth.currentOrganization?.securityMode,
  getContextNonFipsClusterImportEnabled,
  (securityMode, isNonFipsClusterImportEnabed) => {
    if (securityMode !== "fips") return true;
    return isNonFipsClusterImportEnabed;
  }
);
