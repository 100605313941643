import querystring from "query-string";

import store from "services/store";
import history from "services/history";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import { ClusterSchema } from "utils/schemas";
import {
  virtualClustersFetcher,
  VIRTUAL_CLUSTERS_LIST_MODULE,
} from "state/clustergroups/services/listing";
import { getVirtualClustersListQuery } from "state/clustergroups/selectors/listing";

export const virtualClustersListActions = new ListActions({
  hasPagination: true,
  schema: [ClusterSchema],
  initialQuery: () => {
    const query = history.getQuery();

    return {
      clusterName: query?.clusterName || "",
      sortField: query?.sortField || "",
      sortOrder: query?.sortOrder || "",
      limit: 20,
    };
  },
  dataFetcher: virtualClustersFetcher,
});

export const virtualClustersFiltersFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();

    return Promise.resolve({
      clusterName: query?.clusterName || "",
    });
  },
  submit: (data) => {
    const query = getVirtualClustersListQuery(store.getState());
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);

    store.dispatch(
      virtualClustersListActions.batchChangeQuery({
        module: VIRTUAL_CLUSTERS_LIST_MODULE,
        query: {
          ...query,
          clusterName: data.clusterName,
        },
      })
    );
  },
});
