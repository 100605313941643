import React from "react";
import { Slider as AntSlider } from "antd";
import styled, { css } from "styled-components";
import { lavender, lightLavender } from "utils/constants/colors";

const StyledSlider = styled(AntSlider)`
  &.ant-slider-with-marks {
    margin: 28px 6px 10px 6px;
  }

  .ant-slider-mark-text {
    pointer-events: none;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #3e4856;
    white-space: nowrap;
  }
  ${(props) =>
    props.withLabels &&
    css`
      .ant-slider-mark-text {
        transform: translateX(0) !important;
        &:last-child {
          transform: translateX(-100%) !important;
        }
      }

      .ant-slider-dot {
        display: none;
      }
    `}

  .ant-slider-handle {
    width: 16px;
    height: 16px;
    margin-top: -6px;
  }

  &:hover,
  & {
    .ant-slider-track {
      background-color: ${lightLavender};
    }

    .ant-slider-handle {
      border: 2px solid ${lavender}!important;
    }
  }

  .ant-slider-mark {
    top: -19px;
  }
`;

export default function Slider({ marks, leftLabel, rightLabel, ...rest }) {
  const marksFromLevels = {
    0: leftLabel,
    100: rightLabel,
  };

  const withLabels = leftLabel || rightLabel;

  return (
    <StyledSlider
      {...rest}
      withLabels={withLabels}
      marks={marks || marksFromLevels}
      {...(withLabels ? { tooltipVisible: false } : {})}
    />
  );
}
