import React, { createContext } from "react";
import { useLocation, useParams, withRouter } from "react-router";

export const ProjectContext = createContext();

const ProjectContextProvider = withRouter(function ({ children }) {
  const params = useParams();
  const location = useLocation();

  if (location.pathname.startsWith("/admin/project/") && params.projectUid) {
    return (
      <ProjectContext.Provider value={params.projectUid}>
        {children}
      </ProjectContext.Provider>
    );
  }

  return children;
});

export default ProjectContextProvider;
