import React, { useRef, createContext, useContext } from "react";
import useMeasure from "react-use-measure";

export function usePrevious(value) {
  const currentRef = useRef(value);
  const previousRef = useRef();
  if (currentRef.current !== value) {
    previousRef.current = currentRef.current;
    currentRef.current = value;
  }
  return previousRef.current;
}

export function watchBounds() {
  const Context = createContext();

  return {
    Provider({ children }) {
      const [ref, bounds] = useMeasure();

      return (
        <Context.Provider value={{ ref, bounds }}>{children}</Context.Provider>
      );
    },
    useRef() {
      return useContext(Context)?.ref;
    },
    useBounds() {
      return useContext(Context)?.bounds;
    },
  };
}
