export const initialState = {};

const moduleState = {
  currentStep: 0,
  steps: [],
};

export function WIZARD__NEXT_STEP(nextState, { module }, state) {
  nextState[module].currentStep = state[module].currentStep + 1;
}

export function WIZARD__PREV_STEP(nextState, { module }, state) {
  nextState[module].currentStep = state[module].currentStep - 1;
}

export function WIZARD__SELECT_STEP(nextState, { step, module }) {
  nextState[module].currentStep = step;
}

export function WIZARD__SET_STEP_DESCRIPTION(
  nextState,
  { step, description, module }
) {
  nextState[module].steps[step].description = description;
}

export function WIZARD__UPDATE_STEPS(nextState, { steps, module }) {
  nextState[module].steps = steps;
}

export function WIZARD__INITIALIZE(nextState, { steps, module }) {
  nextState[module] = {
    ...moduleState,
    steps: steps || moduleState.steps,
  };
}
