import React, { createContext, useContext, useEffect, useState } from "react";
import { menu } from "services/localstorage/cache";

const MenuContext = createContext();

export function useMenu() {
  return useContext(MenuContext);
}

export default function MenuProvider({ children }) {
  const lsMenuExpanded = menu.get("expanded");
  const defaultExpanded = lsMenuExpanded === null ? true : lsMenuExpanded;

  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    menu.set("expanded", expanded);
  }, [expanded]);

  return (
    <MenuContext.Provider
      value={{
        expanded,
        setExpanded,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
