import i18n from "i18next";

import history from "services/history";
import api, { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import {
  overlordConfigureModal,
  datacentersFetcher,
  setOverlordNodesModal,
} from "../services/list";

import {
  OVERLORD_CLOUD_MODULE,
  OVERLORD_ACCOUNT_MODULE,
} from "state/overlord/services/list";
import { ipamFormActions } from "./ipam";
import { IPAM_MODULE } from "../services/ipam";
import { getIpamFormPayload } from "../selectors/ipam";
import i18next from "i18next";
import { dnsMappingsFetcher } from "state/dns/services";
import {
  openstackOverlordAccountFormActions,
  openStackOverlordCloudFormActions,
  overlordCloudForm,
} from "./configure/openstack";
import {
  overlordAccountFormActions,
  overlordCloudFormActions,
} from "./configure/vsphere";
import {
  maasOverlordAccountFormActions,
  maasOverlordCloudForm,
  maasOverlordCloudFormActions,
} from "./configure/maas";

//

const STEPS = ["account", "config", "ipam"];

export function fetchOverlordConfiguration(uid) {
  return function thunk(dispatch) {
    const promise = api.get(`v1/overlords/${uid}`);
    dispatch({
      type: "FETCH_OVERLORD_CONFIG",
      promise,
    });
    return promise;
  };
}

const overlordAccountFormModule = {
  vsphere: overlordAccountFormActions,
  openstack: openstackOverlordAccountFormActions,
  maas: maasOverlordAccountFormActions,
};

const overlordCloudFormModule = {
  vsphere: overlordCloudFormActions,
  openstack: openStackOverlordCloudFormActions,
  maas: maasOverlordCloudFormActions,
};

export function openOverlordConfigureModal(uid, cloudType) {
  return async (dispatch, getState) => {
    dispatch({ type: "RESET_OVERLORD_CONFIG_STEP" });

    dispatch(
      overlordAccountFormModule[cloudType].init({
        module: OVERLORD_ACCOUNT_MODULE,
      })
    );

    overlordConfigureModal.open({ uid, cloudType }).then(
      async () => {
        const state = getState();
        const { step, configureIPPoolUid } = state.overlord.configure;
        const cloudFormData = state.forms[OVERLORD_CLOUD_MODULE]?.data;
        const cloudPropertiesFetcher = {
          openstack: overlordCloudForm.effects.fetchProperties,
          maas: maasOverlordCloudForm.effects.fetchProperties,
        };

        if (step === "account") {
          await dispatch(
            overlordAccountFormModule[cloudType].submit({
              module: OVERLORD_ACCOUNT_MODULE,
            })
          );

          await dispatch(
            overlordCloudFormModule[cloudType].init({
              module: OVERLORD_CLOUD_MODULE,
            })
          );

          if (Object.keys(cloudPropertiesFetcher).includes(cloudType)) {
            cloudPropertiesFetcher[cloudType]();
          }

          return Promise.reject();
        }

        if (step === "ipam") {
          const errors = await dispatch(
            ipamFormActions.validateForm({ module: IPAM_MODULE })
          );
          if (errors.length) {
            return Promise.reject();
          }
          const method = configureIPPoolUid ? "put" : "post";
          const poolsPath = configureIPPoolUid
            ? `pools/${configureIPPoolUid}`
            : "pools";

          try {
            const result = await nonProjectApi[method](
              `v1/overlords/vsphere/${overlordConfigureModal.data.uid}/${poolsPath}`,
              getIpamFormPayload(state)
            ).then((res) => res?.uid);

            if (!configureIPPoolUid) {
              dispatch({
                type: "CONFIGURE_IPAM_EDIT_MODE",
                configureIPPoolUid: result,
              });
            }
          } catch (err) {
            notifications.error({
              message: i18next.t(
                "Something went wrong while creating the ip pool"
              ),
              description: err.message,
            });
            return Promise.reject(err);
          }
        }

        if (step === "config" && cloudFormData?.networkType === "staticIP") {
          const errors = await dispatch(
            overlordCloudFormModule[cloudType].validateForm({
              module: OVERLORD_CLOUD_MODULE,
            })
          );

          if (errors.length) {
            return Promise.reject();
          }

          dispatch({ type: "CONFIGURE_IPAM_STEP" });
          await dispatch(ipamFormActions.init({ module: IPAM_MODULE }));

          const ipamData = await nonProjectApi
            .get(`v1/overlords/vsphere/${uid}/pools`)
            .then((data) => data.items?.[0]);

          dispatch({
            type: "CONFIGURE_IPAM_EDIT_MODE",
            configureIPPoolUid: ipamData ? ipamData.metadata.uid : null,
          });

          if (!ipamData) {
            dispatch(
              ipamFormActions.onChange({
                module: IPAM_MODULE,
                name: "name",
                value: "cloud-gateway-ip-pool",
              })
            );
          } else {
            dispatch(
              ipamFormActions.batchChange({
                module: IPAM_MODULE,
                updates: {
                  ...(ipamData?.spec || {}),
                  ...(ipamData?.spec?.pool || {}),
                  mode: ipamData?.spec?.pool?.subnet ? "subnet" : "range",
                  name: ipamData?.metadata?.name,
                  nameserverAddresses:
                    ipamData?.spec?.pool?.nameserver?.addresses || [],
                  nameserverSearch:
                    ipamData?.spec?.pool?.nameserver?.search || [],
                  cidrPrefix: ipamData?.spec?.pool?.prefix,
                },
              })
            );
          }
          return Promise.reject();
        }

        await dispatch(
          overlordCloudFormModule[cloudType].submit({
            module: OVERLORD_CLOUD_MODULE,
          })
        );

        history.push("/settings/privatecloudgateways");
      },
      () => history.push("/settings/privatecloudgateways")
    );
    dispatch(dnsMappingsFetcher.fetch());
  };
}

export function openSetOverlordNodesModal(uid, cloudType) {
  return async (dispatch, getState) => {
    const cloudTypePropertiesFetchers = {
      vsphere: () => dispatch(datacentersFetcher.fetch()),
      openstack: () => {
        dispatch(overlordCloudForm.fetchers.regionsFetcher.fetch());
        dispatch(overlordCloudForm.fetchers.projectsFetcher.fetch());
        overlordCloudForm.effects.fetchProperties();
      },
      maas: () => {
        maasOverlordCloudForm.effects.fetchProperties();
      },
    };

    setOverlordNodesModal.open({ uid, cloudType }).then(
      async () => {
        await dispatch(
          overlordCloudFormModule[cloudType].submit({
            module: OVERLORD_CLOUD_MODULE,
          })
        );

        history.push("/settings/privatecloudgateways");
      },
      () => history.push("/settings/privatecloudgateways")
    );

    await dispatch(fetchOverlordConfiguration(uid));

    await dispatch(
      overlordCloudFormModule[cloudType].init({ module: OVERLORD_CLOUD_MODULE })
    );

    cloudTypePropertiesFetchers[cloudType]();
  };
}

export function validateOverlordAccount() {
  return async (dispatch, getState) => {
    const formData = getState().forms[OVERLORD_ACCOUNT_MODULE]?.data;
    const cloudType = overlordConfigureModal?.data?.cloudType;

    const payload = {
      vsphere: () => ({
        account: {
          vcenterServer: formData.vcenterServer,
          username: formData.username,
          password: formData.password,
          insecure: formData.insecure,
        },
      }),
      openstack: () => ({
        account: {
          caCert: !formData.insecure ? formData.caCert.trim() : undefined,
          defaultDomain: formData.defaultDomain,
          defaultProject: formData.defaultProject,
          identityEndpoint: formData.identityEndpoint,
          insecure: formData.insecure,
          parentRegion: formData.parentRegion,
          password: formData.password,
          username: formData.username,
        },
      }),
      maas: () => ({
        account: {
          apiEndpoint: formData.apiEndpoint,
          apiKey: formData.apiKey,
        },
      }),
    };

    const errors = await dispatch(
      overlordAccountFormModule[cloudType].validateForm({
        module: OVERLORD_ACCOUNT_MODULE,
      })
    );
    if (errors.length) {
      return Promise.reject();
    }

    const validatePromise = api.post(
      `v1/overlords/${cloudType}/${overlordConfigureModal.data.uid}/account/validate`,
      payload[cloudType]()
    );
    dispatch({
      type: "VALIDATE_OVERLORD_ACCOUNT",
      promise: validatePromise,
    });
    try {
      await validatePromise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to validate your account"
        ),
        description: err.message,
      });
      return;
    }
  };
}

export function prevStep() {
  return function thunk(dispatch, getState) {
    const step = getState().overlord.configure.step;

    const prevStepIndex = STEPS.indexOf(step);
    if (prevStepIndex !== -1) {
      const prevStep = STEPS[prevStepIndex - 1];

      if (prevStep === "account") {
        dispatch(fetchOverlordConfiguration(overlordConfigureModal.data.uid));
      }

      dispatch({
        type: "OVERLORD_CONFIGURE_PREV_STEP",
        step: prevStep,
      });
    }
  };
}
