import React, { useState } from "react";

export default function BackedUpImage({
  alt = "",
  src = "",
  fallbackSrc = "",
}) {
  const [hasError, setHasError] = useState(false);
  const imgSrc = hasError ? fallbackSrc : src;

  function onError(e) {
    setHasError(true);
  }

  return <img alt={alt} onError={onError} src={imgSrc} />;
}
