import moment from "moment";

import api from "services/api";
import dataFetcher from "modules/dataFetcher";
import ModalService from "services/modal";
import { getStoreEntity } from "services/store";

import { podsListingActions, PODS_MODULE } from "./pods";
import { deploymentsListingActions, DEPLOYMENTS_MODULE } from "./deployments";
import { daemonSetsListingActions, DAEMONSETS_MODULE } from "./daemonsets";
import {
  statefulSetsListingActions,
  STATEFULSETS_MODULE,
} from "./statefulsets";
import { jobsListingActions, JOBS_MODULE } from "./jobs";
import { cronjobsListingActions, CRONJOBS_MODULE } from "./cronjobs";
import { namespacesListingActions, NAMESPACES_MODULE } from "./namespaces";
import {
  roleBindingsListingActions,
  ROLE_BINDINGS_MODULE,
} from "./rolebindings";
import {
  clusterRoleBindingsListingActions,
  CLUSTER_ROLE_BINDINGS_MODULE,
} from "./clusterrolebindings";
import { getClusterGroupEntity } from "state/clustergroups/selectors/details";
import { WORKLOAD_SCHEMAS } from "state/cluster/services/workloads";

import { GENERAL_DATE_FORMAT, WORKLOADS_TIME_PERIODS } from "utils/constants";
import { presentWorkloadChartData } from "utils/presenters";

export const clusterWorkspaceSelectedWorkloadModal = new ModalService(
  "clusterWorkspaceWorkloadDetails"
);

export const clusterWorkspaceSelectedWorkloadUsageFetcher = dataFetcher({
  selectors: [
    "clusterWorkspace",
    (state) => getClusterGroupEntity(state)?.metadata.uid,
    (state) => state.location.params?.flag,
    () => clusterWorkspaceSelectedWorkloadModal?.data?.guid,
    "barChartData",
  ],
  fetchData: async ([_1, workspaceUid, currentTab, workloadGuid], filters) => {
    const { timeRange, clusters, namespaces } = filters || {};
    const [unit, unitType] = timeRange.split(" ");
    const period = WORKLOADS_TIME_PERIODS[timeRange].period;
    const workloadEntity = getStoreEntity(
      workloadGuid,
      WORKLOAD_SCHEMAS[currentTab]
    );

    const workloadType = currentTab.substring(0, currentTab.length - 1);
    const isPodWorkload = workloadType === "pod";

    const data = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      {
        filter: {
          startTime: moment()
            .subtract(unit, unitType)
            .utc()
            .format(GENERAL_DATE_FORMAT),
          endTime: moment().utc().format(GENERAL_DATE_FORMAT),
          workspaces: [workspaceUid],
          clusters,
          namespaces,
          includeControlPlaneMachines: true,
          workload: isPodWorkload
            ? {}
            : {
                type: workloadType,
                names: [workloadEntity.metadata.entity.name],
              },
          pods: isPodWorkload
            ? [workloadEntity.metadata.entity.name]
            : undefined,
        },
        options: {
          groupBy: workloadType,
          period,
        },
      }
    );

    return presentWorkloadChartData({ data, timeRange });
  },
});

export const clusterWorkspaceWorkloadUsageFetcher = dataFetcher({
  selectors: [
    "clusterWorkspace",
    (state) => getClusterGroupEntity(state)?.metadata.uid,
    (state) => state.location.params?.flag,
    "barChartData",
  ],
  fetchData: async ([_1, workspaceUid, workloadTab, _3], filters) => {
    const { timeRange, namespaces = [], clusters = [] } = filters || {};
    const [unit, unitType] = timeRange.split(" ");
    const period = WORKLOADS_TIME_PERIODS[timeRange].period;

    const data = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      {
        filter: {
          startTime: moment()
            .subtract(unit, unitType)
            .utc()
            .format(GENERAL_DATE_FORMAT),
          endTime: moment().utc().format(GENERAL_DATE_FORMAT),
          workload: {
            type:
              workloadTab === "pods"
                ? undefined
                : workloadTab.substring(0, workloadTab.length - 1),
          },
          workspaces: [workspaceUid],
          namespaces,
          clusters,
          includeControlPlaneMachines: true,
        },
        options: {
          groupBy: "workspace",
          period,
        },
      }
    );

    return presentWorkloadChartData({ data, timeRange });
  },
});

export const clusterGroupsWorkloadsModules = {
  pods: {
    listActions: podsListingActions,
    module: PODS_MODULE,
  },
  deployments: {
    listActions: deploymentsListingActions,
    module: DEPLOYMENTS_MODULE,
  },
  daemonsets: {
    listActions: daemonSetsListingActions,
    module: DAEMONSETS_MODULE,
  },
  statefulsets: {
    listActions: statefulSetsListingActions,
    module: STATEFULSETS_MODULE,
  },
  jobs: {
    listActions: jobsListingActions,
    module: JOBS_MODULE,
  },
  cronjobs: {
    listActions: cronjobsListingActions,
    module: CRONJOBS_MODULE,
  },
  namespaces: {
    listActions: namespacesListingActions,
    module: NAMESPACES_MODULE,
  },
  rolebindings: {
    listActions: roleBindingsListingActions,
    module: ROLE_BINDINGS_MODULE,
  },
  clusterrolebindings: {
    listActions: clusterRoleBindingsListingActions,
    module: CLUSTER_ROLE_BINDINGS_MODULE,
  },
};

export function initializeClusterGroupWorkloadsByTab(tab) {
  return (dispatch) => {
    const tabItem = clusterGroupsWorkloadsModules?.[tab];
    if (!tabItem) {
      return;
    }
    dispatch(tabItem.listActions.initialize(tabItem.module));
  };
}
