export const initialState = {
  opened: [],
  data: {},
};

export function OPEN_MODAL(nextState, { guid, payload }, state) {
  nextState.data[guid] = payload;
  if (state.opened.includes(guid)) {
    return;
  }
  nextState.opened.push(guid);
}

export function CLOSE_MODAL(nextState, { guid }, state) {
  const index = state.opened.indexOf(guid);
  delete nextState.data[guid];
  if (index === -1) {
    return;
  }
  nextState.opened.splice(index, 1);
}
