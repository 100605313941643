import React from "react";
import styled from "styled-components";
import { Tree } from "antd";
import { white } from "utils/constants/colors";

const ListTreeWrapper = styled.div`
  .ant-tree {
    background: #f2fffc;
    padding: 4px;
    border-radius: 0;

    .ant-tree-list {
      border-radius: 2px;
      background: #d3f7ed;
    }

    .ant-tree-list-holder {
      padding: 4px;
    }

    .ant-tree-treenode {
      width: 100%;
      height: 40px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 2px;

      :last-child {
        margin-bottom: 0;
      }
    }

    .ant-tree-node-content-wrapper {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
    }

    .ant-tree-node-selected {
      background-color: ${white} !important;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    }

    .ant-tree-title {
      width: 100%;
    }

    .ant-tree-switcher {
      display: none;
    }
  }
`;

function ListTree({ onSelect, onDrop, treeData, selectedKey, expandedKeys }) {
  if (!treeData || treeData?.length === 0) {
    return null;
  }

  return (
    <ListTreeWrapper>
      <Tree
        draggable
        expandedKeys={expandedKeys}
        onSelect={onSelect}
        onDrop={onDrop}
        treeData={treeData}
        selectedKeys={[selectedKey]}
      />
    </ListTreeWrapper>
  );
}

export default ListTree;
