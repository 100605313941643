import ListActions from "modules/list/actions";
import api from "services/api";
import ModalService from "services/modal";
import { AppDeploymentSchema } from "utils/schemas";

export const DEPLOYMENTS_LIST_MODULE = "appDeployments";
export const deleteDeploymentModal = new ModalService();

const deploymentListActionsFactory = (limit = 20) => {
  return {
    hasPagination: true,
    initialQuery() {
      return {
        limit,
      };
    },
    fetchData: async (query) => {
      const { offset, limit, continue: continueToken } = query;
      const continueQueryParam = continueToken
        ? `&continue=${continueToken}`
        : "";

      const payload = {
        sort: [
          {
            field: "lastModifiedTimestamp",
            order: "desc",
          },
        ],
      };

      const data = await api.post(
        `v1/dashboard/appDeployments?limit=${limit}&offset=${offset}${continueQueryParam}`,
        payload
      );

      return { ...data, items: data?.appDeployments || [] };
    },
    schema: [AppDeploymentSchema],
  };
};

export const appDeploymentsListActions = new ListActions(
  deploymentListActionsFactory()
);

export const appDeploymentsListActionsForDashboard = new ListActions(
  deploymentListActionsFactory(5)
);
