import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import { getCluster } from "state/cluster/selectors/details";
import { WorkloadNamespaceSchema } from "utils/schemas";

export const NAMESPACES_MODULE = "namespaces";

export const namespacesFetcher = dataFetcher({
  selectors: ["namespaces", "consumption"],
  async fetchData(_, { filterBy = [] } = {}) {
    const cluster = getCluster(store.getState());

    const data = await api.post(
      `v1/dashboard/spectroclusters/${cluster.metadata.uid}/workloads/namespace`,
      {
        filter: {
          namespaces: filterBy,
        },
      }
    );

    return {
      items: data.namespaces.sort((ns1, ns2) =>
        ns1.metadata?.entity?.name > ns2.metadata?.entity?.name ? 1 : -1
      ),
    };
  },
});

export const namespacesNamesFetcher = dataFetcher({
  selectors: ["namespaces", "names"],
  async fetchData() {
    const cluster = getCluster(store.getState());

    const data = await api.post(
      `v1/dashboard/spectroclusters/${cluster.metadata.uid}/workloads/namespace`
    );

    return {
      items: data.namespaces.sort((ns1, ns2) =>
        ns1.metadata?.entity?.name > ns2.metadata?.entity?.name ? 1 : -1
      ),
    };
  },
});

export const namespacesListingActions = new ListActions({
  dataFetcher: namespacesFetcher,
  initialQuery: () => ({
    filterBy: [],
  }),
  schema: [WorkloadNamespaceSchema],
});

export const clusterNamespacesConfigFetcher = dataFetcher({
  selectors: [
    "clusterNamespacesConfig",
    (state) => getCluster(state)?.metadata?.uid,
  ],
  async fetchData([_, clusterUid]) {
    const response = await api.get(
      `v1/spectroclusters/${clusterUid}/config/namespaces`
    );
    return response?.items || [];
  },
});
