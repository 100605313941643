import dataFetcher from "modules/dataFetcher";

import {
  OCIRegistriesSchema,
  PackRegistriesSchema,
  HelmRegistriesSchema,
} from "utils/schemas";

import ModalService from "services/modal";
import api from "services/api";

export const HELM_MODULE = "helmregistries";
export const OCI_MODULE = "ociregistries";
export const PACK_MODULE = "packregistries";
export const helmRegistryModal = new ModalService("helmRegistryModal");
export const ociRegistryModal = new ModalService("ociRegistryModal");
export const packRegistryModal = new ModalService("packRegistryModal");

export const packRegistryFetcher = dataFetcher({
  selectors: ["packregistries", () => packRegistryModal.data?.uid || ""],
  schema: PackRegistriesSchema,
  async fetchData([_, uid]) {
    return await api.get(`v1/registries/pack/${uid}`);
  },
});

export const helmRegistryFetcher = dataFetcher({
  selectors: ["helmregistries", () => helmRegistryModal.data?.uid || ""],
  schema: HelmRegistriesSchema,
  async fetchData([_, uid]) {
    return await api.get(`v1/registries/helm/${uid}`);
  },
});

export const ociRepositoryFetcher = dataFetcher({
  selectors: [
    "ociregistries",
    () => ociRegistryModal.data?.uid || "",
    () => ociRegistryModal.data?.ociType || "",
  ],
  schema: OCIRegistriesSchema,
  async fetchData([_, uid, ociType]) {
    const data = await api.get(`v1/registries/oci/${uid}/${ociType}`);
    return {
      ...data,
      type: ociType,
    };
  },
});
