export const initialState = {
  selectedEntityId: null,
  selectedProjectRoleId: null,
  loading: false,
  entityType: null,
};

export function SET_SELECTED_ENTITY(
  nextState,
  { selectedEntityId, entityType }
) {
  nextState.selectedEntityId = selectedEntityId;
  nextState.entityType = entityType;
}

export function SET_SELECTED_PROJECT_ROLES(
  nextState,
  { selectedProjectRoleId }
) {
  nextState.selectedProjectRoleId = selectedProjectRoleId;
}

export function REMOVE_TENANT_ROLE_REQUEST(nextState) {
  nextState.loading = true;
}

export function REMOVE_TENANT_ROLE_SUCCESS(nextState) {
  nextState.loading = false;
}

export function REMOVE_TENANT_ROLE_FAILURE(nextState) {
  nextState.loading = false;
}
