import { createSelector } from "reselect";
export const getCurrentStep = (module) => (state) =>
  state.wizard?.[module]?.currentStep;

export const getCheckpointStep = (module) =>
  createSelector(
    (state) => state.wizard?.[module]?.steps || [],
    (steps) => steps.findIndex((step) => !step.description)
  );

export const getSteps = (module) =>
  createSelector(
    (state) => state.wizard[module].steps,
    getCurrentStep(module),
    getCheckpointStep(module),
    (state) => state.language.language,
    (steps, currentStep, checkpointStep) => {
      const checkStepStatus = (index) => {
        if (index === currentStep) {
          return "process";
        }

        if (index < checkpointStep) {
          return "finish";
        }

        return "wait";
      };

      return steps.map((step, index) => {
        return {
          ...step,
          title: typeof step.title === "function" ? step.title() : step.title,
          status: checkStepStatus(index),
        };
      });
    }
  );
