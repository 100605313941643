import { createConnector } from "modules/binder";
import { createSelector } from "reselect";

const toolbarConnector = createConnector({
  selectors: {
    tabs: createSelector(
      (state) => state.toolbar?.tabs || [],
      (state) => state.toolbar?.activeSelection,
      (tabs, selectedTab) => {
        return tabs.map((tab) => ({
          ...tab,
          selected: tab.id === selectedTab,
        }));
      }
    ),
  },
  actions: {
    onTabSelect: (id) => (dispatch, module) => {
      dispatch({
        type: module.actions.toolbar?.onTabSelect,
        tab: id,
      });
      const tabs = module.state.toolbar?.tabs || [];
      const selectedTab = tabs.find((tab) => tab.id === id);

      selectedTab?.onClick();
    },
  },
});

export default toolbarConnector;
