import React from "react";

import toolbarConnector from "modules/profileIDE/connectors/toolbar";
import fileListConnector from "modules/profileIDE/connectors/fileList";
import { ThreeColumnsLayout } from "modules/profileIDE/Layout";

import Toolbar from "modules/profileIDE/components/Toolbar";
import FileList from "modules/profileIDE/components/FileList";
import AppProfileEditor from "../../components/Editor";

const ConnectedToolbar = toolbarConnector.connect(Toolbar);
const ConnectedFileList = fileListConnector.connect(FileList);

export default function Layout() {
  return (
    <>
      <ThreeColumnsLayout />
      <ThreeColumnsLayout.Toolbar>
        <ConnectedToolbar />
      </ThreeColumnsLayout.Toolbar>
      <ThreeColumnsLayout.Files>
        <ConnectedFileList />
      </ThreeColumnsLayout.Files>
      <ThreeColumnsLayout.Editor>
        <AppProfileEditor />
      </ThreeColumnsLayout.Editor>
    </>
  );
}
