import { rcompare, coerce } from "semver";

export function versionSort(version1, version2, shouldCoerce = false) {
  let coercedVersion1 = version1;
  let coercedVersion2 = version2;
  if (version1.split(".").length < 3) {
    coercedVersion1 = `${version1}.0`;
  }
  if (version2.split(".").length < 3) {
    coercedVersion2 = `${version2}.0`;
  }
  if (shouldCoerce) {
    if (coerce(coercedVersion1) !== null) {
      coercedVersion1 = coerce(coercedVersion1).version;
    }
    if (coerce(coercedVersion2) !== null) {
      coercedVersion2 = coerce(coercedVersion2).version;
    }
  }
  try {
    return rcompare(coercedVersion1, coercedVersion2, true);
  } catch (e) {
    return 0;
  }
}
