export function getInitials(string = "") {
  const initials = string.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
  if (!initials) {
    return string;
  }
  return initials.join("");
}

export function limitChars(string, limit) {
  return string.slice(0, limit);
}

export function isValidRegex(string) {
  if (
    !(
      ["/", "~/"].some((start) => string.startsWith(start)) &&
      string.endsWith("/")
    )
  ) {
    return false;
  }

  let exp = string;
  if (exp.startsWith("~/")) {
    exp = string.slice(1);
  }

  try {
    new RegExp(exp);
    return true;
  } catch (e) {
    return false;
  }
}
