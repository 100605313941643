import api from "services/api";
import ModalService from "services/modal";
import notifications from "services/notifications";
import i18next from "i18next";

import {
  projectRolesListActions,
  resourceRolesListActions,
  tenantRolesListActions,
} from "state/roles/actions/list";
import { getSelectedRole } from "../selectors/details";
import { ROLES_MODULE } from "./create";

export const deleteRoleModal = new ModalService();
export const roleDetailsModal = new ModalService("roleDetailsModal");

export function selectRole(role) {
  if (role) {
    roleDetailsModal.open();
  }

  return {
    type: "SELECT_ROLE",
    guid: role?.guid,
  };
}

export function onRoleDelete() {
  return function thunk(dispatch, getState) {
    deleteRoleModal.open().then(async () => {
      const role = getSelectedRole(getState());
      const { uid, name } = role?.metadata;
      const promise = api.delete(`v1/roles/${uid}`);

      dispatch({
        type: "DELETE_ROLE",
        promise,
      });

      try {
        await promise;
        notifications.success({
          message: i18next.t("Role {{name}} was deleted successfully", {
            name,
          }),
        });
        dispatch(selectRole(null));
        const listingActions = {
          project: projectRolesListActions,
          tenant: tenantRolesListActions,
          resource: resourceRolesListActions,
        };

        const currentTab = getState().location.params.tab;

        dispatch(listingActions[currentTab].initialize(ROLES_MODULE));
        roleDetailsModal.close();
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong while deleting {{name}} role",
            { name }
          ),
          description: err.message,
        });
      }
    });
  };
}
