import React from "react";
import i18n from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import store from "services/store";

const DEFAULT_LANGUAGE = "en";

export let language = DEFAULT_LANGUAGE;
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: [DEFAULT_LANGUAGE],
    fallbackLng: DEFAULT_LANGUAGE,
    ns: ["common"],
    defaultNS: "common",
    react: {
      useSuspense: import.meta.env.MODE !== "test",
      transKeepBasicHtmlNodesFor: "",
    },
    backend: {
      loadPath: "/i18n/{{lng}}/{{ns}}.json",
      addPath: "/i18n/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: false,
    keySeparator: false,
  });
i18n.on("initialized", () => {
  store.dispatch({ type: "SET_LANGUAGE", language: i18n.language });
});
function createI18nInstance(namespace) {
  return i18n.cloneInstance({
    ns: [namespace, "common"],
    defaultNS: namespace,
  });
}

const i18nInstances = [];
export default function createNamespace({ namespace, Component }) {
  const i18n = createI18nInstance(namespace);
  i18nInstances.push(i18n);

  return function I18Provider(props) {
    return (
      <I18nextProvider i18n={i18n}>
        <Component {...props} />
      </I18nextProvider>
    );
  };
}

export function changeLanguage(lng) {
  language = lng;
  i18n.changeLanguage(lng);
  i18nInstances.forEach((i18n) => i18n.changeLanguage(lng));
  store.dispatch({ type: "SET_LANGUAGE", language: lng });
}
