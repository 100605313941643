import ListActions from "modules/list/actions";
import { RoleSchema } from "utils/schemas";
import api from "services/api";

export const tenantRolesListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    return {
      limit: 20,
      filters: "spec.scope=tenant",
    };
  },
  fetchData(query) {
    const { continue: continueToken } = query || {};

    return api.get(
      `v1/roles?${continueToken ? `&continue=${continueToken}` : ""}`,
      query
    );
  },
  schema: [RoleSchema],
});

export const projectRolesListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    return {
      limit: 20,
      filters: "spec.scope=project",
    };
  },
  fetchData(query) {
    const { continue: continueToken } = query || {};

    return api.get(
      `v1/roles?${continueToken ? `&continue=${continueToken}` : ""}`,
      query
    );
  },
  schema: [RoleSchema],
});

export const workspaceRolesListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    return {
      limit: 20,
      filters: "spec.scope=workspace",
    };
  },
  fetchData(query) {
    const { continue: continueToken } = query || {};

    return api.get(
      `v1/roles?${continueToken ? `&continue=${continueToken}` : ""}`,
      query
    );
  },
  schema: [RoleSchema],
});

export const resourceRolesListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    return {
      limit: 20,
      filters: "spec.scope=resource",
    };
  },
  fetchData(query) {
    const { continue: continueToken } = query || {};

    return api.get(
      `v1/roles?${continueToken ? `&continue=${continueToken}` : ""}`,
      query
    );
  },
  schema: [RoleSchema],
});

export const rolesListActions = new ListActions({
  fetchData(query) {
    return api.get("v1/roles", query);
  },
  schema: [RoleSchema],
});

export function fetchRoles() {
  return {
    type: "FETCH_ROLES",
    promise: api.get("v1/roles").then((res) => res.items),
    schema: [RoleSchema],
  };
}
