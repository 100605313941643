export const initialState = {
  isLoadingNameValidation: false,
  selectedSettingsMenuAction: "schedule-backups",
};

export function WORKSPACE_NAME_VALIDATION_REQUEST(nextState) {
  nextState.isLoadingNameValidation = true;
}

export function WORKSPACE_NAME_VALIDATION_SUCCESS(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function WORKSPACE_NAME_VALIDATION_FAILURE(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function UPDATE_SETTINGS_MENU_SELECTION(nextState, { newKey }) {
  nextState.selectedSettingsMenuAction = newKey;
}
