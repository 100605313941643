import createFormActions from "modules/form/actions";
import ModalService from "services/modal";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import { clusterProfiletTypeCounter } from "state/clusterprofile/services";
import { redirectToRoute } from "state/newcluster/actions";
import i18next from "i18next";

export const selectCloudModalValidator = new Validator();
selectCloudModalValidator.addRule(
  "cloudType",
  Missing({
    message: () => i18next.t("Please select a cloud in order to continue."),
  })
);

export const selectCloudModal = new ModalService("selectCloud");
export const CLOUD_SELECTOR_MODULE = "cluster";

export const cloudSelectFormActions = createFormActions({
  init: async () => {
    store.dispatch(clusterProfiletTypeCounter.key("aws").fetch());
    return Promise.resolve({ clusterType: "cloud", cloudType: "" });
  },
  validator: selectCloudModalValidator,
  submit: async (values) => {
    const clusterType = values?.clusterType;
    const cloudType = values?.cloudType;
    store.dispatch(
      redirectToRoute(
        `/clusters/create?cloudType=${cloudType}&clusterType=${clusterType}`
      )
    );
  },
});
