import permissionsService from "services/permissions";

const Permissions = (props) => {
  let shouldRender = false;
  if (props.has) {
    shouldRender = permissionsService.has(props.has);
  }

  if (props.is) {
    shouldRender = permissionsService.is(props.is);
  }

  if (props.isAdmin) {
    shouldRender = permissionsService.isAdmin;
  }

  if (!shouldRender) {
    return null;
  }

  return props.children;
};

export default Permissions;
