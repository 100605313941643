import ModalService from "services/modal";
import api from "services/api";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import { mapClusterProfilesQuery } from "state/clusterprofile/actions/listing";
import { clusterDetailsFetcher } from "state/cluster/services/mapview";
import { mapClusterQuery } from "state/cluster/actions/list/clusters";

import { ClusterProfileSchema, ClusterSchema } from "utils/schemas";

const clusterDetailsModal = new ModalService();
const scheduleUpdatesModal = new ModalService();
const SCHEDULE_UPDATES_MODULE = "scheduleUpdates";

export function onClusterClick(clusterUid) {
  return (dispatch) => {
    dispatch(clusterDetailsFetcher.fetch(clusterUid));
    clusterDetailsModal.open();
  };
}

export function scheduleUpdates(selectedClusters) {
  return (dispatch) => {
    const clusterGuids = (selectedClusters || []).map(
      (cluster) => cluster.guid
    );
    dispatch(fetchAllProfiles({ init: true }));
    scheduleUpdatesModal.open({ clusters: clusterGuids }).then(() => {
      return dispatch(
        scheduleUpdatesFormActions.submit({ module: SCHEDULE_UPDATES_MODULE })
      );
    });
    dispatch(
      scheduleUpdatesFormActions.init({ module: SCHEDULE_UPDATES_MODULE })
    );
  };
}

export function initMapViewData() {
  return async (dispatch) => {
    dispatch(fetchAllClusters({ init: true }));
  };
}

const profileListActions = new ListActions({
  initialQuery() {
    return {
      search: "",
      cloudTypes: [],
      profileTypes: [],
      sortField: "",
      sortOrder: "",
      limit: 20,
    };
  },
  fetchData(query) {
    const { offset, limit, continue: continueToken, ...rest } = query;
    const payload = mapClusterProfilesQuery(rest);
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    return api.post(
      `v1/dashboard/clusterprofiles?limit=${limit}${continueQueryParam}`,
      payload
    );
  },
  schema: [ClusterProfileSchema],
});

function fetchAllProfiles({ init } = {}) {
  return async (dispatch, getState) => {
    const MODULE = "scheduleUpdatesProfiles";

    if (init) {
      await dispatch(profileListActions.initialize(MODULE));
    } else {
      await dispatch(profileListActions.nextPage(MODULE));
    }

    const newListState = getState().list[MODULE];
    if (newListState.nextToken) {
      dispatch(fetchAllProfiles());
    }
  };
}

export function fetchAllClusters({ init } = {}) {
  return async (dispatch, getState) => {
    if (init) {
      const payload = mapClusterQuery({});
      dispatch({
        type: "MAP_VIEW",
        promise: api.post(
          "v1/dashboard/spectroclusters/metadata/search",
          payload
        ),
        schema: {
          items: [ClusterSchema],
        },
      });
    }
  };
}

const validator = new Validator();

validator.addRule(
  ["clusterProfile", "profileVersion", "scheduleDay", "scheduleHour"],
  Missing()
);

const scheduleUpdatesFormActions = createFormActions({
  validator,
  init: () => {
    return Promise.resolve({
      clusterProfile: null,
      profileVersion: null,
      scheduleDay: "",
      scheduleHour: "",
    });
  },
  submit() {},
});

export function onClusterVisibilityChange(bounds) {
  return (dispatch) => {
    dispatch({
      type: "MAP_SET_BOUNDS",
      bounds,
    });
  };
}

export function onSelectCluster(clusterGuid) {
  return (dispatch) => {
    dispatch({
      type: "MAP_SELECT_VISIBLE_CLUSTER",
      clusterGuid: clusterGuid,
    });
  };
}

export function onChangeMapFilterTab(mapFilterTab) {
  return (dispatch) => {
    dispatch({
      type: "MAP_SET_FILTER_TAB",
      mapFilterTab,
    });
  };
}
