import sumBy from "lodash/sumBy";

export const MARKER_STATES = {
  HEALTHY: "healthy",
  FAILED: "failed",
  DEGRADED: "degraded",
  DISABLED: "disabled",
  UPDATES_PENDING: "pending",
};

export function computeClusterMarkerState(cluster) {
  const { FAILED, DEGRADED, DISABLED, HEALTHY, UPDATES_PENDING } =
    MARKER_STATES;
  const childMarkers = cluster.getAllChildMarkers();
  const childCount = cluster.getChildCount();
  const failedTotal = sumBy(childMarkers, (marker) =>
    marker.data.markerState === FAILED ? 1 : 0
  );

  let state = HEALTHY;

  if (
    childMarkers.some((marker) =>
      [DEGRADED, FAILED].includes(marker.data.markerState)
    )
  ) {
    state = DEGRADED;
  }
  if (failedTotal > childCount / 2) {
    state = FAILED;
  }

  if (childMarkers.every((marker) => marker.data.markerState === DISABLED)) {
    state = DISABLED;
  }

  if (
    childMarkers.every((marker) => marker.data.markerState === UPDATES_PENDING)
  ) {
    state = UPDATES_PENDING;
  }

  return state;
}
