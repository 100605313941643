import { createSelector } from "reselect";

import { applicationProfileEditor } from "modules/profileIDE/flows/application-edit/index";
import { getAppTiersOperations } from "modules/profileIDE/flows/application-edit/exceptions/Save";

export const hasAppProfileUnsavedChanges = createSelector(
  () => applicationProfileEditor.state,
  (profileEditor) => {
    const fileList = profileEditor.fileList;
    const drafts = profileEditor.drafts;

    if (fileList.files.length === 0) {
      return false;
    }

    const appTiers = getAppTiersOperations({ fileList, drafts });

    return appTiers.some((tier) => {
      if (!tier) {
        return false;
      }

      const hasManifestUpdates =
        tier?.manifestsOps?.length > 0 &&
        (tier?.manifestsOps || []).some(
          (manifest) => manifest.operation !== "ignore"
        );

      if (tier.operation !== "ignore" || hasManifestUpdates) {
        return true;
      }
      return false;
    });
  }
);
