import i18next from "i18next";
import createFormActions from "modules/form/actions";
import { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import Validator from "services/validator";
import { ApplyIf, MaxLength, Missing } from "services/validator/rules";
import { getCurrentUser } from "state/auth/selectors";

const validator = new Validator();
validator.addRule(
  ["bannerTitle"],
  ApplyIf((value, key, data) => data.isEnabled, MaxLength(32))
);
validator.addRule(
  ["bannerMessage"],
  ApplyIf((value, key, data) => data.isEnabled, Missing())
);
validator.addRule(
  ["bannerMessage"],
  ApplyIf((value, key, data) => data.isEnabled, MaxLength(1300))
);

export const changeMessageState = (isEnabled) => {
  return (dispatch) => {
    dispatch(
      atoMessageFormActions.onChange({
        name: "isEnabled",
        value: isEnabled,
        module: "atoMessage",
      })
    );
    if (!isEnabled) {
      dispatch(
        atoMessageFormActions.submit({
          module: "atoMessage",
        })
      );
    }
  };
};

export const atoMessageFormActions = createFormActions({
  validator,
  init: async () => {
    const currentUser = getCurrentUser(store.getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;
    let data;
    try {
      data = await nonProjectApi.get(`v1/tenants/${tenantUid}/loginBanner`);
    } catch (e) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: e?.message,
      });
    }

    return Promise.resolve({
      isEnabled: !!data?.isEnabled,
      bannerTitle: data?.title || "",
      bannerMessage: data?.Message || "",
    });
  },
  submit: async (data) => {
    const currentUser = getCurrentUser(store.getState());
    const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

    try {
      await nonProjectApi.put(`v1/tenants/${tenantUid}/loginBanner`, {
        isEnabled: data.isEnabled,
        title: data.bannerTitle,
        Message: data.bannerMessage,
      });

      notifications.success({
        message: data.isEnabled
          ? i18next.t("The login banner has been set successfully.")
          : i18next.t("The login banner has been unset successfully."),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
    }
  },
});
