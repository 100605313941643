import React from "react";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getDevUsedQuotaSystem,
  getDevUsedQuotaTenant,
} from "state/auth/selectors";
import styled from "styled-components";
import {
  concreteWhite,
  gray,
  green,
  lightGray,
  moonGray,
  red,
  yellow,
} from "utils/constants/colors";

const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const CardWrapper = styled.span`
  border: 2px solid ${concreteWhite};
  border-radius: 7px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  flex-grow: 1;
`;

const CardHeading = styled.div`
  align-self: center;
  margin-bottom: 10px;
`;

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
`;

const StyledMetric = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledSubText = styled.div`
  color: ${gray};
  font-size: 12px;
  text-transform: uppercase;
`;

const StyledMetricType = styled(StyledSubText)`
  color: ${moonGray};
`;

const StyledNote = styled.p`
  color: #818ea1;
  padding-top: 5px;
  font-size: small;
  margin: 0;
`;

const getProgressColor = (percent) => {
  if (percent <= 50) {
    return green;
  } else if (percent > 50 && percent < 100) {
    return yellow;
  } else {
    return red;
  }
};

const Metric = ({ type, data }) => {
  return (
    <StyledMetric>
      <StyledSubText>
        {data?.used}/{data?.allocated}
      </StyledSubText>
      <Progress
        percent={data?.percent}
        showInfo={false}
        size="small"
        strokeColor={getProgressColor(data?.percent)}
        trailColor={lightGray}
      />
      <StyledMetricType>{type}</StyledMetricType>
    </StyledMetric>
  );
};

const MetricCard = ({ heading, tenantData, systemData }) => {
  const { t } = useTranslation();
  return (
    <CardWrapper>
      <CardHeading>{heading}</CardHeading>
      <MetricWrapper>
        <Metric type={t("Tenant")} data={tenantData}></Metric>
        <Metric type={t("System")} data={systemData}></Metric>
      </MetricWrapper>
    </CardWrapper>
  );
};

const MetricsWidget = ({ tenantQuota, systemQuota }) => {
  const { t } = useTranslation();
  return (
    <>
      <WidgetWrapper>
        <MetricCard
          heading={t("No. of Virtual Clusters")}
          tenantData={tenantQuota?.nesterClusters}
          systemData={systemQuota?.nesterClusters}
        ></MetricCard>
        <MetricCard
          heading={t("CPU")}
          tenantData={tenantQuota?.cpu}
          systemData={systemQuota?.cpu}
        ></MetricCard>
        <MetricCard
          heading={t("Memory (GiB)")}
          tenantData={tenantQuota?.memory}
          systemData={systemQuota?.memory}
        ></MetricCard>
        <MetricCard
          heading={t("Storage (GiB)")}
          tenantData={tenantQuota?.storage}
          systemData={systemQuota?.storage}
        ></MetricCard>
      </WidgetWrapper>
      <StyledNote>
        An overview of consumed vs available system resources (Provided by
        Palette), tenant-created resources, and project-created resources.
        <a href="https://docs.spectrocloud.com/devx/resource-quota">
          {" "}
          Learn more about Resource Quota
        </a>
      </StyledNote>
    </>
  );
};

export default connect((state) => ({
  tenantQuota: getDevUsedQuotaTenant(state),
  systemQuota: getDevUsedQuotaSystem(state),
}))(MetricsWidget);
