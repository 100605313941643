import ModalService from "services/modal";
import dataFetcher from "modules/dataFetcher";
import { nonProjectApi } from "services/api";
export const IPAM_MODULE = "ipam";

export const ipamFormModal = new ModalService("ipamFormModal");
export const ipamDeleteModal = new ModalService("ipamDeleteModal");

export const overlordFetcher = dataFetcher({
  selectors: [
    "overlords",
    (state) => state.location?.params?.type,
    (state) => state.location?.params?.uid,
  ],
  fetchData([_, type, uid]) {
    return nonProjectApi.get(`v1/overlords/${uid}`);
  },
});
