import { normalize, schema, denormalize } from "normalizr";
import { createSelector } from "reselect";
import registry from "./registry";
import { uniqueNamesGenerator, colors, starWars } from "unique-names-generator";

const ID_ATTRIBUTE = "_idAttribute";

function Definition(entityType, definition = {}, defaultValues = {}) {
  function getIdentifier(entity) {
    let identifier = definition[ID_ATTRIBUTE] || "metadata.uid";
    if (typeof identifier === "function") {
      identifier = identifier(entity);
    }

    return identifier;
  }

  const entityDefaults = {
    ...defaultValues,
    persisted: true,
  };

  return new schema.Entity(entityType, definition, {
    idAttribute(entity) {
      return registry.getUuid(entityType, entity, {
        identifier: getIdentifier(entity),
      });
    },
    processStrategy(entity) {
      const defaultOverwrites = Object.keys(entityDefaults).reduce(
        (accumulator, key) => {
          if (key === ID_ATTRIBUTE || key === "_id") {
            return accumulator;
          }
          const value = entityDefaults[key];
          if (typeof value === "function") {
            accumulator[key] = value(entity[key], key, entity);
            return accumulator;
          }

          if (entity[key] !== undefined) {
            return accumulator;
          }

          accumulator[key] = entityDefaults[key];
          return accumulator;
        },
        {}
      );

      return {
        ...entity,
        ...defaultOverwrites,
        __internal: {
          entityType,
        },
        guid: registry.getUuid(entityType, entity, {
          identifier: getIdentifier(entity),
        }),
      };
    },
  });
}

function UidStrings(strings) {
  function uidToObject(uid) {
    if (typeof strings === "string") {
      return {
        metadata: {
          uid,
        },
      };
    }

    return {
      metadata: uid,
    };
  }
  if (typeof strings === "string") {
    return uidToObject(strings);
  }

  if (Array.isArray(strings)) {
    return strings.map(uidToObject);
  }

  return strings;
}

export function createEntity(data, Schema) {
  const normalization = normalize(data, Schema);
  let entityName = Schema.key;
  if (Array.isArray(Schema)) {
    entityName = Schema[0].key;
  }
  function getItem(guid) {
    const entity = normalization.entities[entityName][guid];
    entity.persisted = data.persisted || false;

    return entity;
  }
  if (Array.isArray(data)) {
    return normalization.result.map(getItem);
  }

  return getItem(normalization.result);
}

export function getEntity(selector, Schema) {
  return createSelector(
    selector,
    (state) => state.entities,
    (list, entities) => {
      if (!list) {
        return list;
      }
      return denormalize(list, Schema, entities);
    }
  );
}

const namespace = {
  normalize,
  denormalize,
  schema: {
    ...schema,
    Definition,
    UidStrings,
  },
};

export const generateEntityName = ({
  prefix,
  length,
  dictionaries,
  maxLength,
} = {}) => {
  let generatedName = uniqueNamesGenerator({
    dictionaries: dictionaries || [colors, starWars],
    separator: "-",
    length: length || 2,
  });

  var from = [..."ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"];
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  from.forEach((character, i) => {
    generatedName = generatedName.replace(
      new RegExp(from[i], "g"),
      to.charAt(i)
    );
  });

  const name = generatedName.toLocaleLowerCase().replaceAll(/\s/g, "-");
  const result = prefix ? `${prefix}-${name}` : name;

  if (maxLength && result.length > maxLength) {
    return generateEntityName({ prefix, length, dictionaries, maxLength });
  }

  return result;
};

export default namespace;
