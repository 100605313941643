import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import cvc from "assets/images/billing/cvc.png";
import {
  midGray,
  STATUS_COLOR,
  lightMidGray,
  white,
  error,
} from "utils/constants/colors";

const CardWrapper = styled.div`
  margin: 20px 0;
`;

const NumberElementWrap = styled.div`
  border-bottom: 1px solid ${lightMidGray};
  height: 44px;
  padding: 12px 11px;
`;

const CardLabel = styled.label`
  margin-bottom: 7px;
  white-space: nowrap;
  display: block;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.02em;
`;

const CardNumberWrapper = styled.div`
  border: 1px solid ${lightMidGray};
  border-radius: 2px;
  max-width: 428px;
  background-color: ${white};
`;

const ExpiryElementWrap = styled.div`
  flex-basis: 50%;
  border-right: 1px solid ${lightMidGray};
  height: 44px;
  padding: 12px 11px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
`;

const CvcElementWrap = styled.div`
  flex-basis: 50%;
  display: flex;
  height: 44px;
  padding: 12px 11px;

  .StripeElement {
    width: 100%;
  }
`;

const ErrorField = styled.div`
  color: ${error};
  font-size: 12px;
  line-height: 22px;
  margin-top: 7px;
`;

const StyledCvcIcon = styled.img`
  margin-right: 10px;
  width: 33px;
`;

export const cardRef = React.createRef(null);

export default function CardNumber() {
  const { t } = useTranslation();
  const [cardNumberError, setCardNumberError] = useState();
  const [expiryDateError, setExpiryDateError] = useState();
  const [cvcError, setCvcError] = useState();
  const errorMessage = cardNumberError || expiryDateError || cvcError;

  const defaultOptions = {
    style: {
      base: {
        color: midGray,
        "::placeholder": {
          color: lightMidGray,
        },
      },
      invalid: {
        color: STATUS_COLOR.error,
      },
    },
  };

  return (
    <CardWrapper data-qa="card-info">
      <CardLabel>{t("Card Number")}</CardLabel>
      <CardNumberWrapper>
        <NumberElementWrap data-qa="card-number">
          <CardNumberElement
            name="cardNumber"
            onReady={(el) => (cardRef.current = el)}
            options={{
              ...defaultOptions,
              showIcon: true,
            }}
            onChange={(e) => setCardNumberError(e?.error?.message)}
          />
        </NumberElementWrap>
        <FormRow>
          <ExpiryElementWrap data-qa="card-expiry">
            <CardExpiryElement
              name="expiryDate"
              options={defaultOptions}
              onChange={(e) => setExpiryDateError(e?.error?.message)}
            />
          </ExpiryElementWrap>
          <CvcElementWrap data-qa="card-cvc">
            <CardCvcElement
              name="cvc"
              options={defaultOptions}
              onChange={(e) => setCvcError(e?.error?.message)}
            />
            <StyledCvcIcon src={cvc} />
          </CvcElementWrap>
        </FormRow>
      </CardNumberWrapper>
      {errorMessage && <ErrorField>{errorMessage}</ErrorField>}
    </CardWrapper>
  );
}
