import { combineReducers } from "redux";
import createReducer from "utils/createReducer";

import * as create from "./create";
import * as edit from "./edit";
import * as metrics from "./metrics";

export default combineReducers({
  create: createReducer(create),
  edit: createReducer(edit),
  metrics: createReducer(metrics),
});
