import api from "services/api/index";
import ModalService from "services/modal";

import ProfileBuilderModule from "modules/profileBuilder";

import { PackSchema } from "utils/schemas";

export const WIZARD_MODULE = "systemProfile";
export const APP_PROFILE_FORM_MODULE = "systemProfile";
export const systemProfileBuilderCreateModule = new ProfileBuilderModule();
export const systemProfileBuilderEditModule = new ProfileBuilderModule();
export const cancelCreateAppProfileModal = new ModalService();

export function fetchSystemProfilePacks(uid) {
  return function thunk(dispatch) {
    const promise = api
      .get(`v1/clusterprofiles/${uid}/packs?includePackMeta=schema,presets`)
      .then((res) => res.items);

    dispatch({
      type: "FETCH_SELECTED_CLUSTER_PROFILE_PACKS",
      promise,
      schema: [PackSchema],
    });

    return promise;
  };
}
