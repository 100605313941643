import { createSelector } from "reselect";
import isEqual from "fast-deep-equal";
import querystring from "query-string";
import omit from "lodash/omit";
import { CLUSTER_PROFILES } from "utils/constants/routes";
import { generatePath } from "react-router";

export const isInitialProfileListEmpty = createSelector(
  (state) => state.list.clusterprofiles?.items["1"],
  (state) => state.list.clusterprofiles?.isLoading,
  (state) => state.list.clusterprofiles?.isInitializing,
  (state) => state.list.clusterprofiles?.query,
  (clusterProfiles, isLoading, isInitializing, query = {}) => {
    const sortFields = ["sortField", "sortOrder"];
    const initialFilterQuery = {
      limit: 20,
      search: "",
      cloudTypes: [],
      profileTypes: [],
    };
    const isInitialState = isEqual(omit(query, sortFields), initialFilterQuery);
    return (
      !isLoading &&
      !isInitializing &&
      isInitialState &&
      (!clusterProfiles || clusterProfiles.length === 0)
    );
  }
);

export const getClusterProfilesLink = createSelector(
  (state) => state.list?.clusterprofiles?.query,
  (state) => state.location?.params?.tab,
  (data, tab) => {
    const systemProfileTab = generatePath(CLUSTER_PROFILES.ROOT, {
      tab: "system",
    });
    const clusterProfileTab = generatePath(CLUSTER_PROFILES.ROOT, {
      tab: "cluster",
    });

    const rootPath = tab === "system" ? systemProfileTab : clusterProfileTab;

    if (!data) {
      return rootPath;
    }

    const query = querystring.stringify({
      search: "",
      cloudTypes: data.cloudTypes,
      profileTypes: data.profileTypes,
      sortOrder: data.sortOrder,
      sortField: data.sortField,
    });

    return `${rootPath}?${query}`;
  }
);
