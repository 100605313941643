import { Table as AntdTable } from "antd";
import { paginationStyle } from "components/styled/mixins";
import styled, { css } from "styled-components";
import * as colors from "utils/constants/colors";

const Table = styled(AntdTable)`
  .ant-table-thead > tr > th {
    background: ${colors.lightWhite};
    color: ${colors.regentGray};
    padding: 14px 16px;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    line-height: 18px;
    ${(props) =>
      !props?.thOverflow &&
      css`
        text-overflow: ellipsis;
        overflow: hidden;
      `}
  }

  .ant-table-body {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-spin-nested-loading {
    .ant-spin-blur::after,
    > div > .ant-spin {
      pointer-events: none;
    }

    .ant-table-thead {
      pointer-events: auto;
    }
  }

  .ant-table-row {
    ${(props) =>
      props.selectableRow &&
      css`
        cursor: pointer;
      `}
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-tbody > tr + tr > td {
    border-top: 1px solid #f0f0f0;
  }

  .ant-table table {
    background: ${(props) => {
      return props.theme.backgroundColor || colors.white;
    }};
  }

  ${paginationStyle}

  .ant-pagination {
    margin: 0;
  }

  .ant-table-sticky-scroll {
    bottom: 24px !important;
  }

  ${(props) =>
    props.slim &&
    css`
      .ant-table-tbody > tr > td {
        padding: 4px 6px;
        font-size: 13px;
        line-height: 21px;
      }

      .ant-table-thead > tr > th {
        padding: 14px 6px;
      }
    `}

  ${(props) => {
    return (
      props.resizable &&
      css`
        .ant-table-thead > tr > th:last-of-type .resize-handler {
          display: none;
        }
      `
    );
  }}
`;

export default Table;
