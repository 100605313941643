import React, { MouseEvent } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AntdIcon from "@ant-design/icons";

const StyledIcon = styled(AntdIcon)`
  &.anticon {
    margin: 0;
    .svg-inline--fa {
      vertical-align: 0;
    }
  }
`;

interface IconProps {
  awesome?: any;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  [key: string]: any;
}

export default function Icon({
  awesome = null,
  component = null,
  onClick,
  ...rest
}: IconProps) {
  let customSvg = component;
  if (awesome) {
    customSvg = function AwesomeIcon() {
      return <FontAwesomeIcon icon={awesome} {...rest} />;
    };
  }

  return <StyledIcon {...rest} component={customSvg} onClick={onClick} />;
}
