import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { PRIVATE_CLOUD_GATEWAYS } from "utils/constants/routes";

const Ipams = React.lazy(() => import("./ipam"));

export default createNamespace({
  namespace: "privatecloudgateways",
  Component: function Main() {
    return (
      <>
        <Route adminOnly path={PRIVATE_CLOUD_GATEWAYS.IPAM} component={Ipams} />
        <Route
          adminOnly
          path={PRIVATE_CLOUD_GATEWAYS.CREATE_IPAM}
          component={Ipams}
        />
      </>
    );
  },
});
