import React from "react";
import { useTranslation } from "react-i18next";
import linkIcon from "src/assets/images/appModeOverview/link.png";
import playIcon from "src/assets/images/appModeOverview/play.png";
import questionIcon from "src/assets/images/appModeOverview/question.png";
import { InfoWidgetTemplate } from "pages/dev/overview/components/InfoWidgetTemplate";

export const GettingStartedInfoWidget = () => {
  const { t } = useTranslation();
  return (
    <InfoWidgetTemplate
      icon={linkIcon}
      heading={t("Getting started with Palette Dev Engine")}
      description={t(
        "Set up a custom pack registry using a Docker image provided by Spectro Cloud to upload and maintain custom packs."
      )}
    />
  );
};

export const MultiTierModelingInfoWidget = () => {
  const { t } = useTranslation();
  return (
    <InfoWidgetTemplate
      icon={playIcon}
      heading={t("Multi-tier App Modeling")}
      description={t(
        "Model curated, pre-validated services, such as databases (MongoDB, Postgres, Redis) along with Helm Charts and Manifests as reusable App Profiles."
      )}
    />
  );
};

export const VirtualClustersInfoWidget = () => {
  const { t } = useTranslation();
  return (
    <InfoWidgetTemplate
      icon={questionIcon}
      heading={t("Virtual Clusters")}
      description={t(
        "You can manage compute using Cluster Groups which are aggregation of host clusters deployed through the Palette Tenant Admin console."
      )}
    />
  );
};
