import i18next from "i18next";
import i18n from "i18next";
import { generatePath } from "react-router";

import createFormActions from "modules/form/actions";
import { createRoleBindingsFormFactory } from "modules/rolebindings";
import { createContainerImagesFormFactory } from "modules/containerImages";

import store from "services/store";
import Validator from "services/validator";
import {
  Missing,
  isKubernetesName,
  DebouncedRule,
  isValidNamespaceAllocation,
  isWorkspaceQuotaExceeded,
  isZeroOrGreater,
  isValidClusterAllocation,
  isUnlimitedAloc,
  ApplyIf,
  isValidRegexNamespace,
} from "services/validator/rules";
import notifications from "services/notifications";
import api from "services/api";
import historyService from "services/history";

import { formatTags } from "utils/presenters";
import { WORKSPACES } from "utils/constants/routes";
import { EXPIRY_PERIODS, FOUR_MONTHS_IN_HOURS } from "utils/constants";

import { WORKSPACES_FORM_MODULE } from "state/workspaces/services/create";
import { getSelectedClustersList } from "state/workspaces/selectors/create";
import { getScheduledBackupPayload } from "state/workspaces/selectors/backups";

export const validator = new Validator();

const validateWorkspaceName = async (value) => {
  let error;

  const promise = api.get(`v1/workspaces/validate/name?name=${value}`);

  try {
    await promise;
  } catch (err) {
    if (err.code === "ResourceNameAlreadyExists") {
      error = {
        result: i18n.t(
          `A workspace with the name "{{ value }}" already exists`,
          {
            value,
          }
        ),
        field: "name",
      };
    }
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateWorkspaceName);

validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], (value) => {
  if (!value) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "WORKSPACE_NAME_VALIDATION",
    promise,
  });
  return promise;
});

validator.addRule(["name", "clusters"], Missing());
validator.addRule("namespaces", (value, key, data) => {
  if (!data.namespaces?.length) {
    return Missing({ message: () => i18next.t("Missing namespace") })();
  }
});

const parseFloatNumber = (value) => parseFloat(value) || 0;

export function sumResource(namespaces, clusterUid, resourceName) {
  return namespaces.reduce((acc, ns) => {
    const resourceValue = ns.children.find(
      (cluster) => cluster.clusterUid === clusterUid
    )?.[resourceName];

    const value = !Number.isNaN(resourceValue) ? resourceValue : 0;

    return acc + parseFloatNumber(value);
  }, 0);
}

export function getParsedClustersResources(data) {
  return data.clusters.map((clusterUid, index) => ({
    index,
    clusterUid,
    totalAlocCpu: sumResource(data.namespaces, clusterUid, "alocCpu"),
    totalAlocMemory: sumResource(data.namespaces, clusterUid, "alocMemory"),
  }));
}

validator.addRule("namespaces", function* (value, key, data) {
  const parsedClustersResourcesData = getParsedClustersResources(data);
  const parsedCpuQuota = parseFloat(data?.cpuQuota || 0);

  const higherCpuThanQuotaClusters = parsedClustersResourcesData.filter(
    (cluster) => parsedCpuQuota > 0 && cluster.totalAlocCpu > parsedCpuQuota
  );

  for (const index in data.namespaces) {
    for (const childIndex in data.namespaces[index].children) {
      yield {
        field: `namespaces.${index}.children.${childIndex}.alocCpu`,
        result:
          parseInt(childIndex) === higherCpuThanQuotaClusters?.[0]?.index
            ? i18n.t(
                "The CPU allocated for this cluster has exceeded the workspace quota"
              )
            : false,
      };
    }
  }
});

validator.addRule("namespaces", function* (value, key, data) {
  const parsedClustersResourcesData = getParsedClustersResources(data);
  const parsedMemoryQuota = parseFloat(data?.memoryQuota || 0);

  const higherMemoryThanQuotaClusters = parsedClustersResourcesData.filter(
    (cluster) =>
      parsedMemoryQuota > 0 && cluster.totalAlocMemory > parsedMemoryQuota
  );

  for (const index in data.namespaces) {
    for (const childIndex in data.namespaces[index].children) {
      yield {
        field: `namespaces.${index}.children.${childIndex}.alocMemory`,
        result:
          parseInt(childIndex) === higherMemoryThanQuotaClusters?.[0]?.index
            ? i18n.t(
                "The memory allocated for this cluster has exceeded the workspace quota"
              )
            : false,
      };
    }
  }
});

validator.addRule("namespaces", isUnlimitedAloc);
validator.addRule(["namespaces"], isValidClusterAllocation());
validator.addRule(["namespaces"], isValidNamespaceAllocation());
validator.addRule(["namespaces"], isWorkspaceQuotaExceeded());

validator.addRule("namespace", isValidRegexNamespace());
validator.addRule("namespace", (value, key, data) => {
  if (data?.namespaces?.find((ns) => ns.namespaceName === value)) {
    return i18next.t("Namespaces must be unique");
  }
  return false;
});

validator.addRule(["cpuQuota", "memoryQuota"], isZeroOrGreater());

validator.addRule("backupPrefix", isKubernetesName());
validator.addRule(
  "location",
  ApplyIf((_1, _2, data) => !!data.backupPrefix, Missing())
);
validator.addRule(
  "backupedClusters",
  ApplyIf((_1, _2, data) => !data.includeAllClusters, Missing())
);

const containerImagesValidator = new Validator();
containerImagesValidator.addRule(["namespace", "restrictedImages"], Missing());
validator.addRule("containerImages", containerImagesValidator);

export function parseNamespacesFormData(namespaces = [], containerImages = []) {
  const defaultNamespaces = namespaces.filter((ns) => !ns.isChild);

  const getClusterResourceAllocations = (namespace) => {
    const childAllocations = (namespace?.children || []).filter(
      (ns) =>
        parseFloatNumber(ns.alocMemory) >= -1 &&
        parseFloatNumber(ns.alocCpu) >= -1
    );

    return (childAllocations || []).map((child) => ({
      clusteruid: child.clusterUid,
      resourceAllocation: {
        cpuCores: parseFloatNumber(child.alocCpu),
        memoryMiB:
          parseFloatNumber(child.alocMemory) === -1
            ? -1
            : parseFloatNumber(child.alocMemory) * 1024,
      },
    }));
  };

  return defaultNamespaces.map((namespace) => ({
    name: namespace.namespaceName,
    isRegex: namespace.isRegex,
    namespaceResourceAllocation: {
      defaultResourceAllocation: {
        cpuCores:
          parseFloatNumber(namespace.alocCpu) === -1
            ? 0
            : parseFloatNumber(namespace.alocCpu),
        memoryMiB:
          parseFloatNumber(namespace.alocMemory) === -1
            ? 0
            : parseFloatNumber(namespace.alocMemory) * 1024,
      },
      clusterResourceAllocations: getClusterResourceAllocations(namespace),
    },
    image: {
      blackListedImages: containerImages?.[namespace.namespaceName] || [],
    },
  }));
}

export function parseContainerImages(containerImages = []) {
  return containerImages.reduce((accumulator, image) => {
    if (!accumulator[image.namespace]) {
      accumulator[image.namespace] = image.restrictedImages;
    }

    return accumulator;
  }, {});
}

export function parsePayload(data = {}) {
  const {
    name,
    description,
    tags,
    clusterRoleBindings = [],
    namespaces,
    namespaceRoleBindings = [],
    containerImages,
    cpuQuota,
    memoryQuota,
  } = data;

  const clusterRefs = getSelectedClustersList(store.getState())?.map(
    (entity) => ({
      clusterName: entity?.metadata?.name,
      clusterUid: entity?.metadata?.uid,
    })
  );

  const clusterBindings = {
    spec: {
      bindings: clusterRoleBindings.map((rb) => ({
        role: {
          kind: "ClusterRole",
          name: rb.roleName,
        },
        subjects: rb.subjects || [],
        type: "ClusterRoleBinding",
      })),
    },
  };

  const namespaceBindings = {
    spec: {
      bindings: namespaceRoleBindings.map((rb) => ({
        namespace: rb.namespace,
        role: {
          kind: rb.roleType,
          name: rb.roleName,
        },
        subjects: rb.subjects || [],
        type: "RoleBinding",
      })),
    },
  };

  const clusterRbacs = [
    clusterRoleBindings?.length && clusterBindings,
    namespaceRoleBindings?.length && namespaceBindings,
  ].filter(Boolean);
  const parsedContainerImages = parseContainerImages(containerImages);
  const clusterNamespaces = parseNamespacesFormData(
    namespaces,
    parsedContainerImages
  );

  const backupPolicy = getScheduledBackupPayload(data);
  let policies = {
    ...(Object.keys(backupPolicy).length ? { backupPolicy } : {}),
  };

  return {
    metadata: {
      name,
      labels: formatTags(tags),
      annotations: { description },
    },
    spec: {
      quota: {
        resourceAllocation: {
          cpuCores: parseFloatNumber(cpuQuota),
          memoryMiB: parseFloatNumber(memoryQuota) * 1024,
        },
      },
      clusterRbacs,
      clusterRefs,
      clusterNamespaces,
      policies,
    },
  };
}

async function submit(data) {
  const payload = parsePayload(data);
  let response = null;

  try {
    response = await api.post("v1/workspaces", payload);
  } catch (error) {
    notifications.error({
      message: i18next.t("Something went wrong while creating the workspace"),
      description: error.message,
    });
    return;
  }

  if (response) {
    historyService.push(generatePath(WORKSPACES.ROOT, { tab: "overview" }));
    notifications.success({
      message: `Workspace "${data.name}" has been created successfully`,
    });
  }
}

const workspaceFormActions = createFormActions({
  submit,
  validator,
  init: () => {
    return Promise.resolve({
      name: "",
      description: "",
      tags: [],
      clusters: [],
      clusterRoleBindings: [],
      namespace: "",
      namespaces: [],
      namespaceRoleBindings: [],
      cpuQuota: "",
      memoryQuota: "",

      containerImages: [],

      backupPrefix: "",
      location: null,
      schedule: "never",
      expiryPeriod: EXPIRY_PERIODS[0].value,
      expiryHours: FOUR_MONTHS_IN_HOURS,
      includeAllClusters: true,
      includeAllDisks: true,
      includeClusterResources: true,
      backupedNamespaces: [],
      backupedClusters: [],

      selectedClustersGuids: [],
    });
  },
});

export function submitWorkspace() {
  return (dispatch) => {
    dispatch(workspaceFormActions.submit({ module: WORKSPACES_FORM_MODULE }));
  };
}

export const createWorkspaceRoleBindingsForm = createRoleBindingsFormFactory({
  formActions: workspaceFormActions,
  formModuleName: WORKSPACES_FORM_MODULE,
});

export const createContainerImagesForm = createContainerImagesFormFactory({
  formActions: workspaceFormActions,
  formModuleName: WORKSPACES_FORM_MODULE,
});

export default workspaceFormActions;
