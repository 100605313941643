import ListActions from "modules/list/actions";
import history from "services/history";

import { getArrayFromQuery } from "utils/parsers";
import { PodSchema } from "utils/schemas";

export const PODS_MODULE = "clusterGroupPods";

export const podsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    //TODO
    return {
      items: [],
    };
  },
  initialQuery: () => {
    const query = history.getQuery();
    const namespaces = getArrayFromQuery(query?.namespaces);
    const clusters = getArrayFromQuery(query?.clusters);
    return { namespaces, clusters };
  },
  schema: [PodSchema],
});
