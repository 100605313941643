import React from "react";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import store from "services/store";

import Button from "components/ui/Button";

export default class AsyncAction {
  constructor({ promise }) {
    this.guid = uuid();
    this._registry = {};
    this.promise = promise;
    this.trigger = this.trigger.bind(this);
  }

  get isInProgress() {
    return !!store.getState().asyncAction?.[this.guid]?.isLoading;
  }

  trigger(...args) {
    const promise = this.promise(...args);
    store.dispatch({
      promise,
      guid: this.guid,
      type: "ACTION_LOAD",
    });
    return promise;
  }

  key(value) {
    if (this._registry[value]) {
      return this._registry[value];
    }
    this._registry[value] = new AsyncAction({
      promise: (...args) => this.promise(...args, value),
    });
    return this._registry[value];
  }
}

export function createAsyncActionComponent({
  Component = Button,
  asyncAction,
  triggerOnClick = true,
}) {
  const WrapperComponent = ({ isInProgress, identifier, ...rest }) => {
    const trigger = identifier
      ? asyncAction.key(identifier).trigger
      : asyncAction.trigger;

    const props = {
      ...rest,
      loading: isInProgress,
      disabled: rest.disabled || isInProgress,
    };

    return triggerOnClick ? (
      <Component {...props} onClick={!isInProgress && trigger}></Component>
    ) : (
      <Component {...props}></Component>
    );
  };

  return connect((state, { identifier }) => ({
    isInProgress: identifier
      ? asyncAction.key(identifier).isInProgress
      : asyncAction.isInProgress,
  }))(WrapperComponent);
}
