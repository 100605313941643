import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import { getAllProjects } from "state/auth/selectors";
import { getEnvironments } from "utils/constants";
import { presentClusterFilters } from "utils/presenters";

const availableClusterFiltersFetcher = dataFetcher({
  selectors: ["availableClusterFilters"],
  fetchData() {
    return api.get("v1/dashboard/spectroclusters/metadata/search/schema");
  },
});

const clusterFiltersOptionsFetcher = dataFetcher({
  selectors: ["clusterFiltersOptionsFetcher"],
  fetchData() {
    return api.get("v1/dashboard/spectroclusters/search/input");
  },
});

export const clusterFiltersFetcher = dataFetcher({
  selectors: ["clusterListingFilters"],
  async fetchData() {
    const state = store.getState();
    const [filtersResult, optionsResult] = await Promise.allSettled([
      store.dispatch(availableClusterFiltersFetcher.fetch()),
      store.dispatch(clusterFiltersOptionsFetcher.fetch()),
    ]);

    const projects = getAllProjects(state);
    const environments = getEnvironments(state);

    return presentClusterFilters({
      properties: filtersResult?.value?.schema?.properties,
      options: optionsResult?.value?.inputs,
      projects,
      environments,
    });
  },
});
