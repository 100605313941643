import React from "react";
import * as YAML from "yaml";
import difference from "lodash/difference";
import { BinderProvider, createModule } from "modules/binder";
import store from "services/store";
import Layout from "./layout";

import { bindFormTo } from "modules/profileIDE/state/forms";
import {
  helmPacksFetcher,
  helmPackVersionFetcher,
  helmRepositoriesFetcher,
  ociRepositoriesFetcher,
} from "modules/profileIDE/state/packs";

import ServicesSelectionScreen from "modules/profileIDE/components/Editor/ServicesSelectionScreen";
import { addPackValueToYaml, fixMultilineString } from "utils/yaml";
import OverviewScreen from "modules/profileIDE/components/Editor/OverviewScreen";
import Validator from "services/validator";
import {
  isValidPort,
  isValidReplicas,
  Missing,
} from "services/validator/rules";
import { appCreateReducer } from "./reducer";

function updateFileName({ value, module }) {
  module.dispatch({
    type: module.actions.fileList.updateEntity,
    fileGuid: module.state.fileList.activeFile,
    overwrites: {
      name: value,
    },
  });
}

export function isProtectedRegistry(registryUid) {
  const helmRepos = helmRepositoriesFetcher.selector(store.getState())?.result;
  return (
    (helmRepos || []).find((repo) => {
      return repo.metadata.uid === registryUid;
    })?.spec?.isPrivate || false
  );
}

export function getFormUpdatesOnPackVersionChange(module, value) {
  const defaultYamlValues = `
  pack:
   namespace: '{{.spectro.system.appdeployment.tiername}}'
   releaseNameOverride: '{{.spectro.system.appdeployment.tiername}}-release'
`.trim();

  const packVersionData = helmPackVersionFetcher
    .key(module.form.state.data.packName)
    .selector(store.getState()).result;

  const selectedPackVersion = packVersionData.tags.find(
    (pack) => pack.tag === value
  );
  const packValue = packVersionData.packValues.find(
    (pack) => pack.packUid === selectedPackVersion.packUid
  );

  let values = packValue.values;
  const yamlValues = YAML.parseDocument(packValue.values);

  if (!yamlValues.getIn(["pack", "namespace"])) {
    const mergedDoc = addPackValueToYaml(packValue.values, defaultYamlValues);
    const yamlString = mergedDoc.toString();
    values = fixMultilineString(yamlString);
  }

  return { values, packUid: packValue.packUid };
}

export function protectedHelmEffects({ name, value }, module) {
  if (name === "registryUid") {
    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          packName: "",
          packVersion: "",
        },
      })
    );
  }

  if (name === "packName") {
    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          name: value,
          packVersion: "",
        },
      })
    );

    updateFileName({ value, module });
  }

  if (name === "packVersion") {
    const defaultYamlValues = `
      pack:
       namespace: '{{.spectro.system.appdeployment.tiername}}'
       releaseNameOverride: '{{.spectro.system.appdeployment.tiername}}-release'
    `.trim();

    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          values: defaultYamlValues,
          packUid: "",
          version: value,
        },
      })
    );
  }
}

function helmEffects({ name, value }, module) {
  const registryUid =
    name === "registryUid" ? value : module.form.state.data.registryUid;
  const protectedRegistry = isProtectedRegistry(registryUid);

  if (protectedRegistry) {
    return protectedHelmEffects({ name, value }, module);
  }

  if (name === "registryUid") {
    store.dispatch(helmPacksFetcher.key(value).fetch());
    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          packName: "",
          packVersion: "",
        },
      })
    );
  }

  if (name === "packName") {
    store.dispatch(helmPackVersionFetcher.key(value).fetch(registryUid));
    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          packVersion: "",
          name: value,
        },
      })
    );

    updateFileName({ value, module });
  }

  if (name === "packVersion") {
    const updates = getFormUpdatesOnPackVersionChange(module, value);
    store.dispatch(
      module.form.actions.batchChange({
        updates,
      })
    );
  }
}

function manifestEffects({ name, value }, module) {
  if (name === "name") {
    updateFileName({ value, module });
  }

  if (name === "manifests") {
    const valuesGuid = value.map((manifest) => manifest.guid);
    const activeFile = module.state.fileList.activeFile;
    const entitiesGuid = module.state.fileList.childrenMap[activeFile] || [];
    const filesToAdd = difference(valuesGuid, entitiesGuid);
    const filesToDelete = difference(entitiesGuid, valuesGuid);
    const entities = value.reduce((acc, file) => {
      acc[file.guid] = file;
      return acc;
    }, {});

    filesToAdd.forEach((fileGuid) => {
      module.dispatch({
        type: module.actions.fileList.addChild,
        parentGuid: activeFile,
        file: entities[fileGuid],
      });
      module.dispatch({
        type: module.actions.drafts.stashForm,
        formData: entities[fileGuid],
      });
    });

    filesToDelete.forEach((fileGuid) => {
      module.dispatch({
        type: module.actions.fileList.removeFile,
        fileGuid,
      });
    });
  }
}

function operatorInstanceEffects({ name, value }, module) {
  if (name === "name") {
    updateFileName({ value, module });
  }
}

function containerEffect({ name, value }, module) {
  if (name === "name") {
    updateFileName({ value, module });
  }
}

export const applicationProfileCreator = createModule({
  store,
  moduleReducer: appCreateReducer,
  configuration: {
    updateInstallOrders: true,
  },
  effects: {
    form: {
      load(entity, draft) {
        if (draft.type === "helm") {
          if (!helmRepositoriesFetcher.selector(store.getState()).result) {
            store.dispatch(helmRepositoriesFetcher.fetch());
          }
        }

        if (draft.type === "operator-instance") {
          const formFields = (
            entity?.template?.parameters?.inputParameters || []
          ).reduce((acc, inputParam) => {
            acc[inputParam.name] = inputParam.value || "";

            return acc;
          }, {});

          return {
            ...entity.template,
            packUid: entity.packUid,
            version: entity.version || draft.version,
            databaseVolumeSize: draft.databaseVolumeSize || 0,
            subType: entity.subType || "",
            ...formFields,
          };
        }

        if (draft.type === "container") {
          if (!ociRepositoriesFetcher.selector(store.getState()).result) {
            store.dispatch(ociRepositoriesFetcher.fetch());
          }

          return {
            ...entity.template,
            registryUid: entity.registryUid,
            packUid: entity.packUid,
            version: entity.version,
            containerRegistryUid: draft.containerRegistryUid || "",
            ports: draft.ports || [""],
            access: draft.access || "private",
            values: entity.values,
            image: draft.image || "",
            replicas: draft.replicas || 1,
          };
        }

        if (draft.type === "manifest") {
          return {
            manifests: draft.manifests || [],
          };
        }

        if (draft.type === "child-manifest") {
          return {
            values: draft.values || "",
          };
        }
      },
      change(payload, module) {
        const type = module.form.state.data.type;
        if (type === "helm") {
          helmEffects(payload, module);
          manifestEffects(payload, module);
        }
        if (type === "manifest") {
          manifestEffects(payload, module);
        }
        if (type === "operator-instance") {
          operatorInstanceEffects(payload, module);
        }
        if (type === "container") {
          containerEffect(payload, module);
        }
      },
    },
  },
});

const validator = new Validator();

// general rules
validator.addRule(["name"], Missing());

// Missing field rule
validator.addRule(function* (data) {
  const fields = {
    helm: ["registryUid", "packName", "packVersion"],
    container: ["image"],
    "operator-instance": (data?.parameters?.inputParameters || []).map(
      (param) => param.name
    ),
    manifest: ["manifests"],
    "child-manifest": ["values"],
  };

  for (const field of fields[data.type]) {
    yield {
      result: Missing()(data[field], field, data),
      field,
    };
  }
});

// container rules
validator.addRule(function* (data) {
  if (data.type === "container") {
    for (const portIndex of (data?.ports || []).keys()) {
      const port = data.ports[portIndex];
      if (!port) {
        yield {
          result: Missing()(port),
          field: `ports.${portIndex}`,
        };
        return;
      }
      yield {
        result: isValidPort()(port),
        field: `ports.${portIndex}`,
      };
    }
  }
});

validator.addRule(function* (data) {
  if (data.type === "container") {
    const replicas = data.replicas;
    if (!replicas && replicas !== 0) {
      yield {
        result: Missing()(replicas),
        field: `replicas`,
      };
      return;
    }
    yield {
      result: isValidReplicas()(replicas),
      field: `replicas`,
    };
  }
});

bindFormTo(applicationProfileCreator, validator);

// Workflow
// 1. create reusable reducers
// 2. create components & connectors
// 3. create module & register it
// 4. compose modules with connected components
// 5. overwrite layout with exceptions

export default function ApplicationCreate() {
  return (
    <BinderProvider module={applicationProfileCreator}>
      <Layout />
      <ServicesSelectionScreen />
      <OverviewScreen />
    </BinderProvider>
  );
}
