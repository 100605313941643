import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  canUpgradePlan,
  getCurrentUser,
  getTenantPlanName,
  hasTenantLevelClusterGroup,
} from "state/auth/selectors";
import styled from "styled-components";
import { red, green, darkBlue } from "utils/constants/colors";
import { FLAGS } from "utils/constants/flags";
import flags from "services/flags";
import permissionsService from "services/permissions";

import { pendingCancellationModal } from "state/plandetails/services";
import { isSubscriptionInPending } from "state/plandetails/selectors";
import PendingCancellationModal from "pages/settings/plandetails/components/PendingCancellationModal";
import { useMenu } from "components/common/providers/Menu";
import { TextButton } from "components/ui/Button";
import Icon from "components/ui/Icon";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { faEngineWarning } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "antd";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.2);
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 4px 9px;
  color: #5fd5f9;
  text-overflow: ellipsis;
  text-align: center;

  &:empty {
    min-height: 0;
    padding: 0;
  }
`;

const UpgradeContainer = styled(TextButton)`
  flex-grow: 1;
  background-color: ${(props) => (props.warning ? red : green)};
  color: #3e4856;
  margin: 15px 0 10px 0;
  display: inline-block;
  line-height: 32px;
  text-transform: none;

  &:focus,
  &:hover {
    background-color: ${(props) => (props.warning ? red : green)};
    color: #3e4856;
  }
`;

const InfoText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #bcc3cd;
  white-space: normal;
  text-transform: none;
  margin-top: 8px;
  width: 176px;

  > h3 {
    color: #bcc3cd;
  }
`;

function PlanContainer({
  planName,
  canUpgrade,
  pendingCancellation,
  devMode,
  currentUser,
  hasTenantLevelUsage,
}) {
  const { t } = useTranslation();
  const { expanded } = useMenu();

  if (!planName || !flags.has(FLAGS.BILLING) || !permissionsService.isAdmin) {
    return null;
  }

  const renderUpgradeContainer = () => {
    if (devMode && !hasTenantLevelUsage) {
      return (
        <>
          {expanded ? (
            <>
              <InfoText>
                <h3>{t("Want more quota?")}</h3>
              </InfoText>
              <InfoText>{t("Let us know and we’ll get back to you.")}</InfoText>
            </>
          ) : null}
          <Tooltip
            title={expanded ? null : t("Get more quota")}
            placement="right"
            color={darkBlue}
          >
            <UpgradeContainer
              as="a"
              warning={false}
              expanded={expanded}
              href={`mailto:developer@spectrocloud.com?subject=Palette user quota request &body=Hello Spectro Cloud,%0A%0AI have been using Palette App Mode and would like additional quota because of my use case <tell us more>.%0A%0ALooking forward to your response.`}
            >
              {!expanded ? (
                <Icon awesome={faArrowCircleUp} />
              ) : (
                t("Get more quota")
              )}
            </UpgradeContainer>
          </Tooltip>
        </>
      );
    }

    if (!devMode) {
      if (pendingCancellation) {
        return (
          <>
            {expanded ? (
              <InfoText>
                {t("We are sorry to see you go! Please migrate your clusters")}
              </InfoText>
            ) : null}
            <Tooltip
              title={expanded ? null : t("Plan cancellation pending")}
              placement="right"
              color={darkBlue}
            >
              <UpgradeContainer
                onClick={() => pendingCancellationModal.open()}
                warning={true}
                expanded={expanded}
              >
                {!expanded ? <Icon awesome={faEngineWarning} /> : t("Details")}
              </UpgradeContainer>
            </Tooltip>
          </>
        );
      }

      if (canUpgrade) {
        return (
          <>
            {expanded ? (
              <InfoText>
                {t("Start small and only pay for what you use!")}
              </InfoText>
            ) : null}
            <Tooltip
              title={expanded ? null : t("Upgrade now")}
              placement="right"
              color={darkBlue}
            >
              <UpgradeContainer
                as={Link}
                to="/admin/settings/plandetails"
                warning={pendingCancellation}
                expanded={expanded}
              >
                {!expanded ? (
                  <Icon awesome={faArrowCircleUp} />
                ) : (
                  t("Upgrade now")
                )}
              </UpgradeContainer>
            </Tooltip>
          </>
        );
      }

      return planName();
    }
  };

  return (
    <Wrapper>
      {renderUpgradeContainer()}
      <PendingCancellationModal />
    </Wrapper>
  );
}

export default connect((state) => ({
  planName: getTenantPlanName(state),
  canUpgrade: canUpgradePlan(state),
  pendingCancellation: isSubscriptionInPending(state),
  devMode: state.auth.devMode,
  currentUser: getCurrentUser(state),
  hasTenantLevelUsage: hasTenantLevelClusterGroup(state),
}))(PlanContainer);
