import i18n from "i18next";
import { createSelector } from "reselect";

export const getPermissions = createSelector(
  (state) => state.roles.create.permissions,
  (allPermissions) => {
    return allPermissions.map((permissionGroup) => ({
      title: i18n.t("{{scope}} permissions", { scope: permissionGroup.name }),
      ...permissionGroup,
    }));
  }
);
