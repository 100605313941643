import React, { useMemo } from "react";
import { useBinder } from "modules/binder";
import { createForm } from "modules/form";

export default function LayerForm({ children }) {
  const module = useBinder();
  const FormModule = useMemo(() => {
    return createForm({
      actions: module.form.actions,
      Component({ children }) {
        return children || null;
      },
    });
  }, [module.form.actions]);

  return (
    <FormModule module={`${module.name}-layer-form`} preventInitOnMount>
      {children}
    </FormModule>
  );
}
