import i18next from "i18next";

import store from "services/store";
import api from "services/api";
import Validator from "services/validator";
import { Missing, isKubernetesName } from "services/validator/rules";
import notifications from "services/notifications";
import ModalService from "services/modal";
import history from "services/history";

import { CLUSTER_GROUPS } from "utils/constants/routes";
import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";

import AsyncAction from "modules/asyncAction";
import createFormActions from "modules/form/actions";

import { getClusterGroupEntity } from "state/clustergroups/selectors/details";
import {
  CLUSTER_WORKSPACE_EDIT_BASIC_INFO_MODULE,
  DELETE_CLUSTER_WORKSPACE_FORM_MODULE,
  CLUSTER_WORKSPACE_EDIT_CLUSTERS_MODULE,
  CLUSTER_GROUP_EDIT_CLUSTERS_CONFIG_MODULE,
} from "../../services/edit";
import {
  groupsFilteredClustersFetcher,
  projectHostClustersFetcher,
  revertPackValuesConfirm,
} from "state/clustergroups/services/create";
import { scheduleWorkspaceBackupsFormAction } from "state/clustergroups/actions/backups/schedule";
import { SCHEDULE_WORKSPACE_BACKUPS_MODULE } from "state/clustergroups/services/backups";
import { HOST_CLUSTERS_LIST_MODULE } from "state/clustergroups/services/listing";
import { getHostClustersConfig } from "state/clustergroups/selectors/create";
import { refreshClusterGroup } from "../details";
import { hostClustersListActions } from "state/clustergroups/actions/hostclusters/listing";
import { getQuotaValue } from "utils/presenters";
import { fetchClustersByUids } from "../create/wizardactions";

export const clusterWorkspaceSettingsModal = new ModalService();
export const deleteClusterWorkspaceModal = new ModalService();
const basicInfoValidator = new Validator();
const deleteClusterWorkspaceValidator = new Validator();
const editClustersValidator = new Validator();

basicInfoValidator.addRule(["name"], Missing());
basicInfoValidator.addRule(["name"], isKubernetesName());

editClustersValidator.addRule(["clusters"], Missing());
editClustersValidator.addRule("hostClusters", function* (value, key, data) {
  if (data?.clusterEndpointType === "Ingress") {
    for (const clusterUid in value) {
      yield {
        result: !value?.[clusterUid]?.ingressHost
          ? i18next.t("Missing field")
          : false,
        field: `hostClusters.${clusterUid}.ingressHost`,
      };
    }
  }
});

deleteClusterWorkspaceValidator.addRule(["workspaceName"], Missing());
deleteClusterWorkspaceValidator.addRule(["workspaceName"], function (value) {
  const groupName = getClusterGroupEntity(store.getState())?.metadata?.name;
  if (groupName === value) {
    return false;
  }
  return i18next.t("Field must match the cluster group name");
});

export const clusterGroupEditBasicInfoFormActions = createFormActions({
  init: () => {
    const clusterGroup = getClusterGroupEntity(store.getState());
    const { name, annotations, labels } = clusterGroup?.metadata || {};

    return Promise.resolve({
      name: name || "",
      description: annotations?.description || "",
      tags: parseTagsForInput(labels),
    });
  },
  validator: basicInfoValidator,
  submit: async ({ name, description, tags }) => {
    const uid = getClusterGroupEntity(store.getState())?.metadata?.uid;
    const payload = {
      name,
      annotations: {
        description,
      },
      labels: formatTags(tags),
    };
    const promise = api.put(`v1/clustergroups/${uid}/meta`, payload);

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the cluster group info"
        ),
        description: error?.message,
      });
      return promise;
    }
  },
});

export const clusterGroupEditClusters = createFormActions({
  validator: editClustersValidator,
  init: async () => {
    const clusterGroup = getClusterGroupEntity(store.getState());
    const clusterRefs = clusterGroup?.spec?.clusterRefs || [];
    const clusters = (clusterRefs || []).map((cluster) => cluster?.clusterUid);
    if (clusters.length > 0) {
      await fetchClustersByUids(clusters);
    }

    await store.dispatch(projectHostClustersFetcher.fetch());

    const filteredGuids = (
      projectHostClustersFetcher.selector(store.getState())?.result || []
    )
      .filter((item) => {
        return clusters.includes(item?.metadata.uid);
      })
      .map((item) => item.guid);

    const { endpointType, limitConfig, hostClustersConfig } =
      clusterGroup?.spec?.clustersConfig || {};

    const hostClusters = (clusterRefs || []).reduce((acc, cluster) => {
      const clusterUid = cluster.clusterUid;
      const hostClusterConfig = (hostClustersConfig || []).find(
        (host) => host.clusterUid === clusterUid
      );

      return {
        ...acc,
        [clusterUid]: {
          ingressHost: hostClusterConfig
            ? (
                hostClusterConfig.endpointConfig?.ingressConfig?.host || ""
              )?.replace("*.", "")
            : "",
        },
      };
    }, {});

    await store.dispatch(groupsFilteredClustersFetcher.fetch());

    return Promise.resolve({
      hostClusters,
      clusterEndpointType: endpointType || "Ingress",
      oversubscription: limitConfig?.overSubscription || "120",
      clusters,
      selectedClustersGuids: filteredGuids || [],
    });
  },
  submit: async (data) => {
    const clusterGroup = getClusterGroupEntity(store.getState());

    const clusterRefs = data.clusters.map((clusterUid) => {
      return {
        clusterUid,
      };
    });

    const payload = {
      clusterRefs,
      clustersConfig: {
        ...clusterGroup?.spec?.clustersConfig,
        endpointType: data.clusterEndpointType,
        hostClustersConfig: getHostClustersConfig({
          data,
          selectedClusters: clusterRefs.map(({ clusterUid }) => clusterUid),
        }),
        limitConfig: {
          ...clusterGroup?.spec?.clustersConfig?.limitConfig,
          overSubscription: parseFloat(data.oversubscription) || 0,
        },
      },
    };

    const promise = api.put(
      `v1/clustergroups/${clusterGroup.metadata.uid}/hostCluster`,
      payload
    );

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the Cluster Group"
        ),
        description: err?.message,
      });
      return promise;
    }
  },
});

export const clusterGroupEditClustersConfig = createFormActions({
  init: async () => {
    const clusterGroup = getClusterGroupEntity(store.getState());
    const clusterRefs = clusterGroup?.spec?.clusterRefs || [];
    const clusters = (clusterRefs || []).map((cluster) => cluster?.clusterUid);
    if (clusters.length > 0) {
      await fetchClustersByUids(clusters);
    }

    await store.dispatch(projectHostClustersFetcher.fetch());

    const { limitConfig, values, kubernetesDistroType } =
      clusterGroup?.spec?.clustersConfig || {};

    await store.dispatch(groupsFilteredClustersFetcher.fetch());

    return Promise.resolve({
      cpuLimits: getQuotaValue((limitConfig?.cpuMilliCore || 0) / 1000, 6),
      memoryLimits: getQuotaValue((limitConfig?.memoryMiB || 0) / 1024, 8),
      storageLimits: getQuotaValue(limitConfig?.storageGiB, 10),
      yamlConfigValues: values,
      kubernetesDistroType,
    });
  },
  submit: async (data) => {
    const clusterGroup = getClusterGroupEntity(store.getState());
    const { limitConfig } = clusterGroup?.spec?.clustersConfig || {};

    const payload = {
      clusterRefs: clusterGroup?.spec?.clusterRefs || [],
      clustersConfig: {
        ...clusterGroup?.spec?.clustersConfig,
        limitConfig: {
          cpuMilliCore: getQuotaValue(data.cpuLimits, 6) * 1000,
          memoryMiB: getQuotaValue(data.memoryLimits, 8) * 1024,
          storageGiB: getQuotaValue(data.storageLimits, 10),
          overSubscription: limitConfig?.overSubscription || 0,
        },
        values: data.yamlConfigValues,
      },
    };

    const promise = api.put(
      `v1/clustergroups/${clusterGroup.metadata.uid}/hostCluster`,
      payload
    );

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the Cluster Group"
        ),
        description: err?.message,
      });
      return promise;
    }
  },
});

export function openClusterWorkspaceSettingsModal(menuItem = "basic-info") {
  return () => {
    clusterWorkspaceSettingsModal.open({ menuItem });
  };
}

export const CLUSTER_GROUP_SETTINGS_FORMS = {
  "basic-info": {
    formActions: clusterGroupEditBasicInfoFormActions,
    module: CLUSTER_WORKSPACE_EDIT_BASIC_INFO_MODULE,
  },
  clusters: {
    formActions: clusterGroupEditClusters,
    module: CLUSTER_WORKSPACE_EDIT_CLUSTERS_MODULE,
  },
  settings: {
    formActions: clusterGroupEditClustersConfig,
    module: CLUSTER_GROUP_EDIT_CLUSTERS_CONFIG_MODULE,
  },
  "schedule-backups": {
    formActions: scheduleWorkspaceBackupsFormAction,
    module: SCHEDULE_WORKSPACE_BACKUPS_MODULE,
  },
};

export const deleteClusterWorkspaceFormActions = createFormActions({
  init() {
    return Promise.resolve({ workspaceName: "" });
  },
  validator: deleteClusterWorkspaceValidator,
  submit: async () => {
    const clusterGroup = getClusterGroupEntity(store.getState());
    const promise = api.delete(
      `v1/clustergroups/${clusterGroup?.metadata?.uid}`
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t(
          `Cluster group ${clusterGroup?.metadata?.name} was successfully deleted`
        ),
      });
      clusterWorkspaceSettingsModal.close();
      history.push(CLUSTER_GROUPS.ROOT);
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to delete the cluster group"
        ),
        description: e?.message,
      });
    }
  },
});

export const deleteClusterWorkspaceAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      deleteClusterWorkspaceFormActions.submit({
        module: DELETE_CLUSTER_WORKSPACE_FORM_MODULE,
      })
    );
  },
});

export function onClusterWorkspaceDelete() {
  return function thunk(dispatch) {
    dispatch(
      deleteClusterWorkspaceFormActions.init({
        module: DELETE_CLUSTER_WORKSPACE_FORM_MODULE,
      })
    );
    deleteClusterWorkspaceModal.open().then(() => {
      return deleteClusterWorkspaceAsyncAction.trigger();
    });
  };
}

export function refreshClusterGroups() {
  return (dispatch) => {
    dispatch(hostClustersListActions.refreshItems(HOST_CLUSTERS_LIST_MODULE));
    dispatch(refreshClusterGroup());
  };
}

export function onClusterRemove(clusterUid) {
  return (dispatch, getState) => {
    const formData =
      getState().forms[CLUSTER_WORKSPACE_EDIT_CLUSTERS_MODULE]?.data;
    const clusterUids = formData?.clusters || [];
    const hostClusters = { ...formData?.hostClusters } || {};

    const value = clusterUids.filter((uid) => uid !== clusterUid);

    dispatch(
      clusterGroupEditClusters.onChange({
        module: CLUSTER_WORKSPACE_EDIT_CLUSTERS_MODULE,
        name: "clusters",
        value,
      })
    );

    delete hostClusters[clusterUid];

    dispatch(
      clusterGroupEditClusters.onChange({
        module: CLUSTER_WORKSPACE_EDIT_CLUSTERS_MODULE,
        name: "hostClusters",
        value: hostClusters,
      })
    );

    dispatch(
      clusterGroupEditClusters.clearFieldErrors({
        module: CLUSTER_WORKSPACE_EDIT_CLUSTERS_MODULE,
        field: `hostClusters.${clusterUid}.ingressHost`,
      })
    );
  };
}

export function onEditorValuesChange(value) {
  return (dispatch) => {
    dispatch(
      clusterGroupEditClustersConfig.onChange({
        module: CLUSTER_GROUP_EDIT_CLUSTERS_CONFIG_MODULE,
        name: "yamlConfigValues",
        value,
      })
    );
  };
}

export function onEditorRevert() {
  return (dispatch, getState) => {
    const clusterGroup = getClusterGroupEntity(getState());
    const value = clusterGroup?.spec?.clustersConfig?.values;

    revertPackValuesConfirm.open().then(() => {
      dispatch(
        clusterGroupEditClustersConfig.onChange({
          module: CLUSTER_GROUP_EDIT_CLUSTERS_CONFIG_MODULE,
          name: "yamlConfigValues",
          value,
        })
      );
    });
  };
}
