import { nonProjectApi } from "services/api";
import ModalService from "services/modal";

import dataFetcher from "modules/dataFetcher";

export const API_KEYS_LIST_MODULE = "apikeys";
export const API_KEYS_FORM_MODULE = "apikeys";

export const apiKeyModal = new ModalService("apikeyModal");

export const apiKeysFetcher = dataFetcher({
  selectors: ["apikeys"],
  async fetchData() {
    const promise = nonProjectApi.get(`v1/apiKeys`);
    let response;
    try {
      response = await promise;
    } catch (e) {
      return {
        items: [],
      };
    }
    return response;
  },
});
