import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { APP_MODE_OVERVIEW } from "utils/constants/routes";
import OverviewIndex from "./index";

export default createNamespace({
  namespace: "appModeOverview",
  Component: function Main() {
    return (
      <>
        <Route
          exact
          devOnly
          path={APP_MODE_OVERVIEW.ROOT}
          component={OverviewIndex}
        />
      </>
    );
  },
});
