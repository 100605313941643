import { presentFilterPropertiesSchema } from "utils/presenters";

export const CONJUNCTION_OPTIONS = [
  {
    value: "and",
    label: "and",
  },
  {
    value: "or",
    label: "or",
  },
];

export const OPERATORS_OPTIONS = [
  {
    value: "eq",
    label: "is",
  },
  {
    value: "neq",
    label: "is not",
  },
];

export const STRING_OPERATORS_OPTIONS = [
  ...OPERATORS_OPTIONS,
  {
    value: "contains",
    label: "contains",
  },
  {
    value: "not contain",
    label: "does not contain",
  },
  { value: "beginsWith", label: "begins with" },
  { value: "not beginsWith", label: "does not begin with" },
];

export const NUMERIC_OPERATORS_OPTIONS = [
  {
    label: "is greater than",
    value: "gt",
  },
  {
    label: "is not greater than",
    value: "ngt",
  },
  {
    label: "is lower than",
    value: "lt",
  },
  {
    label: "is not lower than",
    value: "nlt",
  },
  {
    label: "is equal to",
    value: "eq",
  },
  {
    label: "is not equal to",
    value: "neq",
  },
  { label: "is greater than or equal to", value: "gte" },
  { label: "is not greater than or equal to", value: "ngte" },
  { label: "is lower than or equal to", value: "lte" },
  { label: "is not lower than or equal to", value: "nlte" },
];

export const BOOL_OPERATORS_OPTIONS = [
  {
    value: "eq",
    label: "is",
  },
];

export const OPERATORS_MAPPING = {
  gt: { negation: false, value: "gt" },
  ngt: { negation: true, value: "gt" },
  lt: { negation: false, value: "lt" },
  nlt: { negation: true, value: "lt" },
  eq: { negation: false, value: "eq" },
  neq: { negation: true, value: "eq" },
  gte: { negation: false, value: "gte" },
  ngte: { negation: true, value: "gte" },
  lte: { negation: false, value: "lte" },
  nlte: { negation: true, value: "lte" },
  contains: { negation: false, value: "contains" },
  "not contain": { negation: true, value: "contains" },
  beginsWith: { negation: false, value: "beginsWith" },
  "not beginsWith": { negation: true, value: "beginsWith" },
};

export const DEFAULT_CLUSTERS_FILTERS = [
  {
    name: "isDeleted",
    displayName: "Deleted only",
    type: "bool",
    default: true,
  },
  {
    name: "isUpdatesPending",
    displayName: "Updates Pending",
    type: "bool",
    default: false,
  },
];

export const FILTER_FIELD_TYPE_MAPPING = {
  input(data) {
    return {
      defaultValue: data?.default || "",
      operators: STRING_OPERATORS_OPTIONS,
    };
  },
  tags(data) {
    return {
      defaultValue: data?.default || [],
      operators: OPERATORS_OPTIONS,
    };
  },
  select(data) {
    return {
      defaultValue: data?.default || [],
      operators: OPERATORS_OPTIONS,
      options: data.enum?.map((elem) => ({
        label: elem,
        value: elem,
      })),
    };
  },
  bool(data) {
    return {
      defaultValue: data?.default || false,
      operators: BOOL_OPERATORS_OPTIONS,
      options: [
        { label: "true", value: true },
        { label: "false", value: false },
      ],
    };
  },
  coordinates(data) {
    return {
      defaultValue: "",
      operators: OPERATORS_OPTIONS,
      minValue: data?.minFloatVal || 0,
      maxValue: data?.maxFloatVal || 0,
    };
  },
  int(data) {
    return {
      defaultValue: data?.default || 0,
      operators: NUMERIC_OPERATORS_OPTIONS,
      minValue: data?.minFloatVal || 0,
      maxValue: data?.maxFloatVal || 0,
    };
  },
  float(data) {
    return {
      defaultValue: data?.default || 0,
      operators: NUMERIC_OPERATORS_OPTIONS,
      minValue: data?.minIntVal || 0,
      maxValue: data?.maxIntVal || 0,
    };
  },
};

export const PRESENTED_DEFAULT_CLUSTERS_FILTERS = presentFilterPropertiesSchema(
  DEFAULT_CLUSTERS_FILTERS
);

export const ALL_OPERATORS = [
  ...STRING_OPERATORS_OPTIONS,
  ...NUMERIC_OPERATORS_OPTIONS,
];
