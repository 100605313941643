import clusters1 from "./clusters1.png";
import clusters2 from "./clusters2.png";
import clusters3 from "./clusters3.png";
import profiles1 from "./profiles1.png";
import profiles2 from "./profiles2.png";
import profiles3 from "./profiles3.png";
import workspaces1 from "./workspaces1.png";
import workspaces2 from "./workspaces2.png";
import workspaces3 from "./workspaces3.png";
import tenant1 from "./tenant1.png";
import tenant2 from "./tenant2.png";
import tenant3 from "./tenant3.png";

import blurred_clusters2 from "./blurred_clusters2.png";
import blurred_clusters3 from "./blurred_clusters3.png";
import blurred_profiles2 from "./blurred_profiles2.png";
import blurred_profiles3 from "./blurred_profiles3.png";
import blurred_workspaces2 from "./blurred_workspaces2.png";
import blurred_workspaces3 from "./blurred_workspaces3.png";
import blurred_tenant1 from "./blurred_tenant1.png";
import blurred_tenant2 from "./blurred_tenant2.png";
import blurred_tenant3 from "./blurred_tenant3.png";

import dev_option1 from "./dev_option1.png";
import dev_option2 from "./dev_option2.png";
import dev_option3 from "./dev_option3.png";
import astronaut from "./astronaut.png";
import rocket from "./rocket.png";

const images = {
  clusters1,
  clusters2,
  clusters3,
  profiles1,
  profiles2,
  profiles3,
  workspaces1,
  workspaces2,
  workspaces3,
  tenant1,
  tenant2,
  tenant3,
  blurred_clusters2,
  blurred_clusters3,
  blurred_profiles2,
  blurred_profiles3,
  blurred_workspaces2,
  blurred_workspaces3,
  blurred_tenant1,
  blurred_tenant2,
  blurred_tenant3,
  dev_option1,
  dev_option2,
  dev_option3,
  astronaut,
  rocket,
};

export default images;
