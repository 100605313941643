import appEnv from "services/app";
import { RELEASED_FLAGS } from "utils/constants/flags";

export class Flags {
  constructor(params = "") {
    if (!Array.isArray(params)) {
      this.params = params.split(",") || [];
      return;
    }
    this.params = params;
  }

  has(list = []) {
    if (this.params.includes("all")) {
      return true;
    }

    let flags = list;
    if (!Array.isArray(list)) {
      flags = [flags];
    }

    if (list.length === 0) {
      return true;
    }

    return flags.some((param) => this.params.includes(param));
  }

  is(list = []) {
    if (this.params.includes("all")) {
      return true;
    }

    let flags = list;
    if (!Array.isArray(list)) {
      flags = [flags];
    }

    if (list.length === 0) {
      return true;
    }

    return flags.every((param) => this.params.includes(param));
  }
}

const flags = new Flags(
  `${import.meta.env.SPECTRO_FLAGS},${RELEASED_FLAGS.join(",")}`
);

export function getDemoFlags() {
  const flags = Object.keys(appEnv.env.demos || {});
  return new Flags(flags.join(","));
}

export function getFeatureFlags() {
  const flags = appEnv.featureFlags || [];
  return new Flags(flags.join(","));
}

export default flags;
