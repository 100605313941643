import { Tooltip } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { dodgerBlue, greenGray, white } from "utils/constants/colors";

import Icon from "components/ui/Icon";

const ToolbarWrapper = styled.div`
  width: 48px;
  height: 100%;
  background: ${white};
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    display: block;
    color: #9fa9b7;
    font-size: 9px;
    text-transform: uppercase;
    padding: 8px 0;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Tab = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${dodgerBlue};
  transition: all 0.2s ease-in-out;
  border-radius: 3px;

  svg {
    color: ${white};
  }

  :hover {
    opacity: ${(props) => !props.isActive && "0.85"};
  }

  ${(props) =>
    props.light &&
    css`
      width: 48px;
      height: 48px;
      background: transparent;
      svg,
      svg path {
        font-size: 20px;
        color: ${greenGray};
        fill: ${greenGray};
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      width: 48px;
      height: 48px;
      background: #f3f2ff;
      border-radius: 0;

      svg,
      svg path {
        color: ${dodgerBlue};
        fill: ${dodgerBlue};
      }
    `}
`;

function TabItem({ selected, onClick, icon, description, light }) {
  const tab = (
    <Tab isActive={selected} onClick={onClick} light={light}>
      <Icon {...icon} style={{ fontSize: "18px" }} />
    </Tab>
  );

  // need to add the idea of making it default visible on first switch
  if (description && !selected) {
    return (
      <Tooltip placement="right" title={description}>
        {tab}
      </Tooltip>
    );
  }
  return tab;
}

function Toolbar({ tabs, onTabSelect, ...rest }) {
  const { t } = useTranslation();
  const topTabs = useMemo(
    () => tabs.filter((tab) => !tab.bottom && !tab.beforeActions),
    [tabs]
  );
  const beforeActionsTabs = useMemo(
    () => tabs.filter((tab) => tab.beforeActions),
    [tabs]
  );
  const bottomTabs = useMemo(() => tabs.filter((tab) => tab.bottom), [tabs]);
  const onTabClick = useCallback(
    (tab) => {
      onTabSelect?.(tab.id);
    },
    [onTabSelect]
  );

  return (
    <ToolbarWrapper>
      <TabsWrapper>
        <div>
          {beforeActionsTabs.map((tab) => (
            <TabItem
              {...rest}
              {...tab}
              key={tab.id}
              onClick={() => onTabClick(tab)}
            />
          ))}
        </div>
      </TabsWrapper>
      {topTabs.length ? <span>{t("Actions")}</span> : null}
      <TabsWrapper>
        <div>
          {topTabs.map((tab) => (
            <TabItem
              {...rest}
              {...tab}
              key={tab.id}
              onClick={() => onTabClick(tab)}
            />
          ))}
        </div>
        <div>
          {bottomTabs.map((tab) => (
            <TabItem
              {...rest}
              {...tab}
              key={tab.id}
              onClick={() => onTabClick(tab)}
            />
          ))}
        </div>
      </TabsWrapper>
    </ToolbarWrapper>
  );
}

export default React.memo(Toolbar);
