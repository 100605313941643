import i18next from "i18next";
import createFormActions from "modules/form/actions";
import api from "services/api";
import history from "services/history";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import {
  migrateOverlordModal,
  OVERLORD_MIGRATE_MODULE,
} from "../services/list";

const validator = new Validator();
validator.addRule(["targetUid"], Missing());

export const migrateOverlordFormActions = createFormActions({
  validator,
  init: () => {
    return Promise.resolve({
      targetUid: "",
    });
  },
  submit: async (data) => {
    const payload = {
      targetUid: data.targetUid,
      sourceUid: migrateOverlordModal.data?.uid,
    };

    const promise = api.post("v1/overlords/migrate", payload);
    try {
      await promise;
      notifications.success({
        message: i18next.t("Migration process has been triggered successfully"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
    }

    return promise;
  },
});

export function openMigrateOverlordModal(uid, cloudType) {
  return (dispatch) => {
    migrateOverlordModal.open({ uid, cloudType }).then(
      async () => {
        await dispatch(
          migrateOverlordFormActions.submit({
            module: OVERLORD_MIGRATE_MODULE,
          })
        );

        history.push("/settings/privatecloudgateways");
      },
      () => history.push("/settings/privatecloudgateways")
    );

    dispatch(
      migrateOverlordFormActions.init({
        module: OVERLORD_MIGRATE_MODULE,
      })
    );
  };
}
