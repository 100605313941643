import store from "services/store";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  CLOUD_ACCOUNTS_MODULE,
  cloudAccountFetcher,
  cloudAccountFormModal,
  coxEdgeEndpointsFetcher,
  coxEdgeEnvironmentsFetcher,
  coxEdgeOrganizationsFetcher,
  coxEdgeServicesFetcher,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";

const validator = new Validator();
validator.addRule(
  ["name", "apiKey", "apiBaseUrl", "environment", "organizationId", "service"],
  Missing()
);
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

async function submit(data) {
  const payload = {
    metadata: {
      name: data.name,
    },
    spec: {
      apiKey: data.apiKey.trim(),
      apiBaseUrl: data.apiBaseUrl.trim(),
      environment: data.environment.trim(),
      organizationId: data.organizationId.trim(),
      service: data.service.trim(),
    },
  };

  return store.dispatch(createAccount("coxedge", payload));
}

async function init() {
  store.dispatch(coxEdgeEndpointsFetcher.fetch());
  let data;
  if (cloudAccountFormModal.data?.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  return Promise.resolve({
    type: "coxedge",
    name: data?.metadata.name || "",
    apiKey: data?.spec.apiKey || "",
    apiBaseUrl: data?.spec.apiBaseUrl || "",
    environment: data?.spec.environment || "",
    organizationId: data?.spec.organizationId || "",
    service: data?.spec.service || "",
  });
}

export const coxedgeAccountFormActions = createActions({
  submit,
  validator,
  init,
});

export function onApiFieldsBlur(name) {
  return async (dispatch, getState) => {
    const formData = getState().forms.cloudAccounts.data;
    const initialFormData = getState().forms.cloudAccounts.initialData;
    const isInitialData =
      initialFormData.apiBaseUrl === formData.apiBaseUrl &&
      initialFormData.apiKey === formData.apiKey;

    dispatch(
      coxedgeAccountFormActions.validateField({
        name,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    if (!formData.apiKey || !formData.apiBaseUrl) {
      return;
    }

    if (isInitialData) {
      return;
    }

    dispatch(coxEdgeOrganizationsFetcher.fetch());
    dispatch(coxEdgeServicesFetcher.fetch());
  };
}

export function onChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: CLOUD_ACCOUNTS_MODULE };
    const isAccountValidated = getState().cloudaccounts.form.isValidated;

    if (isAccountValidated) {
      dispatch({
        type: "SET_ACCOUNT_AS_INVALID",
      });
    }

    if (name === "apiBaseUrl") {
      dispatch(
        coxedgeAccountFormActions.batchChange({
          module: CLOUD_ACCOUNTS_MODULE,
          updates: {
            apiKey: "",
            organizationId: "",
            environment: "",
            service: "",
          },
        })
      );
    }

    if (name === "organizationId") {
      dispatch(coxEdgeEnvironmentsFetcher.key(value).fetch());
    }

    dispatch(coxedgeAccountFormActions.onChange({ ...props }));
  };
}
