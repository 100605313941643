import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { VIRTUAL_CLUSTER } from "utils/constants/routes";

const VirtualClusterIndex = React.lazy(() => import("./index"));
const CreateVirtualCluster = React.lazy(() => import("./create"));

export default createNamespace({
  namespace: "virtual-cluster",
  Component: function Main() {
    return (
      <>
        <Route
          exact
          devOnly
          path={VIRTUAL_CLUSTER.ROOT}
          component={VirtualClusterIndex}
        />
        <Route
          exact
          devOnly
          path={VIRTUAL_CLUSTER.CREATE_CLUSTER}
          component={CreateVirtualCluster}
        />
      </>
    );
  },
});
