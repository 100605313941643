import { v4 as uuid } from "uuid";

function getNestedValue(obj, path) {
  let paths = path;
  if (typeof path === "string") {
    paths = path.split(".");
    return getNestedValue(obj, paths);
  }
  return paths.reduce((accumulator, pathPart) => accumulator?.[pathPart], obj);
}

export class UuuidRegistry {
  constructor() {
    this._registry = {};
  }

  getUuid(entityType, entity, { identifier = "id", id = entity.id } = {}) {
    const entityId = id || getNestedValue(entity, identifier);
    if (entity.guid) {
      return entity.guid;
    }

    this._registry[entityType] = this._registry[entityType] || {};
    if (!entityId) {
      if (this._registry[entityType][entity.guid]) {
        return this._registry[entityType][entity.guid];
      }

      entity.guid = uuid();
      this._registry[entityType][entity.guid] = entity.guid;
      return entity.guid;
    }

    if (this._registry[entityType][entityId]) {
      return this._registry[entityType][entityId];
    }

    entity.guid = uuid();
    this._registry[entityType][entityId] = entity.guid;
    return entity.guid;
  }
}

const registry = new UuuidRegistry();
export default registry;
