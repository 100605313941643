import React from "react";
import isEqual from "fast-deep-equal";
import { createSelector } from "reselect";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { versionSort } from "utils/versionSort";
import * as colors from "utils/constants/colors";
import { Tooltip } from "antd";

import store from "services/store";
import ModalService from "services/modal";
import { getStoreEntity } from "services/store";
import fetchers from "./services";

import { PackSchema } from "utils/schemas";
import { extractPayload } from "./utils";
import { extractInstallOrder } from "utils/yaml";

import ProfileBuilderActions from "./actions";
import { getMacrosSuggestions } from "state/macros/selectors";
import {
  DISABLED_SYSTEM_STATES,
  SYSTEM_STATE_ICON,
  SYSTEM_STATE_TOOLTIP_MSG,
} from "utils/constants/clusterprofile";

const TooltipMessage = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.white};
  opacity: 0.7;
`;

function matchAmbiguousPatch(tag = "") {
  const [major, minor, patch] = tag.split(".");
  return major !== undefined && minor !== undefined && patch === "x";
}

function sanitizeVersion(version) {
  let sanitizedVersion = version;
  if (version.split(".").length < 3) {
    sanitizedVersion = `${version}.0`;
  }

  return sanitizedVersion
    .split(".")
    .map((subVersion) => {
      if (subVersion.startsWith("0")) {
        return subVersion.replace("0", "");
      }

      return subVersion;
    })
    .join(".");
}

class ProfileBuilder {
  constructor() {
    this.guid = uuid();
    this.revertPackValuesConfirm = new ModalService();
    this.removeLayerConfirm = new ModalService();
    this.cancelLayerChanges = new ModalService();
    this.addNewRepositoryModal = new ModalService();

    this.actions = new ProfileBuilderActions({
      guid: this.guid,
      revertPackValuesConfirm: this.revertPackValuesConfirm,
      removeLayerConfirm: this.removeLayerConfirm,
      cancelLayerChanges: this.cancelLayerChanges,
      isLayerFormInitialState: this.selectors.isLayerFormInitialState,
      areCoreLayersConfigured: this.selectors.areCoreLayersConfigured,
      addNewRepositoryModal: this.addNewRepositoryModal,
      getState: () => this.state,
    });
  }

  get state() {
    const state = store.getState().profileBuilder[this.guid];

    return {
      ...state,
      orderedLayers: this.selectors.getOrderedLayers(store.getState()),
      macros: getMacrosSuggestions(store.getState()),
    };
  }

  get payload() {
    const state = this.state;

    if (!state) {
      return {
        layers: [],
        variables: [],
      };
    }

    return {
      layers: extractPayload(state.layers),
      variables: state?.variables || [],
    };
  }

  isProfileValid() {
    const { layers, draftLayers, selectedLayer, showEditor, errors } =
      this.state || {};
    const isDraftsEmpty = !draftLayers?.length;
    let areLayersValid = true;
    const packLayersConfigured =
      layers?.length &&
      layers
        .filter((layer) => layer.type !== "manifest")
        .every((layer) => layer.config?.tag);
    const manifestLayersConfigured =
      layers?.length &&
      layers
        .filter((layer) => layer.type === "manifest")
        .every((layer) => layer.config?.name && layer.config.manifests?.length);

    if (errors && Object.keys(errors)?.length) {
      areLayersValid = !Object.keys(errors)?.find((layerUid) =>
        errors[layerUid]?.find((err) => err.result)
      );
    }

    const isValid =
      areLayersValid &&
      packLayersConfigured &&
      manifestLayersConfigured &&
      isDraftsEmpty &&
      !selectedLayer &&
      !showEditor;
    return isValid;
  }

  selectors = {
    getOrderedLayers: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (layers) => {
        if (!layers) {
          return [];
        }

        return layers.sort((layer1, layer2) => {
          const order1 = parseInt(extractInstallOrder(layer1.config));
          const order2 = parseInt(extractInstallOrder(layer2.config));
          const areBasicLayer = [layer1.type, layer2.type].some((type) =>
            ["os", "cni", "csi", "k8s"].includes(type)
          );

          if (areBasicLayer) {
            return 0;
          }

          if (layer1.type === "new") {
            return 1;
          }

          if (Number.isNaN(order1) || Number.isNaN(order2)) {
            return 0;
          }

          return order1 - order2;
        });
      }
    ),
    getPackErrors: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (state) => state.profileBuilder[this.guid]?.errors,
      (layers = [], errors = []) => {
        return layers.reduce((accumulator, layer) => {
          const packErrors = errors[layer.guid]?.filter(
            (error) => !!error.result
          );
          if (Array.isArray(packErrors) && packErrors.length !== 0) {
            accumulator.push({
              name: layer.config.name,
              errors: packErrors.map((error) => ({
                ...error,
                message: error.result,
              })),
            });
          }

          return accumulator;
        }, []);
      }
    ),
    hasIncompleteLayers: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (layers = []) => {
        if (layers.length === 0) {
          return true;
        }

        return layers.some((layer) => {
          if (layer.type === "manifest") {
            const manifests = layer?.config?.manifests || [];
            if (manifests.length === 0) {
              return true;
            }

            return manifests.some(
              (manifest) => !manifest.persisted && !manifest.content
            );
          }

          return !layer.config?.tag;
        });
      }
    ),
    hasUnsavedChanges: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (state) => state.profileBuilder[this.guid]?.initialLayers,
      (layers, initialLayers) => {
        const payloads = [
          extractPayload(layers),
          extractPayload(initialLayers),
        ];

        return !isEqual(...payloads);
      }
    ),
    hasDeletedPacks: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (layers) => {
        return (layers || []).some(
          (layer) => layer?.annotations?.system_state === "deleted"
        );
      }
    ),
    areCoreLayersConfigured: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (state) => state.profileBuilder[this.guid]?.isEditMode,
      (state) => state.forms.clusterprofile?.data.profileType,
      (layers = [], isEditMode, profileType) => {
        if (isEditMode || profileType === "add-on") {
          return true;
        }

        const areLayersConfigured = layers
          .filter((layer) => ["os", "cni", "csi", "k8s"].includes(layer.type))
          .every((layer) => layer.config?.tag);

        return areLayersConfigured;
      }
    ),
    isLayerFormInitialState: createSelector(
      (state) =>
        state.forms[state.profileBuilder[this.guid].formType]?.initialData,
      (state) => state.forms[state.profileBuilder[this.guid].formType]?.data,
      (initialData, data) => {
        return isEqual(initialData, data);
      }
    ),
    getPackVersionsTree: createSelector(
      fetchers.packVersionsFetcher.selector,
      (state) => state.profileBuilder[this.guid],
      (
        { result: versions },
        { selectedLayer = "", isEditMode, isClusterProfileUsed } = {}
      ) => {
        if (!versions) {
          return;
        }

        let sortedVersions = [...versions].sort((pack1, pack2) => {
          let version1 = sanitizeVersion(pack1.version);
          let version2 = sanitizeVersion(pack2.version);
          return versionSort(version1, version2, true);
        });

        if (isEditMode) {
          const selectedLayerData =
            selectedLayer && getStoreEntity(selectedLayer, PackSchema)?.spec;

          if (selectedLayerData?.layer === "k8s") {
            const selectedVersionIndex = sortedVersions.findIndex(({ tag }) => {
              return selectedLayerData.version === tag;
            });
            sortedVersions = sortedVersions.map((version, index) => ({
              ...version,
              disabled:
                isClusterProfileUsed && selectedVersionIndex > -1
                  ? index >= selectedVersionIndex + 1
                  : false,
            }));
          }
        }

        const getIcon = (version) => {
          const systemState = version?.annotations?.system_state;
          if (
            ![...DISABLED_SYSTEM_STATES, "deprecated"].includes(systemState)
          ) {
            return null;
          }

          return (
            <Tooltip
              title={
                <TooltipMessage>
                  {SYSTEM_STATE_TOOLTIP_MSG(systemState)}
                </TooltipMessage>
              }
              color={colors.darkBlue}
              placement="right"
            >
              {SYSTEM_STATE_ICON(systemState)}
            </Tooltip>
          );
        };

        const isDisabled = (version) => {
          if (
            DISABLED_SYSTEM_STATES.includes(version?.annotations?.system_state)
          ) {
            return true;
          }
          return version.disabled;
        };

        const roots = sortedVersions
          .filter((version) => {
            return matchAmbiguousPatch(version.tag);
          })
          .map((version) => {
            return {
              title: version.tag,
              value: version.tag,
              annotations: version?.annotations,
              info: version.version,
              icon: getIcon(version),
              disabled: isDisabled(version),
            };
          });

        sortedVersions.forEach((version) => {
          const parentTags = version?.parentTags || [];
          const parent = parentTags.find(matchAmbiguousPatch);

          if (!parent) return;

          const parentVersion = roots.find(
            (rootVersion) => rootVersion.title === parent
          );

          if (parentVersion) {
            parentVersion.children = parentVersion.children || [];
            parentVersion.children.push({
              title: version.tag,
              value: version.tag,
              annotations: version?.annotations,
              icon: getIcon(version),
              disabled: isDisabled(version),
            });
          }
        });

        return roots;
      }
    ),
    getVariables: createSelector(
      (state) => state.profileBuilder[this.guid]?.layers,
      (state) => state.profileBuilder[this.guid]?.variables,
      (layers = [], variables) => {
        const values = layers
          .filter((layer) => layer.config)
          .reduce((accumulator, layer) => {
            const manifests = layer?.config?.manifests || [];
            manifests.forEach((manifest) => {
              accumulator.push({
                parentName: layer.config.name,
                name: manifest.name,
                values: manifest.content,
              });
            });
            accumulator.push({
              name: layer.config.name,
              values: layer.config.values,
            });
            return accumulator;
          }, []);

        return variables.map((variable) => {
          const usedIn = values.reduce((accumulator, value) => {
            const { name, values, parentName } = value;
            const regex = new RegExp(
              `{{\\s*\\.spectro\\.var\\.${variable.name}\\s*}}`,
              "g"
            );
            const matches = values?.match(regex) || [];
            if (matches.length) {
              accumulator.push({
                name,
                parentName,
                occurrences: matches.length,
              });
            }
            return accumulator;
          }, []);

          return {
            ...variable,
            usedIn,
          };
        });
      }
    ),
  };
}

export default ProfileBuilder;
