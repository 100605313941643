import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import ModalService from "services/modal";

export const modalService = new ModalService();

export const auditSysMsgFetcher = dataFetcher({
  selectors: ["audit-user-message", (state) => state.audit.auditUid],
  async fetchData([_, auditUid]) {
    const res = await api.get(`v1/audits/${auditUid}/sysMsg`);
    return res.actionMsg;
  },
});

export const auditFetcher = dataFetcher({
  selectors: ["audit-details", () => modalService.data?.auditUid],
  async fetchData([_, auditUid]) {
    return api.get(`v1/audits/${auditUid}`);
  },
});
