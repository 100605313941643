import ConfirmationModal from "components/common/ConfirmationModal";
import { createForm } from "modules/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  editHostPairingKeyModal,
  EDIT_HOST_PAIRING_KEY,
} from "state/cluster/services/edgemachines";
import Fields from "components/ui/Fields";
import { editPairingKeyActions } from "state/cluster/actions/list/edgemachines";

const EditPairingKeyForm = createForm({
  actions: editPairingKeyActions,
  Component: function Form() {
    const { t } = useTranslation();
    return (
      <>
        <Fields.Tags
          name="tags"
          data-qa="tags"
          tokenSeparators={[",", " "]}
          label={t("Tags")}
        />
      </>
    );
  },
});

function EditPairingKeyConfirmationModal() {
  const { t } = useTranslation();
  return (
    <ConfirmationModal title={t("Edit Host")} service={editHostPairingKeyModal}>
      <EditPairingKeyForm module={EDIT_HOST_PAIRING_KEY} />
    </ConfirmationModal>
  );
}

export default connect()(EditPairingKeyConfirmationModal);
