import store, { getStoreEntity } from "services/store";
import api, { nonProjectApi } from "services/api";
import createFormActions from "modules/form/actions";
import fetchers, {
  manifestsValidator,
  MODULES,
  packActionsValidator,
  packListActions,
  editorValidator,
  importPacksValidator,
  systemChartsListActions,
  FORM_TYPES,
} from "../services";
import { mapFormDataToLayers, generateUbuntuAdvantagePresets } from "../utils";
import { UBUNTU_ADVANTAGE_SCHEMAS } from "utils/constants/schemas";
import { ManifestSchema } from "utils/schemas";
import {
  getPackValuesWithoutPresetsComment,
  getDefaultPresetsFromValues,
} from "utils/parsers";
import { presentUniquePackName, getPackNameComponents } from "utils/presenters";
import MANIFEST_ICON from "assets/icons/manifest.svg";

import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { validator } from "state/packregistries/actions/create";
import i18next from "i18next";
import notifications from "services/notifications";
import {
  getAvailableRepositories,
  getSelectedRepository,
  getSelectedSystemRepository,
  getSystemSelectedPack,
} from "../selectors";
import { createSelector } from "reselect";
import {
  extractInstallOrder,
  extractUbuntuAdvantagePresetOptions,
  extractManifestType,
  updateInstallOrder,
  updateManifestTypeValues,
  extractPackDisplayName,
} from "utils/yaml";
import { getEnvironments } from "utils/constants";
import { createTwoWaySyncer, PARAM_SEPARATOR } from "utils/editor/formMode";
import { isBasicLayer } from "modules/profileBuilder/utils";
import Validator from "services/validator";
import {
  ApplyIf,
  Missing,
  SemanticVersion,
  isValidIP,
  isValidIPRange,
} from "services/validator/rules";
import { v4 as uuid } from "uuid";

function parseManifest(manifest) {
  return {
    uid: manifest.metadata.uid,
    name: manifest.metadata.name,
    content: manifest.spec?.draft?.content || manifest.spec?.published?.content,
  };
}

export const getSelectedPack = createSelector(
  (state) => fetchers.packsListSearchFetcher.selector(state)?.result?.items,
  (state) => state.forms.layerPack?.data.pack,
  (items = [], packName) => {
    return items.find((pack) => pack.spec.name === packName);
  }
);

function getHelmRepositoryPayload(
  { name, endpoint, noAuth, username, password, isPrivate },
  expandedPack
) {
  return {
    metadata: {
      name,
    },
    spec: {
      endpoint,
      isPrivate,
      auth: {
        type: noAuth ? "noAuth" : "basic",
        username: noAuth ? undefined : username,
        password: noAuth ? undefined : password,
      },
      createOption: {
        skipSync: false,
        charts: [
          {
            name: expandedPack.config?.name,
            version: expandedPack.config?.tag,
          },
        ],
      },
    },
  };
}

export function presentVariableToPayload(data) {
  return {
    name: data.name,
    displayName: data.displayName,
    description: data.description,
    format: data.format,
    isSensitive: data.isMasked,
    required: data.isRequired,
    regex: data.useRegex ? data.regex : undefined,
    defaultValue: data.useDefaultValue ? data.defaultValue : undefined,
    hidden: data.isHidden,
    immutable: data.isReadOnly,
  };
}

export default function createProfileFormActions(
  guid,
  {
    togglePackValues,
    getFormUpdatesOnPresetsChange,
    setNewHelmRegistry,
    modals,
    options,
  }
) {
  const getState = () => {
    return store.getState().profileBuilder[guid];
  };

  const dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid: guid,
    });
  };

  const variableValidator = new Validator();
  variableValidator.addRule(["name", "displayName"], Missing());
  variableValidator.addRule(["name"], (value, key, data) => {
    const { variables } = getState();
    const existingVariables = variables.filter(
      (variable) => data.guid !== variable.guid && variable.name === value
    );
    const isInvalid = existingVariables.length;
    return isInvalid
      ? i18next.t("A variable with this name already exists")
      : false;
  });
  variableValidator.addRule(["displayName"], (value, key, data) => {
    const { variables } = getState();
    const existingVariables = variables.filter(
      (variable) =>
        data.guid !== variable.guid && variable.displayName === value
    );
    const isInvalid = existingVariables.length;
    return isInvalid
      ? i18next.t("A variable with this Display Name already exists")
      : false;
  });
  variableValidator.addRule(
    ["regex"],
    ApplyIf((value, key, data) => data.useRegex, Missing())
  );
  variableValidator.addRule(
    ["defaultValue"],
    ApplyIf((value, key, data) => data.useDefaultValue, Missing())
  );

  variableValidator.addRule(
    "defaultValue",
    ApplyIf(
      (value, key, data) => data.useDefaultValue && value && data.useRegex,
      (value, key, data) => {
        return !new RegExp(data.regex).test(value)
          ? i18next.t(`Value does not match the regex pattern "${data.regex}"`)
          : false;
      }
    )
  );

  variableValidator.addRule(
    "defaultValue",
    ApplyIf(
      (value, key, data) =>
        data.useDefaultValue && value && data.format === "number",
      (value, key, data) => {
        return isNaN(value) ? i18next.t("Value is not a number") : false;
      }
    )
  );

  variableValidator.addRule(
    "defaultValue",
    ApplyIf(
      (value, key, data) =>
        data.useDefaultValue && value && data.format === "version",
      SemanticVersion()
    )
  );

  variableValidator.addRule(
    "defaultValue",
    ApplyIf(
      (value, key, data) =>
        data.useDefaultValue && value && data.format === "boolean",
      (value, key, data) => {
        return !["true", "false"].includes(value)
          ? i18next.t("Value is not a boolean")
          : false;
      }
    )
  );

  variableValidator.addRule(
    "defaultValue",
    ApplyIf(
      (value, key, data) =>
        data.useDefaultValue && value && ["ipv4", "ipv6"].includes(data.format),
      (value, key, data) =>
        isValidIP({ version: data.format.replace("ipv", "") })(value, key, data)
    )
  );
  variableValidator.addRule(
    "defaultValue",
    ApplyIf(
      (value, key, data) =>
        data.useDefaultValue && value && data.format === "ipv4cidr",
      (value, key, data) =>
        isValidIPRange({
          version: data.format.replace("ipv", "").replace("cidr", ""),
        })(value, key, data)
    )
  );

  variableValidator.addRule(
    ["useDefaultValue"],
    ApplyIf((value, key, data) => {
      return data.isReadOnly;
    }, Missing({ message: () => i18next.t("If the variable is Read-only you must provide a default value") }))
  );

  const variableFormActions = createFormActions({
    validator: variableValidator,
    init: async () => {
      const guid = modals.variableForm.data?.guid;
      const data =
        getState().variables.find((variable) => variable.guid === guid) || {};
      return {
        name: "",
        displayName: "",
        description: "",
        format: "string",
        useRegex: false,
        regex: "",
        isRequired: false,
        isHidden: false,
        isMasked: false,
        useDefaultValue: false,
        isReadOnly: false,
        defaultValue: "",
        ...data,
      };
    },
    submit: async (data) => {
      const { isEditMode, profileUid } = getState();

      if (isEditMode && profileUid) {
        try {
          await api.patch(`v1/clusterprofiles/${profileUid}/variables`, {
            variables: [presentVariableToPayload(data)],
          });
        } catch (err) {
          notifications.error({
            message: data.guid
              ? i18next.t("Failed to update variable")
              : i18next.t("Failed to create variable"),
            description: err.message,
          });

          return Promise.reject();
        }

        notifications.success({
          message: data.guid
            ? i18next.t("Variable {{variableName}} updated successfully", {
                variableName: data.displayName,
              })
            : i18next.t("Variable {{variableName}} created successfully", {
                variableName: data.displayName,
              }),
        });
      }

      dispatch({
        type: "PROFILE_BUILDER_UPSERT_VARIABLE",
        data: {
          ...data,
          guid: data.guid || uuid(),
          isPersisted: isEditMode && profileUid,
        },
      });
    },
  });

  const packSearchActions = createFormActions({
    init: async () => {
      const { selectedLayer, draftLayers, layers } = getState();

      let layer = {};

      if (selectedLayer) {
        layer = layers.find((layer) => layer.guid === selectedLayer) || {};
      } else if (draftLayers.length) {
        layer = layers.find((layer) => layer.guid === draftLayers[0]) || {};
      }

      const packType =
        (layer.type === "new" ? layer.defaultPackType : layer.type) ||
        undefined;

      return Promise.resolve({
        packType,
        registry: layer?.config?.registryUid || undefined,
      });
    },
    submit: () => {},
  });

  const packActions = createFormActions({
    init: async () => {
      const { selectedLayer, draftLayers, layers, formType, layerClone } =
        getState();
      const layer = layers.find((layer) => layer.guid === selectedLayer) || {};
      const isSystemProfile = formType === MODULES.SYSTEM_MODULE;
      const { type, config } = layer;
      let packType = type || "";
      let accessType = layerClone?.accessType || "";
      const cloudType = store.getState().forms.clusterprofile?.data?.cloudType;
      const isUbuntu = config?.name?.includes("ubuntu");
      const managedCloudTypes = getEnvironments(getState())
        .filter((env) => env.isSupervised)
        .map((env) => env.apiKey);
      const isManagedCloudType = managedCloudTypes.includes(cloudType);
      const showUbuntuAdvantage =
        config?.values && !isManagedCloudType && packType === "os" && isUbuntu;
      const selectedPresets =
        config?.selectedPresets ||
        getDefaultPresetsFromValues(config?.values, showUbuntuAdvantage);
      const displayName = extractPackDisplayName(config);
      const packName =
        getPackNameComponents(config?.name)?.[1] || config?.name || "";
      let packValues;

      dispatch({ type: "PROFILE_BUILDER_RESOLVE_NEW_VERSION_CHANGES" });

      if (!fetchers.repositoriesFetcher.selector(store.getState()).result) {
        await store.dispatch(fetchers.repositoriesFetcher.fetch());
      }

      if (
        config?.name &&
        config?.registryUid &&
        config?.tag &&
        !isSystemProfile
      ) {
        const packVersions = await store.dispatch(
          fetchers.packVersionsFetcher.fetch({
            repositoryUid: config.registryUid,
            packName,
            layerType: type,
          })
        );
        packValues = packVersions?.find((pack) => pack.tag === config.tag);
      }

      if (config?.packUid && !isSystemProfile) {
        packValues =
          (
            await store.dispatch(
              fetchers.packValuesFetcher.fetch(config?.packUid)
            )
          )?.[0]?.spec || {};

        dispatch({
          type: "PROFILE_BUILDER_UPDATE_LAYER",
          layer: {
            config: {
              ...layer.config,
              originalValues: packValues.values,
              presets: packValues.presets,
              ubuntuAdvantagePresets:
                showUbuntuAdvantage &&
                generateUbuntuAdvantagePresets(
                  selectedPresets?.ubuntuAdvantage
                ),
              schema: [
                ...(packValues.schema || []),
                ...(showUbuntuAdvantage ? UBUNTU_ADVANTAGE_SCHEMAS : []),
              ],
              selectedPresets,
            },
          },
        });
      }

      let extraProps = Object.entries(layer.config || {}).reduce(
        (accumulator, [key, value]) => {
          if (key.startsWith(PARAM_SEPARATOR)) {
            accumulator[key] = value;
          }

          return accumulator;
        },
        layer?.config?.template ? { template: layer.config.template } : {}
      );

      const mergedManifests = await fetchManifestInformation();
      if (isSystemProfile) {
        dispatch({
          type: "APPLY_MANIFESTS",
          appliedManifests: mergedManifests.length
            ? mergedManifests.map((manifest) => manifest.guid)
            : [],
          currentAttachedManifestGuid: mergedManifests?.[0]?.guid,
        });

        extraProps = {
          isVmManifest: extractManifestType(config) === "vm",
        };
      }

      const installOrder = extractInstallOrder(config);
      const systemPackType = type || "manifest";

      if (!packType && !selectedLayer && draftLayers.length) {
        const draftLayer =
          layers.find((layer) => layer.guid === draftLayers[0]) || {};

        packType = draftLayer.defaultPackType || "";
        accessType = draftLayer?.accessType;
      }

      return Promise.resolve({
        profileModuleGuid: guid,
        packType: isSystemProfile ? systemPackType : packType,
        installOrder: isBasicLayer(type) ? undefined : installOrder || "0",
        displayName,
        repository: config?.registryUid || getLastSelectedRepoUid(type) || "",
        pack: packName,
        name: config?.name || "",
        version: config?.tag || "",
        packUid: config?.packUid,
        originalValues: packValues?.values,
        values: getPackValuesWithoutPresetsComment(config?.values),
        readme: config?.readme || packValues?.readme || "",
        manifests: mergedManifests,
        presets: selectedPresets,
        isIntegration: config?.isIntegration,
        isOci: config?.isOci,
        formType,
        accessType,
        annotations: layer.annotations,
        ...extraProps,
      });
    },
    validator: packActionsValidator,
  });

  const editorActions = createFormActions({
    init: async () => {
      const { layers } = getState();

      const packVersions = layers.reduce((acc, layer) => {
        const { selectedPresets, values, manifests, name, schema } =
          layer.config || {};
        acc[layer.guid] = {
          presets:
            selectedPresets ||
            getDefaultPresetsFromValues(values, isUbuntuAdvantageDisplayed()),
          values: getPackValuesWithoutPresetsComment(values),
          manifests: manifests || [],
          schema: schema || [],
          name,
          type: layer.type,
        };
        return acc;
      }, {});

      return Promise.resolve(packVersions);
    },
    validator: editorValidator,
  });

  const fetchManifestInformation = async () => {
    const { selectedLayer, layers } = getState();

    const layer =
      layers.find((layer) => layer.guid === selectedLayer) ||
      layers.find((layer) => layer.isDraft) ||
      {};

    const currentProfile = getRawClusterProfile(store.getState());
    const packName =
      layer.initialPackName || presentUniquePackName(layer.config);

    if (currentProfile && layer?.config?.packUid && layer?.persisted) {
      try {
        const manifestPromise = api
          .get(
            `v1/clusterprofiles/${currentProfile.metadata.uid}/packs/${packName}/manifests`
          )
          .then(({ items }) => (items || []).map(parseManifest));

        await store.dispatch({
          promise: manifestPromise,
          type: "FETCH_PACK_MANIFESTS",
          layer: selectedLayer,
          schema: [ManifestSchema],
        });
      } catch (err) {}
    }

    return (layer.config?.manifests || []).map((manifest) => {
      const entity = getStoreEntity(manifest.guid, ManifestSchema) || {};

      return {
        ...entity,
        ...manifest,
      };
    });
  };

  const manifestFormActions = createFormActions({
    init: async () => {
      const { selectedLayer, layers, initialLayers } = getState();
      const layer = layers.find((layer) => layer.guid === selectedLayer) || {};
      const initialLayer = initialLayers.find(
        (layer) => layer.guid === selectedLayer
      );
      const hasManifestsContent = initialLayer?.config?.manifests?.every(
        (manifest) => manifest.content
      );
      const { config = {} } = layer;

      const mergedManifests = await fetchManifestInformation();
      dispatch({
        type: "APPLY_MANIFESTS",
        appliedManifests: mergedManifests.length
          ? mergedManifests.map((manifest) => manifest.guid)
          : [],
        currentAttachedManifestGuid: mergedManifests?.[0]?.guid,
      });

      if (initialLayer && !hasManifestsContent) {
        dispatch({
          type: "PROFILE_BUILDER_ADD_CONTENT_TO_LAYER_MANIFESTS",
          layer: {
            ...initialLayer,
            config: { ...initialLayer.config, manifests: mergedManifests },
          },
        });
      }
      const installOrder = extractInstallOrder(config);
      const isVmManifest = extractManifestType(config) === "vm";

      return Promise.resolve({
        uid: config?.uid,
        name: config?.name || "",
        type: "manifest",
        installOrder: installOrder || "0",
        isVmManifest,
        logo: MANIFEST_ICON,
        manifests: mergedManifests,
        persisted: !!config?.uid,
        values: config?.values,
        packUid: config.packUid,
      });
    },
    submit: async (data) => {
      if (!data.name) {
        return;
      }

      dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER",
        layer: mapFormDataToLayers(data),
      });
    },
    validator: manifestsValidator,
  });

  const importActions = createFormActions({
    init: async () => {
      return Promise.resolve({});
    },
    submit: () => {},
    validator: importPacksValidator,
  });

  const getLastSelectedRepoUid = (packType) => {
    const repos = getAvailableRepositories(store.getState());
    const { helmRepoUid, packRepoUid } = getState();
    const lastSelectedRepoUid =
      packType === "helmChart" ? helmRepoUid : packRepoUid;

    if (repos?.length === 1) {
      return repos[0].value;
    }
    if (!packType) {
      return "";
    }
    return lastSelectedRepoUid;
  };

  function isUbuntuAdvantageDisplayed() {
    const module = getState().formType;
    const managedCloudTypes = getEnvironments(getState())
      .filter((env) => env.isSupervised)
      .map((env) => env.apiKey);
    const { packType, pack } = store.getState().forms[module]?.data || {};
    const cloudType = store.getState().forms.clusterprofile?.data?.cloudType;
    const isManagedCloudType = managedCloudTypes.includes(cloudType);
    const isUbuntu = pack?.includes("ubuntu");
    return !isManagedCloudType && packType === "os" && isUbuntu;
  }

  async function getFormUpdatesOnPackVersionChange({
    protectedRepository,
    formValues,
    value,
  }) {
    let formUpdates;

    if (!protectedRepository) {
      const packVersions =
        fetchers.packVersionsFetcher.selector(store.getState())?.result || [];
      let useTag = true;
      let selectedPack = packVersions.find((pack) => pack.tag === value);

      if (!selectedPack) {
        // fallback for some packs, when backend returns version and not tag
        selectedPack = packVersions.find((pack) => pack.version === value);
        useTag = false;
      }

      const packUid = selectedPack?.packUid;

      const [packValues] =
        (await store.dispatch(fetchers.packValuesFetcher.fetch(packUid))) || [];
      const values = packValues?.spec?.values;
      const readme = packValues?.spec?.readme || "";

      let isIntegration =
        !!packValues.spec?.template?.parameters?.inputParameters?.length;

      formUpdates = {
        packUid,
        version: useTag ? selectedPack.tag : selectedPack.version,
        values: getPackValuesWithoutPresetsComment(values),
        presets: getDefaultPresetsFromValues(
          values,
          isUbuntuAdvantageDisplayed()
        ),
        template: packValues.spec?.template,
        isIntegration,
        readme,
        originalValues: values,
      };

      const syncer = createTwoWaySyncer({
        values: formUpdates.values,
        formMeta: formUpdates?.template?.parameters?.inputParameters,
      });
      const params = syncer.populateForm();
      formUpdates = {
        ...formUpdates,
        ...params,
      };
    } else {
      formUpdates = {
        values:
          formValues.values ||
          `
pack:
  namespace: "default"
          `.trim(),
        packUid: undefined,
        schema: [
          {
            // eslint-disable-next-line no-template-curly-in-string
            format: "${string}",
            hints: [],
            listOptions: [],
            name: "pack.namespace",
            readonly: false,
            regex: "^.+$",
            required: true,
            type: "string",
          },
        ],
      };
    }

    return formUpdates;
  }

  function onPackVersionChange({ formValues, version, formUpdates }) {
    const { version: prevVersion, values: prevValues } = formValues;
    const { isEditorExpanded, displayVersionChangeDiff } = getState();

    if (
      !prevVersion ||
      prevVersion === version ||
      formUpdates.values === prevValues
    ) {
      return;
    }

    if (!isEditorExpanded) {
      togglePackValues("packs");
    }

    if (!displayVersionChangeDiff) {
      dispatch({
        type: "PROFILE_BUILDER_VERSION_CHANGE",
        previousVersionData: {
          readme: formValues.readme,
          values: formValues.values,
          version: formValues.version,
          presets: formValues.presets,
          schema: formValues.schema,
          packUid: formValues.packUid,
          originalValues: formValues.originalValues,
        },
      });
    }
  }

  async function getFormUpdatesOnPackChange({
    formValues,
    protectedRepository,
    value,
  }) {
    let formUpdates;
    const module = getState().formType;
    const repository = formValues?.repository;
    const packType = formValues?.packType;
    const selectedPack = getSelectedPackByModule(module);

    formUpdates = {
      values: "",
      presets: null,
      annotations: selectedPack?.spec?.registries?.[0]?.annotations,
    };

    if (packType !== "zarf") {
      formUpdates.version = "";
    }

    if (selectedPack?.spec?.addonType === "integration") {
      formUpdates.packType = selectedPack.spec.addonSubType;
      formUpdates.isIntegration = true;
    }

    if (!protectedRepository) {
      await store.dispatch(
        fetchers.packVersionsFetcher.fetch({
          repositoryUid: repository,
          packName: value,
          layerType: packType,
        })
      );
      if (
        selectedPack?.spec?.registries?.[0]?.annotations
          ?.versionAutoSelected === "true" &&
        value.includes(".x")
      ) {
        const packVersion = fetchers.packVersionsFetcher
          .selector(store.getState())
          .result?.find((version) => !version.tag.includes(".x"));

        if (packVersion) {
          packFieldChange({
            name: "version",
            value: packVersion.tag,
          });
          formUpdates = {
            annotations: selectedPack?.spec?.registries?.[0]?.annotations,
          };
        }
      }
    }

    return formUpdates;
  }

  function getFormUpdatesOnValuesChange(values) {
    const module = getState().formType;
    const { presets: currentPresets, template } =
      store.getState().forms[module]?.data || {};
    const isUbuntuAdvantageEnabled = currentPresets?.ubuntuAdvantage?.enabled;
    const ubuntuAdvantagePresets = isUbuntuAdvantageEnabled
      ? {
          ubuntuAdvantage: {
            ...currentPresets.ubuntuAdvantage,
            ...extractUbuntuAdvantagePresetOptions(values),
          },
        }
      : {};

    const syncer = createTwoWaySyncer({
      values: values,
      formMeta: template?.parameters?.inputParameters,
    });
    const params = syncer.populateForm();

    return {
      values,
      installOrder: extractInstallOrder({ values }),
      isVmManifest: extractManifestType({ values }) === "vm",
      displayName: extractPackDisplayName({ values }),
      ...params,
      presets: {
        ...(currentPresets || {}),
        ...ubuntuAdvantagePresets,
      },
    };
  }

  function getSelectedPackByModule(module) {
    if (module === MODULES.SYSTEM_MODULE) {
      return getSystemSelectedPack(store.getState());
    }

    return getSelectedPack(store.getState());
  }

  function getSelectedRepositoryByModule(module) {
    if (module === MODULES.SYSTEM_MODULE) {
      return getSelectedSystemRepository(store.getState());
    }
    return getSelectedRepository(store.getState());
  }

  const clearValuesErrors = () => {
    const { errors, draftLayers, selectedLayer } = getState();
    const selectedLayerGuid = selectedLayer || draftLayers[0];
    const layerValueErrors = errors[selectedLayerGuid] || [];
    const hasValuesErrors = layerValueErrors.find(
      (err) => err.field === "values"
    );
    if (!hasValuesErrors) {
      return;
    }
    dispatch({
      type: "PROFILE_BUILDER_UPDATE_PACKS_ERRORS",
      errors: {
        ...errors,
        [selectedLayerGuid]: layerValueErrors.filter(
          (err) => err.field !== "values"
        ),
      },
    });
  };

  async function packFieldChange({ name, value }) {
    const { formType } = getState();
    const module = formType;
    const isManifest = formType === "manifest";
    const isSystemModule = module === MODULES.SYSTEM_MODULE;
    const selectedRepository = getSelectedRepositoryByModule(module);
    let formValues = store.getState().forms[module]?.data;
    const packType = formValues?.packType;
    const accessType = formValues?.accessType;

    const protectedRepository =
      packType === "zarf" ||
      accessType === "private" ||
      selectedRepository?.kind === "oci" ||
      (selectedRepository?.kind === "helm" && selectedRepository?.isPrivate);
    let formUpdates;

    if (isManifest && !isSystemModule) {
      store.dispatch(
        manifestFormActions.onChange({
          module: MODULES.MANIFEST_MODULE,
          name,
          value,
        })
      );
    }

    if (
      name !== "presets" &&
      name !== "values" &&
      (!isManifest || isSystemModule)
    ) {
      store.dispatch(
        packActions.onChange({
          module,
          name,
          value,
        })
      );
    }

    if (name.startsWith(PARAM_SEPARATOR)) {
      store.dispatch(
        packActions.onChange({
          module,
          name,
          value,
        })
      );

      const formData = store.getState().forms[module]?.data;
      const syncer = createTwoWaySyncer({
        values: formData.values,
        formData: formData,
        formMeta: formData?.template?.parameters?.inputParameters,
      });
      const values = syncer.populateValues();

      store.dispatch(
        packActions.onChange({
          module,
          name: "values",
          value: values,
        })
      );
    }

    if (["packType", "pack", "repository"].includes(name)) {
      store.dispatch(
        packActions.onChange({
          module,
          name: "manifests",
          value: [],
        })
      );

      togglePackValues("");
    }

    if (name === "repository") {
      formUpdates = {
        packUid: "",
      };
    }

    if (name === "packType") {
      formUpdates = {
        pack: undefined,
        repository: "",
        version: "",
        values: "",
        presets: null,
      };
    }

    if (name === "pack") {
      formUpdates = await getFormUpdatesOnPackChange({
        formValues,
        protectedRepository,
        value,
      });
    }

    if (name === "repository") {
      if (isSystemModule) {
        store.dispatch(
          systemChartsListActions.initialize(MODULES.PACK_LIST_MODULE)
        );
      }

      if (module === MODULES.PACK_MODULE) {
        await store.dispatch(
          packListActions.initialize(MODULES.PACK_LIST_MODULE)
        );
      }

      dispatch({
        type: "PROFILE_BUILDER_SET_REPOSITORY",
        packType,
        repositoryUid: value,
      });
      formUpdates = {
        version: packType === "zarf" ? "1.0.0" : "",
        values: "",
        pack: null,
        presets: null,
      };
    }

    if (name === "version") {
      formUpdates = await getFormUpdatesOnPackVersionChange({
        formValues,
        value,
        protectedRepository,
      });

      clearValuesErrors();
      onPackVersionChange({ formValues, version: value, formUpdates });

      const formData = store.getState().forms[module]?.data;
      const { isEditorExpanded } = getState();
      if (!isEditorExpanded) {
        let editorValue = "packs";

        if (formUpdates.isIntegration || formData.isIntegration) {
          editorValue = "config";
        }

        if (formUpdates?.readme || formData?.readme) {
          editorValue = "readme";
        }

        togglePackValues(editorValue);
      }
    }

    if (name === "installOrder") {
      formUpdates = {
        installOrder: value,
        values: updateInstallOrder({
          values: formValues?.values,
          installOrder: value,
        }),
      };
    }

    if (name === "isVmManifest") {
      formUpdates = {
        isVmManifest: value,
        values: updateManifestTypeValues({
          values: formValues?.values,
          showType: value,
        }),
      };
    }

    if (name === "presets") {
      const {
        presets: currentPresets,
        values: currentValues,
        manifests,
      } = store.getState().forms[module]?.data || {};

      if ([FORM_TYPES.SYSTEM, FORM_TYPES.MANIFEST].includes(formType)) {
        const { presets, values } = await getFormUpdatesOnPresetsChange(value, {
          currentPresets,
          currentValues: "",
        });

        const updatedManifests = manifests.map((manifest, index) => {
          if (index === 0) {
            return {
              ...manifest,
              content: values,
            };
          }

          return manifest;
        });

        formUpdates = {
          presets,
          manifests: updatedManifests,
        };
      } else {
        formUpdates = await getFormUpdatesOnPresetsChange(value, {
          currentPresets,
          currentValues,
        });
      }
    }

    if (name === "values") {
      clearValuesErrors();
      formUpdates = getFormUpdatesOnValuesChange(value);
    }

    formUpdates &&
      store.dispatch(
        packActions.batchChange({
          module,
          updates: {
            ...formUpdates,
            // maybe isOci is overloading the form itself and there could be better options than this
            isOci:
              selectedRepository?.kind === "oci" || selectedRepository?.isOci,
          },
        })
      );

    dispatch({
      type: "PROFILE_BUILDER_UPDATE_LAYER",
      layer: mapFormDataToLayers(store.getState().forms[module]?.data),
    });
  }

  const validateLayers = async (packsData) => {
    for (const key in packsData) {
      const validations = editorValidator.run(packsData[key]);
      for await (const error of validations) {
        dispatch({
          type: "PROFILE_BUILDER_UPDATE_PACK_ERROR",
          error,
          packGuid: key,
        });
      }
    }
  };

  return {
    variableFormActions,
    packActions,
    packSearchActions,
    editorActions,
    manifestFormActions,
    importActions,
    packFieldChange,
    validateLayers,
    fetchManifestInformation,
    isUbuntuAdvantageDisplayed,
    helmRegistryFormActions: createFormActions({
      init: async () => {
        return Promise.resolve({
          name: "",
          endpoint: "",
          username: "",
          password: "",
          isValid: false,
          isPrivate: false,
          noAuth: false,
        });
      },
      submit: async (data) => {
        const payload = getHelmRepositoryPayload(data, getState().expandedPack);
        try {
          const packRegistry = await nonProjectApi.post(
            "v1/registries/helm",
            payload
          );
          setNewHelmRegistry(packRegistry);
        } catch (error) {
          notifications.error({
            message: i18next.t(
              "Something went wrong when creating a helm registry"
            ),
            description: error.message,
          });
        }
      },
      validator,
    }),

    importFieldChange: (name, value) => {
      store.dispatch(
        importActions.onChange({
          module: MODULES.IMPORT_MODULE,
          name,
          value,
        })
      );
    },
    onEditorPresetsFormChange: (value) => {
      const { packsEditor } = getState();

      store.dispatch(
        editorActions.onChange({
          module: MODULES.PACKS_EDITOR_MODULE,
          name: `${packsEditor.selectedGuid}.presets`,
          value,
        })
      );
    },

    onEditorValuesFormChange: async (value) => {
      const { packsEditor, errors } = getState();

      if (Object.keys(errors)?.length) {
        const { data } = store.getState().forms[MODULES.PACKS_EDITOR_MODULE];
        const hasErrors = Object.keys(data).some((key) =>
          errors[key]?.find((err) => err.result)
        );

        if (hasErrors) {
          await validateLayers(data);
        }
      }

      store.dispatch(
        editorActions.onChange({
          module: MODULES.PACKS_EDITOR_MODULE,
          name: `${packsEditor.currentName}`,
          value,
        })
      );
    },

    newManifestFieldChange: ({ name, value }) => {
      store.dispatch(
        manifestFormActions.onChange({
          module: MODULES.MANIFEST_MODULE,
          name,
          value,
        })
      );
    },
  };
}
