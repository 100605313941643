import { createMaasFormFactory } from "modules/cluster/maas";
import clusterFormActions from "../form";

export const maasCloudForm = createMaasFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

function selectCredentialEffect() {
  return function (dispatch) {
    dispatch(maasCloudForm.fetchers.domainsFetcher.fetch());
  };
}

const effects = {
  selectCredentialEffect,
};

export default effects;
