import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import spaceMan from "assets/images/login/man_space_lost.png";
import FullPageOverlay from "components/common/layouts/Page";
import Icon from "components/ui/Icon";
import { lavender, purple, white } from "utils/constants/colors";
import Title from "components/common/Title";
import {
  faExternalLink,
  faLongArrowLeft,
} from "@fortawesome/pro-light-svg-icons";

const ContentWrap = styled.div`
  width: 1088px;
  background: ${white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  h2 {
    color: ${purple};
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
  }

  a {
    color: ${lavender};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 20px;
  display: inline-block;
  background-color: #aaa;
  margin: 0 20px;
  vertical-align: middle;
`;

const Content = styled.div`
  max-width: 1200px;
  overflow: hidden;
  text-align: center;
  border-radius: 20px;

  margin: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const BoundingBox = styled.div`
  display: inline-block;
  width: 340px;

  .anticon {
    margin: 0 5px;
  }
`;
const Man = styled.img`
  margin: 20px;
  width: 50%;

  @media (max-height: 500px) {
    display: none;
  }
`;

const PageTitle = styled.h1`
  color: ${lavender};
`;

export default function Error() {
  const { t } = useTranslation();

  return (
    <FullPageOverlay>
      <Title>{t("Page Not Found | Spectro")}</Title>
      <ContentWrap>
        <Content>
          <BoundingBox>
            <PageTitle>{t("404 - Page not found")}</PageTitle>
            <br />
            <h2>{t("The page you requested could not be found.")}</h2>
            <p>{t("We are sorry. Here are some helpful links")}</p>
            <a href="https://console.spectrocloud.com/auth">
              <Icon awesome={faLongArrowLeft} /> {t("Back to Console")}
            </a>{" "}
            <Divider />
            <a href="https://spectrocloud.com">
              {t("spectrocloud.com")} <Icon awesome={faExternalLink} />
            </a>
          </BoundingBox>
          <Man src={spaceMan} />
        </Content>
      </ContentWrap>
    </FullPageOverlay>
  );
}
