import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import VirtualClusterListing from "../components/VirtualClusterListing";
import { VIRTUAL_CLUSTER } from "utils/constants/routes";
import { Link } from "react-router-dom";
import Button from "components/ui/Button";
import { faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import Icon from "components/ui/Icon";
import { VIRTUAL_CLUSTERS_LIST_MODULE } from "state/cluster/actions/list/nestedclusters";
import allPermissions from "services/permissions";
import Loader from "components/ui/Loader";
import { WidgetHeader } from "../components/Status";

const StyledButton = styled(Button)`
  min-width: auto;
`;

const LoaderWrap = styled(Loader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 100%;
`;

const AddNewButton = () => {
  const { t } = useTranslation();
  if (!allPermissions.has("appDeployment.create")) {
    return null;
  }

  return (
    <Link to={VIRTUAL_CLUSTER.CREATE_CLUSTER}>
      <StyledButton data-qa="clusters_widget_redirect_button" type="primary">
        <Icon awesome={faCirclePlus} /> {t("New Virtual Cluster")}
      </StyledButton>
    </Link>
  );
};

const ClusterWidget = ({ totalVirtualCLuster, isClusterListInitializing }) => {
  const { t } = useTranslation();

  if (isClusterListInitializing) {
    return <LoaderWrap />;
  }

  return (
    <>
      <WidgetHeader
        totalCount={totalVirtualCLuster}
        createButton={<AddNewButton />}
        title={t("Virtual Clusters")}
      ></WidgetHeader>
      <VirtualClusterListing />
    </>
  );
};

export default connect((state) => ({
  totalVirtualCLuster: state?.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.count,
  isClusterListInitializing:
    state.list?.[VIRTUAL_CLUSTERS_LIST_MODULE]?.isInitializing,
}))(ClusterWidget);
