import React from "react";
import { Route } from "react-router";

import { MY_API_KEYS, MY_PROFILE } from "utils/constants/routes";
import ClusterProfiles from "./clusterprofiles/main";
import Clusters from "./clusters/main";
import Settings from "./settings/main";
import AuditLogs from "./audit/main";
import Roles from "./roles/main";
import Projects from "./projects/main";
import Management from "./management/main";
import Overlords from "./overlords/main";
import MyProfile from "./my-profile";
import MyApiKeys from "./my-api-keys";
import OrganizationOverview from "./organization/main";
import ProjectOverview from "./projectoverview/main";
import Workspaces from "./workspaces/main";
import ClusterGroups from "./clustergroups/main";

// DEV PAGES
import NestedCluster from "./dev/nestedcluster/main";
import AppProfiles from "./dev/appprofiles/main";
import AppDeployments from "./dev/appdeployments/main";
import Registries from "./dev/registries/main";
import AppModeOverview from "./dev/overview/main";

const Pages = () => (
  <>
    <OrganizationOverview />
    <ProjectOverview />
    <Clusters />
    <ClusterProfiles />
    <Settings />
    <AuditLogs />
    <Roles />
    <Projects />
    <Management />
    <Overlords />
    <Workspaces />
    <ClusterGroups />
    <Route path={MY_PROFILE} component={MyProfile} />
    <Route path={MY_API_KEYS.ROOT} component={MyApiKeys} />

    {/* DEV PAGES */}
    <AppModeOverview />
    <NestedCluster />
    <AppProfiles />
    <AppDeployments />
    <Registries />
  </>
);

export default Pages;
