import React from "react";
import ClipboardJS from "react-clipboard.js";
import i18next from "i18next";
import styled from "styled-components";
import { CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import notifications from "services/notifications";
import { darkBlue, lightWhite, lightMidGray } from "utils/constants/colors";

const Wrap = styled.div`
  display: ${(props) => (props.isInline ? "inline-flex" : "flex")};
  align-items: center;

  > span > .copy-button {
    border-radius: 4px;
    padding: 2px 5px;
    border: 1px solid ${lightMidGray};
    background: ${lightWhite};
    color: ${darkBlue};

    :hover {
      cursor: pointer;
    }

    :focus {
      outline: none;
    }
  }
`;

export default function Clipboard({
  text,
  successMessage = i18next.t("Successfully copied to clipboard"),
  children,
  isInline = false,
}) {
  function onSuccess() {
    notifications.success({
      message: successMessage,
    });
  }

  return (
    <Wrap isInline={isInline}>
      {children}
      <Tooltip title={i18next.t("Copy to clipboard")} placement="bottom">
        <span>
          <ClipboardJS
            className="copy-button"
            component="div"
            data-clipboard-text={text}
            onSuccess={onSuccess}
          >
            <CopyOutlined />
          </ClipboardJS>
        </span>
      </Tooltip>
    </Wrap>
  );
}
