import React from "react";
import { createConnector } from "modules/binder";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { PermissionService } from "services/permissions";

import Icon from "components/ui/Icon";
import fileListConnector from "modules/profileIDE/connectors/fileList";

export default createConnector({
  selectors: {
    canDelete: (state) => {
      const { permissions } = state?.fileList?.metadata?.annotations || {};
      const isEditMode = state.editor?.isEdit;
      const appProfilePermissions = new PermissionService(
        permissions?.split(",")
      );

      return (
        (appProfilePermissions.has([
          "appProfile.delete",
          "appProfile.update",
        ]) &&
          isEditMode) ||
        !isEditMode
      );
    },
  },
  actions: {
    deleteFile: () => async (dispatch, module) => {
      const activeFile = module.state.fileList.activeFile;

      const files = module.state.fileList.files;
      const childrenMap = module.state.fileList.childrenMap;
      let deletedFileParentGuid;
      let deletedFileIndex = files.findIndex((file) => file === activeFile);

      let isChild = false;

      // child file
      if (deletedFileIndex === -1) {
        isChild = true;

        deletedFileParentGuid = Object.keys(childrenMap).find((parentGuid) =>
          childrenMap[parentGuid].includes(activeFile)
        );

        deletedFileIndex = childrenMap[deletedFileParentGuid].findIndex(
          (childGuid) => childGuid === activeFile
        );
      }

      const filesGuids = isChild ? childrenMap[deletedFileParentGuid] : files;

      if (deletedFileIndex === filesGuids.length - 1) {
        deletedFileIndex = deletedFileIndex - 1;
      }

      dispatch({
        type: module.actions.validations.removeFileValidation,
        fileGuid: activeFile,
      });

      dispatch({
        type: module.actions.fileList.removeFile,
        fileGuid: activeFile,
      });

      // remove manifest from parent manifest form data array
      if (isChild) {
        const parentDraft = module.state.drafts[deletedFileParentGuid];

        const newManifests = (parentDraft?.manifests || []).filter(
          (manifest) => manifest.guid !== activeFile
        );

        module.dispatch({
          type: module.actions.drafts.stashForm,
          formData: { ...parentDraft, manifests: newManifests },
        });
      }

      // select new file
      const updatedFiles = module.state.fileList.files;
      const updatedChildrenMap = module.state.fileList.childrenMap;

      const updatedFilesGuids = isChild
        ? updatedChildrenMap[deletedFileParentGuid]
        : updatedFiles;

      if (updatedFilesGuids.length === 0) {
        if (isChild) {
          module.dispatch(
            fileListConnector.actions.onSelect([deletedFileParentGuid])
          );

          module.dispatch({
            type: module.actions.editor.switchMode,
            mode: "form",
          });
        } else {
          module.dispatch({
            type: module.actions.toolbar.onTabSelect,
            tab: "add",
          });
        }

        return;
      }

      module.dispatch(
        fileListConnector.actions.onSelect([
          updatedFilesGuids[deletedFileIndex],
        ])
      );
    },
  },
}).connect(({ canDelete, deleteFile }) => {
  if (!canDelete) return null;

  return (
    <Icon awesome={faTrashAlt} onClick={deleteFile} style={{ fontSize: 16 }} />
  );
});
