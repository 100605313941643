import i18n from "i18next";
import { createSelector } from "reselect";

import { AppProfileSchema, ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { getQuotaValue } from "utils/presenters";
import { getQuotaPercentages } from "utils/presenters";

import {
  DEPLOYMENT_FORM_MODULE,
  clusterGroupsFetcher,
} from "state/appdeployments/services/create";
import { sandboxClusterQuotaUsageFetcher } from "state/devnestedcluster/services";

export const getSelectedAppProfileEntity = getEntity(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data?.selectedAppProfile,
  AppProfileSchema
);

const getSelectedVirtualClusterEntity = getEntity(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data?.selectedVirtualCluster,
  ClusterSchema
);

export const getPayload = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data,
  getSelectedAppProfileEntity,
  getSelectedVirtualClusterEntity,
  (formData, selectedAppProfileEntity, selectedVirtualClusterEntity) => {
    const {
      name,
      clusterGroup,
      clusterName,
      cpuLimits,
      memoryLimits,
      storageLimits,
      deployTargetValue,
      selectedAppProfileVersion,
    } = formData || {};
    const metadata = { name };
    const selectedVersionUid = selectedAppProfileEntity?.spec.versions.find(
      (version) => version?.guid === selectedAppProfileVersion
    )?.uid;
    const appProfileUid =
      selectedVersionUid || selectedAppProfileEntity?.metadata?.uid;

    if (deployTargetValue === "existingVirtualCluster") {
      return {
        metadata,
        spec: {
          profile: {
            appProfileUid,
          },
          config: {
            targetSpec: {
              clusterUid: selectedVirtualClusterEntity?.metadata?.uid,
            },
          },
        },
      };
    }

    if (deployTargetValue === "newVirtualCluster") {
      return {
        metadata,
        spec: {
          profile: {
            appProfileUid,
          },
          config: {
            targetSpec: {
              clusterGroupUid: clusterGroup,
              clusterName,
              clusterLimits: {
                cpu: getQuotaValue(cpuLimits, 6),
                memoryMiB: getQuotaValue(memoryLimits, 8) * 1024,
                storageGiB: getQuotaValue(storageLimits, 10),
              },
            },
          },
        },
      };
    }

    return {};
  }
);

export const getSelectedVirtualClusterFieldError = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.errors,
  (formErrors) => {
    if (!formErrors.length) {
      return null;
    }

    const error = formErrors.find(
      (error) => error.field === "selectedVirtualCluster"
    );

    if (error) {
      return i18n.t("Please select a cluster in order to continue");
    }

    return "";
  }
);

export const getSelectedAppProfileFieldError = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.errors,
  (formErrors) => {
    if (!formErrors.length) {
      return null;
    }

    const error = formErrors.find(
      (error) => error.field === "selectedAppProfile"
    );

    if (error) {
      return i18n.t("Please select an app profile");
    }

    return "";
  }
);

export const hasUnlimitedSandboxClusterQuota = createSelector(
  sandboxClusterQuotaUsageFetcher.selector,
  ({ result }) => {
    const allocatedCredit = result?.allocatedCredit || {};

    return (
      allocatedCredit.cpu === 0 &&
      allocatedCredit.memoryGiB === 0 &&
      allocatedCredit.virtualClusterLimit === 0 &&
      allocatedCredit.storageGiB === 0
    );
  }
);

export const getQuotaRemainingPercentages = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data,
  sandboxClusterQuotaUsageFetcher.selector,
  (formData, { result }) => {
    const { usedCredit, allocatedCredit } = result || {};

    return getQuotaPercentages(formData, usedCredit, allocatedCredit);
  }
);

export const hasSelectedClusterGroup = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data,
  (formData) => {
    return !!formData?.clusterGroup;
  }
);

const isAppDeploymentQuotaExceeded = createSelector(
  getQuotaRemainingPercentages,
  hasUnlimitedSandboxClusterQuota,
  ({ usedCredit, freeCredit }, hasUnlimitedQuota) => {
    if (hasUnlimitedQuota) {
      return false;
    }

    return Object.keys(freeCredit).some(
      (key) =>
        freeCredit[key] > 100 ||
        usedCredit[key] > 100 ||
        (key === "virtualClusters" && freeCredit[key] === 0)
    );
  }
);

export const isClusterGroupProjectScoped = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data,
  clusterGroupsFetcher.selector,
  (formData, { result }) => {
    const clusterGroupUid = formData?.clusterGroup;
    const clusterGroup = (result || []).find(
      (clusterGroup) => clusterGroup?.uid === clusterGroupUid
    );
    return clusterGroup?.scope === "project";
  }
);

export const isAppDeploymentSubmissionDisabled = createSelector(
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.data?.deployTargetValue,
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.submitting,
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.validating,
  (state) => state.forms[DEPLOYMENT_FORM_MODULE]?.errors?.length,
  isAppDeploymentQuotaExceeded,
  isClusterGroupProjectScoped,
  (
    deployTargetValue,
    isSubmitting,
    isValidating,
    hasErrors,
    isQuotaExceeded,
    isClusterGroupProjectScoped
  ) => {
    return (
      isSubmitting ||
      isValidating ||
      hasErrors ||
      (isQuotaExceeded &&
        deployTargetValue === "newVirtualCluster" &&
        !isClusterGroupProjectScoped)
    );
  }
);
