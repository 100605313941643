import React from "react";
import styled from "styled-components";

const StyledCountIconWrapper = styled.span`
  align-items: center;
  width: fit-content;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  color: ${(props) => props.color};
  font-weight: 300;
  background: ${(props) => props.background};
  border-radius: 11px;
  padding: 2px 13px;
  margin-right: 10px;
`;

const StyledSpan = styled.span`
  margin-right: 20px;
`;

const DetailsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledText = styled.span`
  font-weight: 400;
`;

const LargeCount = styled(StyledText)`
  font-size: 60px;
  line-height: 60px;
  margin-right: 30px;
  margin-left: 20px;
`;

const StyledHeadingText = styled(StyledText)`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
`;

const StyledHeadingSubtext = styled(StyledText)`
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
  line-height: 53px;
`;

const EmptyWidgetSpace = styled.span`
  padding: 87px;
`;

const WidgetStatus = ({ currentStatus, showTitle = true }) => {
  return currentStatus.map((statusVal, index) => {
    return (
      <span key={index}>
        <StyledCountIconWrapper
          color={statusVal.color}
          background={statusVal.background}
        >
          {statusVal.count}
        </StyledCountIconWrapper>
        {showTitle && <StyledSpan>{statusVal.title}</StyledSpan>}
      </span>
    );
  });
};

export const WidgetHeader = ({
  totalCount,
  subStatusCounts,
  createButton,
  title,
  showTitle,
}) => {
  return (
    <DetailsWrap>
      <DetailsWrap>
        <LargeCount> {totalCount} </LargeCount>
        <div>
          <StyledHeadingText> {title} </StyledHeadingText>
          <div>
            <StyledHeadingSubtext>
              {subStatusCounts ? (
                <WidgetStatus
                  currentStatus={Object.values(subStatusCounts)}
                  showTitle={showTitle}
                ></WidgetStatus>
              ) : (
                <EmptyWidgetSpace></EmptyWidgetSpace>
              )}
            </StyledHeadingSubtext>
          </div>
        </div>
      </DetailsWrap>
      <div> {createButton} </div>
    </DetailsWrap>
  );
};
