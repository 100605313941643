export const initialState = {
  isValid: false,
  isValidating: false,
};

export function VALIDATE_CREDENTIALS_REQUEST(nextState) {
  nextState.isValidating = true;
}

export function VALIDATE_CREDENTIALS_SUCCESS(nextState) {
  nextState.isValid = true;
  nextState.isValidating = false;
}

export function VALIDATE_CREDENTIALS_FAILURE(nextState) {
  nextState.isValid = false;
  nextState.isValidating = false;
}
