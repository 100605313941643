import querystring from "query-string";

import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import {
  RepositorySchema,
  ClusterProfileSchema,
  PackSchema,
  PackVersionSchema,
  ClusterSchema,
} from "utils/schemas";
import api from "services/api";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import store from "services/store";
import history from "services/history";
import ProfileBuilderModule from "modules/profileBuilder";
import { getEnvironments } from "utils/constants";
import { mapClusterProfilesQuery } from "./actions/listing";
import createFormActions from "modules/form/actions";
import { mapClusterQuery } from "state/cluster/actions/list/clusters";
import {
  getRawClusterProfile,
  getUpdateNotification,
  getUpdateNotificationPacks,
} from "./selectors/details";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";
import { isBasicLayer } from "modules/profileBuilder/utils";

export const editClusterProfile = new ModalService("editClusterProfile");
export const deleteProfileModal = new ModalService("deleteProfile");
export const deployClusterModal = new ModalService("deployCluster");
export const createNewVersionModal = new ModalService("createNewVersion");
export const selectClusterModal = new ModalService("selectClusterModal");
export const importClusterProfileModal = new ModalService(
  "importClusterProfile"
);
export const importClusterProfileValidateModal = new ModalService(
  "importClusterProfileValidate"
);
export const deleteImportClusterProfileFile = new ModalService(
  "deleteImportClusterProfileFile"
);

export const repositoriesFetcher = dataFetcher({
  selectors: ["repositories", "pack"],
  schema: [RepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/pack");
    return response.items;
  },
});

export const packNamesFetcher = dataFetcher({
  selectors: [
    "packName",
    (state) => state.forms.packSteps.data.type,
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  async fetchData([_, layerType, cloudType]) {
    let filters = `spec.layer=${layerType}`;
    if (!isBasicLayer(layerType)) {
      filters = `spec.layer=addonANDspec.addonType=${layerType}`;
    }

    if (cloudType === "all") {
      filters = `spec.cloudTypes_in_${getEnvironments()
        .map((env) => env.apiKey)
        .join(",")},allAND${filters}`;
    } else {
      filters = `spec.cloudTypes_in_${cloudType},allAND${filters}`;
    }

    const promise = api.get("v1/packs", { filters, limit: 200 });
    store.dispatch({
      type: "FETCH_LAYER_PACKS",
      promise,
      schema: {
        items: [PackSchema],
      },
    });
    const response = await promise;
    const items = response.items.reduce((accumulator, item) => {
      accumulator.add(item.spec.name);
      return accumulator;
    }, new Set());

    return [...items]
      .map((name) => {
        const item = response.items.find((item) => item.spec.name === name);
        const disabled = JSON.parse(item.spec?.annotations?.disabled || false);

        return {
          label: item.spec.displayName,
          value: name,
          disabled,
          comingSoon: disabled,
          name,
          logo: item.spec.logoUrl,
          cloudTypes: cloudType === "all" ? item.spec.cloudTypes || [] : [],
          registries: [
            ...new Set(
              response.items
                .filter((item) => item.spec.name === name)
                .map((item) => item.spec.registryUid)
            ),
          ],
          presets: item.spec.presets,
        };
      })
      .sort((packA, packB) => {
        return packA.disabled - packB.disabled;
      });
  },
});

export const configurePacksModal = new ModalService("configurePacks");

export const cloneClusterProfileModal = new ModalService("cloneClusterProfile");

export const clusterProfileListFetcher = dataFetcher({
  selectors: ["clusterProfileList"],
  schema: [ClusterProfileSchema],
  fetchData: async () => {
    const payload = mapClusterProfilesQuery();
    const response = await api.post(`v1/dashboard/clusterprofiles`, payload);
    return response?.items || [];
  },
});

export const clusterProfiletTypeCounter = keyedDataFetcher({
  selectors: ["clusterProfileTypeCount"],
  async fetchData([_1, clusterProfileName]) {
    const filters = {
      filter: {
        environment: [clusterProfileName],
        profileType: ["cluster", "infra"],
        profileName: {
          contains: "",
        },
      },
    };
    const response = await api.post("v1/dashboard/clusterprofiles", filters);
    return response?.listmeta?.count || 0;
  },
});

export const profileListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    const query = history.getQuery();
    return {
      search: query?.search || "",
      cloudTypes: query?.cloudTypes || [],
      profileTypes: query?.profileTypes || [],
      sortField: query?.sortField || "",
      sortOrder: query?.sortOrder || "",
      limit: 20,
    };
  },
  fetchData(query) {
    const { offset, limit, continue: continueToken, ...rest } = query;

    const payload = mapClusterProfilesQuery(rest);
    const queryString = querystring.stringify(rest);
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    history.replace(`?${queryString}`);

    return api.post(
      `v1/dashboard/clusterprofiles?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );
  },
  schema: [ClusterProfileSchema],
});

export const profileBuilderCreateModule = new ProfileBuilderModule();
export const profileBuilderEditModule = new ProfileBuilderModule({
  options: { isEdit: true },
});

// NOTE: similar to the cluster counterpart but simplified
export const updatesFormAction = createFormActions({
  async init() {
    const clusterprofile = getRawClusterProfile(store.getState());

    const updateNotifications = getUpdateNotification(store.getState());
    const events = updateNotifications.reduce(
      (accumulator, notification) => [...accumulator, ...notification.events],
      []
    );

    if (events.length === 0) {
      return {};
    }

    const listOfPacks = new Set();
    const promises = events.reduce((acc, event) => {
      if (
        ["RegistryPackUpdate", "RegistryPackVersionUpdate"].includes(
          event.type
        ) &&
        !listOfPacks.has(event.packName)
      ) {
        const promise = store.dispatch({
          promise: api.get(
            `v1/clusterprofiles/${clusterprofile.metadata.uid}/packs/${event.packName}/config?packUid=${event.packUid}`
          ),
          type: "FETCH_PACK_DIVERGENCE_INFO",
          packName: event.packName,
          packUid: event.packUid,
          schema: {
            items: [
              {
                spec: PackVersionSchema,
              },
            ],
          },
        });
        listOfPacks.add(event.packName);
        acc.push(promise);
      }

      return acc;
    }, []);
    await Promise.all(promises);

    const divergenceInfo =
      store.getState().clusterprofile.details.notification.divergences;
    const packs = getUpdateNotificationPacks(store.getState());

    const values = packs.reduce((accumulator, pack) => {
      const [profileInfo, repoInfo] =
        divergenceInfo[pack.metadata.name]?.items || [];

      if (!profileInfo) {
        return accumulator;
      }

      if (!profileInfo.spec.isValuesOverridden) {
        accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
          repoInfo.spec?.values
        );
      } else {
        accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
          profileInfo.spec?.values
        );
      }

      return accumulator;
    }, {});

    return values;
  },
});

export const clustersFetcher = dataFetcher({
  selectors: ["clusters"],
  schema: [ClusterSchema],
  async fetchData(_, query) {
    const filters = [
      {
        conditions: [
          {
            displayName: "Import Mode",
            operator: "neq",
            property: "clusterImportMode",
            type: "string",
            values: ["read-only"],
          },
          {
            property: "isDeleted",
            values: false,
            operator: "eq",
            displayName: "Deleted",
            type: "bool",
          },
        ],
        conjunction: "and",
      },
    ];
    const payload = mapClusterQuery({
      filterGroups: filters,
      mainConjunction: "or",
      checkForDeletedFilter: false,
    });
    const response = await api.post(
      `v1/dashboard/spectroclusters/search`,
      payload
    );
    return response?.items || [];
  },
});
