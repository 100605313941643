import i18n from "i18next";
import { createSelector } from "reselect";

import { getEntity } from "utils/entities";
import { DEFAULT_SCHEDULE_OPTIONS } from "utils/constants";
import { BackupSchema } from "utils/schemas";
import { getCronScheduleValue } from "utils/parsers";

import { backupStatusesFetcher, backupDetailsModal } from "./services";

export const getClusterBackupSchedule = createSelector(
  (state) => backupStatusesFetcher.selector(state),
  ({ result }) => {
    const scheduledRunTime = result?.spec?.config?.schedule?.scheduledRunTime;
    const defaultScheduleLabel = DEFAULT_SCHEDULE_OPTIONS.find(
      (option) => option.value === scheduledRunTime
    )?.label;

    if (!scheduledRunTime) {
      return () => i18n.t("Never");
    }
    if (!defaultScheduleLabel) {
      return () => i18n.t("Custom");
    }
    return defaultScheduleLabel;
  }
);

export const getBackupDetails = getEntity(
  () => backupDetailsModal?.data?.guid,
  BackupSchema
);

export const getScheduledBackupPayload = (data) => {
  if (!data || !data.backupPrefix) {
    return {};
  }

  const {
    backupPrefix,
    location,
    schedule,
    occurrence,
    includeAllDisks,
    includeClusterResources,
    backupedNamespaces,
    expiryPeriod,
    expiryHours,
  } = data;

  return {
    backupLocationUid: location,
    backupPrefix,
    namespaces: backupedNamespaces,
    includeAllDisks,
    includeClusterResources,
    durationInHours: expiryPeriod === "custom" ? expiryHours : expiryPeriod,
    schedule: {
      scheduledRunTime: getCronScheduleValue(schedule, occurrence),
    },
  };
};
