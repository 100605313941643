import React from "react";
import { lightPurpleGray } from "utils/constants/colors";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Pill } from "components/common/Pill";

export function TechPreview() {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t(
        "This functionality is currently in the Tech Preview phase, representing an early release for user exploration and feedback"
      )}
    >
      <Pill inline compresed color={lightPurpleGray}>
        {t("Tech Preview")}
      </Pill>
    </Tooltip>
  );
}
