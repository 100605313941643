import { createSelector } from "reselect";
import uniq from "lodash/uniq";

const sortByOrderArray = (array = [], orderArray = []) => {
  return array.sort((a, b) => {
    const indexA = orderArray.indexOf(a);
    const indexB = orderArray.indexOf(b);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;
    return 0;
  });
};

export const createListingFiltersSelector = (module) => {
  return createSelector(
    (state) => state.list?.[module]?.filters,
    (filters) => {
      return filters || {};
    }
  );
};

export const createListingQuickFiltersSelector = (module) => {
  return createSelector(
    (state) => createListingFiltersSelector(module)(state),
    (filters) => {
      const { config = {}, pinned = [] } = filters;
      const { data = {}, order = [] } = config;
      const pinnedFilters = Object.keys(data).filter(
        (key) => data[key].pinned || data[key].locked
      );
      const allPinned = sortByOrderArray(
        uniq([...pinned, ...pinnedFilters]),
        order
      );

      return allPinned.map((pinnedFilterKey) => data[pinnedFilterKey]);
    }
  );
};

export const createListingQuickFiltersPillsSelector = (module) => {
  return createSelector(
    (state) => createExtendedFiltersSelector(module)(state),
    (allFilters) => {
      return allFilters.reduce(
        (accum, { options = [], displayName, name }) => {
          accum.labels[name] = displayName;
          accum.options[name] = options;
          return accum;
        },
        {
          labels: {},
          options: {},
        }
      );
    }
  );
};

export const createListingSearchFilterSelector = (module) => {
  return createSelector(
    (state) => createListingFiltersSelector(module)(state),
    (filters) => {
      const data = filters.config?.data || {};

      const searchKey = Object.keys(data).find(
        (key) => data[key].componentType === "search"
      );

      if (searchKey) {
        return data[searchKey];
      }
    }
  );
};

export const createExtendedFiltersSelector = (module) => {
  return createSelector(
    (state) => createListingFiltersSelector(module)(state),
    (filters) => {
      const { config = {}, pinned = [] } = filters;
      const { order = [], data = {} } = config;
      const keys = Object.keys(data).filter((key) => {
        const { name, componentType } = data[key];

        return name && componentType && componentType !== "search";
      });
      const orderedKeys = sortByOrderArray(keys, order);

      return orderedKeys.map((key) => ({
        ...data[key],
        pinned: pinned.includes(data[key].name),
      }));
    }
  );
};
