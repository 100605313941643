import i18next from "i18next";
import store, { getStoreEntity } from "services/store";

import ModalService from "services/modal";
import api from "services/api";
import notifications from "services/notifications";

import {
  getSelectedUser,
  getSelectedTeam,
  // getCurrentResourceRoles,
  getSelectedResourceRoles,
  getSelectedResourceRoleEntity,
} from "../selectors";
import ListActions from "modules/list/actions";
import { ResourceRolesSchema } from "utils/schemas";
import dataFetcher from "modules/dataFetcher";

export const resourceRolesListActions = new ListActions({
  fetchData,
  schema: [ResourceRolesSchema],
});

export const resourceRolesModal = new ModalService();

export function fetchData() {
  const state = store.getState();
  const currentEntityType = state.roleManagement.entityType;
  const selectedEntity =
    currentEntityType === "user"
      ? getSelectedUser(state)
      : getSelectedTeam(state);

  return api
    .get(
      `v1/${currentEntityType}s/${selectedEntity.metadata.uid}/resourceRoles`
    )
    .then((res) => {
      return {
        items: res.resourceRoles.map((resourceRole) => ({
          uid: resourceRole.uid,
          projects: resourceRole.projectUids.map((project) => ({
            name: project.name,
            uid: project.uid,
          })),
          filters: resourceRole.filterRefs.map((filter) => ({
            name: filter.name,
            uid: filter.uid,
          })),
          roles: resourceRole.roles.map((role) => ({
            metadata: { uid: role.uid, name: role.name },
          })),
          // inheritedRoles: resourceRole.inheritedRoles.map((role) => ({
          //   metadata: { uid: role.uid, name: role.name },
          // })),
        })),
      };
    });
}

function getRolesUids(roles) {
  return roles.map((role) => role.metadata.uid);
}

export function updateData(data) {
  const state = store.getState();
  const roles = getRolesUids(getSelectedResourceRoles(state));
  const currentEntityType = state.roleManagement.entityType;
  const selectedEntity =
    currentEntityType === "user"
      ? getSelectedUser(state)
      : getSelectedTeam(state);

  const payload = {
    projectUids: data.projectsUids,
    filterRefs: data.filters,
    roles,
  };

  const apiRoot = `v1/${currentEntityType}s/${selectedEntity.metadata.uid}/resourceRoles`;

  const resourceRoleEntity = getSelectedResourceRoleEntity(state);

  const promise = data.isEdit
    ? api.patch(`${apiRoot}/${resourceRoleEntity.uid}`, payload)
    : api.post(apiRoot, payload);

  return promise;
}

export function onResourceRoleDelete(guid) {
  return async (dispatch, getState) => {
    const state = getState();
    const resourceRoleEntity = getStoreEntity(guid, ResourceRolesSchema);
    const currentEntityType = state.roleManagement.entityType;
    const selectedEntity =
      currentEntityType === "user"
        ? getSelectedUser(state)
        : getSelectedTeam(state);

    const promise = api.delete(
      `v1/${currentEntityType}s/${selectedEntity.metadata.uid}/resourceRoles/${resourceRoleEntity.uid}`
    );

    dispatch({
      type: "REMOVE_RESOURCE_ROLE",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t("Role has been removed"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });

      return;
    }

    dispatch(resourceRolesListActions.initialize("resourceRoles"));
  };
}

export const filtersFetcher = dataFetcher({
  selectors: ["filters"],
  fetchData: async () => {
    const response = await api.get("v1/filters/metadata");

    return response.items || [];
  },
});
