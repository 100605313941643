import { createSelector } from "reselect";
import { IPAM_MODULE } from "../services/ipam";

export const getIpamFormPayload = createSelector(
  (state) => state.forms[IPAM_MODULE]?.data,
  (formData) => {
    const settings =
      formData.mode === "range"
        ? {
            prefix: parseInt(formData.prefix),
            start: formData.start,
            end: formData.end,
          }
        : {
            subnet: formData.subnet,
            prefix: parseInt(formData.cidrPrefix),
          };

    return {
      metadata: {
        name: formData.name,
      },
      spec: {
        restrictToSingleCluster: formData.restrictToSingleCluster,
        pool: {
          nameserver: {
            addresses: formData.nameserverAddresses,
            search: formData.nameserverSearch,
          },
          gateway: formData.gateway,
          ...settings,
        },
      },
    };
  }
);
