export function createContainerImagesFormFactory({
  formActions,
  formModuleName,
}) {
  const module = formModuleName;

  function onAddContainerImage() {
    return (dispatch, getState) => {
      const containerImages = [
        ...getState().forms[module].data.containerImages,
      ];
      containerImages.push({
        namespace: "",
        restrictedImages: [],
      });

      dispatch(
        formActions.batchChange({
          module: module,
          updates: {
            containerImages,
          },
        })
      );
    };
  }

  function onDeleteContainerImage(name) {
    return (dispatch, getState) => {
      const pathParts = name.split(".");
      const deletedEntityIndex = pathParts[1];
      const containerImages = [
        ...getState().forms[module].data.containerImages,
      ];
      containerImages.splice(deletedEntityIndex, 1);

      dispatch(
        formActions.batchChange({
          module: module,
          updates: {
            containerImages,
          },
        })
      );
      dispatch(
        formActions.clearFieldErrors({
          module: module,
          field: `containerImages.${deletedEntityIndex}.namespace`,
        })
      );
      dispatch(
        formActions.clearFieldErrors({
          module: module,
          field: `containerImages.${deletedEntityIndex}.restrictedImages`,
        })
      );
    };
  }

  return {
    actions: {
      onAddContainerImage,
      onDeleteContainerImage,
    },
  };
}
