import createList from "modules/list";
import { edgeMachinesListActions } from "state/cluster/actions/list/edgemachines";
import { EdgeMachineSchema } from "utils/schemas";

const ListingModule = createList({
  actions: edgeMachinesListActions,
  schema: [EdgeMachineSchema],
});

export default ListingModule;
