export function VALIDATE_CLOUD_ACCOUNT_REQUEST(nextState) {
  nextState.isValidating = true;
}

export function VALIDATE_CLOUD_ACCOUNT_SUCCESS(nextState) {
  nextState.isValidating = false;
  nextState.isValidated = true;
}

export function VALIDATE_CLOUD_ACCOUNT_FAILURE(nextState, { error }) {
  nextState.isValidating = false;
  nextState.error = error;
}

export function FORM_LOAD_SUCCESS(nextState, { module, result }) {
  if (module !== "cloudAccountForm") {
    return;
  }

  nextState.isValidated = result.isValidated;
}

export function OPEN_MODAL(nextState, { payload, name }) {
  if (name !== "cloudAccountForm") {
    return;
  }

  nextState.isValidated = false;
  nextState.isValidating = false;
}

export function SET_ACCOUNT_AS_VALID(nextState) {
  nextState.isValidated = true;
}

export function SET_ACCOUNT_AS_INVALID(nextState) {
  nextState.isValidated = false;
}
