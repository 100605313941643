export const initialState = {
  packDifferences: {},
  targetProfiles: [],
};

export function FETCH_CLUSTER_PACK_DIFFS_SUCCESS(
  nextState,
  { profileUid, packName, result },
  state
) {
  nextState.packDifferences[profileUid] =
    state.packDifferences[profileUid] || {};
  nextState.packDifferences[profileUid][packName] = result;
}

export function SET_CLUSTER_UPDATES_TARGET_PROFILES(nextState, { items }) {
  nextState.targetProfiles = items;
}
