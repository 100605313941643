import React from "react";

import Link from "components/common/History/Link";
import Button from "components/ui/Button";
import Icon from "components/ui/Icon";
import Permissions from "components/ui/Permissions";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";

export default function CreateButton({ linkTo, label, permissions, hidden }) {
  return (
    <Permissions has={permissions}>
      <Link to={linkTo} hidden={hidden}>
        <Button data-qa="create_button" type="primary">
          <Icon awesome={faPlusCircle} />
          {label}
        </Button>
      </Link>
    </Permissions>
  );
}
