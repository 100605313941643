import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { CLUSTER_PROFILES } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));
const CreateClusterProfile = React.lazy(() =>
  import("./create/ClusterProfile")
);
const Details = React.lazy(() => import("./details"));
const CreateSystemProfile = React.lazy(() => import("./create/SystemProfile"));

export default createNamespace({
  namespace: "clusterprofiles",
  Component: function Main() {
    return (
      <>
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.ROOT}
          component={Index}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.CREATE_CLUSTER_PROFILE}
          component={CreateClusterProfile}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.DETAILS}
          component={Details}
        />
        <Route
          adminEnabled
          exact
          path={CLUSTER_PROFILES.CREATE_SYSTEM_PROFILE}
          component={CreateSystemProfile}
        />
      </>
    );
  },
});
