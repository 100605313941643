import React from "react";
import styled from "styled-components";
import { lightMidGray, white } from "utils/constants/colors";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid ${lightMidGray};
  background: ${white};
`;

export default function Header({ children }) {
  return <HeaderWrapper>{children}</HeaderWrapper>;
}
