import isEqual from "fast-deep-equal";

function parseEntity(entity) {
  const permissions = entity?.metadata?.annotations?.permissions;

  if (!permissions) {
    return entity;
  }

  const sortedPerms = permissions
    .split(",")
    .sort((perm1, perm2) => perm1.localeCompare(perm2))
    .join(",");

  entity.metadata.annotations.permissions = sortedPerms;

  return entity;
}

export function ADD_ENTITIES(state, { entities }) {
  if (!entities) {
    return;
  }
  Object.keys(entities).forEach((entityType) => {
    const entitiesOfType = entities[entityType];
    state[entityType] = state[entityType] || {};
    Object.keys(entitiesOfType).forEach((guid) => {
      const entity = parseEntity(entitiesOfType[guid]);
      const existingEntity = state?.[entityType]?.[guid];
      if (!existingEntity) {
        state[entityType][entity.guid] = entity;
        return;
      }

      const updates = ["metadata", "spec", "status", "specSummary"].reduce(
        (accumulator, key) => {
          if (entity[key] === undefined) {
            return accumulator;
          }
          accumulator[key] = {
            ...existingEntity[key],
            ...entity[key],
          };
          return accumulator;
        },
        {}
      );

      const updatedEntity = parseEntity({
        ...existingEntity,
        ...entity,
        ...updates,
      });

      if (isEqual(updatedEntity, existingEntity)) {
        return;
      }
      state[entityType][entity.guid] = updatedEntity;
    });
  });
}

export function UPDATE_ENTITY(state, { entityType, id, updates }) {
  const existingEntity = state[entityType][id];
  state[entityType][id] = {
    ...existingEntity,
    ...updates,
  };
}
