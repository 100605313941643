import { v4 as uuid } from "uuid";

function noOp() {}

export default class ModalService {
  static store;
  static setup(store) {
    ModalService.store = store;
  }

  constructor(name) {
    this.guid = uuid();
    this.name = name;
  }

  open(payload) {
    ModalService.store.dispatch({
      type: "OPEN_MODAL",
      payload,
      guid: this.guid,
      name: this.name,
    });
    return this;
  }

  close = () => {
    ModalService.store.dispatch({
      type: "CLOSE_MODAL",
      guid: this.guid,
      name: this.name,
    });
    return this;
  };

  isOpened() {
    return ModalService.store.getState().modal.opened.includes(this.guid);
  }

  resolve = () => {
    this.close();
  };

  reject = () => {
    this.close();
  };

  get data() {
    return ModalService.store.getState().modal.data[this.guid];
  }

  then(resolve = noOp, reject = noOp) {
    this.resolve = async (...args) => {
      try {
        await resolve(...args);
        this.close();
      } catch (e) {
        console.warn(e);
      }
    };
    this.reject = (...args) => {
      reject(...args);
      this.close();
    };
  }
}
