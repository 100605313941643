import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";

import { red } from "utils/constants/colors";

const InvalidIcon = styled(FontAwesomeIcon)`
  color: ${red};
  font-size: 14px;
  margin-left: 4px;
`;

function InfoTooltip({ showTooltip, title }) {
  if (!showTooltip) {
    return null;
  }

  return (
    <Tooltip position="top" title={title}>
      <InvalidIcon icon={faExclamationCircle} />
    </Tooltip>
  );
}

export default InfoTooltip;
