import { createSelector } from "reselect";
import { VIRTUAL_CLUSTER_FORM_MODULE } from "state/devnestedcluster/actions/create";
import { clusterGroupsFetcher } from "state/clustergroups/services/listing";
import { sandboxClusterQuotaUsageFetcher } from "state/devnestedcluster/services";
import { getQuotaPercentages } from "utils/presenters";
import { hasUnlimitedSandboxClusterQuota } from "state/appdeployments/selectors/create";

export const getClusterEndpointType = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data?.clusterGroupUid,
  clusterGroupsFetcher.selector,
  (clusterGroupUid, { result }) => {
    // TO DO: update this once the API is modified
    return "Ingress";
  }
);

export const getVirtualClusterPayload = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  getClusterEndpointType,
  (data = {}, clusterEndpointType) => {
    const loadBalancerConfig = {
      externalTrafficPolicy: "Cluster",
      externalIPs: [],
      loadBalancerSourceRanges: [],
    };

    let clusterConfig = {
      hostClusterConfig: {
        clusterGroup: {
          uid: data.clusterGroupUid,
        },
        hostCluster: {
          uid: data.hostCluster,
        },
      },
    };

    if (clusterEndpointType === "LoadBalancer") {
      clusterConfig.hostClusterConfig.config = loadBalancerConfig;
    }

    return {
      metadata: {
        name: data.name,
        labels: {},
        annotations: {
          description: "",
        },
      },
      spec: {
        profiles: [],
        cloudConfig: {
          helmRelease: {
            chart: {
              name: null,
              repo: null,
              version: null,
            },
            values: null,
          },
        },
        clusterConfig,
        machinepoolconfig: [
          {
            cloudConfig: {
              instanceType: {
                maxCPU: data.cpuLimits ? parseFloat(data.cpuLimits) : 6,
                maxMemInMiB: data.memoryLimits
                  ? parseFloat(data.memoryLimits) * 1024
                  : 8192,
                maxStorageGiB: data.storageLimits
                  ? parseFloat(data.storageLimits)
                  : 10,
                minCPU: 0,
                minMemInMiB: 0,
              },
            },
          },
        ],
      },
    };
  }
);

export const getResourcesRemainingPercentages = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  sandboxClusterQuotaUsageFetcher.selector,
  (formData, { result }) => {
    const { usedCredit, allocatedCredit } = result || {};

    return getQuotaPercentages(formData, usedCredit, allocatedCredit);
  }
);

export const hasSelectedClusterGroup = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  (formData) => {
    return !!formData?.clusterGroupUid;
  }
);

export const isVirtualClusterQuotaExceeded = createSelector(
  getResourcesRemainingPercentages,
  hasUnlimitedSandboxClusterQuota,
  ({ usedCredit, freeCredit }, hasUnlimitedQuota) => {
    if (hasUnlimitedQuota) {
      return false;
    }

    return Object.keys(freeCredit).some(
      (key) =>
        freeCredit[key] > 100 ||
        usedCredit[key] > 100 ||
        (key === "virtualClusters" && freeCredit[key] === 0)
    );
  }
);

export const isClusterGroupProjectScoped = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  clusterGroupsFetcher.selector,
  (formData, { result }) => {
    const clusterGroupUid = formData?.clusterGroupUid;
    const clusterGroups = result?.items || [];
    const clusterGroup = clusterGroups.find(
      (clusterGroup) => clusterGroup?.uid === clusterGroupUid
    );

    return clusterGroup?.scope === "project";
  }
);

export const isVirtualClusterSubmissionDisabled = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.submitting,
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.validating,
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.errors?.length,
  isVirtualClusterQuotaExceeded,
  isClusterGroupProjectScoped,
  (
    isSubmitting,
    isValidating,
    hasErrors,
    isQuotaExceeded,
    isClusterGroupProjectScoped
  ) => {
    return (
      isSubmitting ||
      isValidating ||
      hasErrors ||
      (isQuotaExceeded && !isClusterGroupProjectScoped)
    );
  }
);
