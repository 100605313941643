import querystring from "query-string";

import store from "services/store";
import history from "services/history";
import { HostClusterSchema } from "utils/schemas";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import {
  hostClustersFetcher,
  HOST_CLUSTERS_LIST_MODULE,
} from "state/clustergroups/services/listing";
import { getHostClustersListQuery } from "state/clustergroups/selectors/listing";

export const hostClustersListActions = new ListActions({
  hasPagination: true,
  schema: [HostClusterSchema],
  dataFetcher: hostClustersFetcher,
  initialQuery: () => {
    const query = history.getQuery();
    return { hostClusterName: query?.hostClusterName || "", limit: 20 };
  },
});

export const hostClustersFiltersFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();

    return Promise.resolve({
      hostClusterName: query?.hostClusterName || "",
    });
  },
  submit: (data) => {
    const query = getHostClustersListQuery(store.getState());
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);

    store.dispatch(
      hostClustersListActions.batchChangeQuery({
        module: HOST_CLUSTERS_LIST_MODULE,
        query: {
          ...query,
          hostClusterName: data.hostClusterName,
        },
      })
    );
  },
});
