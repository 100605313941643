import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";

import store from "services/store";

import { getCluster } from "state/cluster/selectors/details";
import { getClusterByUid } from "state/cluster/actions/details";

import { WorkloadVirtualClusterSchema } from "utils/schemas";

export const VIRTUAL_CLUSTERS_MODULE = "workloads/virtual-clusters";

const virtualClusters = dataFetcher({
  selectors: ["clusters", "virtual-clusters"],
  async fetchData(_) {
    let cluster = getCluster(store.getState());
    const clusterUid = store.getState().location.params.id;

    if (!cluster) {
      await store.dispatch(getClusterByUid(clusterUid));
      cluster = getCluster(store.getState());
    }

    return {
      items: cluster?.status?.virtual?.virtualClusters || [],
    };
  },
});

export const virtualClustersListingActions = new ListActions({
  dataFetcher: virtualClusters,
  schema: [WorkloadVirtualClusterSchema],
});
