import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import api, { nonProjectApi } from "services/api";
import ModalService from "services/modal";
import store from "services/store";
import { AppProfileSchema, ClusterGroupSchema } from "utils/schemas";

export const DEPLOYMENT_FORM_MODULE = "createDeployment";
export const APP_PROFILE_LIST_MODULE = "appProfiles";
export const VIRTUAL_CLUSTERS_LIST_MODULE = "deployments/virtual-clusters";
export const cancelCreateDeploymentModal = new ModalService();
export const appProfileSelectionModal = new ModalService();

export const clusterGroupsFetcher = dataFetcher({
  selectors: ["clusterGroups"],
  schema: [ClusterGroupSchema],
  async fetchData() {
    const result = await nonProjectApi.get(
      `v1/clustergroups/hostCluster/metadata`
    );
    return result?.items || [];
  },
});
export const clusterGroupsFetcherWithProjectId = dataFetcher({
  selectors: ["clusterGroups"],
  schema: [ClusterGroupSchema],
  async fetchData() {
    const result = await api.get(`v1/clustergroups/hostCluster/metadata`);
    return result?.items || [];
  },
});

export const appProfilesListActions = new ListActions({
  hasPagination: true,
  schema: [AppProfileSchema],
  initialQuery: () => {
    return {
      limit: 20,
      search: "",
    };
  },
  async fetchData(query) {
    const { continue: continueToken, limit, offset, search } = query || {};
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    const payload = {
      filter: {
        profileName: {
          contains: search || "",
        },
      },
      sort: [
        {
          field: "lastModifiedTimestamp",
          order: "desc",
        },
      ],
    };

    const data = await api.post(
      `v1/dashboard/appProfiles?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );

    return {
      listmeta: data?.listmeta || {},
      items: data?.appProfiles || [],
    };
  },
});

export const appProfileFetcher = dataFetcher({
  selector: ["appProfile"],
  schema: AppProfileSchema,
  async fetchData(_, uid) {
    const data = await api.get(`v1/appProfiles/${uid}`);

    const versions = data.spec.versions;
    versions.forEach((version) => {
      store.dispatch({
        schema: AppProfileSchema,
        data: {
          metadata: {
            uid: version.uid,
            name: data.metadata.name,
          },
          spec: {
            version: version.version,
            versions,
          },
        },
      });
    });

    return data;
  },
});
