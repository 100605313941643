export const initialState = {
  permissions: [],
  loading: false,
};

export function FETCH_PERMISSIONS_REQUEST(nextState) {
  nextState.loading = true;
}

export function FETCH_PERMISSIONS_SUCCESS(nextState, { result }) {
  nextState.loading = false;
  nextState.permissions = result;
}

export function FETCH_PERMISSIONS_FAILURE(nextState) {
  nextState.loading = false;
}
