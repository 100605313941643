import i18next from "i18next";
import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import notifications from "services/notifications";

export const teamsFetcher = dataFetcher({
  selectors: ["teams"],
  fetchData() {
    try {
      const response = api.get("v1/teams");
      return response;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to fetch the teams"
        ),
        description: err?.message,
      });
      return [];
    }
  },
});
