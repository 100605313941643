import store from "services/store";
import i18n from "i18next";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import ModalService from "services/modal";
import notifications from "services/notifications";
import { RoleSchema } from "utils/schemas";

import createFormActions from "modules/form/actions";
import api from "services/api";
import {
  projectRolesListActions,
  resourceRolesListActions,
  tenantRolesListActions,
} from "state/roles/actions/list";

import { getPermissions } from "../selectors/create";
import { getSelectedRole } from "../selectors/details";
import { selectRole } from "./details";

export const ROLES_MODULE = "roles";

export const roleModal = new ModalService("roleModal");

export function onCreateRole() {
  return (dispatch) => {
    roleModal.open().then(() => {
      return dispatch(roleFormActions.submit({ module: ROLES_MODULE }));
    });
    dispatch(roleFormActions.init({ module: ROLES_MODULE }));
  };
}

export function onEditRole() {
  return function thunk(dispatch, getState) {
    const role = getSelectedRole(getState());
    const uid = role.metadata.uid;
    if (uid) {
      roleModal.open({ uid }).then(() => {
        return dispatch(roleFormActions.submit({ module: ROLES_MODULE }));
      });
      dispatch(roleFormActions.init({ module: ROLES_MODULE }));
      dispatch(fetchPermissions(role.spec.scope));
    }
  };
}

export const validator = new Validator();
function createValidator() {
  validator.removeRules();
  validator.addRule(["scope", "name", "permissions"], Missing());
}

createValidator();

export async function submit(data) {
  const uid = roleModal.data?.uid;
  const permissionNames = getPermissions(store.getState()).map(
    (permission) => permission.name
  );

  const payload = {
    metadata: {
      name: data.name,
      annotations: { scope: data.scope },
    },
    spec: {
      permissions: data.permissions.filter(
        (permission) => !permissionNames.includes(permission)
      ),
      scope: data.scope,
    },
    status: {
      isEnabled: true,
    },
  };

  try {
    if (uid) {
      await api.put(`v1/roles/${uid}`, payload);
      store.dispatch({
        type: "UPDATE_ROLE",
        promise: api.get(`v1/roles/${uid}`),
        schema: RoleSchema,
      });
      return;
    }
    store.dispatch(selectRole(null));
    await api.post("v1/roles", payload);

    const listingActions = {
      project: projectRolesListActions,
      tenant: tenantRolesListActions,
      resource: resourceRolesListActions,
    };

    const currentTab = store.getState().location.params.tab;

    store.dispatch(listingActions[currentTab].initialize(ROLES_MODULE));
    notifications.success({
      message: i18n.t("Role '{{name}}' created successfully", {
        name: data.name,
      }),
    });
  } catch (error) {
    const message = uid
      ? `Something went wrong while editing the role`
      : `Something went wrong while creating the role`;
    notifications.error({
      message: i18n.t(message),
      description: error.message,
    });

    return Promise.reject(error);
  }
}

export const roleFormActions = createFormActions({
  validator,
  submit,
  init: async () => {
    const scope = store.getState().location.params?.tab;

    store.dispatch(fetchPermissions(scope));

    let initialData = {
      scope,
      name: "",
      permissions: [],
    };

    if (roleModal.data?.uid) {
      const role = await api.get(`v1/roles/${roleModal.data?.uid}`);
      initialData = {
        scope: role.spec.scope,
        name: role.metadata.name,
        permissions: role.spec.permissions,
      };
    }
    return Promise.resolve(initialData);
  },
});

export function fetchPermissions(scope) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PERMISSIONS",
      promise: api.get("v1/permissions", { scope }),
    });
  };
}
