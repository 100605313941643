import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import { WorkloadNamespaceSchema } from "utils/schemas";

export const NAMESPACES_MODULE = "clusterGroupNamespaces";

export const namespacesFetcher = dataFetcher({
  selectors: ["workload", "namespaces"],
  async fetchData(_, { namespaces = [], clusters = [] } = {}) {
    //TODO: integrate API
    return { items: [] };
  },
});

export const namespacesListingActions = new ListActions({
  dataFetcher: namespacesFetcher,
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [WorkloadNamespaceSchema],
});
