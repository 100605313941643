import { createOpenstackFormFactory } from "modules/cluster/openstack";
import clusterFormActions from "../form";
import { getSelectedCredential } from "state/cluster/selectors/create";

export const openstackCloudForm = createOpenstackFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

function selectCredentialEffect() {
  return async function (dispatch, getState) {
    const account = getSelectedCredential(getState());
    openstackCloudForm.actions.onAccountSelected("credential", account);
  };
}

function onChangeStaticPlacement(value) {
  return (dispatch) => {
    if (!value) {
      dispatch(
        clusterFormActions.clearFieldErrors({
          field: "network",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.clearFieldErrors({
          field: "subnet",
          module: "cluster",
        })
      );
    } else {
      dispatch(
        clusterFormActions.clearFieldErrors({
          field: "nodeCidr",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.clearFieldErrors({
          field: "dnsNameservers",
          module: "cluster",
        })
      );
    }
  };
}

const effects = {
  selectCredentialEffect,
  onChangeStaticPlacement,
};

export default effects;
