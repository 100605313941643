import querystring from "query-string";

import api from "services/api";
import history from "services/history";
import ListActions from "modules/list/actions";

import { ClusterSchema } from "utils/schemas";
import { getBoolean, parseFilterGroup } from "utils/parsers";

export const VIRTUAL_CLUSTERS_LIST_MODULE = "clusters/virtual";

function mapClusterQuery({
  search,
  sortField,
  sortOrder,
  deleted,
  showOnlyHealthy = false,
} = {}) {
  const mappedFilters = [
    {
      conjunction: "and",
      conditions: [
        {
          property: "environment",
          values: ["nested"],
          operator: "eq",
          type: "string",
        },
        {
          property: "isDeleted",
          values: deleted,
          operator: "eq",
          type: "bool",
        },
        {
          displayName: "Name",
          operator: "contains",
          property: "clusterName",
          type: "string",
          values: search,
        },
        showOnlyHealthy && {
          property: "clusterState",
          values: "Running",
          operator: "eq",
          type: "string",
        },
        showOnlyHealthy && {
          property: "healthState",
          values: ["Healthy"],
          operator: "eq",
          type: "string",
        },
      ].filter(Boolean),
    },
  ];

  const filters = mappedFilters.map((group) => ({
    conjunction: group.conjunction,
    filters: group.conditions.map((condition) => parseFilterGroup(condition)),
  }));

  return {
    filter: {
      conjunction: "and",
      filterGroups: filters,
    },
    sort: [
      {
        field: sortField || "lastModifiedTimestamp",
        order: sortOrder || "desc",
      },
    ],
  };
}

const virtualClusterListActionFactory = (limit = 20) => {
  return {
    hasPagination: true,
    schema: [ClusterSchema],
    defaultQuery: {
      search: "",
      deleted: false,
      sortField: "",
      sortOrder: "",
      limit,
    },
    initialQuery: () => {
      const query = history.getQuery();
      return {
        search: query?.search || "",
        deleted: getBoolean(query?.deleted),
        sortField: query?.sortField || "",
        sortOrder: query?.sortOrder || "",
        limit,
      };
    },
    fetchData(query) {
      const { offset, limit, continue: continueToken, ...rest } = query;
      const continueQueryParam = continueToken
        ? `&continue=${continueToken}`
        : "";
      const payload = mapClusterQuery(rest);
      const queryString = querystring.stringify(rest);
      history.replace(`?${queryString}`);

      return api.post(
        `v1/dashboard/spectroclusters/search?limit=${limit}&offset=${offset}${continueQueryParam}`,
        payload
      );
    },
  };
};

export const virtualClustersListActions = new ListActions(
  virtualClusterListActionFactory()
);
export const virtualClustersListActionsDashboard = new ListActions(
  virtualClusterListActionFactory(5)
);

export const healthyVirtualClustersListActions = new ListActions({
  hasPagination: true,
  schema: [ClusterSchema],
  initialQuery: () => {
    const query = history.getQuery();
    return {
      search: query?.search || "",
      sortField: query?.sortField || "",
      sortOrder: query?.sortOrder || "",
      limit: 20,
    };
  },
  async fetchData(query) {
    const { offset, limit, continue: continueToken, ...rest } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const payload = mapClusterQuery({ ...rest, showOnlyHealthy: true });
    const queryString = querystring.stringify(rest);
    history.replace(`?${queryString}`);

    const response = await api.post(
      `v1/dashboard/spectroclusters/search?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );

    const filteredClusters = (response?.items || []).filter((cluster) => {
      const clusterLifeCycleStatus =
        cluster?.status?.virtual?.lifecycleStatus?.status;
      return !["paused", "pausing", "resuming"].includes(
        clusterLifeCycleStatus?.toLowerCase()
      );
    });

    return {
      items: filteredClusters,
      listmeta: { ...response.listmeta, count: filteredClusters.length },
    };
  },
});
