export const periodicTableElements = [
  "Actinium",
  "Aluminum",
  "Americium",
  "Antimony",
  "Argon",
  "Arsenic",
  "Astatine",
  "Barium",
  "Berkelium",
  "Beryllium",
  "Bismuth",
  "Bohrium",
  "Boron",
  "Bromine",
  "Cadmium",
  "Calcium",
  "Californium",
  "Carbon",
  "Cerium",
  "Cesium",
  "Chlorine",
  "Chromium",
  "Cobalt",
  "Copper",
  "Curium",
  "Darmstadtium",
  "Dubnium",
  "Dysprosium",
  "Einsteinium",
  "Erbium",
  "Europium",
  "Fermium",
  "Fluorine",
  "Francium",
  "Gadolinium",
  "Gallium",
  "Germanium",
  "Gold",
  "Hafnium",
  "Hassium",
  "Helium",
  "Holmium",
  "Hydrogen",
  "Indium",
  "Iodine",
  "Iridium",
  "Iron",
  "Krypton",
  "Lanthanum",
  "Lawrencium",
  "Lead",
  "Lithium",
  "Lutetium",
  "Magnesium",
  "Manganese",
  "Meitnerium",
  "Mendelevium",
  "Mercury",
  "Molybdenum",
  "Neodymium",
  "Neon",
  "Neptunium",
  "Nickel",
  "Niobium",
  "Nitrogen",
  "Nobelium",
  "Oganesson",
  "Osmium",
  "Oxygen",
  "Palladium",
  "Phosphorus",
  "Platinum",
  "Plutonium",
  "Polonium",
  "Potassium",
  "Praseodymium",
  "Promethium",
  "Protactinium",
  "Radium",
  "Radon",
  "Rhenium",
  "Rhodium",
  "Roentgenium",
  "Rubidium",
  "Ruthenium",
  "Rutherfordium",
  "Samarium",
  "Scandium",
  "Seaborgium",
  "Selenium",
  "Silicon",
  "Silver",
  "Sodium",
  "Strontium",
  "Sulfur",
  "Tantalum",
  "Technetium",
  "Tellurium",
  "Terbium",
  "Thallium",
  "Thorium",
  "Thulium",
  "Tin",
  "Titanium",
  "Tungsten",
  "Ununbium",
  "Ununhexium",
  "Ununpentium",
  "Ununquadium",
  "Ununseptium",
  "Ununtrium",
  "Uranium",
  "Vanadium",
  "Xenon",
  "Ytterbium",
  "Yttrium",
  "Zinc",
  "Zirconium",
];

export const constellationsGalaxiesAndStars = [
  "Acamar",
  "Achernar",
  "Achird",
  "Acrab",
  "Acrux",
  "Acubens",
  "Adhafera",
  "Adhara",
  "Adhil",
  "Ain",
  "Ainalrami",
  "Aladfar",
  "Albaldah",
  "Albali",
  "Albireo",
  "Alchiba",
  "Alcor",
  "Alcyone",
  "Aldebaran",
  "Alderamin",
  "Aldhanab",
  "Aldhibah",
  "Aldulfin",
  "Alfirk",
  "Algedi",
  "Algenib",
  "Algieba",
  "Algol",
  "Algorab",
  "Alhena",
  "Alioth",
  "Aljanah",
  "Alkaid",
  "Alkalurops",
  "Alkaphrah",
  "Alkarab",
  "Alkes",
  "Almaaz",
  "Almach",
  "Alnair",
  "Alnasl",
  "Alnilam",
  "Alnitak",
  "Alniyat",
  "Alphard",
  "Alphecca",
  "Alpheratz",
  "Alpherg",
  "Alrakis",
  "Alrescha",
  "Alruba",
  "Alsafi",
  "Alsciaukat",
  "Alsephina",
  "Alshain",
  "Alshat",
  "Altair",
  "Altais",
  "Alterf",
  "Aludra",
  "Alula Australis",
  "Alula Borealis",
  "Alya",
  "Alzirr",
  "Ancha",
  "Angetenar",
  "Ankaa",
  "Anser",
  "Antares",
  "Arcturus",
  "Arkab Posterior",
  "Arkab Prior",
  "Arneb",
  "Ascella",
  "Asellus Australis",
  "Asellus Borealis",
  "Ashlesha",
  "Aspidiske",
  "Asterope",
  "Athebyne",
  "Atik",
  "Atlas",
  "Atria",
  "Avior",
  "Azelfafage",
  "Azha",
  "Azmidi",
  "Backward",
  "Bellatrix",
  "Betelgeuse",
  "Black Eye",
  "Butterfly",
  "Camelopardalis",
  "Canes Venatici",
  "Canis Major",
  "Canis Minor",
  "Canopus",
  "Capella",
  "Caph",
  "Capricornus",
  "Carina",
  "Cartwheel",
  "Cassiopeia",
  "Castor",
  "Centaurus",
  "Cepheus",
  "Cetus",
  "Chamaeleon",
  "Circinus",
  "Columba",
  "Coma Berenices",
  "Coma Pinwheel",
  "Comet",
  "Condor",
  "Cor Caroli",
  "Corona Australis",
  "Corona Borealis",
  "Corvus",
  "Crater",
  "Crux",
  "Cyg X-1",
  "Cygnus",
  "Delphinus",
  "Deneb",
  "Denebola",
  "Diphda",
  "Dorado",
  "Draco",
  "Dschubba",
  "Dubhe",
  "Dziban",
  "Enif",
  "Equuleus",
  "Eridanus",
  "Etamin",
  "Edasich",
  "Electra",
  "Elgafar",
  "Elkurud",
  "Elnath",
  "Eltanin",
  "Errai",
  "Fafnir",
  "Fang",
  "Fawaris",
  "Felis",
  "Fomalhaut",
  "Fulu",
  "Fumalsamakah",
  "Furud",
  "Fuyue",
  "Gacrux",
  "Giausar",
  "Gienah",
  "Ginan",
  "Gomeisa",
  "Grumium",
  "Gudja",
  "Guniibuu",
  "Gemini",
  "Grus",
  "Hercules",
  "Hockey Stick",
  "Hydra",
  "Lacerta",
  "Leo Minor",
  "Leo",
  "Lepus",
  "Libra",
  "Little Sombrero",
  "Lupus",
  "Lynx",
  "Lyra",
  "Medusa Merger",
  "Mensa",
  "Mice",
  "Milky Way",
  "Monoceros",
  "Needle",
  "Octans",
  "Ophiuchus",
  "Orion",
  "Pavo",
  "Pegasus",
  "Phoenix",
  "Pinwheel",
  "Pisces",
  "Piscis Austrinus",
  "Puppis",
  "Reticulum",
  "Sagitta",
  "Sagittarius",
  "Scorpius",
  "Sculptor Dwarf",
  "Sculptor",
  "Serpens",
  "Sextans",
  "Sombrero",
  "Southern Pinwheel",
  "Sunflower",
  "Tadpole",
  "Taurus",
  "Triangulum",
  "Tucana",
  "Ursa Major",
  "Ursa Minor",
  "Vela",
  "Virgo",
  "Vulpecula",
  "Whirlpool",
  "Wasat",
  "Wazn",
  "Wezen",
  "Wurren",
  "Xamidimura",
  "Xuange",
  "Yed Posterior",
  "Yed Prior",
  "Yildun",
  "Zaniah",
  "Zaurak",
  "Zavijava",
  "Zhang",
  "Zibal",
  "Zosma",
  "Zubenelgenubi",
  "Zubenelhakrabi",
  "Zubeneschamali",
];
