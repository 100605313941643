import querystring from "query-string";

import { ClusterGroupSchema } from "utils/schemas";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import api from "services/api";
import history from "services/history";
import store from "services/store";

import { groupsFilteredClustersFetcher } from "state/clustergroups/services/create";

export const clusterGroupsListActions = new ListActions({
  schema: [ClusterGroupSchema],
  async fetchData() {
    const result = await api.get(`v1/clustergroups/hostCluster`);
    return {
      items: result?.summaries || [],
    };
  },
});

function getQueryFormData(query) {
  const clusters =
    query?.clusters && !Array.isArray(query.clusters)
      ? [query.clusters]
      : query.clusters;

  return {
    clusters,
    groupName: query?.groupName || "",
  };
}

export const clusterGroupsFiltersFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    const data = getQueryFormData(query);
    store.dispatch(groupsFilteredClustersFetcher.fetch());

    return Promise.resolve({
      groupName: data?.groupName || "",
      clusters: data?.clusters || [],
    });
  },
  submit: (data) => {
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);
  },
});
