import { createSelector } from "reselect";

import { paymentPlansFetcher, subscriptionFetcher } from "./services";
import { getTenantPlanType } from "state/auth/selectors";

export const getStripePlans = createSelector(
  paymentPlansFetcher.selector,
  ({ result }) => {
    const planDetails = result?.stripePlanDetails || [];
    const annualPlan = planDetails.find(
      (plan) => plan.name === "AnnualSubscription"
    );
    return [
      ...planDetails.filter((plan) => plan.name !== "AnnualSubscription"),
      annualPlan,
    ].filter(Boolean);
  }
);

export const getSelectedStripePlan = createSelector(
  getStripePlans,
  getTenantPlanType,
  (plans, tenantPlan) => {
    return plans?.find((plan) => plan.name === tenantPlan);
  }
);

export const isSubscriptionInPending = createSelector(
  subscriptionFetcher.selector,
  ({ result }) => {
    return result?.subscriptionState === "PendingCancellation";
  }
);
