import i18next from "i18next";

import api from "services/api";
import historyService from "services/history";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import Validator from "services/validator";
import { ApplyIf, Missing } from "services/validator/rules";

import { AuditTrailSchema } from "utils/schemas";
import { SETTINGS } from "utils/constants/routes";

import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import { getCurrentUser } from "state/auth/selectors";

import {
  deleteConfirmService,
  auditTrailsModal,
  auditTrailsFetcher,
  AUDIT_TRAILS_LIST_MODULE,
  AUDIT_TRAILS_FORM_MODULE,
} from "./services";

const auditTrailsValidator = new Validator();
auditTrailsValidator.addRule(["name", "type", "group", "region"], Missing());
auditTrailsValidator.addRule(
  ["accessKey", "secretKey"],
  ApplyIf((value, key, data) => data.credentialType === "secret", Missing())
);
auditTrailsValidator.addRule(
  ["arn"],
  ApplyIf((value, key, data) => data.credentialType === "sts", Missing())
);

export const auditTrailsListActions = new ListActions({
  dataFetcher: auditTrailsFetcher,
  schema: [AuditTrailSchema],
});

export function onAuditTrailDelete(guid) {
  return function thunk(dispatch, getState) {
    deleteConfirmService.open({ guid }).then(async () => {
      const tenantUid = getCurrentUser(getState())?.metadata?.annotations
        ?.tenantUid;
      const entity = getStoreEntity(guid, AuditTrailSchema);
      const { name } = entity?.metadata || {};
      const promise = api.delete(`v1/tenants/${tenantUid}/assets/dataSinks`);

      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18next.t("Something went wrong while deleting the audit."),
          description: error.message,
        });
        dispatch(auditTrailsListActions.initialize(AUDIT_TRAILS_LIST_MODULE));
        return;
      }
      notifications.success({
        message: i18next.t("Audit '{{name}}' was deleted successfully", {
          name,
        }),
      });
      dispatch(auditTrailsListActions.initialize(AUDIT_TRAILS_LIST_MODULE));
    });
  };
}

function getPayload(data, uid) {
  const {
    name,
    type,
    group,
    region,
    credentialType,
    accessKey,
    secretKey,
    arn,
    externalId,
    stream,
  } = data;

  const payload = {
    metadata: {
      name,
      uid,
    },
    spec: {
      auditDataSinks: [
        {
          cloudWatch: {
            credentials: {
              credentialType,
              accessKey: credentialType === "secret" ? accessKey : undefined,
              secretKey: credentialType === "secret" ? secretKey : undefined,
              sts:
                credentialType === "sts"
                  ? {
                      arn,
                      externalId,
                    }
                  : {},
            },
            group,
            region,
            stream,
          },
          type,
        },
      ],
    },
  };

  return payload;
}

export const auditTrailsFormActions = createFormActions({
  init: async () => {
    const uid = auditTrailsModal?.data?.uid;
    const tenantUid = getCurrentUser(store.getState())?.metadata?.annotations
      ?.tenantUid;
    let data;

    const stsData = await api.get("v1/clouds/aws/account/sts");

    if (uid) {
      const promise = api.get(`v1/tenants/${tenantUid}/assets/dataSinks`);
      try {
        data = await promise;
      } catch (err) {}
    }
    const { cloudWatch, type } = data?.spec?.auditDataSinks?.[0] || {};
    const { group, region, credentials, stream } = cloudWatch || {};

    return Promise.resolve({
      name: data?.metadata?.name || "",
      type: type || "cloudwatch",
      group: group || "",
      region: region || "",
      credentialType: credentials?.credentialType || "secret",
      accessKey: credentials?.accessKey || "",
      secretKey: credentials?.secretKey || "",
      arn: credentials?.sts?.arn || "",
      stream: stream || "",
      isValid: !!uid,
      accountId: stsData?.accountId,
      externalId: stsData?.externalId || "",
    });
  },
  validator: auditTrailsValidator,
  submit: async (data) => {
    const uid = auditTrailsModal?.data?.uid;
    const payload = getPayload(data, uid);
    const tenantUid = getCurrentUser(store.getState())?.metadata?.annotations
      ?.tenantUid;
    let promise;

    if (uid) {
      promise = api.put(`v1/tenants/${tenantUid}/assets/dataSinks`, payload);
    } else {
      promise = api.post(`v1/tenants/${tenantUid}/assets/dataSinks`, payload);
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: uid
          ? i18next.t("Something went wrong while updating the audit trail")
          : i18next.t("Something went wrong while creating the audit trail"),
        description: error.message,
      });
      return Promise.reject(error);
    }

    notifications.success({
      message: uid
        ? i18next.t("Audit trail '{{name}}' was updated successfully", {
            name: data?.name,
          })
        : i18next.t("Audit trail '{{name}}' was created successfully", {
            name: data?.name,
          }),
    });
  },
});

export function openAuditTrailModal(uid) {
  return (dispatch) => {
    function fallback() {
      historyService.push(SETTINGS.AUDIT_TRAILS);
    }
    auditTrailsModal.open({ uid }).then(async () => {
      await dispatch(
        auditTrailsFormActions.submit({
          module: AUDIT_TRAILS_FORM_MODULE,
        })
      );
      fallback();
    }, fallback);
    dispatch(auditTrailsFormActions.init({ module: AUDIT_TRAILS_FORM_MODULE }));
  };
}

export function validateAuditTrail() {
  return async (dispatch, getState) => {
    const {
      group,
      region,
      accessKey,
      secretKey,
      credentialType,
      arn,
      externalId,
    } = getState().forms[AUDIT_TRAILS_FORM_MODULE]?.data || {};

    const errors = await dispatch(
      auditTrailsFormActions.validateForm({
        module: AUDIT_TRAILS_FORM_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const payload = {
      credentials: {
        accessKey: credentialType === "secret" ? accessKey : undefined,
        secretKey: credentialType === "secret" ? secretKey : undefined,
        credentialType,
        sts:
          credentialType === "sts"
            ? {
                arn,
                externalId,
              }
            : {},
      },
      group,
      region,
    };
    const promise = api.post("v1/clouds/aws/cloudwatch/validate", payload);

    dispatch({
      type: "VALIDATE_AUDIT_TRAIL",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong while validating the audit"),
        description: error.message,
      });
    }
  };
}

export function onFormChangeField(name, value) {
  return (dispatch, getState) => {
    const isValidated = getState().auditTrails.isValid;

    if (isValidated) {
      dispatch({
        type: "VALIDATE_AUDIT_TRAIL_FAILURE",
      });
    }

    dispatch(
      auditTrailsFormActions.onChange({
        name,
        value,
        module: AUDIT_TRAILS_FORM_MODULE,
      })
    );
  };
}
