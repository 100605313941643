import i18next from "i18next";
import store from "services/store";

import api from "services/api";
import ModalService from "services/modal";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import notifications from "services/notifications";

import {
  getSelectedUser,
  getSelectedTeam,
  getCurrentWorkspaceRoles,
  getSelectedWorkspaceRoles,
  getSelectedWorkspaceRoleEntity,
} from "../selectors";
import ListActions from "modules/list/actions";
import { WorkspaceRolesSchema } from "utils/schemas";

const TYPE_TO_API_PATH = {
  user: "users",
  team: "teams",
};
export const workspaceRolesModal = new ModalService();

function parseWorkspaceRolesPayload(projects) {
  const parsedRoles = projects.reduce((acc, project) => {
    const workspaces = (project.workspaces || []).map((workspace) => {
      const inheritedRoles =
        workspace?.inheritedRoles.map((workspaceRole) => ({
          metadata: {
            uid: workspaceRole.uid,
            name: workspaceRole.name,
            inherited: true,
          },
        })) || [];

      const roles =
        workspace?.roles.map((workspaceRole) => ({
          metadata: {
            uid: workspaceRole.uid,
            name: workspaceRole.name,
            inherited: false,
          },
        })) || [];

      return {
        project: { metadata: { name: project.name, uid: project.uid } },
        workspaces: [
          {
            metadata: { name: workspace.name, uid: workspace.uid },
          },
        ],
        roles: [...roles, ...inheritedRoles],
      };
    });

    return [...acc, ...workspaces];
  }, []);

  return parsedRoles.filter((parsedRole) => parsedRole.roles.length);
}

export const workspaceRolesListActions = new ListActions({
  async fetchData() {
    const state = store.getState();
    const currentEntityType = state.roleManagement.entityType;
    const selectedEntity =
      currentEntityType === "user"
        ? getSelectedUser(state)
        : getSelectedTeam(state);

    let promise;
    if (TYPE_TO_API_PATH[currentEntityType]) {
      promise = api.get(
        `v1/workspaces/${TYPE_TO_API_PATH[currentEntityType]}/${selectedEntity.metadata.uid}/roles`
      );
    }

    let response;
    try {
      response = await promise;
    } catch (error) {}

    return {
      items: parseWorkspaceRolesPayload(response?.projects),
    };
  },
  schema: [WorkspaceRolesSchema],
});

export const workspaceRolesValidator = new Validator();
workspaceRolesValidator.addRule(
  ["roles", "workspaces", "projectUid"],
  Missing()
);

function getRolesUids(roles) {
  return roles.map((role) => role.metadata.uid);
}

function getWorkspacesUids(workspaces) {
  return workspaces.map((workspace) => workspace.metadata.uid);
}

export async function updateData(data) {
  const state = store.getState();
  const selectedRoles = getRolesUids(getSelectedWorkspaceRoles(state));
  const selectedWorkspaceRoleId = getSelectedWorkspaceRoleEntity(state)?.guid;
  const currentEntityType = state.roleManagement.entityType;
  const selectedEntity =
    currentEntityType === "user"
      ? getSelectedUser(state)
      : getSelectedTeam(state);

  const currentWorkspaceRoles = getCurrentWorkspaceRoles(state).map(
    ({ project, workspaces, roles }) => {
      return {
        projectUid: project.metadata.uid,
        workspaces: getWorkspacesUids(workspaces),
        roles: getRolesUids(roles),
      };
    }
  );

  if (selectedWorkspaceRoleId) {
    const index = currentWorkspaceRoles.findIndex(
      (workspaceRole) => workspaceRole.workspaces[0] === data.workspaces[0]
    );

    currentWorkspaceRoles[index] = {
      projectUid: data.projectUid,
      workspaces: data.workspaces,
      roles: selectedRoles,
    };
  } else {
    currentWorkspaceRoles.push({
      projectUid: data.projectUid,
      workspaces: data.workspaces,
      roles: selectedRoles,
    });
  }

  const parsedWorkspaces = currentWorkspaceRoles.reduce(
    (acc, workspaceRole) => {
      const workspaces = (workspaceRole.workspaces || []).map((uid) => ({
        uid,
        roles: workspaceRole.roles,
      }));
      return [...acc, ...workspaces];
    },
    []
  );

  let promise;
  if (TYPE_TO_API_PATH[currentEntityType]) {
    promise = api.put(
      `v1/workspaces/${TYPE_TO_API_PATH[currentEntityType]}/${selectedEntity.metadata.uid}/roles`,
      {
        workspaces: parsedWorkspaces,
      }
    );
  }

  let response;
  try {
    response = await promise;
  } catch (error) {
    notifications.error({
      message: i18next.t("Something went wrong"),
      description: error?.message,
    });
    return;
  }

  return response;
}
export function onWorkspaceRoleRemove(workspaceRoleGuid) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentWorkspaceRoles = getCurrentWorkspaceRoles(state);
    const currentEntityType = state.roleManagement.entityType;
    const selectedEntity =
      currentEntityType === "user"
        ? getSelectedUser(state)
        : getSelectedTeam(state);

    const workspaceRoleIndex = currentWorkspaceRoles.findIndex(
      (workspaceRole) => workspaceRole.guid === workspaceRoleGuid
    );

    const newWorkspacesRoles = currentWorkspaceRoles.map(
      ({ workspaces, roles }) => ({
        uid: workspaces[0].metadata.uid,
        roles: roles.map((role) => role.metadata.uid),
      })
    );

    newWorkspacesRoles[workspaceRoleIndex].roles = [];

    let promise;
    if (TYPE_TO_API_PATH[currentEntityType]) {
      promise = api.put(
        `v1/workspaces/${TYPE_TO_API_PATH[currentEntityType]}/${selectedEntity.metadata.uid}/roles`,
        {
          workspaces: newWorkspacesRoles,
        }
      );
    }

    dispatch({
      type: "REMOVE_WORKSPACE_ROLES",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t("Role has been removed"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });

      return;
    }

    dispatch(workspaceRolesListActions.initialize("workspaceRoles"));
  };
}
