import { listingTablePreferences } from "services/localstorage/cache";

export default class ColumnActions {
  savePreferences({ module, reset = false }) {
    return (_, getState) => {
      const updates = getState()?.list[module]?.columns || {};
      const cache = listingTablePreferences.get(module) || {};
      const { columnPreferences = {} } = cache;
      const newColumnPreferences = !reset
        ? {
            ...columnPreferences,
            ...updates,
          }
        : {};
      listingTablePreferences.set(module, {
        ...cache,
        columnPreferences: newColumnPreferences,
      });
    };
  }

  getColumnsSettings = ({ module }) => {
    return (_, getState) => {
      return getState()?.list?.[module]?.columns || {};
    };
  };

  toggleShowColumn = ({ module, key, value }) => {
    return (dispatch, getState) => {
      const columnSettings = getState()?.list[module]?.columns || {};
      const hidden = [...columnSettings?.hidden] || [];
      const newHidden = updateColumnStateArray(value, hidden, key);
      dispatch({
        module,
        type: "TOGGLE_SHOW_COLUMN",
        value: newHidden,
      });
      dispatch(this.savePreferences({ module }));
    };
  };

  togglePin = ({ module, key, value, scrollableColumnsKeys }) => {
    return (dispatch, getState) => {
      const columnSettings = getState()?.list[module]?.columns || {};
      const pinned = [...columnSettings?.pinned] || [];
      const scrollable = [...scrollableColumnsKeys] || [];
      const newPinned = updateColumnStateArray(!value, pinned, key);

      if (value) {
        const index = scrollable.findIndex((elem) => elem === key);
        scrollable.splice(index, 1);
      } else {
        scrollable.unshift(key);
      }

      dispatch({
        module,
        type: "TOGGLE_PIN",
        pinned: newPinned,
        scrollable,
      });
      dispatch(this.savePreferences({ module }));
    };
  };

  dragAndDropColumn = ({ module, key, value }) => {
    return (dispatch) => {
      dispatch({
        module,
        type: "DND_COLUMNS",
        key,
        value,
      });
      dispatch(this.savePreferences({ module }));
    };
  };

  resetColumnsPreferences = ({ module }) => {
    return (dispatch) => {
      dispatch({
        module,
        type: "RESET_COLUMNS",
      });
      dispatch(this.savePreferences({ module, reset: true }));
    };
  };

  onColumnResize = ({ module, key, newWidth }) => {
    return (dispatch) => {
      dispatch({
        module,
        type: "RESIZE_COLUMN",
        key,
        value: newWidth,
      });
      dispatch(this.savePreferences({ module }));
    };
  };
}

const updateColumnStateArray = (value, stateArray, key) => {
  const newStateArray = [...stateArray];
  if (value) {
    const index = newStateArray.findIndex((elem) => elem === key);
    newStateArray.splice(index, 1);
  } else {
    newStateArray.push(key);
  }

  return newStateArray;
};
