export const initialState = {
  isLoading: false,
  list: [],
  data: {},
  isMapViewLoading: true,
  mapSelectedClusterGuid: null,
  bounds: null,
  mapFilterTab: "total",
  mapData: [],
  selectedFilterTag: null,
};

export function FETCH_CLUSTERS_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function FETCH_CLUSTERS_SUCCESS(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTERS_FAILURE(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTERS_NORMALIZATION(nextState, { result }) {
  nextState.list = result;
}

export function GET_CLUSTERS_TOTAL_CORES(nextState, { data }) {
  nextState.data = { ...data };
}

export function MAP_VIEW_REQUEST(nextState) {
  nextState.isMapViewLoading = true;
}

export function MAP_VIEW_NORMALIZATION(nextState, { result }) {
  nextState.isMapViewLoading = false;
  if (result) {
    nextState.mapData = result.items;
  }
}

export function MAP_SELECT_VISIBLE_CLUSTER(nextState, { clusterGuid }) {
  nextState.mapSelectedClusterGuid = clusterGuid;
}

export function MAP_SET_BOUNDS(nextState, { bounds }) {
  nextState.bounds = bounds;
}

export function MAP_SET_FILTER_TAB(nextState, { mapFilterTab }) {
  nextState.mapFilterTab = mapFilterTab;
}

export function SELECT_FILTER_TAG(nextState, { selectedFilterTag }) {
  nextState.selectedFilterTag = selectedFilterTag;
}
