import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import { getCluster } from "state/cluster/selectors/details";
import { DaemonSetSchema } from "utils/schemas";

export const DAEMONSETS_MODULE = "daemonsets";

export const daemonSetsListingActions = new ListActions({
  async fetchData({ filterBy = [] } = {}) {
    const cluster = getCluster(store.getState());

    const data = await api.post(
      `v1/dashboard/spectroclusters/${cluster.metadata.uid}/workloads/daemonset`,
      {
        filter: {
          namespaces: filterBy,
        },
      }
    );

    return {
      items: (data?.daemonSets || []).sort((daemon1, daemon2) =>
        daemon1.metadata.namespace > daemon2.metadata.namespace ? 1 : -1
      ),
    };
  },
  initialQuery: () => ({
    filterBy: [],
  }),
  schema: [DaemonSetSchema],
});
