import { getClusterCloudConfig } from "state/cluster/selectors/nodes";
import { getClusterByUid } from "./details";
import { pollClusterEvents } from "utils/tasks";
import { eventListActions } from "../services";

export function fetchClusterAndPollEvents(uid) {
  return async (dispatch, getState) => {
    const state = getState();
    const clusterCloudConfig = getClusterCloudConfig(state);
    if (!clusterCloudConfig) {
      await dispatch(getClusterByUid(uid));
    }

    dispatch(eventListActions.initialize("clusterevents"));

    pollClusterEvents.start();
  };
}
