import createFormActions from "modules/form/actions";
import moment from "moment";
import {
  projectCPUUtilizationFetcher,
  projectsMonthlyCoreHoursFetcher,
} from "state/project/services/metrics";

import {
  ORGANIZATION_OVERVIEW_FILTERS_MODULE,
  projectsConsumptionFetcher,
  projectsCostFetcher,
  tenantLevelClustersConsumptionFetcher,
  tenantLevelClustersCostFetcher,
} from "./services";

export function onOrganizationChartsCalendarChange(date = moment()) {
  return async (dispatch) => {
    const isFirstDayOfMonth = moment().date() === 1;
    const startOfMonth = moment(date).startOf("month");
    const endOfPrevMonth = moment(date).subtract(1, "months").endOf("month");
    const endOfMonth = moment(date).endOf("month");

    const query = {
      startTime: isFirstDayOfMonth ? endOfPrevMonth : startOfMonth,
      endTime: endOfMonth,
    };

    const filter = {
      value: moment(date).daysInMonth(),
      unit: "days",
    };

    dispatch(
      organizationFilterFormActions.batchChange({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        updates: {
          query,
          filter,
        },
      })
    );

    dispatch(projectsCostFetcher.fetch(query));
    dispatch(tenantLevelClustersCostFetcher.fetch(query));
    dispatch(projectsConsumptionFetcher.fetch(query));
    dispatch(tenantLevelClustersConsumptionFetcher.fetch(query));
    dispatch(projectsMonthlyCoreHoursFetcher.fetch(query));
    dispatch(projectCPUUtilizationFetcher.fetch(query));
  };
}

export const organizationFilterFormActions = createFormActions({
  init: () => {
    return Promise.resolve({
      query: {},
      filter: {},
      filterBy: [],
      consumptionType: "cpu",
      groupBy: "project",
      enableTenantScopedCluster: true,
    });
  },
  submit: () => {},
});

export function onProjectSelect(value) {
  return (dispatch) => {
    dispatch(
      organizationFilterFormActions.onChange({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        name: "filterBy",
        value,
      })
    );
  };
}

export function onConsumptionTypeSelect(value) {
  return (dispatch) => {
    dispatch(
      organizationFilterFormActions.onChange({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        name: "consumptionType",
        value,
      })
    );
  };
}

export function onCostGroupBySelect(value) {
  return (dispatch, getState) => {
    dispatch(
      organizationFilterFormActions.onChange({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        name: "groupBy",
        value,
      })
    );

    const query =
      getState().forms[ORGANIZATION_OVERVIEW_FILTERS_MODULE].data.query;

    dispatch(projectsCostFetcher.fetch(query));
    dispatch(tenantLevelClustersCostFetcher.fetch(query));
    dispatch(projectsConsumptionFetcher.fetch(query));
    dispatch(tenantLevelClustersConsumptionFetcher.fetch(query));
    dispatch(
      organizationFilterFormActions.onChange({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        name: "filterBy",
        value: [],
      })
    );
  };
}
