function sortByPriority(obj1, obj2) {
  if (obj1.priority > obj2.priority) {
    return 1;
  }
  if (obj1.priority < obj2.priority) {
    return -1;
  }

  return 0;
}

export const getFieldErrors = (state) => {
  const errors = state.errors || [];

  const byField = errors.reduce((accumulator, error) => {
    const existingErrors = accumulator[error.field];
    if (existingErrors) {
      accumulator[error.field].push(error);
      return accumulator;
    }

    accumulator[error.field] = [error];
    return accumulator;
  }, {});

  Object.keys(byField).forEach((key) => {
    byField[key] = byField[key].sort(sortByPriority);
  });

  return byField;
};
