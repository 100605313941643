export const READONLY_PACK_DISPLAY_NAME_SCHEMA = {
  // eslint-disable-next-line no-template-curly-in-string
  format: "${string}",
  hints: ["Display name cannot be updated here"],
  listOptions: [],
  name: "pack.spectrocloud\\.com/display-name",
  readonly: true,
  type: "string",
};

export const READONLY_K8S_SERVICE_DOMAIN_FIELD = {
  // eslint-disable-next-line no-template-curly-in-string
  format: "${string}",
  hints: ["DNS service domain cannot be updated after being set"],
  name: "pack.serviceDomain",
  readonly: true,
  type: "string",
};

export const UBUNTU_ADVANTAGE_SCHEMAS = [
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${password}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.token",
    readonly: false,
    regex: "^.+$",
    required: true,
    type: "string",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.esm-infra.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.esm-apps.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.fips.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.fips-updates.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.livepatch.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.cis.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.cc-eal.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
  {
    // eslint-disable-next-line no-template-curly-in-string
    format: "${boolean}",
    hints: [],
    listOptions: [],
    name: "ubuntu-advantage.services.usg.enabled",
    readonly: false,
    required: true,
    type: "boolean",
  },
];
