export const initialState = {
  isLoadingNameValidation: false,
};

export function DEV_VIRTUAL_CLUSTER_NAME_VALIDATION_REQUEST(nextState) {
  nextState.isLoadingNameValidation = true;
}

export function DEV_VIRTUAL_CLUSTER_NAME_VALIDATION_SUCCESS(nextState) {
  nextState.isLoadingNameValidation = false;
}

export function DEV_VIRTUAL_CLUSTER_NAME_VALIDATION_FAILURE(nextState) {
  nextState.isLoadingNameValidation = false;
}
