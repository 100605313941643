import React from "react";
import { Alert } from "antd";
import styled from "styled-components";

const StyledAlert = styled(Alert)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    padding-right: 50px;
  }
`;

export default function MobileAlert() {
  return (
    <StyledAlert
      showIcon
      type="warning"
      description={
        "Palette is not supported on mobile. Please sign in on your laptop or desktop"
      }
    />
  );
}
