import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import EditorLayout, {
  EditorSlot,
  FooterSlot,
  SidebarSlot,
} from "components/common/layouts/Editor";
import Modal from "components/ui/Modal";
import DocLink from "components/common/DocLink";
import Icon from "components/ui/Icon";

import ProfilesList from "./ProfilesList";
import Button, { TextButton } from "components/ui/Button";
import { yellow } from "utils/constants/colors";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import EditorSplitTitle, {
  PackTitle,
} from "components/common/ClusterProfiles/EditorSplitTitle";
import Alert from "components/common/Alert";
import Editor from "components/common/Editor";
import { connectField, createForm } from "modules/form";

const WarningIcon = styled(Icon)`
  color: ${yellow};
  font-size: 16px;
  margin-right: 6px;
`;

const MainWrap = styled.div`
  margin: -24px;
`;

const TitleBanner = styled.div`
  width: 100%;
`;

const ActionsWrap = styled.section`
  display: flex;
  gap: 8px;
`;

const FooterWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  border-top: 1px solid #ddd;
`;

function getLayerBannerInfo(layer) {
  return {
    displayName:
      layer?.pack?.spec?.displayName || layer?.pack?.spec?.name || layer?.name,
    tag: layer?.spec?.version || layer?.version || "",
    logoUrl: layer?.spec?.logoUrl || layer?.pack?.spec?.logoUrl,
    addonType:
      layer?.kind ||
      layer?.type ||
      layer?.pack?.spec?.addonType ||
      layer?.pack?.spec?.type,
  };
}

const ConnectedEditor = connectField(Editor);

function ClusterPackDiffsModal({
  profilesDiffs,
  onValuesChange,
  selectedLayerGuid,
  selectedProfileGuid,
  modalService,
  isSubmitting = false,
  isRepaveRequired = false,
  readOnly = false,
  modalOpened = false,
  canUpdateLater = false,
  cloudType,
  originalValues = {},
  valuesForm,
  ...rest
}) {
  const { t } = useTranslation();
  const [reviewedLayers, setReviewedLayers] = useState([]);
  const confirmLabel = isRepaveRequired ? t("Update") : t("Apply Changes");

  const ValuesForm = useMemo(
    () =>
      createForm({
        actions: valuesForm.actions,
        Component({ children }) {
          return children;
        },
      }),
    [valuesForm.actions]
  );

  useEffect(() => {
    if (!modalOpened) {
      setReviewedLayers([]);
    }
  }, [modalOpened]);

  useEffect(() => {
    if (
      !selectedLayerGuid ||
      reviewedLayers.includes(selectedLayerGuid) ||
      !modalOpened
    ) {
      return;
    }

    setReviewedLayers((prevState) => [...prevState, selectedLayerGuid]);
  }, [selectedLayerGuid, reviewedLayers, modalOpened]);

  const allLayers = useMemo(() => {
    const packs = (profilesDiffs || []).flatMap((profile) => profile.packs);
    const manifests = packs.flatMap((profile) => profile.manifests);

    return [...packs, ...manifests];
  }, [profilesDiffs]);

  const selectedLayer = useMemo(() => {
    return allLayers.find(
      ({ current, target }) =>
        target?.manifest?.guid === selectedLayerGuid ||
        current?.manifest?.guid === selectedLayerGuid ||
        target?.pack?.guid === selectedLayerGuid ||
        current?.pack?.guid === selectedLayerGuid
    );
  }, [selectedLayerGuid, allLayers]);

  const showDiff = !["pack-added", "manifest-added"].includes(
    selectedLayer?.type
  );

  const editorInfo = useMemo(() => {
    const { current, target } = selectedLayer || {};
    const currentInfo = current?.manifest || current?.pack;
    const targetInfo = target?.manifest || target?.pack;

    return {
      to: {
        ...getLayerBannerInfo(targetInfo),
        helperText: t("Pending Configuration"),
        tooltipText: t(
          "This section represents the configuration that will become the new current, applied configuration. You can either edit it directly, or use the editor to keep customizations from the current configuration on the left."
        ),
      },
      from: {
        ...getLayerBannerInfo(currentInfo),
        helperText: t("Current Configuration"),
        tooltipText: t(
          "This section represents your current configuration. It includes any customizations that you have made to the pack default configuration."
        ),
      },
    };
  }, [t, selectedLayer]);

  function renderEditorTitle() {
    return (
      <TitleBanner>
        {showDiff ? (
          <EditorSplitTitle {...editorInfo} />
        ) : (
          <PackTitle {...editorInfo.to} />
        )}
      </TitleBanner>
    );
  }

  return (
    <Modal
      width="1400px"
      title={t("Review Update Changes")}
      service={modalService}
      footer={null}
    >
      <MainWrap>
        <EditorLayout />
        <SidebarSlot.Source>
          <ProfilesList
            profilesDiffs={profilesDiffs}
            selectedLayer={selectedLayerGuid}
            reviewedLayers={reviewedLayers}
            {...rest}
          />
        </SidebarSlot.Source>
        <EditorSlot.Source>
          <ValuesForm preventInitOnMount module={valuesForm.name}>
            <ConnectedEditor
              defaultValues={selectedLayer?.current?.values || ""}
              originalValues={originalValues?.[selectedLayerGuid] || ""}
              language="yaml"
              showDiff={showDiff}
              readOnly={readOnly}
              {...rest}
              title={renderEditorTitle()}
              key={selectedLayerGuid || "none"}
              name={selectedLayerGuid || "none"}
            />
          </ValuesForm>
        </EditorSlot.Source>
        <FooterSlot.Source>
          {isRepaveRequired && (
            <Alert type="warning">
              <WarningIcon awesome={faTriangleExclamation} />
              {cloudType === "edge-native"
                ? t("These pack updates could cause a service disruption.")
                : t(
                    "Repaving might temporarily affect your cluster's performance or configuration."
                  )}{" "}
              <DocLink
                location="full-cluster-repave"
                text={t(
                  "Learn more about repave behavior and configuration changes"
                )}
                fontSize={12}
              />
            </Alert>
          )}
          <FooterWrap>
            <ActionsWrap>
              <TextButton
                data-qa="cancel-action"
                onClick={() => {
                  modalService.reject();
                }}
              >
                {t("Cancel")}
              </TextButton>
              <Button
                data-qa="continue-review"
                loading={isSubmitting}
                onClick={() => modalService.resolve()}
                disabled={reviewedLayers.length !== allLayers.length}
              >
                {confirmLabel}
              </Button>
              {canUpdateLater && (
                <Button
                  data-qa="update-later"
                  loading={isSubmitting}
                  onClick={() => modalService.resolve({ updateLater: true })}
                  disabled={reviewedLayers.length !== allLayers.length}
                >
                  {t("Apply Changes Later")}
                </Button>
              )}
            </ActionsWrap>
          </FooterWrap>
        </FooterSlot.Source>
      </MainWrap>
    </Modal>
  );
}

export default ClusterPackDiffsModal;
