import React from "react";
import styled from "styled-components";
import { Alert as AlertComponent } from "antd";

import { TextButton } from "components/ui/Button";

const StyledAlert = styled(AlertComponent)`
  &.ant-alert-with-description.ant-alert-no-icon {
    padding: 10px;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  > span {
    flex: 1;
  }
`;

export default function Alert({
  children,
  description,
  actionLabel,
  onClick,
  ...rest
}) {
  const renderAlertContent = () => {
    return (
      <Description>
        <span>{description}</span>
        {onClick && actionLabel && (
          <TextButton onClick={onClick}>{actionLabel}</TextButton>
        )}
      </Description>
    );
  };

  return (
    <StyledAlert {...rest} description={children || renderAlertContent()} />
  );
}
