import React, { useContext } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import createList, { ListContext } from "modules/list";
import ListActions from "modules/list/actions";
import { createSelectComponent } from "modules/dataFetcher";

import Loader from "components/ui/Loader";
import EmptyResults from "components/ui/EmptyResults";

const LoaderWrap = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
`;

export default function createAutocompleteComponent({
  Component,
  dataFetcher,
  parseOptions = (result) => result,
  schema,
  listActionsModule,
  identifier,
  ...rest
}) {
  const SelectComponent = createSelectComponent({
    Component,
    dataFetcher,
    parseOptions,
  });

  const listActions =
    listActionsModule ||
    new ListActions({ ...rest, schema, dataFetcher, identifier });
  const ListModule = createList({
    actions: listActions,
    schema,
  });

  function onSearch(module, searchTerm) {
    return function thunk(dispatch) {
      dispatch(
        listActions.changeQuery({ name: "search", value: searchTerm, module })
      );
    };
  }

  function NotFoundContent() {
    const listContext = useContext(ListContext);

    return listContext.isLoading ? (
      <LoaderWrap>
        <Loader small />
      </LoaderWrap>
    ) : (
      <EmptyResults />
    );
  }

  function SelectAutocomplete({ onSearch, ...rest }) {
    return (
      <SelectComponent
        filterOption={false}
        notFoundContent={<NotFoundContent />}
        onSearch={onSearch}
        {...rest}
      />
    );
  }

  function AsyncListModule({ module, preventInitOnMount, ...rest }) {
    return (
      <ListModule module={module} preventInitOnMount={preventInitOnMount}>
        <SelectAutocomplete {...rest} />
      </ListModule>
    );
  }

  return connect(null, (dispatch, ownProps) => ({
    onSearch: (searchTerm) => dispatch(onSearch(ownProps.module, searchTerm)),
  }))(AsyncListModule);
}
