import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker&type=module";
import yamlWorker from "monaco-yaml/yaml.worker?worker&type=module";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker&type=module";
// eslint-disable-next-line no-restricted-globals
self.MonacoEnvironment = self.MonacoEnvironment || {
  getWorker: function (moduleId, label) {
    if (label === "yaml") {
      return yamlWorker();
    }
    if (label === "json") {
      return jsonWorker();
    }
    return editorWorker();
  },
};
