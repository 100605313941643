import i18next from "i18next";
import { generatePath } from "react-router";

import createFormActions from "modules/form/actions";

import Validator from "services/validator";
import store from "services/store";
import {
  Missing,
  areValidKubernetesTags,
  SemanticVersion,
} from "services/validator/rules";
import historyService from "services/history";
import notifications from "services/notifications";
import api from "services/api/index";

import {
  getRawClusterProfile,
  isClusterProfileUsed,
} from "state/clusterprofile/selectors/details";
import { CLUSTER_PROFILES } from "utils/constants/routes";

import { getFormattedPayload } from "state/profiles/system/selectors/create";
import {
  fetchSystemProfilePacks,
  systemProfileBuilderCreateModule,
  systemProfileBuilderEditModule,
} from "state/profiles/system/services/create";
import { getSelectedClusterProfileTemplate } from "state/clusterprofile/selectors/layerConfig";
import { fetchResolvedValues } from "state/clusterprofile/actions/details";
import { FORM_TYPES } from "modules/profileBuilder/services";
import { extractInstallOrder, extractPackDisplayName } from "utils/yaml";

//

export const validator = new Validator();

validator.addRule(["name"], Missing());
validator.addRule(["tags"], areValidKubernetesTags());
validator.addRule(["version"], SemanticVersion());

export const appProfileFormActions = createFormActions({
  validator,
  async init() {
    const { location } = store.getState().router;

    if (location.pathname.includes("system/create")) {
      systemProfileBuilderCreateModule.actions.initialize({ layers: [] });
      return Promise.resolve({
        name: "",
        description: "",
        tags: [],
        layers: [],
        persisted: false,
      });
    }

    const clusterprofile = getRawClusterProfile(store.getState());

    if (clusterprofile) {
      const { metadata } = clusterprofile;

      await store.dispatch(fetchSystemProfilePacks(metadata.uid));

      const clusterProfileTemplate = getSelectedClusterProfileTemplate(
        store.getState()
      );

      const layers = clusterProfileTemplate.packs.map((packVersion) => {
        const { pack } = packVersion;
        let type = pack.spec.addonType || pack.spec.layer;
        let formType = FORM_TYPES.PACKS;

        if (pack.spec.type === "manifest") {
          type = "manifest";
          formType = FORM_TYPES.SYSTEM;
        }

        if (["oci", "helm"].includes(pack.spec.type)) {
          type = "helmChart";
        }

        const manifestMap = pack?.spec?.manifests
          ? pack.spec.manifests
          : pack?.pack?.manifests;

        const layer = {
          type,
          guid: pack.metadata.name,
          initialPackName: pack.metadata.name,
          persisted: true,
          formType,
          config: {
            packUid: pack.metadata.uid,
            uid: pack.metadata.uid,
            packVersionGuid: packVersion.guid,
            name: pack.metadata.name,
            tag: packVersion.tag,
            registryUid: pack.spec.registryUid,
            values: packVersion.values,
            logo: pack.spec.logoUrl,
            presets: pack.spec.presets,
            schema: pack.spec.schema,
            isOci: pack.spec.type === "oci",
            manifests:
              manifestMap?.map(({ content, ...rest }) => ({
                ...rest,
              })) || [],
          },
        };
        layer.config.installOrder = extractInstallOrder(layer.config);
        layer.config.displayName = extractPackDisplayName(layer.config);
        return layer;
      });

      systemProfileBuilderEditModule.actions.initialize({
        layers,
        isEditMode: true,
        isClusterProfileUsed: isClusterProfileUsed(store.getState()),
      });
      try {
        const response = await store.dispatch(
          fetchResolvedValues(metadata.uid)
        );
        systemProfileBuilderEditModule.actions.updateResolvedValues(
          response?.resolved
        );
      } catch (err) {}

      return Promise.resolve({
        persisted: true,
        name: metadata.name,
        description: metadata.annotations?.description || "",
        tags: (metadata?.labels && Object.keys(metadata?.labels)) || [],
        layers,
      });
    }

    return {
      name: "",
      version: "",
      description: "",
      tags: [],
      layers: [],
      persisted: false,
    };
  },
  async submit(formData) {
    const payload = getFormattedPayload(store.getState());
    let promise;
    const { metadata } = getRawClusterProfile(store.getState()) || {};
    if (formData.persisted) {
      payload.metadata.uid = metadata.uid;
      payload.metadata.labels = metadata.labels;
      promise = api.put(`v1/clusterprofiles/${metadata.uid}`, payload);
    } else {
      promise = api.post("v1/clusterprofiles", payload);
    }

    try {
      const response = await promise;
      const publishPromise = api.patch(
        `v1/clusterprofiles/${response.uid || metadata.uid}/publish`
      );

      await publishPromise;

      if (!formData.persisted) {
        historyService.push(
          generatePath(CLUSTER_PROFILES.ROOT, {
            tab: "system",
          })
        );
      }

      notifications.withAudit({
        message: i18next.t(
          "System profile {{profileName}} was saved and published",
          { profileName: formData.name }
        ),
        ...(formData.persisted && {
          promise: publishPromise,
        }),
      });
    } catch (error) {
      const message = formData.persisted
        ? i18next.t("Something went wrong when editing the system profile")
        : i18next.t("Something went wrong when creating the system profile");

      notifications.error({
        message,
        description: error.message,
      });
    }

    return promise;
  },
});
