import React from "react";
import styled from "styled-components";

const WidgetWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`;

const IconWrap = styled.img`
  height: 40px;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0025em;
  margin: 0;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #3e4856;
`;

export const InfoWidgetTemplate = ({ icon, heading, description }) => {
  return (
    <WidgetWrap>
      <IconWrap src={icon} alt={"missing icon"} />
      <div>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </div>
    </WidgetWrap>
  );
};
