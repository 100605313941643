export default function fileListReducer(initialState = {}) {
  return {
    name: "fileList",
    initialState: {
      files: [], // rootFileIds
      deletedFiles: [],
      deletedChildren: [],
      entities: {},
      childrenMap: {},
      activeFile: null,
      ...initialState,
    },
    onFileSelect(nextState, { fileGuid }) {
      nextState.activeFile = fileGuid;
    },
    addRootFile(nextState, { file }, state) {
      nextState.files = [file.guid, ...state.files];
      nextState.entities[file.guid] = file;
      nextState.childrenMap[file.guid] = [];
    },
    addChild(nextState, { parentGuid, file, index }, state) {
      let childrenMap = [...(state.childrenMap[parentGuid] || [])];
      const position =
        typeof index !== "undefined" ? index : childrenMap.length;

      childrenMap.splice(position, 0, file.guid);

      nextState.entities[file.guid] = file;
      nextState.deletedChildren = state.deletedChildren.filter(
        (pair) => pair.guid !== file.guid || pair.parentGuid !== parentGuid
      );
      nextState.childrenMap[parentGuid] = childrenMap;
    },
    reorderFiles(nextState, { reorderedFiles }) {
      nextState.files = reorderedFiles;
    },
    removeFile(nextState, { fileGuid }, state) {
      // if root file
      if (state.files.includes(fileGuid)) {
        nextState.files = state.files.filter((file) => file !== fileGuid);
        nextState.deletedFiles = [...state.deletedFiles, fileGuid];
        return;
      }

      // if childFile
      const deletedFileParentGuid = Object.keys(state.childrenMap).find(
        (parentGuid) => state.childrenMap[parentGuid].includes(fileGuid)
      );

      nextState.childrenMap[deletedFileParentGuid] = state.childrenMap[
        deletedFileParentGuid
      ].filter((childGuid) => childGuid !== fileGuid);
      nextState.deletedChildren = [
        ...state.deletedChildren,
        { guid: fileGuid, parentGuid: deletedFileParentGuid },
      ];
    },
    updateEntity(nextState, { fileGuid, overwrites }, state) {
      nextState.entities[fileGuid] = {
        ...state.entities[fileGuid],
        ...overwrites,
      };
    },
  };
}
