import React from "react";
import { connect } from "react-redux";
import { Progress, Tooltip } from "antd";
import styled from "styled-components";

import { getDevUsedQuota } from "state/auth/selectors";
import { useMenu } from "components/common/providers/Menu";
import { useTranslation } from "react-i18next";

const QuotasWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.expanded ? "5px 12px 0 12px" : "0 4px")};

  .ant-progress {
    line-height: 0;
  }

  > div {
    margin-bottom: 5px;
  }
`;

const UsageText = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.1;
`;

function UsageQuotaBar({ percent, usage, unit, expanded, tooltipTitle }) {
  const quotaBar = (
    <div>
      <UsageText>
        {usage.used}/{usage.allocated} {unit}
      </UsageText>
      <Progress
        percent={percent}
        showInfo={false}
        size="small"
        strokeColor={"rgba(255, 255, 255, 0.3)"}
        trailColor={"rgba(255, 255, 255, 0.15)"}
      />
    </div>
  );

  if (!expanded) {
    return (
      <Tooltip title={tooltipTitle} placement="right">
        {quotaBar}
      </Tooltip>
    );
  }

  return quotaBar;
}

function QuotaUtilization({ quota }) {
  const { expanded } = useMenu();
  const { t } = useTranslation();

  if (!quota) {
    return null;
  }

  return (
    <QuotasWrapper expanded={expanded}>
      <UsageQuotaBar
        expanded={expanded}
        percent={quota.nesterClusters?.percent}
        usage={quota.nesterClusters}
        unit={expanded ? t("Virtual clusters") : t("Clusters")}
        tooltipTitle={t("Virtual clusters")}
      />
      <UsageQuotaBar
        expanded={expanded}
        percent={quota.cpu?.percent}
        usage={quota.cpu}
        unit={"CPU"}
        tooltipTitle={t("CPU")}
      />
      <UsageQuotaBar
        expanded={expanded}
        percent={quota.memory?.percent}
        usage={quota.memory}
        unit={expanded ? t("GiB of memory") : "GiB"}
        tooltipTitle={t("Memory")}
      />
      <UsageQuotaBar
        expanded={expanded}
        percent={quota.storage?.percent}
        usage={quota.storage}
        unit={expanded ? t("GiB of storage") : "GiB"}
        tooltipTitle={t("Storage")}
      />
    </QuotasWrapper>
  );
}

export default connect((state) => ({
  quota: getDevUsedQuota(state),
}))(QuotaUtilization);
