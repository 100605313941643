import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { APP_PROFILES } from "utils/constants/routes";

const AppProfilesIndex = React.lazy(() => import("./index"));
const CreateAppProfile = React.lazy(() => import("./create/index"));
const AppProfileDetails = React.lazy(() => import("./details"));

export default createNamespace({
  namespace: "appProfiles",
  Component: function Main() {
    return (
      <>
        <Route
          exact
          devOnly
          path={APP_PROFILES.ROOT}
          component={AppProfilesIndex}
        />
        <Route
          devOnly
          exact
          path={APP_PROFILES.CREATE}
          component={CreateAppProfile}
        />
        <Route
          exact
          devOnly
          path={APP_PROFILES.DETAILS}
          component={({ match, ...rest }) => {
            return match?.params?.uid === "create" ? null : (
              <AppProfileDetails {...rest} />
            );
          }}
        />
      </>
    );
  },
});
