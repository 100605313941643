import { createSelector } from "reselect";
import moment from "moment";

import {
  publishableKeyFetcher,
  paymentCardsFetcher,
  invoicesFetcher,
  invoiceFetcher,
} from "state/billingdetails/services";
import { getEntity } from "utils/entities";
import { InvoiceSchema } from "utils/schemas";
import { subscriptionFetcher } from "state/plandetails/services";

export const getPublishableKey = createSelector(
  publishableKeyFetcher.selector,
  ({ result }) => {
    return result?.publishKey;
  }
);

export const getPaymentCards = createSelector(
  paymentCardsFetcher.selector,
  ({ result }) => {
    return result?.paymentMethods?.map((data) => ({
      value: data.paymentMethodId,
      fullname:
        data.firstName && data.lastName && `${data.firstName} ${data.lastName}`,
      ...data.card,
    }));
  }
);

export const getDefaultPaymentMethod = createSelector(
  paymentCardsFetcher.selector,
  ({ result }) => {
    return result?.defaultPaymentMethod;
  }
);

export const getInvoicesTableData = createSelector(
  invoicesFetcher.selector,
  ({ result }) => {
    return (
      result?.map((invoice) => {
        const states = invoice.status?.states || [];
        // The current invoice state is the most recent one
        const maxTimestamp = moment.max(
          ...states.map((state) => moment(state.timestamp))
        );
        return {
          ...invoice,
          paymentState:
            states.find((state) =>
              moment(state.timestamp).isSame(maxTimestamp)
            ) || {},
        };
      }) || []
    );
  }
);

export const getInvoice = getEntity(invoiceFetcher.selector, InvoiceSchema);

export const getPaymentFailedInvoice = createSelector(
  getInvoicesTableData,
  (invoices) => {
    return invoices?.find(
      (invoice) => invoice.status?.state === "PaymentFailed"
    );
  }
);

export const shouldRetryPayment = createSelector(
  subscriptionFetcher.selector,
  getPaymentFailedInvoice,
  ({ result: subscription }, failedPaymentInvoice) => {
    const paymentIntentStatus = subscription?.paymentIntent?.status;
    const subscriptionState = subscription?.subscriptionState;

    return (
      subscriptionState === "Incomplete" &&
      paymentIntentStatus === "requires_payment_method" &&
      !!failedPaymentInvoice
    );
  }
);

export const shouldHandle3dSecure = createSelector(
  subscriptionFetcher.selector,
  ({ result: subscription }) => {
    return (
      subscription?.subscriptionState === "Incomplete" &&
      subscription?.paymentIntent?.status === "requires_action"
    );
  }
);
