export const initialState = {
  isValid: false,
  isValidating: false,
};

export function VALIDATE_AUDIT_TRAIL_REQUEST(nextState) {
  nextState.isValidating = true;
}

export function VALIDATE_AUDIT_TRAIL_SUCCESS(nextState) {
  nextState.isValid = true;
  nextState.isValidating = false;
}

export function VALIDATE_AUDIT_TRAIL_FAILURE(nextState) {
  nextState.isValid = false;
  nextState.isValidating = false;
}

export function OPEN_MODAL(nextState, { name }) {
  if (name !== "auditTrailsModal") {
    return;
  }
  nextState.isValid = false;
  nextState.isValidating = false;
}

export function FORM_LOAD_SUCCESS(nextState, { module, result }) {
  if (module !== "auditTrails") {
    return;
  }
  nextState.isValid = result.isValid;
}
