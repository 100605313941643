import { generatePath } from "react-router";
import { CLUSTER_GROUPS, CLUSTERS } from "utils/constants/routes";
import {
  ADMIN_PREFIX_WITH_PROJECT,
  ADMIN_PREFIX,
} from "components/common/History/Route";
import { getCurrentContext } from "state/auth/selectors/common";
import { formatTimestampToGeneralDateFormat } from "utils/date";
import store from "services/store";

export function downloadFile({
  content = "",
  fileName = "download",
  mimeType = "application/octet",
}) {
  let a = document.createElement("a");

  a.href = URL.createObjectURL(
    new Blob([content], {
      type: mimeType,
    })
  );
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function formatDate(date) {
  if (!date) {
    return "-";
  }

  return formatTimestampToGeneralDateFormat(date);
}

export function convertToCSV(array = []) {
  const csvData = array
    .map((item) => {
      const { spec, metadata } = item;
      const actionType = spec.actionType;
      const dateTime = formatDate(metadata.creationTimestamp);
      const [date, time] = dateTime.split(",");
      const resourceType = spec.resource.kind;
      const update =
        '"' + (spec.contentMsg || spec.actionMsg).replaceAll(/\n/g, "") + '"';
      const user =
        spec.actor.actorType === "user"
          ? spec.actor.user.emailId
          : spec.actor.serviceName;
      return [actionType, date, time, resourceType, update, user].join(",");
    })
    .join("\r\n");

  return `actionType,date,time,resourceType,update,user\r\n${csvData}`;
}

export function generateClusterDetailsPath({
  clusterCategory,
  useAdminPrefix = false,
  ...rest
}) {
  const context = getCurrentContext(store.getState());
  let path =
    clusterCategory === "clustergroups"
      ? CLUSTER_GROUPS.CLUSTER_GROUP_DETAILS
      : CLUSTERS.TAB_DETAILS;

  const hasClusters = ["clusters", "clustergroups"].includes(clusterCategory);

  if (hasClusters && (context?.isAdmin || useAdminPrefix)) {
    if (rest.projectUid) {
      path = `${ADMIN_PREFIX_WITH_PROJECT}${path}`;
    } else {
      path = `${ADMIN_PREFIX}${path}`;
    }
  }

  return generatePath(path, { clusterCategory, ...rest });
}
