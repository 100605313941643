import React from "react";
import { createRoot } from "react-dom/client";
import "antd/dist/antd.css";
import "./index.css";

import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import "leaflet.markercluster/dist/MarkerCluster.css";

import observeHistory from "services/history/observer";
import App from "./App";
import store from "services/store";
import { initApp } from "state/auth/actions";
import history from "services/history";
import { updateFullStoryCookie } from "utils/fullstory";
import { hookHistoryWithContext } from "services/history/context";
import { Globals } from "@react-spring/core";
// if (process.env.NODE_ENV !== "production") {
//   var axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

Globals.assign({
  frameLoop: "always",
});

hookHistoryWithContext();
async function startApplication() {
  updateFullStoryCookie();
  if (
    history.location.pathname.startsWith("/auth") ||
    history.location.pathname.startsWith("/dev/auth")
  ) {
    observeHistory();
    return;
  }
  await store.dispatch(initApp());

  observeHistory();
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
startApplication();
