import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import Modal from "components/ui/Modal";
import Form from "./components/Form";
import Enumeration from "components/common/Enumeration";
import ActionMenu from "components/common/ActionMenu";
import AddNewItem from "components/styled/AddNewItem";

import createFormActions from "modules/form/actions";
import { createForm } from "modules/form";
import createList, { Blocks } from "modules/list";
import { getAllProjects } from "state/auth/selectors";
import {
  getSelectedWorkspaceRoleEntity,
  getSelectedUser,
} from "state/roleManagement/selectors";
import {
  workspaceRolesModal,
  onWorkspaceRoleRemove,
} from "state/roleManagement/actions/workspaceRoles";
import { projectWorkspacesFetcher } from "state/roleManagement/services";

import { WorkspaceRolesSchema } from "utils/schemas";
import { getFullName } from "utils/presenters";
import { red } from "utils/constants/colors";
import notifications from "services/notifications";
import i18next from "i18next";

export default function createWorkspaceRoleManagement({
  listActions,
  updateData,
}) {
  const moduleName = "workspaceRoles";
  const workspaceRolesValidator = new Validator();
  workspaceRolesValidator.addRule(
    ["projectUid", "workspaces", "roles"],
    Missing()
  );

  const formActions = createFormActions({
    validator: workspaceRolesValidator,
    init: async () => {
      const selectedWorkspaceRole = getSelectedWorkspaceRoleEntity(
        store.getState()
      );
      return Promise.resolve({
        projectUid: selectedWorkspaceRole?.project.metadata.uid || null,
        workspaces:
          selectedWorkspaceRole?.workspaces.map(
            (workspace) => workspace.metadata.uid
          ) || [],
        roles: selectedWorkspaceRole?.roles.map((role) => role.guid) || [],
      });
    },
    submit: async (data) => {
      try {
        await updateData(data);
      } catch (err) {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: err?.message,
        });

        return Promise.reject();
      }
      store.dispatch(listActions.initialize(moduleName));
    },
  });

  function onProjectChange(value) {
    return function thunk(dispatch) {
      dispatch(
        formActions.onChange({ module: moduleName, value, name: "projectUid" })
      );
      dispatch(projectWorkspacesFetcher.fetch());
    };
  }

  const ConnectedForm = connect(
    (state) => {
      const projects = getAllProjects(state);
      const selectedWorkspaceRoleId =
        state.modal.data[workspaceRolesModal.guid]?.selectedWorkspaceRoleId;

      const parsedProjects = projects.map((project) => ({
        value: project.metadata.uid,
        label: project.metadata.name,
      }));

      return {
        projects: parsedProjects,
        selectedWorkspaceRoleId,
      };
    },
    { onProjectChange }
  )(Form);

  const ProjectRolesForm = createForm({
    Component: ConnectedForm,
    actions: formActions,
  });

  function openWorkspaceRolesModal(selectedWorkspaceRoleId) {
    return async (dispatch) => {
      workspaceRolesModal.open({ selectedWorkspaceRoleId }).then(() => {
        return dispatch(formActions.submit({ module: moduleName }));
      });

      await dispatch(formActions.init({ module: moduleName }));
      if (selectedWorkspaceRoleId) {
        dispatch(projectWorkspacesFetcher.fetch());
      }
    };
  }

  const ListModule = createList({
    actions: listActions,
    schema: [WorkspaceRolesSchema],
  });

  function TableRowActions({ selectedEntity, onEdit, onRemove }) {
    const { t } = useTranslation();
    const { guid, roles } = selectedEntity;
    const canDelete = roles.some((role) => !role.metadata.inherited);

    let options = [
      {
        key: "edit",
        label: t("Edit"),
        onClick: onEdit(guid),
      },
    ];

    if (canDelete) {
      options = [
        ...options,
        {
          key: "remove",
          label: t("Remove"),
          color: red,
          onClick: onRemove(guid),
        },
      ];
    }

    return <ActionMenu options={options} />;
  }

  const ConnectedTableRowActions = connect(null, (dispatch) => ({
    onEdit: (guid) => () => dispatch(openWorkspaceRolesModal(guid)),
    onRemove: (guid) => () => dispatch(onWorkspaceRoleRemove(guid)),
  }))(TableRowActions);

  function WorkspaceRolesModule({ openModal, selectedEntity }) {
    const { t } = useTranslation();

    const columns = [
      {
        title: t("Workspace"),
        key: ["workspace"],
        render: function renderWorkspace({ workspaces }) {
          return <span>{workspaces?.[0].metadata.name}</span>;
        },
      },
      {
        title: t("Project"),
        key: ["project"],
        render: function renderProject({ project }) {
          return <span>{project.metadata.name}</span>;
        },
      },
      {
        title: t("Role"),
        key: "role",
        render: function renderRoles({ roles }) {
          const rolesNames = roles.map(
            (role) =>
              `${role.metadata.name} ${
                role.metadata?.inherited ? `(${t("team inherited")})` : ""
              }`
          );
          return <Enumeration items={rolesNames} />;
        },
      },
      {
        title: "",
        render: function renderTableRowActions(data) {
          return <ConnectedTableRowActions selectedEntity={data} />;
        },
        key: "actions",
      },
    ];

    return (
      <>
        <ListModule module={moduleName}>
          <Blocks.Table columns={columns} />
        </ListModule>
        <AddNewItem onClick={() => openModal(null)} data-qa="add_project_role">
          {t("New Workspace Role")}
        </AddNewItem>
        <Modal
          service={workspaceRolesModal}
          title={t("Add Roles to {{name}}", {
            name: selectedEntity?.metadata.name || getFullName(selectedEntity),
          })}
        >
          <ProjectRolesForm module={moduleName} preventInitOnMount />
        </Modal>
      </>
    );
  }

  return connect(
    (state) => ({
      selectedEntity: getSelectedUser(state),
    }),
    {
      openModal: openWorkspaceRolesModal,
    }
  )(WorkspaceRolesModule);
}
