import React from "react";
import Loader from "components/ui/Loader";

const ClusterProfilePreview = React.lazy(() =>
  import("./ClusterProfilePreview")
);

function ClusterProfilePreviewLazy({ ...rest }) {
  return (
    <React.Suspense fallback={<Loader />}>
      <ClusterProfilePreview {...rest} />
    </React.Suspense>
  );
}

export default ClusterProfilePreviewLazy;
