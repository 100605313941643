export const comingSoonCards = {
  security: [],
  database: [
    {
      label: "YugabyteDB",
      type: "operator-instance",
      subType: "yugabyte-operator",
      disabled: true,
      layer: {},
    },
  ],
  storage: [
    {
      label: "Amazon S3",
      type: "operator-instance",
      subType: "amazon-operator",
      disabled: true,
      layer: {},
    },
  ],
};
