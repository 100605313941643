import {
  ReadOnlyProvider,
  SyncFormChanges,
  createForm,
  useFormContext,
} from "modules/form";
import React, { useContext, useMemo, useRef } from "react";
import Fields from "components/ui/Fields";
import { useTranslation } from "react-i18next";
import Validator from "services/validator";
import {
  ApplyIf,
  Missing,
  SemanticVersion,
  isValidIP,
  isValidIPRange,
} from "services/validator/rules";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { ProfileBuilderContext } from "modules/profileBuilder/services";
import createFormActions from "modules/form/actions";
import styled from "styled-components";
import store from "services/store";

export const variablePreviewValidator = new Validator();
variablePreviewValidator.addRule(
  "preview",
  ApplyIf((value, key, data) => data.isRequired, Missing())
);
variablePreviewValidator.addRule(
  "preview",
  ApplyIf(
    (value, key, data) => value && data.useRegex,
    (value, key, data) => {
      return !new RegExp(data.regex).test(value)
        ? i18next.t(`Value does not match the regex pattern "${data.regex}"`)
        : false;
    }
  )
);

variablePreviewValidator.addRule(
  "preview",
  ApplyIf(
    (value, key, data) => value && data.format === "number",
    (value, key, data) => {
      return isNaN(value) ? i18next.t("Value is not a number") : false;
    }
  )
);

variablePreviewValidator.addRule(
  "preview",
  ApplyIf(
    (value, key, data) => value && data.format === "version",
    SemanticVersion()
  )
);

variablePreviewValidator.addRule(
  "preview",
  ApplyIf(
    (value, key, data) => value && data.format === "boolean",
    (value, key, data) => {
      return ![true, false].includes(value)
        ? i18next.t("Value is not a boolean")
        : false;
    }
  )
);

variablePreviewValidator.addRule(
  "preview",
  ApplyIf(
    (value, key, data) => value && ["ipv4", "ipv6"].includes(data.format),
    (value, key, data) =>
      isValidIP({ version: data.format.replace("ipv", "") })(value, key, data)
  )
);
variablePreviewValidator.addRule(
  "preview",
  ApplyIf(
    (value, key, data) => value && data.format === "ipv4cidr",
    (value, key, data) =>
      isValidIPRange({
        version: data.format.replace("ipv", "").replace("cidr", ""),
      })(value, key, data)
  )
);

export function getPreviewValue(data) {
  let previewValue = data.defaultValue || "";
  if (!data.useDefaultValue) {
    previewValue = "";
  }
  if (data.format === "boolean" && data.useDefaultValue) {
    previewValue =
      data.defaultValue === "true"
        ? true
        : data.defaultValue === "false"
        ? false
        : null;
  }

  return previewValue;
}

export const previewActions = createFormActions({
  validator: variablePreviewValidator,
  async init() {
    const fieldData = store.getState().forms["profile-variable"].data;
    return {
      ...fieldData,
      preview: getPreviewValue(fieldData),
    };
  },
});

const PreviewWrap = styled.div`
  background-color: #f7f9ff;
  border: 1px solid #dee1ea;
  padding: 8px;
  margin-bottom: 20px;

  > div {
    margin: 0;
  }
`;

export function PreviewVariableField({ data, name }) {
  let PreviewComponent = data.isMasked ? Fields.PasswordField : Fields.Input;

  if (data.isHidden) {
    PreviewComponent = Fields.Empty;
  }

  if (data.format === "boolean") {
    PreviewComponent = Fields.Switch;
  }

  return (
    <ReadOnlyProvider isActive={data.isReadOnly}>
      <PreviewComponent
        name={name}
        data-qa={name}
        label={data.displayName}
        helperText={data.description}
        required={data.isRequired}
      />
    </ReadOnlyProvider>
  );
}

const PreviewForm = createForm({
  actions: previewActions,
  Component({ data }) {
    const { t } = useTranslation();
    return (
      <>
        <Fields.Label label={t("Preview")} required={true} />
        <PreviewWrap>
          <PreviewVariableField name="preview" data={data} />
        </PreviewWrap>
      </>
    );
  },
});

export function VariableForm() {
  const dispatch = useDispatch();
  const module = useContext(ProfileBuilderContext);

  const onChange = useRef((data) => {
    dispatch(
      previewActions.batchChange({
        updates: data,
        module: "profile-variable-preview",
      })
    );

    let previewValue = getPreviewValue(data);
    dispatch(
      previewActions.onChange({
        name: "preview",
        value: previewValue,
        module: "profile-variable-preview",
      })
    );
    dispatch(
      previewActions.validateField({
        name: "preview",
        module: "profile-variable-preview",
      })
    );
  });

  const VariableForm = useMemo(() => {
    if (!module.actions) {
      return () => null;
    }

    const inUse = module.state.isClusterProfileUsed;

    return createForm({
      actions: module.actions.forms.variableFormActions,
      Component: () => {
        const form = useFormContext();
        const { t } = useTranslation();

        return (
          <>
            <ReadOnlyProvider isActive={inUse}>
              <Fields.Input
                addonBefore={"{{.spectro.var."}
                addonAfter={"}}"}
                label={t("Variable")}
                name="name"
                data-qa="name"
                disabled={form.data.isPersisted}
              />
              <Fields.Input
                label={t("Display name")}
                name="displayName"
                description={t(
                  "Name for the profile variable that is visible on cluster creation"
                )}
                data-qa="displayName"
              />
              <Fields.TextArea
                label={t("Description")}
                name="description"
                data-qa="description"
                required={false}
              />
              <Fields.Select
                label={t("Format")}
                name="format"
                data-qa="format"
                options={[
                  { label: t("String"), value: "string" },
                  { label: t("Number"), value: "number" },
                  { label: t("Boolean"), value: "boolean" },
                  {
                    label: t("Version"),
                    value: "version",
                    description: t(
                      "Format follows the Semantic Versioning convention (e.g. 1.0.0)"
                    ),
                  },
                  { label: t("IPv4"), value: "ipv4" },
                  { label: t("IPv4 CIDR"), value: "ipv4cidr" },
                  { label: t("IPv6"), value: "ipv6" },
                ]}
                onChange={(value) => {
                  form.onFieldChange("format", value);
                  if (!["string", "number"].includes(value)) {
                    form.onFieldChange("regex", "");
                    form.onFieldChange("useRegex", false);
                    form.revalidateField("regex");
                  }
                }}
              />

              <Fields.Switch
                label={t("Custom input validation")}
                name="useRegex"
                data-qa="useRegex"
                required={false}
                description={t(
                  "If enabled this variable's value must match with a regex pattern"
                )}
                disabled={!["string", "number"].includes(form.data.format)}
                onChange={(value) => {
                  form.onFieldChange("useRegex", value);
                  form.revalidateField("regex");
                }}
              />
              {form.data.useRegex ? (
                <Fields.Input name="regex" data-qa="regex" />
              ) : null}
              <Fields.Switch
                label={t("Required")}
                name="isRequired"
                required={false}
                data-qa="isRequired"
              />
              <Fields.Switch
                label={t("Default value")}
                name="useDefaultValue"
                data-qa="useDefaultValue"
                required={false}
                description={t("Predefine a value for this variable")}
                onChange={(value) => {
                  form.onFieldChange("useDefaultValue", value);
                  form.revalidateField("defaultValue");
                }}
              />
              {form.data.useDefaultValue ? (
                <Fields.Input name="defaultValue" data-qa="defaultValue" />
              ) : null}
              <Fields.Switch
                label={t("Mask value")}
                name="isMasked"
                data-qa="isMasked"
                required={false}
                description={t(
                  "Perfect for passwords and other sensitive data"
                )}
              />
              <Fields.Switch
                label={t("Hidden")}
                name="isHidden"
                required={false}
                data-qa="isHidden"
                description={t(
                  "Users will not see this variable when creating a cluster with this profile"
                )}
                onChange={(value) => {
                  form.onFieldChange("isHidden", value);
                  if (
                    !form.data.isPersisted &&
                    !form.data.isReadOnlyUpdatedByUser
                  ) {
                    form.onFieldChange("isReadOnly", value);
                  }
                  form.revalidateField("useDefaultValue");
                }}
              />
              <Fields.Switch
                label={t("Read-only")}
                name="isReadOnly"
                required={false}
                data-qa="isReadOnly"
                description={t(
                  "If enabled this variable's value will not be editable when creating a cluster"
                )}
                onChange={(value) => {
                  form.onFieldChange("isReadOnly", value);
                  form.onFieldChange("isReadOnlyUpdatedByUser", true);
                  form.revalidateField("useDefaultValue");
                }}
              />
            </ReadOnlyProvider>
            <SyncFormChanges onChange={onChange.current} />
          </>
        );
      },
    });
  }, [module.actions, module.state.isClusterProfileUsed]);

  return (
    <>
      <PreviewForm module="profile-variable-preview" preventInitOnMount />
      <VariableForm module="profile-variable" preventInitOnMount />
    </>
  );
}
