import React, { useContext } from "react";
import { v4 as uuid } from "uuid";
import { ThemeContext, ThemeProvider } from "styled-components";

function getNestedValue(obj, key) {
  const keys = key.split(".");
  if (keys.length > 1) {
    return keys.reduce((accumulator, key) => {
      return accumulator?.[key];
    }, obj);
  }

  return obj?.[key];
}

export function getThemeValue(defaultTheme, key) {
  const themeKey = defaultTheme.guid;
  return (props) => {
    const defaultThemeValue = getNestedValue(defaultTheme.get()[themeKey], key);
    const actualThemeValue = getNestedValue(props.theme[themeKey], key);
    return actualThemeValue || defaultThemeValue;
  };
}

class Theme {
  static isTheme(theme) {
    return theme instanceof Theme;
  }
  constructor({ guid, ...definitions }) {
    if (!guid) {
      this.guid = uuid();
    } else {
      this.guid = guid;
    }
    this.definitions = definitions;
  }

  extend(theme) {
    return new Theme({ ...this.definitions, ...theme, guid: this.guid });
  }

  get() {
    return {
      [this.guid]: this.definitions,
    };
  }
}

export function createTheme(theme) {
  return new Theme(theme);
}

export function OverwriteTheme({ theme, children }) {
  const themeContext = useContext(ThemeContext);

  if (!Theme.isTheme(theme)) {
    return <ThemeProvider theme={themeContext}>{children}</ThemeProvider>;
  }

  return (
    <ThemeProvider theme={{ ...themeContext, ...theme.get() }}>
      {children}
    </ThemeProvider>
  );
}
