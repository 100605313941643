import i18next from "i18next";
import store from "services/store";

import ModalService from "services/modal";
import api from "services/api";
import notifications from "services/notifications";

import {
  getSelectedProjectRoles,
  getSelectedUser,
  getCurrentProjectRoles,
  getSelectedTeam,
} from "../selectors";
import ListActions from "modules/list/actions";
import { ProjectRolesSchema } from "utils/schemas";

export const projectRolesListActions = new ListActions({
  fetchData,
  schema: [ProjectRolesSchema],
});

export const projectRolesModal = new ModalService();

export function fetchData() {
  const state = store.getState();
  const currentEntityType = state.roleManagement.entityType;
  const selectedEntity =
    currentEntityType === "user"
      ? getSelectedUser(state)
      : getSelectedTeam(state);

  return api
    .get(`v1/${currentEntityType}s/${selectedEntity.metadata.uid}/projects`)
    .then((res) => {
      return {
        items: res.projects.map((projectRole) => ({
          project: {
            metadata: {
              name: projectRole.name,
              uid: projectRole.uid,
            },
          },
          roles: projectRole.roles.map((role) => ({
            metadata: { uid: role.uid, name: role.name },
          })),
          inheritedRoles: projectRole.inheritedRoles.map((role) => ({
            metadata: { uid: role.uid, name: role.name },
          })),
        })),
      };
    });
}

function getRolesUids(roles) {
  return roles.map((role) => role.metadata.uid);
}

export function updateData(data) {
  const state = store.getState();
  const selectedRoles = getRolesUids(getSelectedProjectRoles(state));
  const currentEntityType = state.roleManagement.entityType;
  const selectedEntity =
    currentEntityType === "user"
      ? getSelectedUser(state)
      : getSelectedTeam(state);

  // this is necessary because upon editing 1 project role, it is needed to send all projects
  let currentProjectRoles = getCurrentProjectRoles(state).map((projectRole) => {
    return {
      roles: getRolesUids(projectRole.roles),
      projectUid: projectRole.project.metadata.uid,
    };
  });

  if (data.isEdit) {
    const index = currentProjectRoles.findIndex(
      (projectRole) => projectRole.projectUid === data.projectsUids[0]
    );

    currentProjectRoles[index] = {
      roles: selectedRoles,
      projectUid: data.projectsUids[0],
    };
  } else {
    const newProjectRoles = data.projectsUids.map((projectUid) => ({
      roles: selectedRoles,
      projectUid,
    }));

    currentProjectRoles = [...currentProjectRoles, ...newProjectRoles];
  }

  const payload = {
    projects: currentProjectRoles,
  };

  return api.put(
    `v1/${currentEntityType}s/${selectedEntity.metadata.uid}/projects`,
    payload
  );
}

export function onProjectRolesRemove(projectRoleGuid) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentProjectRoles = getCurrentProjectRoles(state);
    const currentEntityType = state.roleManagement.entityType;
    const selectedEntity =
      currentEntityType === "user"
        ? getSelectedUser(state)
        : getSelectedTeam(state);

    const projectRoleIndex = currentProjectRoles.findIndex(
      (projectRole) => projectRole.guid === projectRoleGuid
    );

    const newProjects = currentProjectRoles.map(({ project, roles }) => ({
      projectUid: project.metadata.uid,
      roles: roles.map((role) => role.metadata.uid),
    }));

    newProjects.splice(projectRoleIndex, 1);

    const payload = {
      projects: newProjects,
    };

    const promise = api.put(
      `v1/${currentEntityType}s/${selectedEntity.metadata.uid}/projects`,
      payload
    );

    dispatch({
      type: "REMOVE_PROJECT_ROLES",
      promise,
    });

    try {
      await promise;
      notifications.success({
        message: i18next.t("Role has been removed"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });

      return;
    }

    dispatch(projectRolesListActions.initialize("projectRoles"));
  };
}
