import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { dodgerBlue, silver, red, white } from "utils/constants/colors";

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 16px;
`;

const Wrapper = styled.div`
  margin: 4px;
  padding: 13px 20px;
  background: ${white};
  border-radius: 4px;
  text-transform: capitalize;

  font-size: 13px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  cursor: pointer;
  color: ${dodgerBlue};

  ${(props) =>
    props.hasError &&
    css`
      border: 1px dashed ${red};
      color: ${red};
    `};

  ${(props) =>
    props.disabled &&
    css`
      border: 1px dashed ${silver};
      color: ${silver};
      cursor: not-allowed;
    `};
`;

export default function AddNewItem({
  children,
  disabled = false,
  onClick,
  hasError = false,
  ...rest
}) {
  return (
    <Wrapper
      onClick={!disabled && onClick}
      disabled={disabled}
      hasError={hasError}
      {...rest}
    >
      <StyledIcon icon={faPlusCircle} />
      {children}
    </Wrapper>
  );
}
