import ListActions from "modules/list/actions";

import { StatefulSetSchema } from "utils/schemas";

export const STATEFULSETS_MODULE = "clusterGroupStatefulSets";

export const statefulSetsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    //TODO
    return {
      items: [],
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [StatefulSetSchema],
});
