import styled from "styled-components";
import * as colors from "utils/constants/colors";

const Card = styled.div`
  background: ${colors.white};
  box-sizing: border-box;
  border-radius: 4px;
`;

Card.defaultProps = {
  theme: {
    background: colors.white,
    textColor: "inherit",
  },
};

export default Card;
