import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import TopLayerSVG from "assets/icons/base_layer_pillar.svg?react";
import LayerSVG from "assets/icons/layer_pillar.svg?react";

import { LAYER_TYPES } from "utils/constants";
import { lightMidGray, white, darkBlue } from "utils/constants/colors";
import { SVGLayerIcon } from "components/common/ClusterProfilePreview/SVGLayerIcon";
import { Tooltip } from "antd";

const TopLayerWrap = styled.div`
  display: inline-flex;
  path:nth-child(2) {
    fill: ${(props) => props.color};
  }
`;

const LayerWrap = styled.div`
  display: inline-flex;
  path {
    fill: ${(props) => props.color};
  }
`;

const LayersWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;

  > div,
  svg {
    width: 100%;
    height: 100%;
  }

  > div {
    margin-top: -10%;
  }

  > div:first-child {
    margin-top: 0;
  }

  ${(props) =>
    props.hasSpace &&
    css`
      > div:last-child {
        margin-top: -4%;
      }
    `}
`;

const LayerGrouping = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: -10%;
  }

  > div:first-child {
    margin-top: 0;
  }
`;

const PackName = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 13px;
  line-height: 21px;
  color: ${white};
  opacity: 0.7;
  text-transform: capitalize;
`;

const TopLayer = ({ color = lightMidGray }) => {
  return (
    <TopLayerWrap color={color}>
      <TopLayerSVG />
    </TopLayerWrap>
  );
};

const Layer = ({ color = lightMidGray }) => {
  return (
    <LayerWrap color={color}>
      <LayerSVG />
    </LayerWrap>
  );
};

function renderPacks(pack, index) {
  const layer = getLayer(pack);
  return (
    <PackName key={index} data-qa="cluster-profile-pack" data-qa-index={index}>
      {layer && <SVGLayerIcon path={layer.icon} color={layer.color} />}{" "}
      {pack.displayName || pack.name} {pack.tag || pack.version}
    </PackName>
  );
}
function getLayer(pack = {}) {
  const layerType =
    pack.layer === "addon" ? pack.addonType || pack.type : pack.layer;
  return LAYER_TYPES.find((layer) => layer.type === layerType);
}

function renderPackLayers(item = {}, index) {
  const layer = getLayer(item);
  if (index === 0) {
    return <TopLayer key={item.guid} color={layer?.color || lightMidGray} />;
  }

  return <Layer key={item.guid} color={layer?.color || lightMidGray} />;
}

function renderInfraPackLayers(item = {}) {
  const layer = getLayer(item);
  return <Layer key={item.guid} color={layer?.color || lightMidGray} />;
}

function filterPacks(packs) {
  const { addonPacks, infraPacks } = (packs || []).reduce(
    (accumulator, pack) => {
      pack.layer === "addon"
        ? accumulator.addonPacks.push(pack)
        : accumulator.infraPacks.push(pack);

      return accumulator;
    },
    {
      addonPacks: [],
      infraPacks: [],
    }
  );

  return {
    addonPacks: addonPacks.reverse(),
    infraPacks: infraPacks.reverse(),
  };
}

function Layers({ data, width = 32, ...props }) {
  const { addonPacks, infraPacks } = useMemo(() => {
    return filterPacks(data);
  }, [data]);

  return (
    <LayersWrap width={width} hasSpace={addonPacks.length} {...props}>
      {addonPacks.map(renderPackLayers)}
      <LayerGrouping>
        {infraPacks.map(
          addonPacks.length ? renderInfraPackLayers : renderPackLayers
        )}
      </LayerGrouping>
    </LayersWrap>
  );
}

export default function LayerStack({ data, hasTooltip = true, width = 32 }) {
  let packs = data.spec?.published?.packs || data.spec?.draft?.packs;

  const tooltipPacks = useMemo(() => {
    const { addonPacks, infraPacks } = filterPacks(packs);
    return [...(addonPacks || []), ...(infraPacks || [])];
  }, [packs]);

  if (!hasTooltip) {
    return <Layers width={width} data={packs} />;
  }

  return (
    <Tooltip
      color={darkBlue}
      placement="right"
      title={tooltipPacks.map(renderPacks)}
      overlayClassName="clusterprofile-tooltip"
    >
      <Layers width={width} data={packs} />
    </Tooltip>
  );
}
