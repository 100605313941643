export function presentNamespaces(namespaces = []) {
  return namespaces
    .sort((ns1, ns2) => {
      return ns1.isRegex - ns2.isRegex;
    })
    .reduce((acc, ns, index) => {
      return [
        ...acc,
        {
          ...ns,
          children: (ns.children || []).map((child) => ({
            ...child,
            parentIndex: index,
          })),
        },
      ];
    }, []);
}
