import Modal from "components/ui/Modal";
import styled, { css } from "styled-components";
import { white } from "utils/constants/colors";

const TourModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-content {
    border-radius: 8px;
    // tablet
    @media (max-width: 768px) {
      width: 100vw;
    }

    ${(props) =>
      props.modalBackground &&
      css`
        background-color: ${white};
        background-image: url(${(props) => props.modalBackground});
        background-repeat: no-repeat;
        background-size: cover;
      `}
  }

  .ant-modal-body {
    height: 98vh;
    padding: 0;

    background: transparent !important;

    // tablet
    @media (max-width: 768px) {
      height: 100vh;
      width: 100vw;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }

  .ant-modal-close-x {
    height: 100%;
    width: 100%;
  }
`;
export default TourModal;
