import i18next from "i18next";
import api from "services/api";
import store from "services/store";
import notifications from "services/notifications";
import createFormActions from "modules/form/actions";
import { getCurrentUser } from "state/auth/selectors";
import isEqual from "fast-deep-equal";
import ModalService from "services/modal";

export const RESOURCE_LIMITS_FORM_MODULE = "resourcelimits";
export const discardChangesConfirmation = new ModalService();

export const resourceLimitsFormActions = createFormActions({
  init: async () => {
    try {
      const currentUser = getCurrentUser(store.getState());
      const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

      const response = await api.get(`v1/tenants/${tenantUid}/resourceLimits`);
      return response?.resources || [];
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the resource limits"
        ),
        description: err.message,
      });
    }
  },
  submit: async (data) => {
    try {
      const currentUser = getCurrentUser(store.getState());
      const initialData = store.getState().forms?.resourcelimits?.initialData;
      const tenantUid = currentUser?.metadata?.annotations?.tenantUid;

      const resources = data
        .filter((item, index) => !isEqual(item, initialData[index]))
        .map(({ kind, limit }) => ({
          kind,
          limit,
        }));

      await api.patch(`v1/tenants/${tenantUid}/resourceLimits`, {
        resources,
      });

      notifications.success({
        message: i18next.t("Resource limits have been updated successfully"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Resource limits couldn't be updated"),
        description: err?.message,
      });
    }
  },
});

export function onDiscard() {
  return (dispatch, getState) => {
    const initialData =
      getState().forms[RESOURCE_LIMITS_FORM_MODULE]?.initialData || {};
    discardChangesConfirmation.open().then(() => {
      dispatch(
        resourceLimitsFormActions.batchChange({
          module: RESOURCE_LIMITS_FORM_MODULE,
          updates: {
            ...initialData,
          },
        })
      );
    });
  };
}
