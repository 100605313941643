import { getEntity } from "utils/entities";
import { RoleSchema } from "utils/schemas";
import { createSelector } from "reselect";

export const getSelectedRole = getEntity(
  (state) => state?.roles?.details?.selectedRoleGuid,
  RoleSchema
);

export const getSelectedRolePermissionNames = createSelector(
  getSelectedRole,
  (rolesDetails) =>
    rolesDetails?.spec?.permissions?.map((permission) => permission.name)
);
