import { loadStripe } from "@stripe/stripe-js";

export default class StripeService {
  constructor() {
    this.stripePromise = null;
  }

  get stripe() {
    return this.stripePromise;
  }

  async loadStripe(publicKey) {
    if (this.stripePromise) return;
    this.stripePromise = await loadStripe(publicKey);
  }
}
