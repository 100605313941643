import { combineReducers } from "redux";
import createReducer from "utils/createReducer";

import * as details from "./details";
import * as create from "./create";

export default combineReducers({
  details: createReducer(details),
  create: createReducer(create),
});
