import api from "services/api";
import { SSHKeysSchema } from "utils/schemas";
import ListActions from "modules/list/actions";

export const sshKeysListActions = new ListActions({
  schema: [SSHKeysSchema],
  fetchData() {
    return api.get("v1/users/assets/sshkeys");
  },
  initialQuery() {
    return {
      limit: 50,
    };
  },
});
