import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Fields from "components/ui/Fields";
import { FieldLabel } from "components/styled/Field";

import {
  onArchitectureChange,
  onEdgeHostChange,
} from "state/cluster/actions/create/flows/baremetal";
import { getNicsByDeviceUid } from "state/cluster/selectors/create";
import { ARCHITECTURE_TYPES, APPLIANCES_STATES_MAP } from "utils/constants";
import { createDeviceListingSelection } from "../edge/Devices";

const DevicesWrapper = styled.div`
  margin-top: 12px;
`;

export const DeviceSelection = createDeviceListingSelection({
  name: "cluster-create",
  statusOptions: [APPLIANCES_STATES_MAP.ready, APPLIANCES_STATES_MAP.unpaired],
});
const ConnectedDeviceSelection = connect(
  (state, { poolIndex }) => ({
    nicsByHost: getNicsByDeviceUid(state),
    value: state?.forms?.cluster?.data?.nodePools?.[poolIndex]?.edgeHosts || [],
    devicesInUse: state?.forms?.cluster?.data?.nodePools.reduce(
      (accumulator, pool, index) => {
        if (index === poolIndex) {
          return accumulator;
        }

        return accumulator.concat(pool.edgeHosts.map((host) => host.guid));
      },
      []
    ),
    architecture:
      state?.forms?.cluster?.data?.nodePools?.[poolIndex]?.architecture,
  }),
  (dispatch, { poolIndex }) => ({
    onChange: (value) =>
      dispatch(onEdgeHostChange({ poolIndex, edgeHosts: value })),
  })
)(DeviceSelection.Block);

function EdgeNativeConfigurationFields({
  name,
  index,
  formData,
  onArchitectureChange,
  nodePoolsWrapperRef,
}) {
  const { t } = useTranslation();
  function getName(subField) {
    const currentPath = name ? `${name}.` : "";
    return `${currentPath}${subField}`;
  }

  return (
    <>
      <Fields.Select
        name={`${name}.architecture`}
        label={t("Architecture")}
        data-qa={`${name}.architecture`}
        options={ARCHITECTURE_TYPES}
        onChange={onArchitectureChange.bind(null, index)}
      />
      <DevicesWrapper>
        <Fields.Empty
          label={t("Edge Hosts")}
          description={t(
            "You can use a two node pool configuration in order to reduce costs."
          )}
        />
        <ConnectedDeviceSelection
          name={getName("edgeHosts")}
          poolIndex={index}
          formData={formData}
          nodePoolsWrapperRef={nodePoolsWrapperRef}
          isMasterPool={formData.nodePools?.[index]?.isMaster}
        />
      </DevicesWrapper>
    </>
  );
}

export default connect(null, { onArchitectureChange })(
  EdgeNativeConfigurationFields
);
