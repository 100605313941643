import lscache from "lscache";

export default class LocalStorage {
  constructor(bucket) {
    this._bucket = bucket;
  }

  setBucket() {
    lscache.setBucket(this._bucket);
  }

  get(...args) {
    this.setBucket();
    return lscache.get(...args);
  }

  set(...args) {
    this.setBucket();
    return lscache.set(...args);
  }

  flush() {
    this.setBucket();
    lscache.flush();
  }
}
