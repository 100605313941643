import React from "react";
import createList, { Blocks } from "modules/list";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import {
  VIRTUAL_CLUSTERS_LIST_MODULE,
  virtualClustersListActionsDashboard,
} from "state/cluster/actions/list/nestedclusters";
import { isInitialVirtualClustersListEmpty } from "state/cluster/selectors/list";
import { CLUSTERS, VIRTUAL_CLUSTER } from "utils/constants/routes";
import { ClusterSchema } from "utils/schemas";
import styled from "styled-components";
import { ViewAllRedirectButton } from "./ViewAllRedirectButton";
import { concreteWhite, darkGray, white } from "utils/constants/colors";
import { useClusterColumns } from "components/common/ClustersList";
import history from "services/history";
import { DEV_PREFIX_WITH_PROJECT } from "components/common/History/Route";

const CardBorder = styled.div`
  border: 2px solid ${concreteWhite};
  border-radius: 3px;
  overflow: hidden;
  height: 248px;
  background: #f7f8f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -5px;
`;

const StyledLink = styled.span`
  background-color: ${white};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${concreteWhite};
  width: 100%;
  position: sticky;
  bottom: "-20px";
  z-index: 99;
  font-size: 14px;
  color: ${darkGray};
  line-height: 32px;
`;

const ListModule = createList({
  actions: virtualClustersListActionsDashboard,
  schema: [ClusterSchema],
  parseItems(items = []) {
    return items.slice(0, 5).map((item) => ({
      ...item,
      key: item.guid,
      health: item.status.health?.state,
      metrics: item.status.metrics,
      isVirtual: true,
    }));
  },
});

const VirtualClusterListing = ({ isEmptyPage }) => {
  const { t } = useTranslation();

  const columns = useClusterColumns({
    isVirtualCluster: true,
    isDevMode: true,
    isDashboardColumn: true,
  });

  return (
    <CardBorder>
      <ListModule module={VIRTUAL_CLUSTERS_LIST_MODULE} preventInitOnMount>
        {isEmptyPage ? (
          <Blocks.EmptyResult
            size="medium"
            description={
              <>
                <p>{t("No virtual cluster to show yet.")}</p>
                <p>{t("Add one by clicking New virtual cluster.")}</p>
              </>
            }
          />
        ) : (
          <>
            <Blocks.Table
              data-qa="virtual-clusters-table"
              columns={columns}
              slim
              onRowClick={(row = { metadata: { uid: "" } }) => {
                history.push(
                  generatePath(
                    `${DEV_PREFIX_WITH_PROJECT}${CLUSTERS.TAB_DETAILS}`,
                    {
                      projectUid: row.specSummary.projectMeta.uid,
                      id: row.metadata.uid,
                      tab: "overview",
                      clusterCategory: "clusters",
                    }
                  )
                );
              }}
            />
            <StyledLink>
              <ViewAllRedirectButton
                path={generatePath(VIRTUAL_CLUSTER.ROOT)}
                options={{ tab: "overview" }}
              />
            </StyledLink>
          </>
        )}
      </ListModule>
    </CardBorder>
  );
};

export default connect((state) => ({
  isEmptyPage: isInitialVirtualClustersListEmpty(state),
}))(VirtualClusterListing);
