import ListActions from "modules/list/actions";

import { WorkloadRoleBindingSchema } from "utils/schemas";

export const CLUSTER_ROLE_BINDINGS_MODULE = "clusterGroupClusterRoleBindings";

export const clusterRoleBindingsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    //TODO
    return { items: [] };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [WorkloadRoleBindingSchema],
});
