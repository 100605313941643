const toolbarReducer = {
  name: "toolbar",
  initialState: {
    activeSelection: null,
    tabs: [],
  },
  onTabSelect(state, { tab }) {
    state.activeSelection = tab;
  },
  loadTabs(state, { tabs }) {
    state.tabs = tabs;
  },
};

export default toolbarReducer;
