import React from "react";
import { useTranslation } from "react-i18next";
import ApiKeys from "../settings/apikeys";
import PageHeader from "components/common/layouts/App/PageHeader";

export default function MyApiKeys() {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader title={t("My API Keys")} withBack={false} />
      <ApiKeys />
    </>
  );
}
