import React from "react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import Modal from "components/ui/Modal";
import ProfilesList from "modules/profileDifferentiator/components/ProfilesList";
import ClusterPackDiffsModal from "modules/profileDifferentiator/components/ClusterPackDiffsModal";

import ModalService from "services/modal";
import { profileDifferentiator } from "modules/profileDifferentiator/utils";
import Button, { TextButton } from "components/ui/Button";
import createFormActions from "modules/form/actions";
import store from "services/store";

export function createProfileDiffModule({
  name,
  selectors,
  onReviewComplete,
  onLayerValuesChange,
  reviewAdditions = false,
  ChangeSchema,
} = {}) {
  const { getTargetProfiles, getCurrentProfiles, getValues, getEntities } =
    selectors;

  const diffSummaryModal = new ModalService(`${name}-profileSummaryModal`);
  const diffReviewModal = new ModalService(`${name}-profileReviewModal`);
  const valuesFormModuleName = `${name}-values`;

  const getProfileModuleDifferentiator = createSelector(
    getTargetProfiles,
    getCurrentProfiles,
    getValues,
    getEntities,
    (targetProfiles, currentProfiles, values, entities) => {
      const diffManager = profileDifferentiator.getPackChanges({
        targetProfiles: targetProfiles || [],
        currentProfiles: currentProfiles || [],
        entities: entities || {},
        values: values || {},
        ...(ChangeSchema && { ChangeSchema }),
      });

      return diffManager;
    }
  );

  const getModuleState = createSelector(
    (state) => state.profileDifferentiator?.[name],
    (moduleState) => {
      return moduleState;
    }
  );

  const areModalOpened = createSelector(
    () => diffSummaryModal.isOpened(),
    () => diffReviewModal.isOpened(),
    (summaryOpened, reviewOpened) => summaryOpened || reviewOpened
  );

  const getSnapshot = createSelector(
    getModuleState,
    getProfileModuleDifferentiator,
    areModalOpened,
    (moduleState, differ, areModalOpened) => {
      if (!areModalOpened) {
        return differ;
      }
      return moduleState?.diffSnapshot;
    }
  );

  const getPacksChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      return moduleDifferentiator.denormalized;
    }
  );

  const getManifestsUnknownChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      return moduleDifferentiator.unknownManifests;
    }
  );

  const hasUnknownManifestChanges = createSelector(
    getManifestsUnknownChanges,
    (unknownManifests) => {
      return !isEmpty(unknownManifests);
    }
  );

  const getAllProfileChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      return moduleDifferentiator.groupedChanges;
    }
  );

  const getFilteredProfileChanges = createSelector(
    getSnapshot,
    (moduleDifferentiator) => {
      if (reviewAdditions) {
        return moduleDifferentiator.updatesAndAdditionsOnly;
      }
      return moduleDifferentiator.updatesOnly;
    }
  );

  function toggleExpandedProfiles(profileGuids) {
    return function thunk(dispatch) {
      dispatch({
        type: "DIFF_VIEWER_UPDATE_EXPANDED",
        profileGuids,
        module: name,
      });
    };
  }

  const valuesFormActions = createFormActions({
    async init() {
      const profileDiffs = getFilteredProfileChanges(store.getState());
      const packChanges = (profileDiffs || []).flatMap(
        (profile) => profile.packs
      );

      return packChanges.reduce((acc, change) => {
        const manifestChanges = change.manifests || [];
        const manifestValues = manifestChanges.reduce((acc, change) => {
          return {
            ...acc,
            [change.target.manifest.guid]: change.target.values || "",
          };
        }, {});

        return {
          ...acc,
          ...manifestValues,
          [change.target.pack.guid]: change.target.values || "",
        };
      }, {});
    },
  });

  function onSelectDiffViewerLayer(layerGuid, profileGuid) {
    return function thunk(dispatch) {
      dispatch({
        type: "DIFF_VIEWER_SELECT_LAYER",
        layerGuid,
        profileGuid,
        module: name,
      });
    };
  }

  function onValuesChange(values) {
    return function thunk(dispatch, getState) {
      const selectedLayerGuid = getModuleState(getState())?.selectedLayer;
      if (onLayerValuesChange) {
        return dispatch(onLayerValuesChange(selectedLayerGuid, values));
      }
    };
  }

  function toggleLayers({ profilesDiffs, isSummary = false }) {
    return function thunk(dispatch) {
      if (isSummary) {
        const profileGuids = profilesDiffs.map(
          (profileDiff) => profileDiff?.profile?.guid
        );
        return dispatch(toggleExpandedProfiles(profileGuids));
      }

      const { profile, packs } = profilesDiffs?.[0] || {};
      const { target, current } = packs?.[0] || {};
      const packGuid = target?.pack?.guid || current?.pack?.guid;

      dispatch(toggleExpandedProfiles([profile?.guid]));
      dispatch(onSelectDiffViewerLayer(packGuid, profile?.guid));
    };
  }

  function openDiffSummaryModal(...args) {
    return function thunk(dispatch, getState) {
      const differentiator = getProfileModuleDifferentiator(getState());
      dispatch({
        type: "DIFF_VIEWER_SET_DIF_SNAPSHOT",
        snapshot: differentiator.clone(),
        module: name,
      });
      diffSummaryModal.open(...args).then((modalData) => {
        const profilesDiffs = getFilteredProfileChanges(getState());
        if (!profilesDiffs.length) {
          if (onReviewComplete) {
            return dispatch(onReviewComplete(modalData));
          }
          return;
        }
        dispatch(toggleLayers({ profilesDiffs }));
        dispatch(valuesFormActions.init({ module: valuesFormModuleName }));
        return diffReviewModal.open(...args).then((modalData) => {
          if (onReviewComplete) {
            const values = getState().forms?.[valuesFormModuleName]?.data || {};
            if (onLayerValuesChange) {
              Object.keys(values).forEach((layerGuid) => {
                dispatch(onLayerValuesChange(layerGuid, values[layerGuid]));
              });
            }
            return dispatch(onReviewComplete(modalData));
          }
        });
      });

      const profilesDiffs = getAllProfileChanges(getState());
      dispatch(toggleLayers({ profilesDiffs, isSummary: true }));
    };
  }

  const ConnectedProfileList = connect(
    (state) => ({
      profilesDiffs: getAllProfileChanges(state),
      expanded: getModuleState(state)?.expanded,
    }),
    {
      onSelectDiffViewerLayer,
      toggleExpandedProfiles,
    }
  )(ProfilesList);

  function ProfileDiffsSummaryModal({
    withReviewStep,
    title,
    canUpdateLater = false,
    packsUpdatesMessages = {},
  }) {
    const { t } = useTranslation();
    const confirmLabel = withReviewStep
      ? t("Review changes in Editor")
      : t("Apply Changes");

    function renderFooter() {
      return (
        <div>
          <TextButton
            data-qa="cancel-action"
            onClick={() => {
              diffSummaryModal.reject();
            }}
          >
            {t("Cancel")}
          </TextButton>
          <Button
            data-qa="apply-changes"
            onClick={() => diffSummaryModal.resolve()}
          >
            {confirmLabel}
          </Button>
          {canUpdateLater && !withReviewStep && (
            <Button
              data-qa="update-profile-later"
              onClick={() => diffSummaryModal.resolve({ updateLater: true })}
            >
              {t("Apply Changes Later")}
            </Button>
          )}
        </div>
      );
    }

    return (
      <Modal
        width="600px"
        title={title || t("Changes Summary")}
        service={diffSummaryModal}
        footer={renderFooter()}
      >
        <span>Upcoming changes in the new profile configuration:</span>
        <ConnectedProfileList
          withSummary={true}
          packsUpdatesMessages={packsUpdatesMessages}
        />
      </Modal>
    );
  }

  const ConnectedProfileDiffsSummaryModal = connect((state) => ({
    withReviewStep: !isEmpty(getFilteredProfileChanges(state)),
  }))(ProfileDiffsSummaryModal);

  const ProfileDiffsReviewModal = connect(
    (state) => ({
      selectedLayerGuid: getModuleState(state)?.selectedLayer,
      selectedProfileGuid: getModuleState(state)?.selectedProfile,
      profilesDiffs: getFilteredProfileChanges(state),
      expanded: getModuleState(state)?.expanded,
      modalService: diffReviewModal,
      modalOpened: diffReviewModal.isOpened(),
      valuesForm: {
        actions: valuesFormActions,
        name: valuesFormModuleName,
      },
    }),
    {
      onSelectDiffViewerLayer,
      toggleExpandedProfiles,
      onValuesChange,
    }
  )(ClusterPackDiffsModal);

  return {
    selectors: {
      getSnapshot,
      getPacksChanges,
      getManifestsUnknownChanges,
      hasUnknownManifestChanges,
      getAllProfileChanges,
      getFilteredProfileChanges,
      getModuleState,
    },
    actions: {
      openDiffSummaryModal,
    },
    services: {
      diffSummaryModal,
      diffReviewModal,
    },
    Block({
      isSubmitting = false,
      isRepaveRequired = false,
      readOnly = false,
      cloudType,
      title,
      canUpdateLater = false,
      packsUpdatesMessages = {},
      originalValues = {},
    }) {
      return (
        <>
          <ConnectedProfileDiffsSummaryModal
            title={title}
            canUpdateLater={canUpdateLater}
            packsUpdatesMessages={packsUpdatesMessages}
          />
          <ProfileDiffsReviewModal
            isSubmitting={isSubmitting}
            isRepaveRequired={isRepaveRequired}
            readOnly={readOnly}
            title={title}
            cloudType={cloudType}
            canUpdateLater={canUpdateLater}
            originalValues={originalValues}
          />
        </>
      );
    },
  };
}
