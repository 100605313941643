import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import history from "services/history";

import { getCluster } from "state/cluster/selectors/details";

import { getArrayFromQuery } from "utils/parsers";
import { PodSchema } from "utils/schemas";

export const PODS_MODULE = "pods";

export const podsListingActions = new ListActions({
  async fetchData({ filterBy = [] } = {}) {
    const cluster = getCluster(store.getState());

    const data = await api.post(
      `v1/dashboard/spectroclusters/${cluster.metadata.uid}/workloads/pod`,
      {
        filter: {
          namespaces: filterBy,
        },
      }
    );

    return {
      items: (data?.pods || [])
        .map((item) => ({
          ...item,
          status: {
            ...item.status,
            containers: (item.status?.containers || []).map(
              (statusContainer) => ({
                ...statusContainer,
                resources: (item.spec?.containers || []).find(
                  (container) => container.name === statusContainer.name
                )?.resources,
              })
            ),
          },
        }))
        .sort((pod1, pod2) =>
          pod1.metadata.namespace > pod2.metadata.namespace ? 1 : -1
        ),
    };
  },
  initialQuery: () => {
    const query = history.getQuery();
    const filterBy = getArrayFromQuery(query?.namespaces);

    return {
      filterBy,
    };
  },
  schema: [PodSchema],
});
