import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { REGISTRY_SCOPE_SPECIFICITY } from "utils/constants";

export function getLatestVersionRegistry(registries) {
  const sortedRegistries = registries
    .filter((registry) => !!registry?.uid)
    .sort((a, b) => {
      return (
        REGISTRY_SCOPE_SPECIFICITY[b?.scope] -
        REGISTRY_SCOPE_SPECIFICITY[a?.scope]
      );
    });

  return sortedRegistries?.[0];
}

export const containersFetcher = dataFetcher({
  selectors: ["app-builder", "containers"],
  async fetchData() {
    const response = await api.post("v1/packs/search?limit=100", {
      filter: {
        type: ["spectro", "oci"],
        layer: ["addon"],
        environment: ["all"],
        addOnType: ["container"],
        registryUid: [],
      },
      sort: [
        {
          field: "displayName",
          order: "asc",
        },
      ],
    });

    const output = [];

    const items = response.items.map(async (item, index) => {
      const registryUid = getLatestVersionRegistry(item.spec.registries)?.uid;

      if (!registryUid) {
        return item;
      }

      const pack = await api.get(
        `v1/packs/${item.spec.name}/registries/${registryUid}`
      );

      const latestPackVersion = (pack?.tags || []).find(
        (pack) => !pack.parentTags || !pack.parentTags?.length
      );

      const latestPackValues = pack.packValues.find(
        (packValue) => packValue.packUid === latestPackVersion.packUid
      );

      output.push({
        ...item.spec,
        ...latestPackValues,
        packUid: latestPackVersion.packUid,
        version: latestPackVersion.version,
      });

      return pack;
    });

    await Promise.allSettled(items);

    return output;
  },
});
